import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import saIdParser from "south-african-id-parser";
import { AuthContext } from "../context/AuthContext";
import { setDataError } from "../redux/actions/DataErrorActions";
import {
  createPrincipalMember
} from "../redux/actions/PrincipalMemberAction";
import { IPrincipalMemember } from "../redux/types/principalMember";

interface modelProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const PrincipalMember: React.FC<modelProps> = (modelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [idNumberError, setIdNumberError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const { user } = useContext(AuthContext);
  const dataError = useSelector((state: any) => state.dataError);

  const [inputValues, setInputValues] = useState({
    assessor: user?.id,
    full_names: "",
    surname: "",
    country: "none",
    id_number: "",
    passport: "",
    date_of_birth: "",
    gender: "",
    residence_country: "",
    birth_country: "",
    risk_status: "",
    termination_date: "",
    contact_number: "",
    address: "",
  });

  const handleOk = () => {
    const model: IPrincipalMemember = {
      assessor: inputValues.assessor,
      full_names: inputValues.full_names,
      surname: inputValues.surname,
      country: "none",
      residence_country: inputValues.residence_country,
      id_number: inputValues.id_number,
      passport: inputValues.passport,
      date_of_birth: inputValues.date_of_birth,
      gender: inputValues.gender,
      birth_country: inputValues.birth_country,
      risk_status: inputValues.risk_status,
      termination_date: inputValues.termination_date,
      contact_number: inputValues.contact_number,
      address: inputValues.address,
    };

    dispatch(createPrincipalMember(model));

    setInputValues({
      full_names: "",
      surname: "",
      country: "none",
      id_number: "",
      passport: "",
      date_of_birth: "",
      gender: "",
      residence_country: "",
      birth_country: "",
      risk_status: "",
      termination_date: "",
      contact_number: "",
      address: "",
      assessor: user?.id,
    });
  };

  const handleCancel = () => {
    modelProps.setIsModalOpen(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    modelProps.setIsModalOpen(false);
    handleOk();
  };

  useEffect(() => {
    if (inputValues.id_number.trim().length === 13) {
      const isValid = saIdParser.validate(inputValues.id_number);
      if (!isValid) {
        dispatch(
          setDataError([
            ...dataError,
            { idNumber: "Principal Member Form ID number is not valid" },
          ])
        );
      }
    }
  }, [inputValues.id_number, dispatch]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    // Validate ID number input to accept only numbers and 13 characters
    if (name === "id_number") {
      const onlyNumbers = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      const limitedValue = onlyNumbers.substr(0, 13); // Limit to 13 characters

      // Check if the ID number is exactly 13 characters
      if (limitedValue.length !== 13) {
        setIdNumberError("ID number must be exactly 13 characters.");
      } else {
        if (!saIdParser.validate(value)) {
          setIdNumberError("ID number is not valid");
        } else {
          setIdNumberError(""); // Clear the error message if the ID number is valid
        }
      }

      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: limitedValue,
      }));
    } else if (name === "contact_number") {
      let onlyNumbers = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      let limitedValue = onlyNumbers.substr(0, 10);
      if (limitedValue.length !== 10) {
        setPhoneNumberError("Phone number must be exactly 10 digits");
      } else {
        setPhoneNumberError("");
      }
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: limitedValue,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    modelProps.setIsModalOpen(modelProps.isModalOpen);
  }, [modelProps.isModalOpen]);

  return (
    <>
      <Modal
        open={modelProps.isModalOpen}
        onCancel={handleCancel}
        okText="Save"
        onOk={handleOk}
      >
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add a new principal member</h5>
            <p></p>

            <form className="row g-3" id="member-form" onSubmit={handleSubmit}>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Full Name(s)</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="floatingfullnames"
                    name="full_names"
                    value={inputValues.full_names}
                    placeholder="Full Name(s)"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Surname</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="floatingPassword"
                    name="surname"
                    value={inputValues.surname}
                    placeholder="Surname"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="country">ID Type</label>
                  <select
                    className="form-control"
                    name="country"
                    id="country"
                    value={inputValues.country}
                    onChange={handleInputChange}
                  >
                    <option value="none">--------</option>
                    <option value="1">RSA ID</option>
                    <option value="2">Passport</option>
                  </select>
                </div>
              </div>

              <div
                className={`col-md-4 ${idNumberError ? "has-error" : ""}`}
                id="rsa-id-type"
                style={{
                  display: inputValues.country === "1" ? "block" : "none",
                }}
              >
                <div className="form-group">
                  <label>ID Number</label>
                  <input
                    type="number"
                    value={inputValues.id_number}
                    required
                    className="form-control"
                    id="floatingID"
                    name="id_number"
                    placeholder="ID Number"
                    onChange={handleInputChange}
                  />
                  {idNumberError && (
                    <span style={{ color: "red" }} className="help-block">
                      {idNumberError}
                    </span>
                  )}
                </div>
              </div>

              {inputValues.country === "2" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Passport</label>
                    <input
                      type="text"
                      value={inputValues.passport}
                      required
                      className="form-control"
                      id="floatingpass"
                      name="passport"
                      placeholder="Passport"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              {inputValues.country !== "1" && (
                <>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        value={inputValues.date_of_birth}
                        required
                        className="form-control"
                        id="floatingdateob"
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="floatingGender">Gender</label>
                      <select
                        className="form-control"
                        id="floatingGender"
                        name="gender"
                        value={inputValues.gender}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          ---------
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="id_birth_country">Country of Birth</label>
                  <select
                    name="birth_country"
                    className="form-control"
                    id="id_birth_country"
                    value={inputValues.birth_country}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Please select...
                    </option>
                    <option value="ZA">South Africa</option>
                    <option value="BW">Botswana</option>
                    <option value="LS">Lesotho</option>
                    <option value="MW">Malawi</option>
                    <option value="MZ">Mozambique</option>
                    <option value="NA">Namibia</option>
                    <option value="SZ">Eswatini</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="residence_country">Residence Country</label>
                  <select
                    name="residence_country"
                    className="form-control"
                    id="residence_country"
                    value={inputValues.residence_country}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Please select...
                    </option>
                    <option value="ZA">South Africa</option>
                    <option value="BW">Botswana</option>
                    <option value="LS">Lesotho</option>
                    <option value="MW">Malawi</option>
                    <option value="MZ">Mozambique</option>
                    <option value="NA">Namibia</option>
                    <option value="SZ">Eswatini</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="floatingRisk">Risk Status</label>
                  <select
                    className="form-control"
                    id="floatingRisk"
                    name="risk_status"
                    value={inputValues.risk_status}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      ---------
                    </option>
                    <option value="Active (In-force)">Active (In-force)</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
              </div>

              {inputValues.risk_status === "Inactive" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Termination Date</label>
                    <input
                      type="date"
                      required
                      className="form-control"
                      id="floatingtermdate"
                      name="termination_date"
                      value={inputValues.termination_date}
                      placeholder="Termination Date"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-4">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="floatingcontact"
                    name="contact_number"
                    value={inputValues.contact_number}
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    title="Phone number must be 10 digits"
                  />
                  {phoneNumberError && (
                    <span style={{ color: "red" }} className="help-block">
                      {phoneNumberError}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>Home Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="floatingaddress"
                    value={inputValues.address}
                    name="address"
                    placeholder="Home Address"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PrincipalMember;
