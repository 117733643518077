import React from "react";
import Header from "../Dashboard/header";
import HeldClaimsTable from "./components/HeldClaims";

const HeldClaimsPage: React.FC<any> = () => {
  return (
    <>
      <Header title="Held Claims">
        <HeldClaimsTable />
      </Header>
    </>
  );
};

export default HeldClaimsPage;
