import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  Form,
  Flex,
  TablePaginationConfig,
  Select,
} from "antd";
import instance from "../../axios/axios-instance";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import TextArea from "antd/es/input/TextArea";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface Scheme {
  id: number;
  scheme_name: string;
  scheme_number: string;
}

function SchemesTable() {
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState<number>(0)
  const [filteredData, setFilteredData] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editSchemeForm] = Form.useForm();
  const [selectedScheme, setSelectedScheme] = useState<Scheme>();
  const [query, setQuery] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [insurers, setInsurers] = useState<any[]>([]);
  const [schemeStatuses, setSchemeStatuses] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [page_size, setPage_size] = useState(5);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    const fetchInsurers = async () => {
      const response = await instance.get("/api/administration/insurers");
      setInsurers(
        response?.data.map((item: any) => {
          return { label: item.insurer_name, value: item.id };
        })
      );
    };
    fetchInsurers();
  }, []);

  useEffect(() => {
    const fetchSchemeStatuses = async () => {
      const response = await instance.get("/api/administration/scheme-status");
      setSchemeStatuses(
        response?.data.map((item: any) => {
          return { label: item.scheme_status, value: item.id };
        })
      );
    };
    fetchSchemeStatuses();
  }, []);

  const fetchSchemes = async () => {
    const response = await instance.get(`/api/administration/underwriters?search=${searchKey}&page=${currentPage}&page_size=${page_size}`);
    setData(response?.data?.data);
    setTotalItems(response?.data.total_items);
    setFilteredData(response?.data?.data);
  };

  useEffect(() => {
    fetchSchemes();
  }, [searchKey, currentPage, page_size]);

  const performSearch = () => {
    setSearchKey(query);
    // const filteredData = data.filter((scheme: Scheme) =>
    //   scheme.scheme_name.toLowerCase().includes(query.toLowerCase())
    // );
    // setFilteredData(filteredData);
  };

  const resetSearch = () => {
    setQuery("");
    setSearchKey("");
    setFilteredData(data);
  };

  const createNewScheme = async () => {
    const response = await instance.post("/api/administration/underwriters", {
      ...editSchemeForm.getFieldsValue(),
    });
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Scheme added"));
      fetchSchemes();
    }
    setCreateModalOpen(false);
  };

  const editScheme = async () => {
    const response = await instance.put(
      `/api/administration/underwriters?id=${selectedScheme?.id}`,
      {
        ...editSchemeForm.getFieldsValue(),
      }
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Scheme Updated"));
      fetchSchemes();
    }
    setEditModalOpen(false);
  };

  const handleDetails = (record: any) => {
    editSchemeForm.setFieldsValue({
      scheme_name: record.scheme_name,
      scheme_number: record.scheme_number,
      insurer_name: record.insurer_name,
      scheme_status: record.scheme_status,
      scheme_comments: record.scheme_comments,
      scheme_lapse_rule_death: record.scheme_lapse_rule_death,
      scheme_lapse_rule_number_of_non_payments: record.scheme_lapse_rule_number_of_non_payments
    });
    setSelectedScheme(record);
    setEditModalOpen(true);
  };

  function FormIsValid() {
    const data = editSchemeForm.getFieldsValue();
    return data.scheme_name && data.scheme_number;
  }

  const columns: any[] = [
    {
      title: "Scheme Name",
      dataIndex: "scheme_name",
      key: "scheme_name",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Scheme Number",
      dataIndex: "scheme_number",
      key: "scheme_number",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Insurer",
      dataIndex: "insurer_name",
      key: "insurer_name",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Scheme Status",
      dataIndex: "scheme_status",
      key: "scheme_status",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Lapse-Death",
      dataIndex: "scheme_lapse_rule_death",
      key: "scheme_lapse_rule_death",
      width: 150,
      editable: true,
      fixed: "left",
      render: (text: string, record: any) => <div>{String(record.scheme_lapse_rule_death)}</div>
    },
    {
      title: "Lapse Number of Non-payments",
      dataIndex: "scheme_lapse_rule_number_of_non_payments",
      key: "scheme_lapse_rule_number_of_non_payments",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (record: any) => (
        <Space size="small" style={{ minWidth: "112px" }}>
          <Button onClick={() => handleDetails(record)}>Details</Button>
        </Space>
      ),
    },
  ];


  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  console.log("scheme form", editSchemeForm.getFieldsValue());
  console.log("data", filteredData);

  return (
    <Space direction="vertical">
      <Flex justify="space-between">
        <Space>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value.trim())}
            placeholder="Enter search to begin"
          ></Input>
          <Button disabled={!query} onClick={() => performSearch()}>
            Search
          </Button>
          <Button
            disabled={!query.trim() || filteredData?.length === 0}
            onClick={() => resetSearch()}
          >
            Reset
          </Button>
        </Space>
        <Space align="start" direction="horizontal">
          <Button
            type="primary"
            onClick={() => {
              editSchemeForm.resetFields();
              setCreateModalOpen(true);
            }}
          >
            Add Scheme
          </Button>
        </Space>
      </Flex>
      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        scroll={{ y: windowHeight - 420 }}
        style={{ width: 1100 }}
        onChange={(config: TablePaginationConfig) => {
          setPage_size(config.pageSize!);
          setCurrentPage(config.current!);
        }}
        pagination={{
          total: totalItems,
          showTotal: (total) => `${total} rows`,
          pageSize: page_size,
          current: currentPage,
          showSizeChanger: true,
          pageSizeOptions: ['5','10', '20', '50', '100'],
        }}
      />
      <Modal
        title="Create a Scheme"
        open={isCreateModalOpen}
        onOk={createNewScheme}
        okText="Save"
        /* okButtonProps={{ disabled: !FormIsValid() }} */
        onCancel={() => {
          setCreateModalOpen(false);
        }}
      >
        <Form layout="vertical" form={editSchemeForm}>
          <Form.Item label="Insurer" name="insurer_name">
            <Select options={insurers} />
          </Form.Item>
          <Form.Item label="Scheme Name" name="scheme_name">
            <Input />
          </Form.Item>
          <Form.Item label="Scheme Status" name="scheme_status">
            <Select options={schemeStatuses} />
          </Form.Item>
          <Form.Item label="Scheme Number" name="scheme_number">
            <Input />
          </Form.Item>
          <Form.Item label="Scheme Comments" name="scheme_comments">
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Scheme"
        open={isEditModalOpen}
        onOk={editScheme}
        okText="Update"
        /* okButtonProps={{ disabled: !FormIsValid() }} */
        onCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <Form layout="vertical" form={editSchemeForm}>
          <Form.Item label="Insurer" name="insurer_name">
            <Select options={insurers} />
          </Form.Item>
          <Form.Item label="Scheme Name" name="scheme_name">
            <Input />
          </Form.Item>
          <Form.Item label="Scheme Status" name="scheme_status">
            <Select options={schemeStatuses} />
          </Form.Item>
          <Form.Item label="Scheme Number" name="scheme_number">
            <Input />
          </Form.Item>
          <Form.Item label="Scheme Comments" name="scheme_comments">
            <TextArea />
          </Form.Item>
          <Form.Item label="Lapse-Death" name="scheme_lapse_rule_death">
            <Select options={[{ label: "true", value: true }, { label: "false", value: false }]} />
          </Form.Item>
          <Form.Item label="Lapse No. of Non-payments" name="scheme_lapse_rule_number_of_non_payments">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
}

export default SchemesTable;
