// actions.ts


// ActionTypes.ts
export const SET_DATA_ERROR = 'SET_DATA_ERROR';
export const CLEAR_DATA_ERROR = 'CLEAR_DATA_ERROR';


export const setDataError = (error: any) => {
  return {
    type: SET_DATA_ERROR,
    payload: error,
  };
};

export const clearDataError = () => {
  return {
    type: CLEAR_DATA_ERROR,
  };
};
