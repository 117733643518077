import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Layout,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "./header.css";
import { LogoutOutlined } from "@ant-design/icons";

interface HeaderProps {
  children: React.ReactNode;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ children, title }) => {
  const navigate = useNavigate();
  const { user, logoutUser } = useContext(AuthContext);
  const location = useLocation();

  interface NavButtonProps {
    children: React.ReactNode;
    url: string;
    visible: boolean;
  }

  function NavButton(props: NavButtonProps) {
    const { url, children, visible } = props;
    const path = location.pathname;
    const isActive = url === path;
    return visible ? (
      <Button
        style={{ color: "white", fontWeight: isActive ? "800" : "400" }}
        type={isActive ? "primary" : "link"}
        onClick={() => {
          window.location.href = url;

          // BUG in claim/capture. Claims not resetting
          // navigate(url);
        }}
      >
        {children}
      </Button>
    ) : null;
  }

  if (user === null) navigate("/login");

  function canViewCapture() {
    return (
      user!.is_claim_assessor || user!.is_claim_manager || user!.is_manager
    );
  }

  function canViewClaims() {
    return user!.is_claim_assessor;
  }

  function canReviewClaims() {
    return user!.is_claim_manager || user!.is_manager;
  }

  function canViewAwaitingPayments() {
    return user!.is_manager || user!.is_claim_manager || user!.is_finance;
  }

  function canViewHeldClaims() {
    return user!.is_manager || user!.is_claim_manager || user!.is_finance;
  }

  function canViewInsights() {
    return true;
  }

  function canViewQuote() {
    return (
      user!.is_manager || user!.is_sales_person || user!.is_data_administrator
    );
  }

  const { name, surname } = user!;
  const initials = `${name[0]}${surname[0]}`;
  return (
    <Layout style={{ height: "100%", display: "flex" }}>
      <Layout.Header>
        <Flex justify="space-between" align="center">
          <Button type="link" onClick={() => navigate("/")}>
            <Typography.Title
              level={4}
              style={{ marginBottom: 0, color: "white" }}
            >
              Structured Risk Solutions
            </Typography.Title>
          </Button>
          <Space style={{ flex: "1", padding: "0 16px" }} size="small">
            <NavButton url="/capture-claims" visible={canViewCapture()}>
              Capture
            </NavButton>
            <NavButton url="/view-claims" visible={canViewClaims()}>
              View
            </NavButton>
            <NavButton url="/review-claims" visible={canReviewClaims()}>
              Review
            </NavButton>
            <NavButton
              url="/awaiting-payments"
              visible={canViewAwaitingPayments()}
            >
              Awaiting Payments
            </NavButton>
            <NavButton url="/held-claims" visible={canViewHeldClaims()}>
              Held Claims
            </NavButton>
            <NavButton url="/insights" visible={canViewInsights()}>
              Insights
            </NavButton>
            <NavButton url="/generate-quote" visible={canViewQuote()}>
              Quotes
            </NavButton>
            <NavButton
              url="/policy-admin"
              visible={canViewQuote()}
            >
              Policy Admin
            </NavButton>
            <NavButton
              url="/administration"
              visible={canViewQuote()}
            >
              Administration
            </NavButton>
          </Space>
          <Dropdown
            menu={{
              items: [
                {
                  key: "logout",
                  label: "Logout",
                  icon: <LogoutOutlined />,
                  onClick: logoutUser,
                },
              ],
            }}
          >
            <Button size="large" type="primary">
              <Avatar size="small">{initials}</Avatar>
            </Button>
          </Dropdown>
        </Flex>
      </Layout.Header>
      <Layout style={{ flex: 1, overflowY: "auto" }}>
        <Content
          style={{
            marginTop: 16,
            padding: "0px 50px",
            minHeight: "calc(100vh - 80px)",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Header;