/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Table,
  Form,
  Button,
  Upload,
  UploadFile,
  Modal,
  Space,
  DatePicker,
  Flex,
  Select
} from "antd";
// import ExcelJS from "exceljs";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import instance from "../axios/axios-instance";
import { setSuccessMessage } from "../redux/actions/AppMessageActions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useParams } from "react-router-dom";
import Header from "../Dashboard/header";

interface DataItem {
  billDate: string;
  downloadLink: string;
  key: string;
  billLink: string;
  paymentPeriod: string;
  billedAmount: string;
  paidAmount?: string; // Changed to optional
  payOverListFileUrl?: string;
}

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const CollectionAnalysis = () => {
  const [form] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const [uploadFile, setUploadFile] = useState<UploadFile | null>(null);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [selctedRow, setSelectedRow] = useState(null);
  const [selectedRowUpload, setSelectedRowUpload] = useState<any>(null)
  const [isPremiumModalOpen, setPremiumModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { memberId } = useParams();
  const [collectionData, setCollectionData] = useState<DataItem[]>([]);
  const [premiumRecForm] = Form.useForm();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/api/policies/premium-collection/");
        if (response?.status >= 200 && response?.status < 300) {
          setCollectionData(response?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [dispatch, memberId]);


  const handlePayOverListUpload = async () => {
    const { collection_period, billing_period } = uploadForm.getFieldsValue()
    const form = new FormData();
    form.append("file", uploadFile as any);
    form.append("billing_period", billing_period.format("YYYY-MM-DD"));
    form.append("collection_period", collection_period.format("YYYY-MM-DD"));
    form.append("scheme_id", memberId as any);

    try {
      const response = await instance.post("/api/policies/premium-collection/", form, {
        headers: {
          "Content-Disposition": `attachment; filename="${uploadFile ? uploadFile.name : "upload-file.xlsx"
            }"`,
        },
      });

      if (response?.status >= 200 && response?.status < 300) {
        dispatch(setSuccessMessage("Successful"));
        // Update collectionData state with new data
        setCollectionData(prevState => [...prevState, response.data]);
      }
    } catch (e) {
      console.log(e);
    }

    setUploadModalOpen(false);
  };

  function downloadCSVFile(filename: string, contents: string) {
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
  }


  async function handleBillDonwload(record: any) {
    try {
      const response = await instance.get(`api/policies/endpoint?scheme_id=${memberId}&billing_date=${record?.billing_period}&output=bill`);

      if (response.statusText === "OK") {
        downloadCSVFile(
          response.data?.file_name,
          response.data[response.data?.file_name].join("")
        );
      }
    } catch (e) {
      console.log(e);
    }
  }


  const handlePayOverListDownload = async (record: any) => {
    try {
      const response = await instance.get(`api/policies/download-billing-data/?scheme_id=${memberId}&billing_date=${record?.billing_period}&output=pay-overlist`);

      if (response.statusText === "OK") {
        downloadCSVFile(
          response.data?.file_name,
          response.data[response.data?.file_name].join("")
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      title: "Billing Period",
      dataIndex: "billing_period",
      key: "billing_period",
    },
    {
      title: "Collection Period",
      dataIndex: "collection_period",
      key: "collection_period",
    },
    // {
    //   title: "Bill",
    //   dataIndex: "downloadLink",
    //   key: "downloadLink",
    //   render: (_: string, record: DataItem) => (
    //     <Button
    //       type="primary"
    //       onClick={() => handleBillDonwload(record)}
    //     >
    //       Download
    //     </Button>
    //   ),
    // },

    {
      title: "Pay-over-list",
      dataIndex: "payOverList",
      key: "payOverList",
      render: (_: string, record: DataItem) => (
        <div>
          {record.payOverListFileUrl ? (
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() =>
                handlePayOverListDownload(record?.payOverListFileUrl)
              }
            >
              Download Pay-over-list
            </Button>
          ) : null}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (record: any) => (
        <Space direction="vertical" style={{ minWidth: "112px" }}>
          <Button onClick={() => handleBillDonwload(record)} >Download Bill</Button>
          <Button onClick={() => handlePayOverListDownload(record)}
          >
            Download Pay-over-list
          </Button>
        </Space>
      ),
    },
  ];


  function uploadFormIsValid() {
    const data = uploadForm.getFieldsValue();
    return data.file;
  }



  const handlePremRecSubmit = async () => {
    const { billing_date_start, billing_date_end, collection_date_start, collection_date_end, analysis_level } = premiumRecForm.getFieldsValue();
    try {
      const response = await instance.get("/api/policies/collection-analysis", {
        billing_start_date: billing_date_start.format("YYYY-MM-DD"),
        billing_end_date: billing_date_end.format("YYYY-MM-DD"),
        collection_start_date: collection_date_start.format("YYYY-MM-DD"),
        collection_end_date: collection_date_end.format("YYYY-MM-DD"),
        scheme_id: memberId,
        analysis_level
      } as any);

      if (response.statusText === "OK") {
        downloadCSVFile(
          response.data.file_name,
          response.data[response.data.file_name].join("")
        );
      }
    } catch (e) {
      console.log(e);
    }

    setPremiumModalOpen(false);
  };



  return (
    <Header title="Collection Analysis">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 30,
          height: "calc(100vh - 140px)", // Set the height of the container to the viewport height minus the total vertical space occupied by the Header component
          overflow: "hidden", // Hide the scrollbar of the page
        }}
      >
        <div>
          <h2 style={{ marginBottom: "3rem" }}>Collection Analysis</h2>

          <div style={{ marginTop: "2rem", marginBottom: "2rem" }} >

            <Flex>
              <Space size="large" >
                <Button
                  type="primary"
                  onClick={() => {
                    setPremiumModalOpen(true);
                  }}
                >
                  Premium Reconcillation
                </Button>

                <Button onClick={() => {
                  // setSelectedRowUpload(record)
                  setUploadModalOpen(true)
                }}>
                  Upload
                </Button>

              </Space>
            </Flex>



          </div>
          <Table dataSource={collectionData} columns={columns} pagination={false} />

          <Modal
            title="Upload PayOverList"
            open={isUploadModalOpen}
            onOk={handlePayOverListUpload}
            onCancel={() => {
              setUploadModalOpen(false);
              uploadForm.resetFields();
            }}
            okButtonProps={{ disabled: !uploadFormIsValid() }}
          >
            <Form layout="vertical" form={uploadForm}>
              <Form.Item name="collection_period" label="Collection Period">
                <DatePicker picker="month" format="YYYY-MM" />
              </Form.Item>
              <Form.Item name="billing_period" label="Billing Period">
                <DatePicker picker="month" format="YYYY-MM" />
              </Form.Item>
              <Form.Item required label="File" name="file">
                <Upload
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={(event) => {
                    setUploadFile(event.file as any);
                  }}
                  accept=".csv"
                >
                  <Button>
                    <span>Click to upload</span>
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Premium Reconciliation"
            open={isPremiumModalOpen}
            closable={true}
            onCancel={() => {
              setPremiumModalOpen(false);
            }}
            onOk={handlePremRecSubmit}
          >
            <Form form={premiumRecForm}>
              <Form.Item name="analysis_level" label="Analysis Level">
                <Select options={[{ value: "single_scheme", label: "Single Scheme" }, { value: "all", label: "All" }]} />
              </Form.Item>
              <Form.Item name="billing_date_start" label="Billing Date Start">
                <DatePicker picker="month" format="YYYY-MM" />
              </Form.Item>
              <Form.Item name="billing_date_end" label="Billing Date End">
                <DatePicker picker="month" format="YYYY-MM" />
              </Form.Item>
              <Form.Item name="collection_date_start" label="Collection Date Start">
                <DatePicker picker="month" format="YYYY-MM" />
              </Form.Item>
              <Form.Item name="collection_date_end" label="Collection Date End">
                <DatePicker picker="month" format="YYYY-MM" />
              </Form.Item>
            </Form>
          </Modal>

        </div>
      </div>
    </Header>
  );
};

export default CollectionAnalysis;
