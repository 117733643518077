import React, { useState } from 'react';
import { Button, Modal, Table, Space } from 'antd';
import instance from '../../axios/axios-instance';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../redux/actions/AppMessageActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import SearchPolicy from './SearchPolicy';
import ClaimDetailsModal from './ClaimDetailsModal';
import { ColumnsType } from 'antd/es/table';
import EventDetailModal from './EventDetailsModal';

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface newClaim {
    policy: any;
    claim: any;
    event: any;
}

const NewClaimModal = ({ visible, onCancel, onOk, data, setData }:
    {
        visible: boolean; onOk: any; onCancel: any; data: newClaim[]; setData: any;
    }) => {
    const [searchPolicyModalVisible, setSearchPolicyModalVisible] = useState(false);
    const [claimDetailsModalVisible, setClaimDetailsModalVisible] = useState(false);
    const [eventDetailModalOpen, setEventDetailModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<newClaim | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const handleSelectPolicy = (policies: any[]) => {
        policies.forEach(policy => {
            setData((prevData: newClaim[]) => [...prevData, { policy }]);
        });
    };

    const handleAddClaim = (values: any) => {
        setData(data.map((item: { policy: { id: any; }; }) => item.policy.id === selectedRecord?.policy?.id ? { ...item, claim: values } : item));
    }

    const handleAddEventDetail = (values: any) => {
        setData(data.map((item: { policy: { id: any; }; }) => item.policy.id === selectedRecord?.policy?.id ? { ...item, event: values } : item));
    }

    const columns: ColumnsType<newClaim> = [
        { title: 'Policy Number', dataIndex: ['policy', 'policy_number'], key: 'policy_number' },
        { title: 'Member', dataIndex: ['policy', 'member', 'first_names'], key: 'member' },
        { title: 'Scheme', dataIndex: ['policy', 'scheme', 'scheme_name'], key: 'scheme' },
        { title: 'Policy Status', dataIndex: ['policy', 'policy_status', 'policy_status'], key: 'policy_status' },
        {
            title: 'Action',
            key: 'action',
            render: (_text: any, record: newClaim) => (
                <Space.Compact direction='vertical'>
                    <Button type="link" onClick={() => {
                        setSelectedRecord(record);
                        setClaimDetailsModalVisible(true); // Uncomment this when you have a ClaimDetailsModal
                    }}>
                        Claim Details
                    </Button>
                    <Button type="link" onClick={() => {
                        setSelectedRecord(record);
                        setEventDetailModalOpen(true); // Uncomment this when you have a ClaimDetailsModal
                    }}>
                        Event Details
                    </Button>
                </Space.Compact>
            ),
        },
    ];

    return (
        <>
            <Modal
                visible={visible}
                title="Capture Claim"
                okText="Submit"
                cancelText="Cancel"
                onCancel={onCancel}
                onOk={onOk}
                width="70%"
            >
                <Space size="large">
                    <Button type="primary" onClick={() => setSearchPolicyModalVisible(true)}>
                        Search Policy
                    </Button>
                </Space>
                <Table dataSource={data} columns={columns} scroll={{ x: 'max-content' }} />
            </Modal>
            <SearchPolicy
                visible={searchPolicyModalVisible}
                onOk={handleSelectPolicy}
                onCancel={() => setSearchPolicyModalVisible(false)}
            />
            <ClaimDetailsModal
                visible={claimDetailsModalVisible}
                onCancel={() => setClaimDetailsModalVisible(false)}
                claimData={selectedRecord?.claim}
                onCreate={handleAddClaim}
            />
            <EventDetailModal
                isEventDetailModalOpen={eventDetailModalOpen}
                setEventDetailModalOpen={setEventDetailModalOpen}
                eventDetail={selectedRecord?.event}
                handleAddEventDetails={handleAddEventDetail}
            />

        </>
    );
};

export default NewClaimModal;
