import { PrincipalMemberAction, SET_ERROR, SET_MEMBER } from "../actions/PrincipalMemberAction";
import { IPrincipalMemember } from "../types/principalMember";

const initialState: IPrincipalMemember[] = []; // Change the type to an array


type Action = PrincipalMemberAction | any ;

const PrincipalMemberReduce = (
    state = initialState,
    action: Action
  ): IPrincipalMemember[] => { // Change the return type to an array
    switch (action.type) {
      case SET_MEMBER:
        return [...action.payload]; // Replace the existing state with the array from the action
      case SET_ERROR:
        // Handle error case if needed
        return state;
      default:
        return state;
    }
  };
  
export default PrincipalMemberReduce;
