import { ThunkAction } from "redux-thunk";
import Swal from "sweetalert2";
import instance from "../../axios/axios-instance";
import { setErrorMessage, setSuccessMessage } from "./AppMessageActions";

export const initialState = {
  claims: [],
};

// Action Types
export const ADD_CLAIM = "ADD_CLAIM";
export const REMOVE_CLAIM = "REMOVE_CLAIM";
export const CLEAR_CLAIMS = "CLEAR_CLAIMS"; // Add the new action type value
export const ADD_CLAIMS = "ADD_CLAIMS";
export const IS_LOADING = "IS_LOADING";
export const SET_CACHED_CLAIMS = "SET_CACHED_CLAIMS";
export const CLEAR_CACHED_CLAIMS = "CLEAR_CACHED_CLAIMS";

type ThunkResult<R> = ThunkAction<R, any, unknown, any>;

export const ADD_CLAIM_FAILURE = "ADD_CLAIM_FAILURE";

export interface GetClaimsParams {
  page: number;
  search_text?: string;
  page_number?: number;
  page_size?: number;
  scheme_search?: string;
  insurer_search?: string;
  assessor_search?: string;
  created_date_start?: string;
  created_date_end?: string;
  deceased_date_start?: string;
  deceased_date_end?: string;
  payment_date_start?: string;
  payment_date_end?: string;
  progress_search?: string;
  review_search?: string;
  capture_date_start?: string;
  capture_date_end?: string;
}

// Action Creator for Failure
export interface AddClaimFailureAction {
  type: typeof ADD_CLAIM_FAILURE;
  payload: string; // error message
}

export const addClaimFailure = (
  errorMessage: string
): AddClaimFailureAction => ({
  type: ADD_CLAIM_FAILURE,
  payload: errorMessage,
});

// Action Creators
export const addClaim = (newClaim: any) => {
  return {
    type: ADD_CLAIM,
    payload: newClaim,
  };
};
export const addClaims = (newClaim: any) => {
  return {
    type: ADD_CLAIMS,
    payload: newClaim,
  };
};

export const removeClaim = (claimId: any) => {
  return {
    type: REMOVE_CLAIM,
    payload: claimId,
  };
};

export const clearClaims = () => {
  return {
    type: CLEAR_CLAIMS,
  };
};

export const setIsLoading = (loading: boolean) => {
  return {
    type: IS_LOADING,
    payload: loading,
  };
};

export const setCachedClaims = (claims: any) => {
  return {
    type: SET_CACHED_CLAIMS,
    payload: claims,
  };
};

export const clearCachedClaims = () => {
  return {
    type: CLEAR_CACHED_CLAIMS,
  };
};

export const getClaims = (params: GetClaimsParams): ThunkResult<void> => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const query = Object.entries(params)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
      console.log(query);
      const response = await instance.get(`/api/claims/?format=json&${query}`);

      const newClaim: any = response.data;

      dispatch(addClaims(newClaim));
      dispatch(setIsLoading(false));

      // Optionally, you can add any success handling here, such as showing a success notification, etc.
    } catch (error: any) {
      console.log(error.message);

      dispatch(setIsLoading(false));
      error?.response?.status !== 401 &&
        dispatch(setErrorMessage("There was an error fetching claims"));

      // Dispatch the "ADD_CLAIM_FAILURE" action with the error message
      dispatch(addClaimFailure("Failed to create a new claim."));

      // Optionally, you can show an error notification to the user.
    }
    dispatch(setIsLoading(false));
  };
};

export const claimCapture = (model: any): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      formData.append("json", JSON.stringify(model));

      model.forEach((element: any, index: number) => {
        if (element.documentation) {
          formData.append(
            `documentation-${index}`,
            element.documentation,
            element.documentation.name
          );
        }
      });

      await instance.post(`/api/claims/new-claim/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(setSuccessMessage("Claim captured successfully"));
    } catch (error: any) {
      dispatch(setErrorMessage(`There was an error submitting claim data`));
      console.log(error.message);

      dispatch(addClaimFailure("Failed to create a new claim."));
    }
  };
};

export const editClaim = (model: any, handleReset?: any): any => {
  return async (dispatch: any) => {
    try {
      await instance.put(`/api/claims/update-claim/${model.id}/`, model);

      handleReset?.();
      dispatch(setSuccessMessage("Updates saved successfully"));
    } catch (error: any) {
      console.log(error.message);
      dispatch(setErrorMessage("There was an error editing the claim"));

      dispatch(addClaimFailure("Failed to create a new claim."));
    }
  };
};

export const duplicateClaim = (model: any, handleReset: any): any => {
  return async (dispatch: any) => {
    try {
      const formData = new FormData();

      // Add JSON data to the formData

      formData.append("json", JSON.stringify([model]));

      const response = await instance.post(`/api/claims/new-claim/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        handleReset();
        Swal.fire({
          icon: "success",
          title: "Claim Replicated",
          text: response.data.message,
        });
      }
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error replicating claim",
      });
      console.log(error.message);
    }
  };
};
