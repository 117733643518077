import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import instance from "../../axios/axios-instance";
import { AuthContext } from "../../context/AuthContext";
interface ModelProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: any;
  claimData: any;
  handleReset: any;
}

interface FormData {
  claimID: string;
  amountPaid: string;
  paymentDate: string;
  proofOfPayments: any;
  paymentReference: string;
  closureStatus: string;
  policy_number: string;
}

const PaymentForm: React.FC<ModelProps> = (modelProps) => {
  const { user } = useContext(AuthContext);

  const [paymentData, setPaymentData] = useState<FormData>({
    claimID: modelProps.claimData?.id || "", // Ensure 'id' is available
    amountPaid: "",
    paymentDate: "",
    proofOfPayments: "",
    paymentReference: "",
    closureStatus: "",
    policy_number: modelProps.claimData?.policy_number || "", // Ensure 'policy_number' is available
  });

  // Add a state variable to track form validity
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setPaymentData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const {
      amountPaid,
      paymentDate,
      proofOfPayments,
      paymentReference,
      closureStatus,
    } = paymentData;

    // Check if all required fields have values
    const isValid =
      amountPaid !== "" &&
      paymentDate !== "" &&
      proofOfPayments !== "" &&
      paymentReference !== "" &&
      closureStatus !== "";

    setIsFormValid(isValid);
  };

  const handleOk = async (e: any) => {
    e.preventDefault();

    const model: any = {
      updated_by: user?.user_id,
      id: modelProps.claimData?.id,
      amount_paid: paymentData.amountPaid,
      payment_date: paymentData.paymentDate,
      pop_reference: paymentData.paymentReference,
      claim_closure: { id: parseInt(paymentData.closureStatus) },
      progress_status: paymentData.closureStatus,
      pop_document: paymentData.proofOfPayments,
      policy_number: paymentData.policy_number,
      is_uploaded: true,
      is_paid: true,
    };

    console.log("Payment data", model);

    try {
      const response = await instance.put(
        `/api/claims/payment/${modelProps.claimData.id}/`,
        model,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200 || response.status === 201) {
        modelProps.handleReset();
        modelProps.setIsModalOpen(modelProps.onClose);
        Swal.fire({
          icon: "success",
          title: "Payment Upload",
          text: response.data.message,
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }
    
    
    modelProps.setIsModalOpen(modelProps.onClose);
  };

  useEffect(() => {
    modelProps.setIsModalOpen(modelProps.isModalOpen);
    validateForm();
  }, [modelProps.isModalOpen]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Perform any additional validation here, if neede

    // Now, you can call handleOk to handle the API request
    // handleOk();
  };

  const proof_of_paymentIRL = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setPaymentData({
        ...paymentData,
        proofOfPayments: event.target.files[0],
      });
    }
  };

  return (
    <Modal
      title="Upload Proof of Payment"
      open={modelProps.isModalOpen}
      onOk={handleOk}
      onCancel={() => modelProps.setIsModalOpen(false)}
      okButtonProps={{
        disabled: !(
          paymentData.amountPaid &&
          paymentData.paymentDate &&
          paymentData.paymentReference &&
          paymentData.closureStatus &&
          paymentData.proofOfPayments
        ),
      }}
    >
      <h5>{paymentData.policy_number}</h5>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="amount">
            Amount Paid <span className="text-danger">*Required</span>
          </label>
          <input
            id="amount"
            type="text"
            name="amountPaid"
            className="form-control"
            value={paymentData.amountPaid}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="payment">
            Payment Date <span className="text-danger">*Required</span>
          </label>
          <input
            id="payment"
            type="date"
            name="paymentDate"
            className="form-control"
            value={paymentData.paymentDate}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="col-md-12">
          <label htmlFor="formFile" className="col-sm-12 col-form-label">
            Proof of Payment URL <span className="text-danger">*Required</span>
          </label>
          <input
            onChange={handleInputChange}
            value={paymentData.proofOfPayments}
            className="form-control"
            name="proofOfPayments"
            type="url"
            id="formFile"
          />
        </div>

        <div className="form-group">
          <label htmlFor="reference">
            Payment Reference <span className="text-danger">*Required</span>
          </label>
          <input
            id="reference"
            type="text"
            name="paymentReference"
            className="form-control"
            value={paymentData.paymentReference}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="closureStatus">
            Closure Status <span className="text-danger">*Required</span>
          </label>
          <select
            id="closureStatus"
            name="closureStatus"
            className="form-control"
            value={paymentData.closureStatus}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>
              ---------
            </option>

            <option value="1">Closed</option>
            <option value="2">Claim In Payment</option>
          </select>
          {/* 
          <button
            type="submit"
            onClick={handleOk}
            className="btn btn-primary mt-3"
            disabled={!isFormValid} // Disable the button if the form is not valid
          >
            Submit
      </button> */}
        </div>
      </form>
    </Modal>
  );
};

export default PaymentForm;
