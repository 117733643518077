import { Button, Card, Form, Input, Space, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/srs-logo.jpg";
import instance from "../../axios/axios-instance";
import { AuthContext } from "../../context/AuthContext";
import "./otp.css";

interface Props {}

const OTP: React.FC<Props> = () => {
  const { user } = useContext(AuthContext);
  const [otp, setOtp] = useState<string>("");
  const [timerSeconds, setTimerSeconds] = useState<number>(30);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true);
  const navigate = useNavigate();

  const verifyOtp = async () => {
    const model = {
      email: user?.email,
      otp: otp,
    };

    await instance.post("api/accounts/verify-otp/", model);

    if (user?.is_claim_manager === true) {
      navigate("/review-claims");
    } else if (user?.is_claim_assessor === true) {
      navigate("/view-claims");
    }
  };

  const resendOtp = async () => {
    const email = user?.email;
    await instance.post("api/accounts/send-otp/", { email });
    setIsTimerRunning(true);
    setTimerSeconds(30);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerSeconds((prevTimer) => prevTimer - 1);
      if (timerSeconds === 0) {
        setIsTimerRunning(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timerSeconds]);

  if (user !== null) {
    return (
      <div className="otp">
        <Card className="otp-form">
          <Form autoComplete="false">
            <Space direction="vertical">
              <img src={Logo} width="160" alt="Logo" />
              <Typography.Title level={2}>Hi, {user.name}</Typography.Title>
              <Typography.Title level={5}>
                Enter the verification code sent to your email
              </Typography.Title>

              <Form.Item>
                <Input
                  placeholder="Enter OTP"
                  onChange={(e) => {
                    setOtp(String(e.target.value).padStart(6, "0"));
                  }}
                ></Input>
              </Form.Item>
              <Button type="primary" block onClick={verifyOtp}>
                Submit
              </Button>
              <Button
                block
                type="default"
                disabled={isTimerRunning}
                onClick={resendOtp}
              >
                Resend OTP
                {isTimerRunning ? (
                  <span>
                    -({Math.floor(timerSeconds / 60)}:
                    {(timerSeconds % 60).toString().padStart(2, "0")}){" "}
                  </span>
                ) : (
                  ""
                )}
              </Button>
            </Space>
          </Form>
        </Card>
      </div>
    );
  } else {
    return <div>Please login first</div>;
  }
};

export default OTP;
