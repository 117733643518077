import { ThunkAction } from "redux-thunk";
import { IDeceasedDetails } from "../types/DeceasedDetails";
import instance from "../../axios/axios-instance";
import { setErrorMessage, setSuccessMessage } from "./AppMessageActions";

type ThunkResult<R> = ThunkAction<R, any, unknown, any>;

export const SET_DECEASED_DATA = "SET__DATA";

export interface SetDeceasedDataAction {
  type: typeof SET_DECEASED_DATA;
  payload: IDeceasedDetails;
}

export const setFormData = (
  formData: IDeceasedDetails
): SetDeceasedDataAction => {
  return {
    type: SET_DECEASED_DATA,
    payload: formData,
  };
};

interface ICreateDeceasedDetails extends IDeceasedDetails {
  assessor: any;
}
export const createDeceasedDetails = (
  deceasedData: ICreateDeceasedDetails
): ThunkResult<void> => {
  const model = {
    assessor: deceasedData.assessor,
    deceased_surname: deceasedData.deceasedSurname,
    deceased_name: deceasedData.deceasedName,
    marital_status: deceasedData.maritalStatus,
    deceased_date_of_death: deceasedData.deceasedDateOfDeath,
    deceased_id_number:
      deceasedData.deceasedIdType === "ID" ? deceasedData.deceasedIdNumber : "",
    passport:
      deceasedData.deceasedIdType === "Passport"
        ? deceasedData.deceasedIdNumber
        : "",
    gender: deceasedData.deceasedGender,
    nationality: deceasedData.deceasedNationality,
    date_of_birth: deceasedData.deceasedDateOfBirth,
    deceased_cause_of_death: deceasedData.deceasedCauseOfDeath,
    deceased_address: deceasedData.deceasedHomeAddress,
    residence_country: deceasedData.deceasedResidentialCountry,
    birth_country: deceasedData.deceasedBirthCountry,
  };

  return async (dispatch) => {
    try {
      const response = await instance.post(
        `/api/claims/deceased-details/?format=json`,
        model
      );

      // Assuming the API response contains the newly created deceased details data
      // const newDeceasedDetails: IDeceasedDetails = response.data;
      if (response.status === 201 || response.status === 200) {
        dispatch(setSuccessMessage("Deceased details saved successfully"));
      }
    } catch (error: any) {
      dispatch(setErrorMessage(ErrorResponse(error?.response?.data)));
      console.log(error.message);

      // Optionally, you can dispatch a failure action here or show an error notification to the user.
    }
  };
};
function ErrorResponse(data: any): string {
  throw new Error("Function not implemented.");
}
