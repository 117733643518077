import React, { useEffect, useState } from "react";
import { PolicyAction, setPolicy } from "../../../redux/actions/PolicyAction";
import { IPolicy } from "../../../redux/types/Policy";
import { useDispatch } from "react-redux";
import instance from "../../../axios/axios-instance";

import { get } from "http";

interface ClearProps {
  isInput: boolean | false;
}

interface PolicyProps {
  // isInput: boolean | false;
  ClearPropsIsInput: boolean;
  schemeName: any;
  setSchemeName: React.Dispatch<React.SetStateAction<any>>;

  policyNumber: string;
  setPolicyNumber: React.Dispatch<React.SetStateAction<string>>;

  inceptionDate: string;
  setInceptionDate: React.Dispatch<React.SetStateAction<string>>;

  claimAmount: number;
  setClaimAmount: React.Dispatch<React.SetStateAction<number>>;

  grossPremium: number;
  setGrossPremium: React.Dispatch<React.SetStateAction<number>>;

  dateClaimSubmission: string;
  setDateClaimSubmission: React.Dispatch<React.SetStateAction<string>>;

  insurerName: string;
  setInsurerName: React.Dispatch<React.SetStateAction<string>>;

  schemeEmail: string;
  setSchemeEmail: React.Dispatch<React.SetStateAction<string>>;
}
interface Insurer {
  id: number;
  name: string;
}

interface Scheme {
  id: any;
  scheme_name: string;
}
const Policy: React.FC<PolicyProps> = ({
  ClearPropsIsInput,
  schemeName,
  setSchemeName,
  policyNumber,
  setPolicyNumber,
  inceptionDate,
  setInceptionDate,
  claimAmount,
  setClaimAmount,
  grossPremium,
  setGrossPremium,
  dateClaimSubmission,
  setDateClaimSubmission,
  insurerName,
  setInsurerName,
  schemeEmail,
  setSchemeEmail,
}) => {
  const dispatch = useDispatch();
 

  const [schemes, setSchemes] = useState<Scheme[]>([]); // State to hold schemes data
  const [emailError, setEmailError] = useState("");

  const handleSchemeNameChange = (event: {
    target: { value: React.SetStateAction<any> };
  }) => {
    /*
    const selectedSheme = event.target.value
      ? event.target.value
      : schemeName.id; 
    */
    setSchemeName(event.target.value);
  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSchemeEmailChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const email = event.target.value;
    setSchemeEmail(email);
    setEmailError(validateEmail(email.toString()) ? "" : "Invalid email");
  };

  const handlePolicyNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPolicyNumber(event.target.value);
  };

  const handleClaimAmountChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setClaimAmount(parseInt(event.target.value.toString()));
  };

  const handleGrossPremiumChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setGrossPremium(parseInt(event.target.value.toString()));
  };

  const handleInceptionDateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setInceptionDate(event.target.value);
  };

  const handleDateClaimSubmissionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDateClaimSubmission(event.target.value);
  };

  useEffect(() => {
    if (ClearPropsIsInput) {
      setSchemeName("");
      setInsurerName("");
      setSchemeEmail("");
      setPolicyNumber("");
      setClaimAmount(0);
      setGrossPremium(0);
      setInceptionDate("");
      setDateClaimSubmission("");
    }
  }, [
    ClearPropsIsInput,
    setClaimAmount,
    setDateClaimSubmission,
    setGrossPremium,
    setInceptionDate,
    setPolicyNumber,
    setSchemeName,
    setInsurerName,
    setSchemeEmail,
  ]);

  const getScheme = async () => {
    try {
      const response = await instance.get(
        `api/claims/underwriter/?format=json`
      );
      setSchemes(response.data);
     
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getScheme();
  }, []);



  useEffect(() => {
    const model: IPolicy = {
      schemeName: ( typeof schemeName.id === 'number' ) ? parseInt(schemeName.id) : parseInt(schemeName),
      insurerName: insurerName,
      schemeEmail: schemeEmail,
      policyNumber: policyNumber,
      claimAmount: claimAmount,
      grossPremium: grossPremium,
      inceptionDate: inceptionDate,
      dateClaimSubmission: dateClaimSubmission,
    };

    dispatch(setPolicy(model));
  }, [
    schemeName,
    insurerName,
    schemeEmail,
    policyNumber,
    claimAmount,
    grossPremium,
    inceptionDate,
    dateClaimSubmission,
    dispatch,
  ]);
  

  return (
    <div className="row">
      <div className="col-md-4 flex">
        <div className="form-group mb-3">
          <label htmlFor="scheme_name">
            Scheme Name <span className="text-danger">* Required</span>
          </label>
          <select
            className="form-select"
            id="scheme_name"
            name="SchemeName"
            placeholder="---------"
            aria-label="Scheme Name"
            value={schemeName.id ? schemeName.id : schemeName }
            required
            onChange={handleSchemeNameChange}
          >
            <option value="">---------</option>
            {schemes?.map((scheme) => (
              <option key={scheme.id} value={scheme.id}>
                {scheme.scheme_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-md-4 mb-3 flex">
        <div className="form-group">
          <label htmlFor="scheme_email">Scheme email</label>
          <input
            type="text"
            className={`form-control ${emailError ? "is-invalid" : ""}`}
            id="scheme_email"
            placeholder="Scheme email"
            value={schemeEmail}
            onChange={handleSchemeEmailChange}
          />
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>
      </div>

      <div className="col-md-4 mb-3 flex">
        <div className="form-group">
          <label htmlFor="policy_number">
            Policy Number <span className="text-danger">* Required</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="policy_number"
            required
            placeholder="Policy Number"
            value={policyNumber}
            onChange={handlePolicyNumberChange}
          />
        </div>
      </div>

      <div className="col-md-4 mb-3 flex">
        <div className="form-group">
          <label htmlFor="claim_amount">
            Claim amount <span className="text-danger">* Required</span>
          </label>
          <input
            type="number"
            className="form-control"
            id="claim_amount"
            required
            placeholder="Claim amount"
            value={claimAmount}
            min="0"
            onChange={handleClaimAmountChange}
          />
        </div>
      </div>

      <div className="col-md-4 mb-3 flex">
        <div className="form-group">
          <label htmlFor="gross_premium">
            Gross Premium <span className="text-danger">* Required</span>
          </label>
          <input
            type="number"
            className="form-control"
            id="gross_premium"
            min="0"
            placeholder="Gross Premium"
            value={grossPremium}
            onChange={handleGrossPremiumChange}
          />
        </div>
      </div>

      <div className="col-md-4 mb-3 flex">
        <div className="form-group">
          <label htmlFor="inception_date">
            Inception Date <span className="text-danger">* Required</span>
          </label>
          <input
            type="date"
            className="form-control"
            id="inception_date"
            required
            placeholder="Inception Date"
            value={inceptionDate}
            onChange={handleInceptionDateChange}
          />
        </div>
      </div>

      <div className="col-md-4 flex">
        <div className="form-group">
          <label htmlFor="date_claim_submission">
            Date Claim Submitted <span className="text-danger">* Required</span>
          </label>
          <input
            type="date"
            className="form-control"
            id="date_claim_submission"
            required
            placeholder="Date Claim Submitted"
            value={dateClaimSubmission}
            onChange={handleDateClaimSubmissionChange}
          />
        </div>
      </div>
    </div>
  );
};
export default Policy;
