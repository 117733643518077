import React, { useEffect, useState } from "react";
import {
    Button,
    Input,
    Modal,
    Space,
    Table,
    Form,
    Flex,
    TablePaginationConfig,
} from "antd";
import instance from "../../axios/axios-instance";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface PolicyStatus {
    id: string;
    policy_status: string;
}

function PolicyStatusTable() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [editPolicyStatusForm] = Form.useForm();
    const [selectedPolicyStatus, setSelectedPolicyStatus] =
        useState<PolicyStatus>();
    const [query, setQuery] = useState("");
    const dispatch = useDispatch<AppDispatch>();

    const fetchPolicyStatuses = async () => {
        const response = await instance.get("/api/administration/policy-status");
        setData(response?.data);
        setFilteredData(response?.data);
    };

    useEffect(() => {
        fetchPolicyStatuses();
    }, []);

    const performSearch = () => {
        const filteredData = data.filter(
            (policyStatus: PolicyStatus) =>
                policyStatus.policy_status.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredData(filteredData);
    };

    const resetSearch = () => {
        setQuery("");
        setFilteredData(data);
    };

    const createNewPolicyStatus = async () => {
        const response = await instance.post(
            "/api/administration/policy-status",
            {
                ...editPolicyStatusForm.getFieldsValue(),
            }
        );
        if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Policy Status added"));
            fetchPolicyStatuses();
        }
        setCreateModalOpen(false);
    };

    const editPolicyStatus = async () => {
        const response = await instance.put(
            `/api/administration/policy-status?id=${selectedPolicyStatus?.id}`,
            {
                ...editPolicyStatusForm.getFieldsValue(),
            }
        );
        if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Policy Status Updated"));
            fetchPolicyStatuses();
        }
        setEditModalOpen(false);
    };

    const handleDetails = (record: any) => {
        editPolicyStatusForm.setFieldsValue({
            policy_status: record.policy_status,
        });
        setSelectedPolicyStatus(record);
        setEditModalOpen(true);
    };

    const columns: any[] = [
        {
            title: "Policy Status",
            dataIndex: "policy_status",
            key: "policy_status",
            width: 150,
            editable: true,
            fixed: "left",
        },
        {
            title: "Actions",
            key: "actions",
            width: 150,
            fixed: "right",
            render: (record: any) => (
                <Space size="small" style={{ minWidth: "112px" }}>
                    <Button onClick={() => handleDetails(record)}>Details</Button>
                </Space>
            ),
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPage_size] = useState(4);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        let timeoutId: any;

        const updateWindowHeight = () => {
            timeoutId = setTimeout(() => {
                setWindowHeight(window.innerHeight);
            }, 300);
        };

        window.addEventListener("resize", updateWindowHeight);

        return () => {
            window.removeEventListener("resize", updateWindowHeight);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <Space direction="vertical">
            <Flex justify="space-between">
                <Space>
                    <Input
                        value={query}
                        onChange={(e) => setQuery(e.target.value.trim())}
                        placeholder="Enter search to begin"
                    ></Input>
                    <Button disabled={!query} onClick={() => performSearch()}>
                        Search
                    </Button>
                    <Button
                        disabled={!query.trim() || filteredData?.length === 0}
                        onClick={() => resetSearch()}
                    >
                        Reset
                    </Button>
                </Space>
                <Space align="end" direction="horizontal">
                    <Button
                        type="primary"
                        onClick={() => {
                            editPolicyStatusForm.resetFields();
                            setCreateModalOpen(true);
                        }}
                    >
                        Add Policy Status
                    </Button>
                </Space>
            </Flex>
            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="id"
                scroll={{ y: windowHeight - 420 }}
                style={{ width: 1100 }}
                onChange={(config: TablePaginationConfig) => {
                    setPage_size(config.pageSize!);
                    setCurrentPage(config.current!);
                }}
                pagination={{
                    total: filteredData?.length,
                    showTotal: (total) => `${total} rows`,
                    pageSize: page_size,
                    current: currentPage,
                }}
            />
            <Modal
                title="Create a Policy Status"
                open={isCreateModalOpen}
                onOk={createNewPolicyStatus}
                okText="Save"
                onCancel={() => {
                    setCreateModalOpen(false);
                }}
            >
                <Form layout="vertical" form={editPolicyStatusForm}>
                    <Form.Item label="Policy Status" name="policy_status">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Edit Policy Status"
                open={isEditModalOpen}
                onOk={editPolicyStatus}
                okText="Update"
                onCancel={() => {
                    setEditModalOpen(false);
                }}
            >
                <Form layout="vertical" form={editPolicyStatusForm}>
                    <Form.Item label="Policy Status" name="policy_status">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
}

export default PolicyStatusTable;
