import { Rule } from "antd/es/form";

export const usernameRules: Array<Rule> = [
  { required: true, message: "Username is required" },
];

export const passwordRules: Array<Rule> = [
  {
    required: true,
    message: "Password is required",
  },
];
