import {
  ConfigProvider,
  Divider,
  Modal,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { AxiosError } from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import axiosInstance from "../axios/axios-instance";
import { config } from "./network-and-error.config";

const baseUrl = "https://api-claims-fc09b962-48b9-6f53dd8df9d7.co.za";

function NetworkAndError() {
  const [networkLoading, setNetworkLoading] = useState(0);
  const [networkErrors, setNetworkErrors] = useState<string[]>([]);
  const [api, contextHolder] = notification.useNotification({
    stack: { threshold: 3 },
  });

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        setNetworkLoading((prevNetworkLoading) => prevNetworkLoading + 1);
        return config;
      }
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        const path = response.config?.url?.replace(baseUrl, "");
        const notification = path && config[path];
        if (notification) {
          api.success({
            message: notification.message,
            description: notification.description,
          });
        }
        setNetworkLoading((prevNetworkLoading) => prevNetworkLoading - 1);
        return response;
      },
      (error: AxiosError<{ message: string }>) => {
        const message = error.response?.data.message;
        setNetworkLoading((prevNetworkLoading) => prevNetworkLoading - 1);
        setNetworkErrors((prevNetworkErrors) => [
          ...prevNetworkErrors,
          message || error.message,
        ]);
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [api]);

  function clearNetworkErrors() {
    setNetworkErrors([]);
  }

  return (
    <>
      {contextHolder}
      <Modal
        onOk={clearNetworkErrors}
        onCancel={clearNetworkErrors}
        open={networkErrors.length > 0}
        zIndex={1002}
        cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <Space direction="vertical">
          {networkErrors.map((error) => (
            <>
              <Typography.Text type="danger">{error}</Typography.Text>
              <Divider></Divider>
            </>
          ))}
        </Space>
      </Modal>
      <ConfigProvider
        theme={{ components: { Modal: { contentBg: "transparent" } } }}
      >
        <Modal
          open={networkLoading > 0}
          footer={null}
          title={null}
          width={80}
          zIndex={1001}
        >
          <Spin spinning={true} size="large"></Spin>
        </Modal>
      </ConfigProvider>
    </>
  );
}

export default NetworkAndError;
