import React from "react";
import Header from "../Dashboard/header";
import AwaitingPaymentsTable from "./components/awaitingClaims";


const AwaitingPayments: React.FC<any> = () => {
  return (
    <>
      <Header title='Awaiting Payments'>
            <AwaitingPaymentsTable/>
      </Header>
    </>
  );
};

export default AwaitingPayments;