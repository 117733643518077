/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import BillModal from "./billmodal";
// import Select from "react-select";
import instance from "../../axios/axios-instance";
// import { handleExportToExcel } from "./excelexport";
import Amendments from "./Ammendments";
import moment from "moment";
import Header from "../../Dashboard/header";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { AuthContext } from "../../context/AuthContext";
import NewPolicyModal from "./NewPolicyModal";

type AppDispatch = ThunkDispatch<undefined, any, AnyAction>;


const MembersTable: React.FC = () => {

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchKey, setSearchKey] = useState<string>("");
  // const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  // const [selectedBillPeriod, setSelectedBillPeriod] = useState(null);
  const [memberData, setMemberData] = useState<any[]>([{}]);
  const dispatch: AppDispatch = useDispatch();
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [selectedMemberData, setSelectedMemberData] = useState<any>();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [newBusinessModalVisible, setNewBusinessModalVisible] = useState(false);
  const [newDependantModalVisible, setNewDependantModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [dependantData, setDependantData] = useState([]);
  const [policyNumber, setPolicyNumber] = useState<any>(null);
  const [depPolicyNumber, setDepPolicyNumber] = useState<any>(null);
  const [toggleFetch, setToggleFetch] = useState<boolean>(false);

  //filters
  const [selectedPolicyStatus, setSelectedPolicyStatus] = useState<any[]>([]);
  const [selectedMemberType, setSelectedMemberType] = useState<any[]>([]);
  const [policyStatusOptions, setPolicyStatusOptions] = useState<any[]>([]);
  const [memberTypeOptions, setMemberTypeOptions] = useState<any[]>([]);
  const [policySelectOptions, setPolicySelectOptions] = useState<any[]>([]);
  const [memberSelectOptions, setMemberSelectOptions] = useState<any[]>([]);



  const { user } = useContext(AuthContext);

  const handleSubmit = async (policyNumber: any, data: any) => {
    // Send a POST request to the backend with data
    const policiesData = data.map((item: any) => {
      return ({
        ...item.policy, person_id: item.person?.id,
        key: item.person?.id_number ? item.person.id_number : item.person.passport
      })
    })
    const response = await instance.post("/api/policies/new-business/", {
      policyNumber, user_id: user?.user_id, policiesData, scheme_id: memberId,
    } as any);
    if (response?.status >= 200 && response?.status < 300) {
      setData([])
      setPolicyNumber(null)
      dispatch(setSuccessMessage("Policies submitted"));
    }
  };

  const handleDependantSubmit = async (depPolicyNumber: any, depData: any) => {
    // Send a POST request to the backend with data
    const policiesData = depData.map((item: any) => {
      return ({
        ...item.policy, person_id: item.person?.id,
        key: item.person?.rsa_id_number ? item.person.rsa_id_number : item.person.passport_number
      })
    })
    const response = await instance.post("/api/policies/new-dependant/", {
      depPolicyNumber, user_id: user?.user_id, policiesData, scheme_id: memberId
    } as any);
    if (response?.status >= 200 && response?.status < 300) {
      setData([])
      setPolicyNumber(null)
      dispatch(setSuccessMessage("Dependant policies submitted"));
    }
  };

  const handleDetails = (memberId: any) => {

    const selectedData = memberData?.find(
      (record: any) => record.id === memberId
    );
    setSelectedMemberData(selectedData);
    setIsDetailsModalOpen(true);
  };

  const { memberId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
  
      try {
        // Ensure only distinct values are in the arrays
        const distinctPolicyStatus = Array.from(new Set(selectedPolicyStatus));
        const distinctMemberType = Array.from(new Set(selectedMemberType));
  
        const response = await instance.get(`api/policies/get-membership-data?scheme_id=${encodeURIComponent(memberId as any)}&policy_status=${encodeURIComponent(distinctPolicyStatus?.join(','))}&member_type=${encodeURIComponent(distinctMemberType?.join(','))}&search=${encodeURIComponent(searchKey)}`);
        if (response?.status >= 200 && response?.status < 300) {
          setMemberData(response?.data?.data);
          setTotalItems(response?.data?.total_items)
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [dispatch, memberId, selectedPolicyStatus, selectedMemberType, searchKey, toggleFetch]);
  

  // useEffect(() => {
  //   const fetchData = async () => {

  //     try {
  //       const response = await instance.get(`api/policies/get-membership-data?scheme_id=${encodeURIComponent(memberId as any)}&policy_status=${encodeURIComponent(selectedPolicyStatus?.join(','))}&member_type=${encodeURIComponent(selectedMemberType?.join(','))}&search=${encodeURIComponent(searchKey)}`);
  //       if (response?.status >= 200 && response?.status < 300) {
  //         setMemberData(response?.data?.data);
  //         setTotalItems(response?.data?.total_items)
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, memberId, selectedPolicyStatus, selectedMemberType, searchKey, toggleFetch]);

  function downloadCSVFile(filename: string, contents: string) {
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
  }

  const downloadData = async () => {

    try {
      const response = await instance.get(`api/policies/get-membership-data?scheme_id=${encodeURIComponent(memberId as any)}&policy_status=${encodeURIComponent(selectedPolicyStatus?.join(','))}&member_type=${encodeURIComponent(selectedMemberType?.join(','))}&search=${encodeURIComponent(searchKey)}&download=${encodeURIComponent('download')}`);
      if (response?.status >= 200 && response?.status < 300) {
        downloadCSVFile(
          response.data?.file_name,
          response.data[response.data?.file_name].join("")
        );
      }
    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    const fetchMemberTypes = async () => {
      const response = await instance.get("/api/administration/member-types");
      setMemberTypeOptions(
        response?.data.map((item: any) => {
          return { text: item.member_type, value: item.member_type };
        })
      );
      setMemberSelectOptions(
        response?.data.map((item: any) => {
          return { value: item.id, label: item.member_type };
        })
      );
    };
    fetchMemberTypes();
  }, []);

  useEffect(() => {
    const fetchPolicyStatuses = async () => {
      const response = await instance.get("/api/administration/policy-status");
      setPolicyStatusOptions(
        response?.data.map((item: any) => {
          return { text: item.policy_status, value: item.policy_status };
        })
      );
      setPolicySelectOptions(
        response?.data.map((item: any) => {
          return { value: item.id, label: item.policy_status };
        })
      );
    };
    fetchPolicyStatuses();
  }, []);



  const generateBill = () => {
    setIsBillModalOpen(true);
  };

  const handleGenerateBill = async ({
    member_id,
    bill_period,
    download_option,
  }: {
    member_id: string | undefined;
    bill_period: moment.Moment;
    download_option: string | undefined;
  }) => {
    const response = await instance.post("/api/policies/generate-bill/", {
      scheme_id: memberId,
      bill_period,
      download_option,
      user_id: user!.user_id,
    });

    if (response.statusText === "OK") {
      downloadCSVFile(
        response?.data.file_name,
        response.data[response?.data?.file_name].join("")
      );
    }

    setIsBillModalOpen(false);
  };

  const handleResetSearch = () => {
    setSearchInput("");
    setSearchKey("");
    setSelectedPolicyStatus([]);
    setSelectedMemberType([]);
  };


  const [totalItems, setTotalItems] = useState(0)
  const [page_size, setPage_size] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [ammendmentsForm] = Form.useForm();
  const [isAmmendmentsOpen, setAmmendmentsOpen] = useState(false);
  const handleAmmendmentsSubmit = async () => {
    try {
      // Get the form data
      const formData = ammendmentsForm.getFieldsValue();

      // Split the form data into person and policy objects
      const person = {
        first_names: formData.first_names,
        surname: formData.surname,
        rsa_id_number: formData.rsa_id_number,
        passport_number: formData.passport_number,
        date_of_birth: formData.date_of_birth,
        cellphone_number: formData.cellphone_number,
        email_address: formData.email_address,
        gender: formData.gender,
        residential_address: formData.residential_address,
        nationality: formData.nationality,
        citizenship: formData.citizenship,
        country_of_residence: formData.country_of_residence,
      };

      const policy = {
        policy_number: formData.policy_number,
        policy_status: formData.policy_status,
        policy_start_date: formData.policy_start_date,
        member_type: formData.member_type,
        cover_amount: formData.cover_amount,
        scheme_premium_amount: formData.scheme_premium_amount,
      };

      const person_id = selectedMemberData?.id;

      // Send the request with the person and policy objects
      const response = await instance.patch("/api/policies/amendments/", { person_id, person, policy });

      if (response?.status >= 200 && response?.status < 300) {
        dispatch(setSuccessMessage("Amendments made"));
        setToggleFetch(prev => !prev);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleAmmendmentsClick = (id: any) => {
    // Find the selected row in the memberData state
    const selectedRow = memberData.find((row: any) => row.id === id);
    setSelectedMemberData(selectedRow)
    // Use the data from the selected row to set the fields in the form
    ammendmentsForm.setFieldsValue({
      firstNames: selectedRow?.member?.first_names,
      surname: selectedRow?.member?.surname,
      rsaIdNumber: selectedRow?.member?.rsa_id_number,
      passportNumber: selectedRow?.member?.passport_number,
      dateOfBirth: selectedRow?.member?.date_of_birth ? moment(selectedRow.member.date_of_birth, "YYYY-MM-DD") : null,
      cellphoneNumber: selectedRow?.member?.cellphone_number,
      emailAddress: selectedRow?.member?.email_address,
      residentialAddress: selectedRow?.member?.residential_address,
      nationality: selectedRow?.member?.nationality,
      citizenship: selectedRow?.member?.citizenship,
      countryOfResidence: selectedRow?.member?.country_of_residence,
      policyNumber: selectedRow?.policy_number,
      policyStatus: selectedRow?.policy_status,
      policyStartDate: selectedRow?.policy_start_date ? moment(selectedRow.policy_start_date, "YYYY-MM-DD") : null,
      memberType: selectedRow?.member_type,
      coverAmount: selectedRow?.cover_amount,
      scaledCoverAmount: selectedRow?.scaled_cover_amount,
      schemePremiumAmount: selectedRow?.scheme_premium_amount,
    });
    setAmmendmentsOpen(true);
  };



  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  // const handleExportToCSV = async () => {
  //   setIsModalVisible(false);
  //   const response = await instance.get(
  //     `/api-policy/member/fb03b877-cd9e-4768-a5c9-46f657707176/billing`
  //   );
  //   if (response.statusText === "OK") {
  //     handleExportToExcel(response?.data);
  //   }
  // };

  const billingPeriods = [{ label: "Label", value: "1" }];

  const columns: any = [
    {
      title: "Policy Number",
      dataIndex: "policy_number",
      key: "policy_number",
      fixed: "left",
      width: 150,
    },
    {
      title: "Policy Status",
      dataIndex: ['policy_status', 'policy_status'],
      key: "policy_status",
      width: 150,
      filters: policyStatusOptions,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedPolicyStatus,
      // onFilter: (value: any[], record: any) => {
      //   if (!selectedPolicyStatus.includes(value)) {
      //     setSelectedPolicyStatus(prevStatus => [...prevStatus, value]);
      //   }
      //   return value.includes(record.policy_status);
      // },
      // filterSearch: true
    },
    {
      title: "Policy Start Date",
      dataIndex: "policy_start_date",
      key: "policy_start_date",
      width: 150,
    },
    {
      title: "Member Type",
      dataIndex: ['member_type', 'member_type'],
      key: "member_type",
      width: 150,
      filters: memberTypeOptions,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedMemberType,
      // onFilter: (value: any[], record: any) => {
      //   if (!selectedMemberType.includes(value)) {
      //     setSelectedMemberType(prevType => [...prevType, value]);
      //   }
      //   return value.includes(record.member_type);
      // },
      // filterSearch: true
    },
    {
      title: "Cover Amount",
      dataIndex: "cover_amount",
      key: "cover_amount",
      width: 150,
    },
    {
      title: "Scaled Cover Amount",
      dataIndex: "scaled_cover_amount",
      key: "scaled_cover_amount",
      width: 150,
    },
    {
      title: "Scheme Premium Amount",
      dataIndex: "scheme_premium_amount",
      key: "scheme_premium_amount",
      width: 150,
    },
    {
      title: "First Names",
      dataIndex: "member",
      key: "first_names",
      width: 150,
      render: (member: any) => member?.first_names,
    },
    {
      title: "Surname",
      dataIndex: "member",
      key: "surname",
      width: 150,
      render: (member: any) => member?.surname,
    },
    {
      title: "RSA ID Number",
      dataIndex: "member",
      key: "rsa_id_number",
      width: 150,
      render: (member: any) => member?.rsa_id_number,
    },
    {
      title: "Passport Number",
      dataIndex: "member",
      key: "passport_number",
      width: 150,
      render: (member: any) => member?.passport_number,
    },
    {
      title: "Date of Birth",
      dataIndex: "member",
      key: "date_of_birth",
      width: 150,
      render: (member: any) => member?.date_of_birth,
    },
    {
      title: "Cellphone Number",
      dataIndex: "member",
      key: "cellphone_number",
      width: 150,
      render: (member: any) => member?.cellphone_number,
    },
    {
      title: "Residential Address",
      dataIndex: "member",
      key: "residential_address",
      width: 150,
      render: (member: any) => member?.residential_address,
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 150,
      render: (record: any) => (
        <Space size="small" style={{ minWidth: "112px" }}>
          <Space.Compact direction="vertical">
            {
              <Button type="link" onClick={() => handleDetails(record.id)}>
                Collection Analysis
              </Button>
            }
            {
              <Button
                type="link"
                onClick={() => handleAmmendmentsClick(record.id)}
              >
                Amendments & Cancellations
              </Button>
            }
          </Space.Compact>
        </Space>
      ),
    },
  ];

  return (
    <Header title="Policy Admin">

      <div style={{ marginTop: "2rem", marginBottom: "2rem" }} >

        <Flex justify="space-between">

          <Space>
            <Input
              placeholder="Search..."
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
            />
            <Button type="primary" onClick={() => setSearchKey(searchInput)}>
              Search
            </Button>
            <Button onClick={handleResetSearch}>Reset</Button>

          </Space>

          <Space size="large" >
            <Button onClick={downloadData} >Download</Button>
            <Button type="primary" onClick={() => setNewBusinessModalVisible(true)}>New Business</Button>
            <Button type="primary" onClick={() => setNewDependantModalVisible(true)}>New Dependant</Button>
            <Button type="primary" onClick={generateBill}>Generate Bill</Button>
          </Space>


        </Flex>
      </div>
      <Table

        bordered
        dataSource={memberData}
        columns={columns}
        scroll={{ x: 1300, y: windowHeight - 320 }}
        rowKey={(record) => record.id}
        onChange={(config: TablePaginationConfig, filters: Record<string, any>) => {
          setPage_size(config.pageSize!);
          setCurrentPage(config.current!);
          setSelectedPolicyStatus(filters["policy_status"] ?? []);
          setSelectedMemberType(filters["member_type"] ?? []);
        }}
        pagination={{
          total: totalItems,
          showTotal: (total) => `${total} rows`,
          pageSize: page_size,
          current: currentPage,
          showSizeChanger: true,
          pageSizeOptions: ['5','10', '20', '50', '100'],
        }}
      />

      <BillModal
        onSubmit={handleGenerateBill}
        open={isBillModalOpen}
        onCancel={() => setIsBillModalOpen(false)}
      />
      {/* <Modal
        title="Select Bill Period"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleExportToCSV}
        okType="primary"
        okButtonProps={{
          type: "primary",
          className: "bg-blue-500  text-white ",
        }}
        okText="Submit"
      >
        <Select
          options={billingPeriods}
          value={selectedBillPeriod}
          onChange={(newValue: any) => setSelectedBillPeriod(newValue)}
          placeholder="Select bill period..."
        />
      </Modal> */}

      <Modal
        title="Collection Analysis Download"
        open={isDetailsModalOpen}
        onCancel={() => setIsDetailsModalOpen(false)}
        onOk={() => setIsDetailsModalOpen(false)}
        okType="primary"
        okButtonProps={{
          type: "primary",
          style: { display: "none" },
        }}
        okText="Download"
      >
        <div
          style={{
            padding: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="primary">Download CSV</Button>
        </div>
      </Modal>
      <Amendments
        ammendmentsForm={ammendmentsForm}
        isAmmendmentsOpen={isAmmendmentsOpen}
        setAmmendmentsOpen={setAmmendmentsOpen}
        handleAmmendmentsSubmit={handleAmmendmentsSubmit}
        policySelectOptions={policySelectOptions}
        memberSelectOptions={memberSelectOptions}
      />
      <NewPolicyModal
        visible={newBusinessModalVisible}
        onCancel={() => setNewBusinessModalVisible(false)}
        onOk={handleSubmit}
        data={data}
        setData={setData}
        schemeId={memberId}
        policyNumber={policyNumber}
        setPolicyNumber={setPolicyNumber}
        modalTitle="New Business"
      />
      <NewPolicyModal
        visible={newDependantModalVisible}
        onCancel={() => setNewDependantModalVisible(false)}
        onOk={handleDependantSubmit}
        data={dependantData}
        setData={setDependantData}
        schemeId={memberId}
        policyNumber={depPolicyNumber}
        setPolicyNumber={setDepPolicyNumber}
        modalTitle="New Dependant"
      />

    </Header>
  );
};

export default MembersTable;
