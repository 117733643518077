import axios, { AxiosInstance } from "axios";

const { REACT_APP_BASE_URL_LOCAL, REACT_APP_BASE_URL_PROD, MODE } = process.env;

console.log("MODE:", MODE);
console.log("REACT_APP_BASE_URL_LOCAL:", REACT_APP_BASE_URL_LOCAL);
console.log("REACT_APP_BASE_URL_PROD:", REACT_APP_BASE_URL_PROD);

// Retrieve base URL from localStorage
// const storedBaseURL = localStorage.getItem("base_url");


// Use storedBaseURL if available, otherwise choose based on MODE
// const baseURL = storedBaseURL || (MODE === "development" ? REACT_APP_BASE_URL_LOCAL : REACT_APP_BASE_URL_PROD);
// const baseURL = "https://symplenormics-backend.azurewebsites.net/"

const baseURL = 'https://api-claims-fc09b962-48b9-6f53dd8df9d7.co.za/'


console.log("baseURL:", baseURL);

const instance: AxiosInstance = axios.create({
  baseURL,
  withCredentials: false // Ensure that cookies are sent with the request for authentication
});


// Add request interceptor
instance.interceptors.request.use(
  (config) => {
    if (typeof localStorage !== "undefined") {
      const storedAuthTokens = localStorage.getItem("authTokens");
      const authTokens = storedAuthTokens ? JSON.parse(storedAuthTokens) : null;

      if (authTokens) {
        config.headers.Authorization = `Bearer ${authTokens.access}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
});

// Ensure baseURL is defined
if (!baseURL) {
  throw new Error("Base URL is not defined.");
}

export default instance;


