import { Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import instance from "../../axios/axios-instance";
import Header from "../../Dashboard/header";

interface Statistic {
  metric: string;
  number: string;
  amount: string;
}

interface StatisticsTableProps {
  title: string;
  statistics: Array<Statistic>;
}

function InsightsOverview() {
  const [today, setToday] = useState<Statistic[]>([]);
  const [historic, setHistoric] = useState<Statistic[]>([]);

  function StatisticsTable(props: StatisticsTableProps) {
    const columns: ColumnsType<any> = [
      { title: "", dataIndex: "metric" },
      { title: "Number", dataIndex: "number" },
      { title: "Amount (R)", dataIndex: "amount" },
    ];
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Typography.Title level={4}>{props.title}</Typography.Title>
        <Table
          columns={columns}
          dataSource={props.statistics}
          style={{ width: "100%" }}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </Space>
    );
  }

  useEffect(() => {
    instance.get("/api/claims/insights-all").then(({ data }) => {
      interface StatData {
        standing: string;
        "Total Captured": string;
        "Captured Claims Amount": string;
      }
      const currencyFormat = new Intl.NumberFormat("af", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
      });
      function processStats(period: any) {
        return period.map((item: StatData, index: number) => {
          return {
            key: index,
            metric: item["standing"],
            number: item["Total Captured"],
            amount: currencyFormat.format(
              item["Captured Claims Amount"] as any
            ),
          };
        });
      }
      const statsToday: Statistic[] = processStats(data.statistics_today);
      const statsHistoric: Statistic[] = processStats(data.statistics_historic);
      setToday(statsToday);
      setHistoric(statsHistoric);
    });
  }, []);
  return (
    <Header title="Stats Overview">
      <Typography.Title level={1}>Statistics</Typography.Title>
      <Space direction="horizontal" size="large">
        <StatisticsTable statistics={today} title="Today" />
        <StatisticsTable statistics={historic} title="Historical" />
      </Space>
    </Header>
  );
}

export { InsightsOverview };
