import { Button, Card, Form, Input, Space, Typography } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/srs-logo.jpg";
import { AuthContext } from "../../context/AuthContext";
import "./login.css";
import { passwordRules, usernameRules } from "./login.validations";

const Login = () => {
  const navigate = useNavigate();

  const { loginUser } = useContext(AuthContext);

  return (
    <div className="login">
      <Card className="login-form">
        <Form autoComplete="false" onFinish={loginUser}>
          <Space direction="vertical">
            <Space direction="vertical" size="small">
              <img src={Logo} width="160" alt="Logo" />
              <Typography.Title level={4}>
                Structured Risk Solutions (Pty) Ltd.
              </Typography.Title>
            </Space>
            <Typography.Text type="secondary">
              Sign-in
            </Typography.Text>
            <Form.Item name="username" rules={usernameRules}>
              <Input placeholder="Username"></Input>
            </Form.Item>
            <Form.Item name="password" rules={passwordRules}>
              <Input.Password placeholder="Password"></Input.Password>
            </Form.Item>
            <Button type="primary" block htmlType="submit">
              Log in
            </Button>
            <Button
              type="link"
              block
              onClick={() => navigate("/password-reset")}
            >
              Forgot password
            </Button>
          </Space>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
