import { MemberDetail } from "../types/MemberDetail";

// Action Types
export const SET_MEMBER_DETAIL = "SET_MEMBER_DETAIL";

// Action Creators
export interface MemberDetailAction {
  type: typeof SET_MEMBER_DETAIL;
  payload: MemberDetail;
}

export const setMemberDetail = (
  memberDetail: MemberDetail
): MemberDetailAction => {
  return {
    type: SET_MEMBER_DETAIL,
    payload: memberDetail,
  };
};
