import { Button, Card, Form, Input, Space, Typography } from "antd";
import Logo from "../../assets/srs-logo.jpg";
import instance from "../../axios/axios-instance";
import "./forgotPassword.css";

const ForgotPassword = () => {
  const handleResetPassword = async (form: { email: string }) => {
    await instance.post(`/api/accounts/password-reset-request/`, {
      email: form.email,
    });
  };

  return (
    <div className="forgot-password">
      <Card className="forgot-password-form">
        <Form autoComplete="false" onFinish={handleResetPassword}>
          <Space direction="vertical">
            <img src={Logo} width="160" alt="Logo" />
            <Typography.Title level={4}>
              Structured Risk Solutions
            </Typography.Title>
            <Typography.Text type="secondary">
              Enter your email to reset password
            </Typography.Text>
            <Form.Item name="email">
              <Input placeholder="email" type="email"></Input>
            </Form.Item>
            <Button type="primary" block htmlType="submit">
              Reset
            </Button>
          </Space>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
