import {
  ADD_CLAIM,
  ADD_CLAIMS,
  CLEAR_CACHED_CLAIMS,
  CLEAR_CLAIMS,
  IS_LOADING,
  REMOVE_CLAIM,
  SET_CACHED_CLAIMS,
} from "../actions/ClaimsAction";

interface Claim {
  id: string; // Change 'string' to the actual type of your claim IDs
  // Add other properties for each claim as needed
}

interface State {
  claims: Claim[];
  oneclaim: any;
  isLoading: boolean;
  cachedClaims: any;
  // Use the 'Claim' interface for claims
}

interface AddClaimAction {
  type: typeof ADD_CLAIM;
  payload: Claim; // 'payload' is now of type 'Claim'
}

interface ClearClaimsAction {
  type: typeof CLEAR_CLAIMS;
}

interface RemoveClaimAction {
  type: typeof REMOVE_CLAIM;
  payload: number; // Change 'string' to the actual type of your claim ID
}

interface AddClaimsAction {
  type: typeof ADD_CLAIMS;
  payload: any; // 'payload' is now an array of 'Claim'
}

interface isLoading {
  type: typeof IS_LOADING;
  payload: boolean;
}

interface setCachedClaims {
  type: typeof SET_CACHED_CLAIMS;
  payload: any;
}

interface clearCachedClaims {
  type: typeof CLEAR_CACHED_CLAIMS;
}

type Action =
  | AddClaimAction
  | RemoveClaimAction
  | ClearClaimsAction
  | AddClaimsAction
  | isLoading
  | setCachedClaims
  | clearCachedClaims;

const initialState: State = {
  claims: [],
  isLoading: false,
  oneclaim: null,
  cachedClaims: {
    count: null,
    next: null,
    results: [],
  },
};

const compareById = (obj1: any, obj2: any): boolean => {
  return obj1.id === obj2.id;
};

const updateClaims = (
  oldClaims: Array<any>,
  newClaims: Array<any>
): Array<any> => {
  // Create a new array with modified elements from oldClaims
  let updatedClaims = oldClaims.map((oldClaim) => {
    // Find a matching claim from newClaims
    let newClaim = newClaims.find((newClaim) => {
      return compareById(oldClaim, newClaim);
    }
    );
    // If there is a match, return the new claim
    if (newClaim) {
      return newClaim;
    }
    // Otherwise, return the old claim
    else {
      return oldClaim;
    }
  });

  // Loop through the newClaims array
  for (let newClaim of newClaims) {
    // Check if there is no matching claim in updatedClaims
    if (
      !updatedClaims.some((updatedClaim) => compareById(updatedClaim, newClaim))
    ) {
      // Add the non-matching claim to the start of updatedClaims
      updatedClaims.unshift(newClaim);
    }
  }

  // Return the updated claims array
  return updatedClaims;
};

const ClaimReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ADD_CLAIM:
      return {
        ...state,
        claims: [...state.claims, action.payload],
      };
    case REMOVE_CLAIM:
      return {
        ...state,
        claims: state.claims.filter((claim, index) => index !== action.payload),
      };
    case CLEAR_CLAIMS:
      return {
        ...state,
        claims: [],
      };
    case ADD_CLAIMS:
      return {
        ...state,
        oneclaim: action.payload,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_CACHED_CLAIMS:
      if (action.payload.count) {
        const { count, next, results: latestResults } = action.payload;

        // Merge the latest object with the current state
        const newState = {
          count,
          next,
          results: Array.from(
            new Set([...state.cachedClaims.results, ...latestResults])
          ),
        };

        return {
          ...state,
          cachedClaims: newState,
        };
      } else {

        const { count, next, results } = state.cachedClaims;

       
        
        const updatedClaimsArray = updateClaims(results, action?.payload);

        const newState = {
          count,
          next,
          results: updatedClaimsArray,
        };

        return {
          ...state,
          cachedClaims: newState,
        };
      }
    case CLEAR_CACHED_CLAIMS:
      return {
        ...state,
        cachedClaims: null,
      };

    default:
      return state;
  }
};

export default ClaimReducer;
