import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../axios/axios-instance";

interface UserToken {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  id: number;
  username: string;
  email: string;
  name: string;
  surname: string;
  is_active: boolean;
  is_claim_assessor: boolean;
  is_claim_manager: boolean;
  is_manager: boolean;
  is_finance: boolean;
  is_sales_person: boolean;
  is_data_administrator: boolean;
}

interface AuthContextType {
  authTokens: string | null;
  loginUser: (e: any) => void;
  logoutUser: () => void;
  user: UserToken | null;
}

export const AuthContext = React.createContext<AuthContextType>({
  authTokens: null,
  loginUser: () => {},
  user: null,
  logoutUser: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const roles = [
  "is_claim_assessor",
  "is_claim_manager",
  "is_manager",
  "is_finance",
  "is_data_administrator",
  "is_salesperson",
];

const homePages: any = {
  is_claim_assessor: "/view-claims",
  is_claim_manager: "/review-claims",
  is_manager: "/insights",
  is_finance: "awaiting-payments",
  is_data_administrator: "/generate-quote",
  is_salesperson: "/generate-quote",
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const initialAuthTokens = localStorage.getItem("authTokens");
  const initialUser = initialAuthTokens ? jwt_decode(initialAuthTokens) : null;

  const [authTokens, setAuthTokens] = useState<string | null>(
    initialAuthTokens
  );
  const [user, setUser] = useState<any>(initialUser);
  const navigate = useNavigate();

  const loginUser = async (credentials: {
    username: string;
    password: string;
  }) => {
    const response = await instance.post("/api/token/", credentials);

    const data = await response.data;
    setAuthTokens(data);
    const decodedToken = jwt_decode(data.access);
    setUser(decodedToken);
    localStorage.setItem("authTokens", JSON.stringify(data));

    let selectedRole = "";
    for (const role of roles) {
      // override possible and okay
      if (user[role]) selectedRole = role;
    }

    if (!selectedRole) throw Error("User has no roles assigned!");

    window.location.href = homePages[selectedRole];
  };

  const logoutUser = async () => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens")!);
    if (authTokens) {
      await instance
        .post("/api/accounts/logout/", { refresh_token: authTokens.refresh })
        .finally(() => {
          setAuthTokens(null);
          setUser(null);
          navigate("/login");

          localStorage.removeItem("authTokens");
        });
    }
  };

  const contextData = {
    authTokens,
    loginUser,
    user,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
