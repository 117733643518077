import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { ColumnType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import Header from "../../Dashboard/header";
import instance from "../../axios/axios-instance";
import { AuthContext } from "../../context/AuthContext";
import { Quote } from "./generateQuote.interfaces";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

function GenerateQuote() {
  const { user } = useContext(AuthContext);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [isGenerateQuoteModalOpen, setGenerateQuoteModalOpen] = useState(false);
  const [isQuotePDFModalOPen, setQuotePDFModalOpen] = useState(false);
  const [isEMUModalOpen, setEMUModalOpen] = useState(false);
  const [isSaveQuoteModalOpen, setSaveQuoteModalOpen] = useState(false);
  const [isCleanDataModalOpen, setCleanDataModalOpen] = useState(false);
  const [generateQuoteFormData, setGenerateQuoteFormData] = useState({
    schemeName: "",
    schemeNickName: "",
    file: null,
    administratorComments: "",
    configs: [],
    methodology: "",
    quoteMethod: "",
  });
  const [salesFeedbackModalVisible, setSalesFeedbackModalVisible] =
    useState(false);
  const [reviewQuoreModalVisible, setReviewQuoteModalVisible] = useState(false);
  const [captureModalVisible, setCaptureModalVisible] = useState(false);
  const [selectdQuote, setSelectdQuote] = useState<any>(null);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [cleanDataFile, setCleanDataFile] = useState<UploadFile | null>(null);
  const [quotePDFFile, setQuotePDFFile] = useState<UploadFile | null>(null);
  const [EMUFile, setEMUFile] = useState<UploadFile | null>(null);
  const [salesPeople, setSalesPeople] = useState();
  const [saveQuoteForm] = Form.useForm();
  const [generateQuoteForm] = Form.useForm();
  const [cleanDataForm] = Form.useForm();
  const [quotePDFForm] = Form.useForm();
  const [EMUForm] = Form.useForm();
  const [salesFeedbackForm] = Form.useForm();
  const [reviewQuoteForm] = Form.useForm();
  const [captureForm] = Form.useForm();
  const [feedbackStatuses, setFeedbackStatuses] = useState<any[]>([]);
  const [reviewerStatuses, setReviewerStatuses] = useState<any[]>([]);
  const [adminStatuses, setAdminStatuses] = useState<any[]>([]);
  const [schemes, setSchemes] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  async function getQuotes() {
    const response = await instance.get("/api/policies/quotes/");

    setQuotes(
      response.data.quotes.map((quote: any) => ({ key: quote.id, ...quote }))
    );
  }
  async function getSalespersons() {
    const response = await instance.get("/api/claims/assessor/");
    const users = response.data.map((item: any) => {
      return {
        label: item.username,
        value: item.id,
      };
    });
    setSalesPeople(users);
  }
  useEffect(() => {
    getQuotes();
    getSalespersons();
  }, []);

  function downloadCSVFile(filename: string, contents: string) {
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
  }

  function downloadQuoteCSVFile(response: {
    headers: { [x: string]: any };
    data: BlobPart;
  }) {
    const contentDisposition = response.headers["Content-Disposition"];
    let filename = "download.csv";
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
  }

  async function generateQuote() {
    const { selectedPlans, plans } = generateQuoteForm.getFieldsValue();
    const form = new FormData();
    form.append("scheme_name", generateQuoteFormData.schemeName);
    // form.append("scheme_nick_name", generateQuoteFormData.schemeNickName);
    form.append("quote_method", generateQuoteFormData.quoteMethod);
    form.append("rates_config", JSON.stringify(plans));

    form.append("file", file as any);
    const results = await instance.post("api/policies/generate-quote/", form, {
      headers: {
        "Content-Disposition": `attachment; filename="${file ? file.name : "upload-file.xlsx"
          }"`,
      },
    });

    // downloadCSVFile("quote.csv", results.data.quote.join(""));
    // downloadCSVFile("quote_summary.csv", results.data.quote_summary.join(""));
    // downloadCSVFile(
    //   "scheme_summary_report.csv",
    //   results.data.scheme_summary_report.join("")
    // );

    // Loop over each file in the response
    for (const file of results.data) {
      downloadCSVFile(file.file_name, file[file.file_name].join(""));
    }


    setGenerateQuoteModalOpen(false);
    setGenerateQuoteFormData({
      file: null,
      schemeName: "",
      schemeNickName: "",
      administratorComments: "",
      configs: [],
      methodology: "",
      quoteMethod: "",
    });
    setFile(null);
  }

  async function saveQuote() {
    await instance.post("api/policies/save-quote/", {
      ...saveQuoteForm.getFieldsValue(),
      created_by: user!.user_id,
    });

    saveQuoteForm.resetFields();
    setSaveQuoteModalOpen(false);
    getQuotes();
  }

  async function cleanData() {
    const form = new FormData();

    form.append("file", cleanDataFile as any);
    form.append("scheme_name", cleanDataForm.getFieldValue("scheme_name"));
    form.append(
      "scheme_nick_name",
      cleanDataForm.getFieldValue("scheme_nick_name")
    );
    const response = await instance.post("api/policies/clean-data/", form, {
      headers: {
        "Content-Disposition": `attachment; filename="${cleanDataFile ? cleanDataFile.name : "upload-file.xlsx"
          }"`,
      },
    });
    setCleanDataModalOpen(false);
    // downloadCSVFile("result.csv", response.data);
    downloadCSVFile(response.data?.file_name, response.data[response.data?.file_name].join(""));
    cleanDataForm.resetFields();
  }

  const getScheme = async () => {
    try {
      const response = await instance.get(
        `api/claims/underwriter/?format=json`
      );
      const refinedSchemes = response?.data?.map((item: any) => {
        return { value: item.id, label: item.scheme_name };
      });
      setSchemes(refinedSchemes);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getScheme();
  }, []);

  const columns: Array<ColumnType<Quote>> = [
    { title: "Generated by", dataIndex: "created_by" },
    { title: "Scheme name", dataIndex: "scheme_name" },
    {
      title: "Quote",
      dataIndex: "url_quote",
      render: (value, record) => (
        <a target="_blank" href={record.url_quote} rel="noreferrer">
          <Button type="link" size="small">
            Quote
          </Button>
        </a>
      ),
    },
    {
      title: "Membership Data",
      dataIndex: "url_original_membership",
      render: (value, record) => (
        <a
          target="_blank"
          href={record.url_original_membership}
          rel="noreferrer"
        >
          <Button type="link" size="small">
            Original data
          </Button>
        </a>
      ),
    },
    {
      title: "Quote Summary",
      render: (value, record) => (
        <a target="_blank" href={record.url_quote_summary} rel="noreferrer">
          <Button type="link" size="small">
            Quote summary
          </Button>
        </a>
      ),
    },
    { title: "Salesperson", dataIndex: "sales_person" },
    {
      title: "Created date",
      render: (value, record) =>
        new Date(record.date_created).toLocaleDateString(),
    },
    {
      title: "Updated date",
      render: (value, record) =>
        new Date(record.date_updated).toLocaleDateString(),
    },
    { title: "Status", dataIndex: "progress_status" },
    { title: "Review Standing", dataIndex: "review_standing" },
    {
      title: "Action",
      render: (value, record) => (
        <Space direction="vertical">
          {canViewCaptureButton(record) && (
            <Button
              type="link"
              onClick={() => {
                setCaptureModalVisible(true);
                setSelectdQuote(record);
              }}
            >
              Capture
            </Button>
          )}
          {canViewQuoteReviewButton() && (
            <Button
              type="link"
              onClick={() => {
                setReviewQuoteModalVisible(true);
                setSelectdQuote(record);
              }}
            >
              Quote Review
            </Button>
          )}
          {canViewSalesFeedbackButton() && (
            <Button
              type="link"
              onClick={() => {
                setSalesFeedbackModalVisible(true);
                setSelectdQuote(record);
              }}
            >
              Sales Feedback
            </Button>
          )}
        </Space>
      ),
    },
  ];

  // const memberTypes = ["Principal Member", "Spouse", "Child", "Extended"];
  const [memberTypes, setMemberTypes] = useState<any[]>([]);

  useEffect(() => {
    const fetchMemberTypes = async () => {
      const response = await instance.get("/api/administration/member-types");
      setMemberTypes(
        response?.data.map((item: any) => {
          return { label: item.member_type, value: item.id };
        })
      );
    };
    fetchMemberTypes();
  }, []);

  function canViewCaptureButton(quote: Quote) {
    return (
      quote.administrator_status !== "Ready for Review" &&
      (user!.is_manager || user!.is_data_administrator)
    );
  }

  function canViewQuoteReviewButton() {
    return user!.is_manager;
  }

  function canViewSalesFeedbackButton() {
    return user!.is_manager || user!.is_sales_person;
  }

  function cleanDataFormIsValid() {
    const data = cleanDataForm.getFieldsValue();
    return data.file && true;
  }

  function quotePDFFormIsValid() {
    const data = quotePDFForm.getFieldsValue();
    return data.file && data.scheme_name && data.scheme_number;
  }

  function EMUFormIsValid() {
    const data = EMUForm.getFieldsValue();
    return data.file;
  }

  const getFeedbackStatuses = async () => {
    try {
      const response = await instance.get(
        `/api/policies/sales-feedback/?format=json`
      );
      setFeedbackStatuses(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getFeedbackStatuses();
  }, []);

  const getReviewerDecisions = async () => {
    try {
      const response = await instance.get(
        `/api/policies/reviewer-decision/?format=json`
      );
      setReviewerStatuses(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getReviewerDecisions();
  }, []);

  const getAdminStatuses = async () => {
    try {
      const response = await instance.get(
        `/api/policies/administrator-statuses/?format=json`
      );
      console.log("admin statuses: ", response?.data)
      setAdminStatuses(response?.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAdminStatuses();
  }, []);

  console.log("feedback statuses", feedbackStatuses);
  console.log("reviewr decisions", reviewerStatuses);
  console.log("admin statuses", adminStatuses);
  console.log("schemes", schemes);

  const handleQuotePDF = async () => {
    const form = new FormData();
    form.append("file", quotePDFFile as any);
    form.append("scheme_name", quotePDFForm.getFieldValue("scheme_name"));
    form.append("scheme_number", quotePDFForm.getFieldValue("scheme_number"));
    const response = await instance.post(
      "api/policies/generate-quote-pdf/",
      form,
      {
        headers: {
          "Content-Disposition": `attachment; filename="${quotePDFFile ? quotePDFFile.name : "upload-file.xlsx"
            }"`,
        },
      }
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Successful"));
      const blob = new Blob([response.data[response.data?.file_name]], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = response.data?.file_name;
      link.click();

      window.URL.revokeObjectURL(url);
    }

    setQuotePDFModalOpen(false);
  };

  const handleEMUTemplate = async () => {
    const form = new FormData();
    form.append("file", EMUFile as any);
    const response = await instance.post(
      "api/policies/generate-emu-template/",
      form,
      {
        headers: {
          "Content-Disposition": `attachment; filename="${EMUFile ? EMUFile.name : "upload-file.xlsx"
            }"`,
        },
      }
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Successful"));
      // downloadCSVFile("EMU_Template.csv", response.data);
      downloadCSVFile(response.data?.file_name, response.data[response.data?.file_name].join(""));
    }

    setEMUModalOpen(false);
  };

  return (
    <Header title="Quotes">
      <Flex justify="end">
        <Space>
          <Button type="primary" onClick={() => setEMUModalOpen(true)}>
            EMU Template
          </Button>
          <Button type="primary" onClick={() => setQuotePDFModalOpen(true)}>
            Quote PDF
          </Button>
          <Button type="primary" onClick={() => setCleanDataModalOpen(true)}>
            Clean Data
          </Button>
          <Button
            type="primary"
            onClick={() => setGenerateQuoteModalOpen(true)}
          >
            Generate Quote
          </Button>
          <Button type="primary" onClick={() => setSaveQuoteModalOpen(true)}>
            Save Quote
          </Button>
        </Space>
      </Flex>
      <Modal
        title="Generate Quote"
        open={isGenerateQuoteModalOpen}
        onOk={generateQuote}
        onCancel={() => {
          setGenerateQuoteModalOpen(false);
          setGenerateQuoteFormData({
            file: null,
            schemeName: "",
            schemeNickName: "",
            administratorComments: "",
            configs: [],
            methodology: "",
            quoteMethod: "",
          });
          setFile(null);
        }}
      >
        <Form layout="vertical" form={generateQuoteForm}>
          <Form.Item label="Scheme name">
            <Input
              name="scheme_name"
              type="text"
              value={generateQuoteFormData.schemeName}
              onChange={(event) => {
                setGenerateQuoteFormData({
                  ...generateQuoteFormData,
                  schemeName: event.target.value,
                });
              }}
            />
          </Form.Item>
          {/*  <Form.Item label="Methodology">
            <Select
              showSearch
              placeholder="Select Methodology"
              optionFilterProp="children"
              onChange={(value) =>
                setGenerateQuoteFormData((prev) => ({
                  ...prev,
                  methodology: value,
                }))
              }
              options={[
                {
                  label: "By Principal Member Age",
                  value: "byPrincipalMemberAge",
                },
                { label: "By Policy Max Age", value: "byPolicyMaxAge" },
                { label: "Placeholder", value: "placeholder" },
              ]}
            />
          </Form.Item> */}
          <Form.Item label="Quote Method" name="quoteMethod">
            <Select
              showSearch
              placeholder="Select Quote Method"
              optionFilterProp="children"
              onChange={(value) =>
                setGenerateQuoteFormData((prev) => ({
                  ...prev,
                  quoteMethod: value,
                }))
              }
              options={[
                {
                  label: "By Principal Member Age",
                  value: "byPrincipalMemberAge",
                },
                { label: "By Policy Max Age", value: "byPolicyMaxAge" },
                { label: "Placeholder", value: "placeholder" },
              ]}
            />
          </Form.Item>
          {/* <Form.Item label="Scheme nickname">
            <Input
              name="scheme_nick_name"
              type="text"
              value={generateQuoteFormData.schemeNickName}
              onChange={(event) => {
                setGenerateQuoteFormData({
                  ...generateQuoteFormData,
                  schemeNickName: event.target.value,
                });
              }}
            />
          </Form.Item> */}
          <Form.Item label="Select plans" name="plans" >
            <Select
              onChange={(value) => {
                setGenerateQuoteFormData({
                  ...generateQuoteFormData,
                  configs: value,
                });
              }}
              mode="multiple"
              allowClear
              options={[
                "Family Plan",
                "Single Parent Plan",
                "Individual Plan",
                "M + 5",
                "M + 13",
                "M + 14",
              ].map((v) => ({ label: v, value: v }))}
            />
          </Form.Item>
          {/* generateQuoteFormData.configs.map((config) => (
            <Form.Item label={config} name={['selectedPlans', config]} >
              <Select
                mode="multiple"
                allowClear
                options={memberTypes}
              />
            </Form.Item>
          )) */}
          <Form.Item label="Select membership data">
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              onChange={(event) => {
                setFile(event.file as any);
              }}
              accept=".xlsx,.csv"
            >
              <Button>
                <span>Click to upload</span>
              </Button>
            </Upload>
          </Form.Item>
          {/* <Form.Item label="Administrator comments">
            <TextArea
              value={generateQuoteFormData.administratorComments}
              onChange={(event) => {
                setGenerateQuoteFormData({
                  ...generateQuoteFormData,
                  administratorComments: event.target.value,
                });
              }}
            />
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        title="Save Quote"
        open={isSaveQuoteModalOpen}
        onOk={saveQuote}
        onCancel={() => {
          saveQuoteForm.resetFields();
          setSaveQuoteModalOpen(false);
        }}
      >
        <Form layout="vertical" form={saveQuoteForm}>
          <Form.Item label="Scheme name" name="scheme_name">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Salesperson" name="sales_person">
            <Select options={salesPeople} />
          </Form.Item>
          <Form.Item label="Quote URL" name="url_quote">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Quote Summary URL" name="url_quote_summary">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Original Membership URL"
            name="url_original_membership"
          >
            <Input type="text" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Clean Data"
        open={isCleanDataModalOpen}
        onOk={cleanData}
        onCancel={() => {
          setCleanDataModalOpen(false);
          cleanDataForm.resetFields();
        }}
        okButtonProps={{ disabled: !cleanDataFormIsValid() }}
      >
        <Form layout="vertical" form={cleanDataForm}>
          <Form.Item required label="File" name="file">
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              onChange={(event) => {
                setCleanDataFile(event.file as any);
              }}
              accept=".xlsx"
            >
              <Button>
                <span>Click to upload</span>
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Capture"
        open={captureModalVisible}
        onOk={async () => {
          const response = await instance.put(
            "api/policies/save-quote-updates?id=" + selectdQuote.id,
            { ...captureForm.getFieldsValue(), action: "capture" }
          );
          if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Captured successfully"));
          }

          setCaptureModalVisible(false);
        }}
        onCancel={() => {
          captureForm.resetFields();
          setCaptureModalVisible(false);
        }}
      >
        <Form layout="vertical" form={captureForm} initialValues={selectdQuote}>
          <Form.Item name="url_quote" label="Quote URL">
            <Input />
          </Form.Item>
          <Form.Item name="url_original_membership" label="Original Data">
            <Input />
          </Form.Item>
          <Form.Item name="administrator_status" label="Admin Status">
            <Select
              showSearch
              options={adminStatuses?.length > 0 ? adminStatuses?.map((v) => ({
                label: v.administrator_status,
                value: v.id,
              })) : []}
              placeholder="Select Admin Status"
              optionFilterProp="children"
            />
          </Form.Item>
          <Form.Item label="Comment" name="administrator_comments">
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Review Quote"
        open={reviewQuoreModalVisible}
        onCancel={() => {
          setReviewQuoteModalVisible(false);
          reviewQuoteForm.resetFields();
        }}
        onOk={async () => {
          const response = await instance.put(
            "api/policies/save-quote-updates?id=" + selectdQuote.id,
            { ...reviewQuoteForm.getFieldsValue(), action: "review_quote" }
          );
          if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Reviewed successfully"));
            reviewQuoteForm.resetFields()
          }
          reviewQuoteForm.resetFields();
          setReviewQuoteModalVisible(false);
        }}
      >
        <Form layout="vertical" form={reviewQuoteForm}>
          <Form.Item name="url_quote" label="Quote URL">
            <Input />
          </Form.Item>
          <Form.Item name="url_original_membership" label="Original Data">
            <Input />
          </Form.Item>
          <Form.Item name="reviewer_decision" label="Review Decision">
            <Select
              showSearch
              options={reviewerStatuses?.map((v) => ({
                label: v.reviewer_decision,
                value: v.id,
              }))}
              placeholder="Select Review Decision"
              optionFilterProp="children"
            />
          </Form.Item>
          <Form.Item name="manager_comments" label="Comment">
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Sales feedback"
        open={salesFeedbackModalVisible}
        onOk={async () => {
          const response = await instance.put(
            "api/policies/save-quote-updates?id=" + selectdQuote.id,
            {
              id: selectdQuote.id,
              ...salesFeedbackForm.getFieldsValue(),
              action: "sales_feedback"
            }
          );
          if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Feedback sent"));
            salesFeedbackForm.resetFields()
          }
          setSalesFeedbackModalVisible(false);
        }}
        onCancel={() => {
          setSalesFeedbackModalVisible(false);
          salesFeedbackForm.resetFields()
        }}
      >
        <Form layout="vertical" form={salesFeedbackForm}>
          <Form.Item label="Sales Feedback Status" name="sales_feedback">
            <Select
              showSearch
              options={feedbackStatuses?.map((v) => ({
                label: v.sales_feedback,
                value: v.id,
              }))}
              placeholder="Select Sales Feedback"
              optionFilterProp="children"
            />
          </Form.Item>
          <Form.Item label="Sales comment" name="sales_comments">
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Quote PDF"
        open={isQuotePDFModalOPen}
        onOk={handleQuotePDF}
        onCancel={() => {
          setQuotePDFModalOpen(false);
          quotePDFForm.resetFields();
        }}
        okButtonProps={{ disabled: !quotePDFFormIsValid() }}
      >
        <Form layout="vertical" form={quotePDFForm}>
          <Form.Item name="scheme_name" label="Scheme Name">
            <Input />
          </Form.Item>
          <Form.Item name="scheme_number" label="Scheme Number">
            <Input />
          </Form.Item>
          <Form.Item required label="File" name="file">
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              onChange={(event) => {
                setQuotePDFFile(event.file as any);
              }}
              accept=".xlsx,.csv"
            >
              <Button>
                <span>Click to upload</span>
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="EMU Template"
        open={isEMUModalOpen}
        onOk={handleEMUTemplate}
        onCancel={() => {
          setEMUModalOpen(false);
          EMUForm.resetFields();
        }}
        okButtonProps={{ disabled: !EMUFormIsValid() }}
      >
        <Form layout="vertical" form={EMUForm}>
          <Form.Item required label="File" name="file">
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              onChange={(event) => {
                setEMUFile(event.file as any);
              }}
              accept=".csv,.xslx"
            >
              <Button>
                <span>Click to upload</span>
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        scroll={{ x: 1300, y: windowHeight - 320 }}
        dataSource={quotes}
        columns={columns}
      />
    </Header>
  );
}

export { GenerateQuote };
