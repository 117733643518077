import { Button, Flex, Input, Modal, Space, Table } from "antd";
import { useState } from "react";
import instance from "../../../axios/axios-instance";
import { IPrincipalMemember } from "../../../redux/types/principalMember";

export interface SearchPrincipalMemberProps {
  open: boolean;
  setOpen(open: boolean): void;
  onOk(member: IPrincipalMemember & { key: any }): void;
  onCreate(): void;
}

function SearchPrincipalMembers(props: SearchPrincipalMemberProps) {
  const [query, setQuery] = useState("");
  const [dataSource, setDataSource] = useState<Array<IPrincipalMemember & { key: any}>>([]);
  const [selectedMember, setSelectedMember] = useState<
    (IPrincipalMemember & { key: any }) | null
  >();

  function performSearch() {
    instance
      .get(`/api/claims/principal/?query=${query}`)
      .then((response) => {
        setDataSource(
          response.data.map((member: any) => {
            return {
              key: member.id,
              ...member,
            };
          })
        );
      });
  }

  const columns = [
    { key: "full_names", dataIndex: "full_names", title: "Full names" },
    { key: "surname", dataIndex: "surname", title: "Surname" },
    {
      key: "idorPassport",
      title: "ID/Passport",
      render: (v: any) => {
        return v.id_number || v.passport;
      },
    },
  ];

  return (
    <Modal
      open={props.open}
      okButtonProps={{ disabled: !selectedMember }}
      onOk={() => {
        props.setOpen(false);
        props.onOk(selectedMember!);
        setQuery("");
        setDataSource([]);
        setSelectedMember(null);
      }}
      onCancel={() => {
        props.setOpen(false);
        setQuery("");
        setDataSource([]);
        setSelectedMember(null);
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Flex>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value.trim())}
            placeholder="Enter search to begin"
          ></Input>
          <Button
            style={{ marginLeft: "8px" }}
            disabled={!query}
            onClick={() => performSearch()}
          >
            Search
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={props.onCreate}>
            Create new
          </Button>
        </Flex>
        <Table
          rowSelection={{
            type: "radio",
            onChange: (value) => {
              const member = dataSource.find(
                (member) => member.key === value[0]
              );
              setSelectedMember(member);
            },
          }}
          columns={columns}
          dataSource={dataSource}
        ></Table>
      </Space>
    </Modal>
  );
}

export { SearchPrincipalMembers };
