import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./insights.css";
import Header from "../Dashboard/header";
import { getClaims } from "../redux/actions/ClaimsAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import InsightsModal from "./InsightsModal";
import instance from "../axios/axios-instance";

interface ReqParameters {
  statistic_output: string;
  review_standing: string;
  progress_status: string;
}
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

type DataType = {
  progress_status: string;
  count: number;
  sum: number;
};

type PropsType = {
  data: any;
  title: string;
  setIsModalOpen: any;
  setReqParameters: any;
  setSelectedCount: any;
};

const StatsTable: React.FC<PropsType> = ({
  data,
  title,
  setIsModalOpen,
  setReqParameters,
  setSelectedCount,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onRowClick = (record: DataType, review_standing: string) => {
    return {
      onClick: () => {
        console.log("onclick record", record, "");
        const isToday = title === "Today Stats";

        // Set the reqParameters
        setReqParameters({
          statistic_output: isToday ? "today" : "historic",
          review_standing: review_standing,
          progress_status: record.progress_status,
        });

        const recordCount = String(record?.count);
        setSelectedCount(recordCount);

        setIsModalOpen(true);
      },
    };
  };

  const reviewedData = data?.reviewed.claim_status;
  const pendingData = data?.pending.claim_status;

  const NoWrap = ({ text }: { text: string }) => (
    <div style={{ whiteSpace: "nowrap", minWidth: "112px" }}>{text}</div>
  );

  const columns = (sectionTitle: string) => [
    {
      title: sectionTitle,
      dataIndex: "progress_status",
      key: "progress_status",
      className: "column-metric",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      className: "column-count",
    },
    {
      title: "Sum",
      dataIndex: "sum",
      key: "sum",
      render: (text: string, record: any) => {
        const amount = Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 0,
        }).format(Math.trunc(record.sum));
        return <NoWrap text={amount}></NoWrap>;
      },
      className: "column-sum",
    },
  ];

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 0,
        justifyItems: "flex-start",
        // height: "calc(50vh - 70px)", // Set the height of each table to half of the viewport height minus half of the total vertical space occupied by the Header component
        overflowY: "auto", // Enable scrolling within the table
      }}
    >
      <h5>{title}</h5>
      <Table
        onRow={(record: DataType) => onRowClick(record, "Reviewed")}
        columns={columns("Reviewed")}
        dataSource={reviewedData}
        bordered
        className="stats-table"
        // pagination={{
        //   total: reviewedData?.length,
        //   showTotal: (total) => `${total} rows`,
        //   pageSize: 3,
        // }}
        pagination={false}
        style={{
          width: 700,
        }}
        scroll={{ y: 150 }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} className="summary-cell">
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="summary-cell">
                {
                  <NoWrap
                    text={Intl.NumberFormat("en-ZA", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(data?.reviewed?.total?.count)}
                  ></NoWrap>
                }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="summary-cell">
                {
                  <NoWrap
                    text={Intl.NumberFormat("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                      minimumFractionDigits: 0,
                    }).format(Math.trunc(data?.reviewed?.total?.sum))}
                  ></NoWrap>
                }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <Table
        onRow={(record: DataType) => onRowClick(record, "Pending Review")}
        columns={columns("Pending")}
        dataSource={pendingData}
        bordered
        className="stats-table pending-table"
        // pagination={{
        //   total: reviewedData?.length,
        //   showTotal: (total) => `${total} rows`,
        //   pageSize: 3,
        // }}
        pagination={false}
        style={{ width: 700 }}
        scroll={{ y: 150 }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} className="summary-cell">
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="summary-cell">
                {
                  <NoWrap
                    text={Intl.NumberFormat("en-ZA", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(data?.pending?.total?.count)}
                  ></NoWrap>
                }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="summary-cell">
                {
                  <NoWrap
                    text={Intl.NumberFormat("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                      minimumFractionDigits: 0,
                    }).format(Math.trunc(data?.pending?.total?.sum))}
                  ></NoWrap>
                }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </section>
  );
};

const App: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reqParameters, setReqParameters] = useState<ReqParameters>({
    statistic_output: "",
    review_standing: "",
    progress_status: "",
  });
  const [selectedCount, setSelectedCount] = useState<string>("");
  const tableData = useSelector((state: any) => {
    return state.claims.oneclaim?.results || [];
  });
  const [data, setData] = useState<any>(null);

  // fetch some data

  // useEffect(() => {
  //   dispatch(
  //     getClaims({
  //       page: 1,
  //       page_size: 10,
  //     })
  //   );
  // }, []);

  // fetch insights data
  useEffect(() => {
    async function FetchData() {
      try {
        const response = await instance.get(
          `/api/claims/insights/?format=json`
        );
        console.log("insights response", response);
        if (response.statusText === "OK") {
          setData(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    }
    FetchData();
  }, []);
  // Assuming the data from the API is in the following format
  const apiData = [
    {
      reviewed: [
        { metric: "Paid & Closed", count: 10, sum: 100 },
        { metric: "Claim In Payment", count: 5, sum: 50 },
        { metric: "Rejected", count: 2, sum: 20 },
        { metric: "Action For Payment", count: 7, sum: 70 },
      ],
      pending: [
        { metric: "Paid & Closed", count: 8, sum: 80 },
        { metric: "Claim In Payment", count: 6, sum: 60 },
        { metric: "Rejected", count: 3, sum: 30 },
        { metric: "Action For Payment", count: 4, sum: 40 },
      ],
    },
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Header title="Insights">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 30,
          height: "calc(100vh - 140px)", // Set the height of the container to the viewport height minus the total vertical space occupied by the Header component
          overflow: "hidden", // Hide the scrollbar of the page
        }}
      >
        <StatsTable
          data={data?.today_statistics}
          title="Today Stats"
          setIsModalOpen={setIsModalOpen}
          setReqParameters={setReqParameters}
          setSelectedCount={setSelectedCount}
        />
        {/* Assuming you have similar data for historic stats */}
        <StatsTable
          data={data?.historic_statistics}
          title="Historic Stats"
          setIsModalOpen={setIsModalOpen}
          setReqParameters={setReqParameters}
          setSelectedCount={setSelectedCount}
        />
      </div>
      {isModalOpen && (
        <InsightsModal
          isModalOpen={isModalOpen}
          tableData={tableData}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          progress_status={reqParameters.progress_status}
          review_standing={reqParameters.review_standing}
          statistic_output={reqParameters.statistic_output}
          selectedCount={selectedCount}
        />
      )}
    </Header>
  );
};

export default App;
