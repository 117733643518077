import { SET_PM_FILE, setPMFileAction } from "../actions/PMFileAction";
import { IPMFile } from "../types/PMFile";

const initialState: IPMFile = {
  supportFile: null,
  additionFile: null,
  documentationUrl: ''
};

const PMFileReducer = (
  state = initialState,
  action: setPMFileAction
): IPMFile => {
  switch (action.type) {
    case SET_PM_FILE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default PMFileReducer;
