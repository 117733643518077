import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import saIdParser from "south-african-id-parser";
import { createClaimant } from "../redux/actions/ClaimantActions";
import { setDataError } from "../redux/actions/DataErrorActions";

interface modelProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;
const ClaimantCreate: React.FC<modelProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  // const [isModalOpen, setIsModalOpen] = useState(modelProps.isModalOpen);
  const [claimant, setClaimant] = useState({
    name_of_claimant: "",
    surname_of_claimant: "",
    id_type: "none",
    id_of_claimant: "",
    passport: "",
    date_of_birth: "",
    gender: "",
    birth_country: "",
    residence_country: "",
    contact_number: "",
    address: "",
  });

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [idNumberError, setIdNumberError] = useState("");
  const dataError = useSelector((state: any) => state.dataError);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    if (e.target.name === "id_of_claimant") {
      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      const limitedValue = onlyNumbers.substr(0, 13); // Limit to 13 characters

      // Check if the ID number is exactly 13 characters
      if (limitedValue.length !== 13) {
        setIdNumberError("ID number must be exactly 13 characters.");
      } else {
        if (!saIdParser.validate(e.target.value)) {
          setIdNumberError("ID number is not valid");
        } else {
          setIdNumberError(""); // Clear the error message if the ID number is valid
        }
      }

      setClaimant((prevValues) => ({
        ...prevValues,
        [e.target.name]: limitedValue,
      }));
    } else if (e.target.name === "contact_number") {
      let onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      let limitedValue = onlyNumbers.substr(0, 10);
      if (limitedValue.length !== 10) {
        setPhoneNumberError("Phone number must be exactly 10 digits");
      } else {
        setPhoneNumberError("");
      }
      setClaimant((prevValues) => ({
        ...prevValues,
        [e.target.name]: limitedValue,
      }));
    } else {
      setClaimant((prevValues) => ({
        ...prevValues,
        [e.target.name]: e.target.value,
      }));
    }

    setClaimant({
      ...claimant,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let idNumber = claimant.id_of_claimant;
    if (idNumber.trim().length === 13) {
      const isValid = saIdParser.validate(idNumber);
      if (!isValid) {
        dispatch(
          setDataError([
            ...dataError,
            { idNumber: "Create Claimant ID number is not valid" },
          ])
        );
      }
    }
  }, [claimant.id_of_claimant, dispatch]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleOk();
  };

  const handleIdTypeChange = (e: { target: { value: any } }) => {
    const selectedIdType = e.target.value;
    setClaimant({
      ...claimant,
      id_type: selectedIdType,
    });
  };

  const handleOk = () => {
    const model = {
      name_of_claimant: claimant.name_of_claimant,
      surname_of_claimant: claimant.surname_of_claimant,
      id_type: "none",
      id_of_claimant: claimant.id_of_claimant,
      passport: claimant.passport,
      date_of_birth: claimant.date_of_birth,
      gender: claimant.gender,
      birth_country: claimant.birth_country,
      residence_country: claimant.residence_country,
      contact_number: claimant.contact_number,
      address: claimant.address,
    };

    dispatch(createClaimant(model));

    setIsModalOpen(false);

    setClaimant({
      name_of_claimant: "",
      surname_of_claimant: "",
      id_type: "none",
      id_of_claimant: "",
      passport: "",
      date_of_birth: "",
      gender: "",
      birth_country: "",
      residence_country: "",
      contact_number: "",
      address: "",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  /*
  useEffect(() => {
    setIsModalOpen(modelProps.isModalOpen);
  }, [modelProps.isModalOpen]);
  */
  const showIdTypeFields = () => {
    const { id_type } = claimant;
    if (id_type === "1") {
      return (
        <div className="col-md-4" id="rsa-id-type">
          <div className="form-group">
            <label htmlFor="floatingID">ID Number</label>
            <input
              type="text"
              value={claimant.id_of_claimant}
              onChange={handleInputChange}
              className="form-control"
              id="floatingID"
              maxLength={13}
              name="id_of_claimant"
              placeholder="ID Number"
            />
            {idNumberError && (
              <span style={{ color: "red" }} className="help-block">
                {idNumberError}
              </span>
            )}
          </div>
        </div>
      );
    } else if (id_type === "2") {
      return (
        <div className="col-md-4" id="passport-id-type">
          <div className="form-group">
            <label htmlFor="floatingpass">Passport</label>
            <input
              type="text"
              value={claimant.passport}
              onChange={handleInputChange}
              required
              className="form-control"
              id="floatingpass"
              name="passport"
              placeholder="Passport"
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      okText="Save"
      onOk={handleOk}
    >
      <div>
        <div className="card-body">
          <h5 className="card-title" style={{ color: "#01AAAB" }}>
            Add a new claimant
          </h5>

          <p></p>

          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-4">
              <label htmlFor="floatingfullnames">Full Name(s)</label>
              <div className="form-group">
                <input
                  type="text"
                  value={claimant.name_of_claimant}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  id="floatingfullnames"
                  name="name_of_claimant"
                  placeholder="First names"
                />

                <div style={{ color: "red" }}></div>
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="floatingPassword">Surname</label>
              <div className="form-group">
                <input
                  type="text"
                  value={claimant.surname_of_claimant}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  id="floatingPassword"
                  name="surname_of_claimant"
                  placeholder="Surname"
                />

                <div style={{ color: "red" }}></div>
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="floatingidtype" style={{ color: "#01AAAB" }}>
                Identification Type
              </label>
              <div className="form-group">
                <select
                  className="form-select"
                  id="floatingidtype"
                  onChange={handleIdTypeChange}
                  value={claimant.id_type}
                  aria-label=""
                >
                  <option value="none">--------</option>
                  <option value="1">RSA ID</option>
                  <option value="2">Passport</option>
                </select>
              </div>
            </div>

            {showIdTypeFields()}
            {claimant.id_type !== "1" && (
              <>
                <div className="col-md-4">
                  <label htmlFor="floatingdateob">Date of birth</label>
                  <div className="form-group">
                    <input
                      type="date"
                      value={claimant.date_of_birth}
                      onChange={handleInputChange}
                      required
                      className="form-control"
                      id="floatingdateob"
                      name="date_of_birth"
                      placeholder="Date of birth"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="floatingGender" style={{ color: "#01AAAB" }}>
                    Gender
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      id="floatingGender"
                      name="gender"
                      value={claimant.gender}
                      onChange={handleInputChange}
                      aria-label="Gender"
                    >
                      <option value="">---------</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </>
            )}

            <div className="col-md-4 flex">
              <div>
                <label htmlFor="id_birth_country">Country of Birth</label>
                <select
                  name="birth_country"
                  className="form-control"
                  id="id_birth_country"
                  value={claimant.birth_country}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Please select...
                  </option>
                  <option value="ZA">South Africa</option>
                  <option value="BW">Botswana</option>
                  <option value="LS">Lesotho</option>
                  <option value="MW">Malawi</option>
                  <option value="MZ">Mozambique</option>
                  <option value="NA">Namibia</option>
                  <option value="SZ">Eswatini</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
                <div style={{ color: "red" }}></div>
              </div>
            </div>

            <div className="col-md-4 flex">
              <div>
                <label htmlFor="id_residence_country">
                  Country of Residence
                </label>
                <select
                  name="residence_country"
                  className="form-control"
                  id="id_residence_country"
                  value={claimant.residence_country}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Please select...
                  </option>
                  <option value="ZA">South Africa</option>
                  <option value="BW">Botswana</option>
                  <option value="LS">Lesotho</option>
                  <option value="MW">Malawi</option>
                  <option value="MZ">Mozambique</option>
                  <option value="NA">Namibia</option>
                  <option value="SZ">Eswatini</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
                <div style={{ color: "red" }}></div>
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="floatingcontact">Phone Number</label>
              <div className="form-group">
                <input
                  type="text"
                  value={claimant.contact_number}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  id="floatingcontact"
                  name="contact_number"
                  placeholder="Phone Number"
                  maxLength={10}
                  title="Phone number must be 10 digits"
                />
                {phoneNumberError && (
                  <span style={{ color: "red" }} className="help-block">
                    {phoneNumberError}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <label htmlFor="floatingaddress">Home Address</label>
              <div className="form-group">
                <input
                  type="text"
                  value={claimant.address}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  id="floatingaddress"
                  name="address"
                  placeholder="Home Address"
                />

                <div style={{ color: "red" }}></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ClaimantCreate;
