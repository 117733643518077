import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, Table, InputNumber, Space, Flex, Row, Col } from 'antd';
import moment from 'moment';
import instance from '../../axios/axios-instance';
import { ColumnsType } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../redux/actions/AppMessageActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import saIdParser from 'south-african-id-parser';

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;
const { Option } = Select;



const SearchPersonModal = ({ visible, onOk, onCancel, setPersonModalVisible }:
    { visible: boolean; onOk: any; onCancel: any; setPersonModalVisible: any; }) => {
    const [query, setQuery] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();


    function performSearch() {
        // const response = await instance.get("/api/policies/new-business/", {
        //     action: "add_person_details",
        //     data: values
        // } as any)
        instance.get(`/api/policies/new-business/?action=search_person_details&search_key=${query}`)
            .then((response) => {
                if (response.data.data.length > 0) {
                    setDataSource(
                        response.data.data.map((member: any) => {
                            return {
                                key: member.id,
                                ...member,
                            };
                        })
                    );
                } else {
                    dispatch(setErrorMessage(response.data.message))
                }
            });
    }

    const handleSelectPerson = (person: any) => {

        onOk(person);
        onCancel()
    };

    const columns = [
        { key: "surname", dataIndex: "surname", title: "Surname" },
        { key: "first_names", dataIndex: "first_names", title: "First Names" },
        { key: "rsa_id_number", dataIndex: "rsa_id_number", title: "RSA ID Number" },
        { key: "passport_number", dataIndex: "passport_number", title: "Passport Number" },
        {
            key: "date_of_birth",
            dataIndex: "date_of_birth",
            title: "Date of Birth",
            render: (date: any) => moment(date).format('YYYY-MM-DD')
        },
    ];

    console.log("selected persons", selectedPerson)


    return (
        <Modal
            open={visible}
            title="Search for a Person"
            okText="Select"
            cancelText="Cancel"
            width={720}
            onCancel={() => {
                onCancel();
                setSelectedPerson([]);
                setDataSource([]);
            }}
            onOk={() => {
                handleSelectPerson(selectedPerson);
                onCancel();
                setSelectedPerson([]);
                setDataSource([]);
            }}
        >
            <Flex justify='space-between' >
                <Space direction="horizontal">
                    <Input
                        value={query}
                        onChange={(e) => setQuery(e.target.value.trim())}
                        placeholder="Enter search to begin"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                performSearch();
                            }
                        }}
                    />
                    <Button onClick={performSearch}>Search</Button>

                </Space>
                <Space>
                    <Button type="primary" ghost onClick={() => setPersonModalVisible(true)}>
                        Add Person
                    </Button>
                </Space>
            </Flex>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record: any) => record?.id}
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selectedPerson.map((person: any) => person?.id),  // Highlight the selected rows
                    onChange: (selectedRowKeys: any) => {
                        console.log("selected", selectedRowKeys)
                        // Find the selected persons from the data source
                        const selectedPersons = dataSource.filter((person: any) => selectedRowKeys.includes(person?.id));
                        setSelectedPerson(selectedPersons);
                    },
                }}
            />


        </Modal>
    );
};

const PersonFormModal = ({ visible, onCreate, onCancel }: { visible: boolean; onCreate: any; onCancel: any }) => {
    const [form] = Form.useForm();
    const [rsaId, setRsaId] = useState("");
    const [rsaIdValid, setRsaIdValid] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const handleRsaIdChange = (event: { target: { value: any; }; }) => {
        const id = event.target.value;
        setRsaId(id);
        if (id.length === 13 && saIdParser.validate(id)) {
            setRsaIdValid(true);
        } else {
            setRsaIdValid(false);
        }
    };

    let rsaIdStatus = "";
    let rsaIdHelp = "";

    if (rsaId.length !== 13) {
        rsaIdStatus = "error";
        rsaIdHelp = "RSA ID must be 13 digits";
    } else if (!saIdParser.validate(rsaId)) {
        rsaIdStatus = "error";
        rsaIdHelp = "RSA ID is not valid";
    } else {
        rsaIdStatus = "success";
        rsaIdHelp = "RSA ID is valid";
    }

    const handleOk = () => {
        if (rsaIdValid || form.getFieldValue('passport_number')) {
            form.validateFields()
                .then(values => {
                    onCreate(values)
                        .then(() => {
                            form.resetFields();
                        })
                        .catch((error: any) => {
                            console.log('Error:', error);
                        });
                })
                .catch(info => {
                    console.log('Validation failed:', info);
                })
                .finally(() => onCancel());
        } else {
            dispatch(setErrorMessage("Please enter a valid RSA ID or Passport Number"))
        }
    };


    const countryOptions = [
        { label: 'South Africa', value: 'ZA' },
        { label: 'Botswana', value: 'BW' },
        { label: 'Lesotho', value: 'LS' },
        { label: 'Malawi', value: 'MW' },
        { label: 'Mozambique', value: 'MZ' },
        { label: 'Namibia', value: 'NA' },
        { label: 'Eswatini', value: 'SZ' },
        { label: 'Zambia', value: 'ZM' },
        { label: 'Zimbabwe', value: 'ZW' },
    ];

    const handleRsaIdInput = (event: any) => {
        // Remove any non-integer characters from the input value
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
    };

    return (
        <Modal
            visible={visible}
            title="Add a new person"
            okText="Add"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={handleOk}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                {/* Person Form */}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="first_names" label="First Names" rules={[{ required: true, message: 'Please input the first names!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="surname" label="Surname" rules={[{ required: true, message: 'Please input the surname!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="rsa_id_number"
                            label="RSA ID Number"
                            validateStatus={rsaIdStatus as any}
                            help={rsaIdHelp}
                        >
                            <Input onInput={handleRsaIdInput} onChange={handleRsaIdChange} />
                        </Form.Item>
                        <Form.Item name="passport_number" label="Passport Number">
                            <Input />
                        </Form.Item>
                        {!rsaIdValid && (
                            <Form.Item name="date_of_birth" label="Date of Birth">
                                <DatePicker format="YYYY-MM-DD" />
                            </Form.Item>
                        )}
                        <Form.Item name="cellphone_number" label="Cellphone Number">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="gender" label="Gender">
                            <Select options={[{ value: "MALE", label: "MALE" }, { value: "FEMALE", label: "FEMALE" }]} />
                        </Form.Item>
                        <Form.Item
                            name="email_address"
                            label="Email Address"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not a valid email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="residential_address" label="Residential Address">
                            <Input />
                        </Form.Item>
                        <Form.Item name="nationality" label="Nationality">
                            <Select options={countryOptions} placeholder="Please select..." />
                        </Form.Item>
                        {!rsaIdValid && (
                            <Form.Item name="citizenship" label="Citizenship">
                                <Select options={countryOptions} placeholder="Please select..." />
                            </Form.Item>
                        )}
                        <Form.Item name="country_of_residence" label="Country of Residence">
                            <Select options={countryOptions} placeholder="Please select..." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const PolicyFormModal = ({ visible, onCreate, onCancel, personData,
    memberTypes, policyStatuses, policyData, data
}:
    {
        visible: boolean; onCreate: any; onCancel: any; personData: any;
        memberTypes: any[]; policyStatuses: any[]; policyData: any; data: any[]
    }) => {
    console.log("policy data, in policy modal:,", policyData)
    const [form] = Form.useForm();

    useEffect(() => {
        console.log("policy data", policyData)
        form.resetFields()
        if (policyData) {
            form.setFieldsValue({
                ...policyData
            });
        }
    }, [policyData, form, visible]);

    const [modifiedMemberTypes, setModifiedMemberTypes] = useState(memberTypes);

    useEffect(() => {
        // Find the ID of the "Principal Member" from the memberTypes array
        const principalMemberId = memberTypes.find(type => type.label?.replace(/\s/g, '')?.toLowerCase() === 'Principal Member'.replace(/\s/g, '')?.toLowerCase())?.value;

        // Check if a Principal Member already exists
        const principalMemberExists = data.some(item => item.policy && item.policy.member_type === principalMemberId);

        // Modify the memberTypes options based on whether a Principal Member already exists
        const newMemberTypes = memberTypes.map(type => {
            if (type.value === principalMemberId && principalMemberExists) {
                return { ...type, disabled: true };
            }
            return type;
        });

        setModifiedMemberTypes(newMemberTypes);
    }, [memberTypes, data]);


    useEffect(() => {
        form.setFieldsValue({
            member: `${personData?.first_names} ${personData?.surname}`
        })
    }, [personData?.first_names, personData?.surname, form])

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                onCreate(values);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validation failed:', info);
            })
            .finally(() => onCancel());
    };

    return (
        <Modal
            visible={visible}
            title="Add Policy Details"
            okText="Add"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={handleOk}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                {/* Policy Form */}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="policy_status" label="Policy Status" rules={[{ required: false, message: 'Please input the policy status!' }]}>
                            <Select options={policyStatuses} />
                        </Form.Item>
                        <Form.Item name="member_type" label="Member Type" rules={[{ required: true, message: 'Please input the member type!' }]}>
                            <Select options={modifiedMemberTypes} />
                        </Form.Item>
                        <Form.Item name="policy_start_date" label="Policy Start Date">
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="cover_amount" label="Cover Amount">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        {/* <Form.Item name="scaled_cover_amount" label="Scaled Cover Amount">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item> */}
                        <Form.Item name="scheme_premium_amount" label="Scheme Premium Amount">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const NewPolicyModal = ({ visible, onCancel, onOk, data, setData, schemeId, policyNumber, setPolicyNumber,
    modalTitle
}:
    {
        visible: boolean; onOk: any; onCancel: any; data: any[]; setData: any; schemeId: any;
        policyNumber: any; setPolicyNumber: any; modalTitle: string;
    }) => {
    const [personModalVisible, setPersonModalVisible] = useState(false);
    const [policyModalVisible, setPolicyModalVisible] = useState(false);
    const [searchPersonModalVisible, setSearchPersonModalVisible] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<any>(null);
    const [selectedPolicy, setSelectedPolicy] = useState<any>(null);
    const [memberTypes, setMemberTypes] = useState<any[]>([]);
    const [policyStatuses, setPolicyStatuses] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const [isPolicyNumberModalVisible, setIsPolicyNumberModalVisible] = useState(false);
    const [policyNumberForm] = Form.useForm();
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [policyNumbers, setPolicyNumbers] = useState<any[]>([])

    console.log("data", data)

    const handleAddPerson = async (values: any) => {
        try {
            const response = await instance.post("/api/policies/new-personal-info/", {
                action: "add_person_details",
                data: values
            } as any);
            if (response?.status >= 200 && response?.status < 300) {
                setData([...data, { person: values }]);
                dispatch(setSuccessMessage("Person details submitted"));
                return Promise.resolve();  // Resolve the Promise when the operation is successful
            } else {
                return Promise.reject();  // Reject the Promise when the operation fails
            }
        } catch (error) {
            console.log('Error:', error);
            return Promise.reject();  // Reject the Promise when an error occurs
        }
    };




    const handleAddPolicy = (values: any) => {
        setData(data.map((item: { person: { id: any; }; }) => item.person.id === selectedPerson?.id ? { ...item, policy: values } : item));
    };

    const handlePolicyNumberSubmit = () => {
        const { policyNumber } = policyNumberForm.getFieldsValue()
        setPolicyNumber(policyNumber);
        setIsPolicyNumberModalVisible(false);
    };

    const handleSelectPerson = (persons: any[]) => {
        persons.forEach(person => {
            setData((prevData: any[]) => [...prevData, { person }]);
        });
    };


    console.log("selected policy", selectedPolicy)


    useEffect(() => {
        const fetchMemberTypes = async () => {
            const response = await instance.get("/api/administration/member-types");
            setMemberTypes(
                response?.data.map((item: any) => {
                    return { label: item.member_type, value: item.id };
                })
            );
        };
        fetchMemberTypes();
    }, []);

    useEffect(() => {
        const fetchPolicyStatuses = async () => {
            const response = await instance.get("/api/administration/policy-status");
            setPolicyStatuses(
                response?.data.map((item: any) => {
                    return { label: item.policy_status, value: item.id };
                })
            );
        };
        fetchPolicyStatuses();
    }, []);

    useEffect(() => {
        if (visible && modalTitle?.toLowerCase()?.includes("dependant")) {
            const fetchPolicyNumbers = async () => {
                const response = await instance.get("/api/policies/scheme-policy-numbers/");
                setPolicyNumbers(
                    response?.data.data.map((item: any) => {
                        return { label: item.policy_number, value: item.policy_number };
                    })
                );
            };
            fetchPolicyNumbers();
        }
    }, [modalTitle, visible]);

    const generatePolicyNumber = async () => {
        const response = await instance.get(`/api/policies/new-business/?action=generate_policy_number&scheme_id=${schemeId}`);
        if (response?.status >= 200 && response?.status < 300) {
            setPolicyNumber(response.data.policy_number);
            setIsButtonClicked(true);
        }

    };

    // useEffect(() => {
    //     const fetchPolicyNumber = async () => {
    //         const response = await instance.get(`/api/policies/new-business/?action=generate_policy_number&scheme_id=${schemeId}`/* , {
    //             action: "generate_policy_number",
    //             scheme_id: schemeId
    //         } as any */);
    //         setPolicyNumber(response?.data);
    //     };
    //     fetchPolicyNumber();
    // }, []);


    const columns: ColumnsType<any> = [
        { title: 'First Name', fixed: "left", dataIndex: ['person', 'first_names'], key: 'first_names' },
        { title: 'Surname', fixed: "left", dataIndex: ['person', 'surname'], key: 'surname' },
        { title: 'Policy Number', dataIndex: ['policy', 'policy_number'], key: 'policy_number' },
        { title: 'RSA ID Number', dataIndex: ['person', 'rsa_id_number'], key: 'rsa_id_number' },
        { title: 'Passport Number', dataIndex: ['person', 'passport_number'], key: 'passport_number' },
        { title: 'Date of Birth', dataIndex: ['person', 'date_of_birth'], key: 'date_of_birth', render: (date: moment.MomentInput) => moment(date).format('YYYY-MM-DD') },
        { title: 'Date of Death', dataIndex: ['person', 'date_of_death'], key: 'date_of_death', render: (date: moment.MomentInput) => moment(date).format('YYYY-MM-DD') },
        {
            title: 'Action',
            fixed: "right",
            key: 'action',
            render: (_text: any, record: { person: React.SetStateAction<null>; policy: any; }) => (
                <Button type="link" onClick={() => {
                    setSelectedPerson(record.person);
                    setSelectedPolicy(record?.policy ? record.policy : {});
                    setPolicyModalVisible(true);
                }}>
                    Policy Details
                </Button>
            ),
        },
    ];

    const handleBusinessSubmit = () => {
        if (!(data?.length > 0)) {
            dispatch(setErrorMessage("Please enter data before you submit"))
        } else if (!(data[0]?.policy)) {
            dispatch(setErrorMessage("Please add policy data"))
        } else if (!policyNumber) {
            dispatch(setErrorMessage("Please generate a policy number"))
        } else {
            onOk(policyNumber, data)
        }
    }

    return (
        <>
            <Modal
                open={visible}
                title={modalTitle}
                okText="Submit"
                cancelText="Cancel"
                onCancel={onCancel}
                onOk={handleBusinessSubmit}
                width="70%"
            >
                <Space size="large">
                    <Button type="primary" onClick={() => setSearchPersonModalVisible(true)}>
                        Search Person
                    </Button>
                    {
                        modalTitle?.toLowerCase()?.includes("dependant") ?
                            (<Button type="primary" onClick={() => setIsPolicyNumberModalVisible(true)}>Enter Policy Number</Button>) :
                            (<Button type="primary" onClick={generatePolicyNumber} disabled={isButtonClicked} >Generate Policy Number</Button>)
                    }
                </Space>
                <SearchPersonModal
                    visible={searchPersonModalVisible}
                    onOk={handleSelectPerson}
                    onCancel={() => setSearchPersonModalVisible(false)}
                    setPersonModalVisible={setPersonModalVisible}
                />
                <PersonFormModal
                    visible={personModalVisible}
                    onCreate={handleAddPerson}
                    onCancel={() => setPersonModalVisible(false)}
                />
                <PolicyFormModal
                    visible={policyModalVisible}
                    onCreate={handleAddPolicy}
                    onCancel={() => setPolicyModalVisible(false)}
                    personData={selectedPerson}
                    policyData={selectedPolicy}
                    policyStatuses={policyStatuses}
                    memberTypes={memberTypes}
                    data={data}
                />
                <Table dataSource={data} columns={columns} scroll={{ x: 'max-content' }} />
            </Modal>
            <Modal
                open={isPolicyNumberModalVisible}
                title="Enter Policy Number"
                okText="Submit"
                cancelText="Cancel"
                onCancel={() => setIsPolicyNumberModalVisible(false)}
                onOk={handlePolicyNumberSubmit}
            >
                <Form form={policyNumberForm}>
                    <Form.Item name="policyNumber" label="Policy Number" rules={[{ required: true, message: 'Please input the policy number!' }]}>
                        <Select options={policyNumbers} showSearch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default NewPolicyModal;