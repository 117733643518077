import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  Form,
  Flex,
  TablePaginationConfig,
} from "antd";
import instance from "../../axios/axios-instance";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface Bank {
  id: number;
  bank: string;
  branch_code: string;
}

function BanksTable() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editBankForm] = Form.useForm();
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [query, setQuery] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const fetchBanks = async () => {
    const response = await instance.get("/api/administration/banks");
    setData(response?.data);
    setFilteredData(response?.data);
  };
  useEffect(() => {
    fetchBanks();
  }, []);

  const performSearch = () => {
    const filteredData = data.filter((bank: Bank) =>
      bank.bank.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const resetSearch = () => {
    setQuery("");
    setFilteredData(data);
  };

  const createNewBank = async () => {
    const response = await instance.post("/api/administration/banks", {
      ...editBankForm.getFieldsValue(),
    });
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Bank added"));
      fetchBanks();
    }
    setCreateModalOpen(false);
  };

  const editBank = async () => {
    const response = await instance.put(
      `/api/administration/banks?id=${selectedBank?.id}`,
      {
        ...editBankForm.getFieldsValue(),
      }
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Bank Updated"));
      fetchBanks();
    }
    setEditModalOpen(false);
  };

  const handleDetails = (record: any) => {
    editBankForm.setFieldsValue({
      bank: record.bank,
      branch_code: record.branch_code,
    });
    setSelectedBank(record);
    setEditModalOpen(true);
  };

  function FormIsValid() {
    const data = editBankForm.getFieldsValue();
    return data.bank && data.branch_code;
  }

  const columns: any[] = [
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Branch Code",
      dataIndex: "branch_code",
      key: "branch_code",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (record: any) => (
        <Space size="small" style={{ minWidth: "112px" }}>
          <Button onClick={() => handleDetails(record)}>Details</Button>
        </Space>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [page_size, setPage_size] = useState(4);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  console.log("bank form", editBankForm.getFieldsValue());

  return (
    <Space direction="vertical">
      <Flex justify="space-between">
        <Space>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value.trim())}
            placeholder="Enter search to begin"
          ></Input>
          <Button disabled={!query} onClick={() => performSearch()}>
            Search
          </Button>
          <Button
            disabled={!query.trim() || filteredData?.length === 0}
            onClick={() => resetSearch()}
          >
            Reset
          </Button>
        </Space>
        <Space align="end" direction="horizontal">
          <Button
            type="primary"
            onClick={() => {
              editBankForm.resetFields();
              setCreateModalOpen(true);
            }}
          >
            Add Bank
          </Button>
        </Space>
      </Flex>
      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        scroll={{ y: windowHeight - 420 }}
        style={{ width: 650 }}
        onChange={(config: TablePaginationConfig) => {
          setPage_size(config.pageSize!);
          setCurrentPage(config.current!);
        }}
        pagination={{
          total: filteredData?.length,
          showTotal: (total) => `${total} rows`,
          pageSize: page_size,
          current: currentPage,
        }}
      />
      <Modal
        title="Create a Bank"
        open={isCreateModalOpen}
        onOk={createNewBank}
        okText="Save"
        /* okButtonProps={{ disabled: !FormIsValid() }} */
        onCancel={() => {
          setCreateModalOpen(false);
        }}
      >
        <Form layout="vertical" form={editBankForm}>
          <Form.Item label="Bank" name="bank">
            <Input />
          </Form.Item>
          <Form.Item label="Branch Code" name="branch_code">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Bank"
        open={isEditModalOpen}
        onOk={editBank}
        okText="Update"
        /* okButtonProps={{ disabled: !FormIsValid() }} */
        onCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <Form layout="vertical" form={editBankForm}>
          <Form.Item label="Bank" name="bank">
            <Input />
          </Form.Item>
          <Form.Item label="Branch Code" name="branch_code">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
}

export default BanksTable;
