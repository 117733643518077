import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import saIdParser from "south-african-id-parser";
import instance from "../../axios/axios-instance";
import { setBankDetail } from "../../redux/actions/BankDetailActions";
import { IBankDetail } from "../../redux/types/bankDetail";

interface bankProps {
  isInput: boolean | false;
  accountHolder: string;
  accountIDType: string;
  selectedBank: string;
  accountNumber: string | number;
  setAccountHolder: React.Dispatch<React.SetStateAction<string>>;
  setAccountIDType: React.Dispatch<React.SetStateAction<string>>;
  setSelectedBank: React.Dispatch<React.SetStateAction<string>>;
  setAccountNumber: React.Dispatch<React.SetStateAction<string>>;
}

const BankDetail: React.FC<bankProps> = ({
  isInput,
  accountHolder,
  accountIDType,
  selectedBank,
  accountNumber,
  setAccountHolder,
  setAccountIDType,
  setSelectedBank,
  setAccountNumber,
}) => {
  const dispatch = useDispatch();

  const [accountIDNumber, setAccountIDNumber] = useState("");
  const [accountPassport, setAccountPassport] = useState("");
  const [accountDateOfBirth, setAccountDateOfBirth] = useState("");
  const [claimStatus, setClaimStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [banks, setBanks] = useState([]);
  const [claimStatuses, setClaimStatuses] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [bankDetails, setBankDetails] = useState({
    accountIDNumber,
    accountPassport,
    accountDateOfBirth,
    claimStatus,
    notes,
    accountHolder,
    accountIDType,
    selectedBank,
    accountNumber,
    accountType,
  });

  useEffect(() => {
    setBankDetails({
      accountIDNumber,
      accountPassport,
      accountDateOfBirth,
      claimStatus,
      notes,
      accountHolder,
      accountIDType,
      selectedBank,
      accountNumber,
      accountType,
    });
  }, [
    accountIDNumber,
    accountPassport,
    accountDateOfBirth,
    claimStatus,
    notes,
    accountHolder,
    accountIDType,
    selectedBank,
    accountNumber,
    accountType,
  ]);

  const handleAccountHolderChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountHolder(event.target.value);
  };

  const handleAccountIDTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountIDType(event.target.value);
  };

  const handleAccountTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountType(event.target.value);
  };

  /*
  const handleAccountIDNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountIDNumber(event.target.value);
  }; */

  const handleAccountPassportChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountPassport(event.target.value);
  };

  const handleAccountDateOfBirthChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountDateOfBirth(event.target.value);
  };

  const handleAccountNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountNumber(event.target.value);
  };

  const handleClaimStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setClaimStatus(event.target.value);
  };

  const handleBankChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedBank(event.target.value);
    // You can perform any other actions or state updates based on the selected value here
  };

  const handleNotesChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNotes(event.target.value);
  };

  const handleAccountIDNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const value = event.target.value;
    setAccountIDNumber(event.target.value);

    if (value.length !== 13) {
      setErrorMessage("ID Number must be exactly 13 characters.");
    } else {
      const validateId: any = event.target.value;
      if (!saIdParser.validate(validateId)) {
        setErrorMessage("ID Number is not valid");
      } else {
        setErrorMessage("");
      }
    }
  };

  useEffect(() => {
    if (isInput) {
      setAccountHolder("");
      setAccountIDType("");
      setAccountIDNumber("");
      setAccountPassport("");
      setAccountDateOfBirth("");
      setAccountNumber("");
      setAccountType("");
      setSelectedBank("");
      setClaimStatus("");
      setNotes("");
    }
  }, [isInput]);

  useEffect(() => {
    const model: IBankDetail = {
      BankName: selectedBank,
      AccountIDType: accountIDType,
      AccountHolder: accountHolder,
      AccountPassport: accountPassport,
      AccountNumber: accountNumber,
      AccountType: accountType,
      AccountIDNumber: accountIDNumber,
      Notes: notes,
      ClaimStatus: claimStatus,
    };

    dispatch(setBankDetail(model));
  }, [
    accountHolder,
    accountIDType,
    accountType,
    accountIDNumber,
    accountPassport,
    accountNumber,
    claimStatus,
    notes,
    selectedBank,
    dispatch,
  ]);

  const getBanks = async () => {
    try {
      const response = await instance.get(`api/claims/bank-names/?format=json`);
      setBanks(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const getStatusClaims = async () => {
    try {
      const response = await instance.get(
        `api/claims/claim-status/?format=json`
      );
      setClaimStatuses(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getBanks();
    getStatusClaims();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="floatingbank">
              Bank Name <span className="text-danger">* Required</span>
            </label>
            <select
              className="form-select"
              style={{ width: "100%" }}
              id="floatingbank"
              name="bank_name"
              aria-label="Bank Name"
              onChange={handleBankChange}
              value={selectedBank} // Set the selected value of the dropdown
            >
              <option value="" selected>
                ---------
              </option>
              {banks.map((bank: any) => (
                <option key={bank.id} value={bank.id}>
                  {bank.bank}
                </option>
              ))}
              {/* Add your new option below */}
              {/* <option value="Your New Bank">Your New Bank</option> */}
            </select>
          </div>
        </div>

        <div className="col-md-4 flex mb-3">
          <div className="form-group">
            <label htmlFor="floatingiaccounttype">
              Account Type <span className="text-danger">* Required</span>
            </label>
            <select
              className="form-select"
              style={{ width: "100%" }}
              id="floatingiaccounttype"
              name="account_type"
              value={accountType}
              onChange={handleAccountTypeChange}
              aria-label="Account Type"
            >
              <option value="" selected>
                ---------
              </option>
              <option value="Savings">Savings</option>
              <option value="Cheque">Cheque</option>
              <option value="Transmission">Transmission</option>
            </select>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="floatingaccountname">
              Account Holder <span className="text-danger">* Required</span>
            </label>
            <input
              type="text"
              required
              className="form-control"
              id="floatingaccountname"
              name="account_holder"
              value={accountHolder}
              placeholder="Account Holder"
              onChange={handleAccountHolderChange}
            />
            <div style={{ color: "red" }}></div>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex" id="accountID">
          <div className="form-group">
            <label htmlFor="accountidtype">
              Account Holder ID Type
              <span className="text-danger">* Required</span>
            </label>
            <select
              className="form-select"
              id="accountidtype"
              value={accountIDType}
              onChange={handleAccountIDTypeChange}
              aria-label=""
            >
              <option value="none">--------</option>
              <option value="idNumber">RSA ID</option>
              <option value="Passport">Passport</option>
            </select>
          </div>
        </div>

        {accountIDType === "idNumber" && (
          <div className="col-md-4" id="rsa-account-id-type">
            <div className="form-group">
              <label htmlFor="floatingID">Account Holder ID Number</label>
              <input
                type="text"
                value={accountIDNumber}
                className="form-control"
                id="floatingID"
                name="account_hidnumber"
                placeholder="Account Holder ID Number"
                onChange={handleAccountIDNumberChange}
                maxLength={13}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div>
        )}

        {accountIDType === "Passport" && (
          <div className="col-md-4" id="passport-account-id-type">
            <div className="form-group">
              <label htmlFor="floatingpass">Account Holder Passport</label>
              <input
                type="text"
                value={accountPassport}
                className="form-control"
                id="floatingpass"
                name="account_hidpassport"
                placeholder="Account Holder Passport"
                onChange={handleAccountPassportChange}
              />
            </div>
          </div>
        )}

        {accountIDType === "Passport" && (
          <div className="col-md-4" id="passport-account-date-id-type">
            <div className="form-group">
              <label htmlFor="floatingdateob">
                Account Holder Date of Birth
              </label>
              <input
                type="date"
                value={accountDateOfBirth}
                className="form-control"
                id="floatingdateob"
                name="account_hdate_of_birth"
                placeholder="Account Holder Date of Birth"
                onChange={handleAccountDateOfBirthChange}
              />
            </div>
          </div>
        )}

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="floatingaccno">
              Account Number <span className="text-danger">* Required</span>
            </label>
            <input
              type="number"
              required
              className="form-control"
              id="floatingaccno"
              name="account_number"
              value={accountNumber}
              min="0"
              pattern="^[0-9]*$"
              placeholder="Account Number"
              onChange={handleAccountNumberChange}
            />
            <div style={{ color: "red" }}></div>
          </div>
        </div>

        <div className="col-md-4 flex mb-3">
          <div className="form-group">
            <label htmlFor="floatingstatus">Claim Status</label>
            <select
              className="form-select"
              style={{ width: "100%" }}
              id="floatingstatus"
              name="claim_status"
              aria-label="Claim Status"
              onChange={handleClaimStatusChange}
              value={claimStatus}
            >
              <option value="" selected={!claimStatus}>
                ---------
              </option>
              {claimStatuses.map((status: any) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="floatingnotees">Notes</label>
            <textarea
              className="form-control"
              id="floatingnotees"
              name="notes"
              value={notes}
              placeholder="Notes"
              onChange={handleNotesChange}
            ></textarea>
            <div style={{ color: "red" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetail;
