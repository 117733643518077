import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import instance from "../../axios/axios-instance";
import { AuthContext } from "../../context/AuthContext";

interface ModelProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: any;
  selectedClaim: any;
  handleReset: any;
}

interface FormData {
  claimID: string;
  coverAmount: string;
  claimStatus: string;
  notes: string;
  submissionDate: any;
}

const ClaimInPayment: React.FC<ModelProps> = (modelProps) => {
  const { user } = useContext(AuthContext);

  const [paymentData, setPaymentData] = useState<FormData>({
    claimID: "",
    coverAmount: "",
    claimStatus: "",
    notes: "",
    submissionDate: "",
  });

  const [claimStatuses, setClaimStatuses] = useState([]);

  const getStatusClaims = async () => {
    try {
      const response = await instance.get(
        `api/claims/claim-status/?format=json`
      );
      setClaimStatuses(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getStatusClaims();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setPaymentData((prevData) => ({ ...prevData, [name]: value }));
  };
  // const handleFileChange = (e: any) => {
  //   const file = e.target.files[0];
  //   setPaymentData({ ...paymentData, proofOfPayments: file });
  // };

  const cachedClaims = useSelector(
    (state: any) => state.claims.cachedClaims.results
  );

  const handleOk = async () => {
    const model: any = {
      id: modelProps.selectedClaim?.id,
      claim_closure: 1,
      is_closed: true,
      // cover_amount: paymentData.coverAmount,
      // claim_status: paymentData.claimStatus,
      // notes: paymentData.notes,
      // review_status: null,
      // assessor: user.user_id,
    };

    const updatedClaimData = {
      ...modelProps.selectedClaim,
      bank_name: modelProps.selectedClaim.bank_name?.id,
      is_reviewed: false,
      is_paid: false,
      is_uploaded: false,
      is_held: false,
      payment_status: null,
      claim_closure: "",
      pop_document: null,
      amount_paid: "",
      pop_reference: "",
      modified: "",
      created: "",
      claimant: modelProps.selectedClaim.claimant?.id,
      principal_member: modelProps.selectedClaim?.principal_member?.id,
      product_type: modelProps.selectedClaim?.product_type?.id,
      scheme_name: modelProps.selectedClaim.scheme_name?.id,
      claim_amount: paymentData.coverAmount,
      claim_status: paymentData.claimStatus,
      notes: paymentData.notes,
      date_claim_submitted: paymentData.submissionDate,
      assessor: user?.user_id,
      updated_by: null,
      review_status: null,
      premium_check: null,
      data_confirmation: null,
      is_actioned_payment: false,
      payment_date: null,
    };

    try {
      const response = await instance.post(
        `/api/claims/claim-in-payment/`,
        updatedClaimData
      );

      await instance.put(
        `/api/claims/review/${modelProps.selectedClaim.id}/`,
        model,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200 || response.status === 201) {
        modelProps.handleReset();
        modelProps.setIsModalOpen(modelProps.onClose);
        Swal.fire({
          icon: "success",
          title: "Submitted as new claim",
          text: response.data.message,
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }

    modelProps.setIsModalOpen(modelProps.onClose);
  };

  useEffect(() => {
    modelProps.setIsModalOpen(modelProps.isModalOpen);
  }, [modelProps.isModalOpen]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <Modal
      title="Claim In Payment"
      open={modelProps.isModalOpen}
      onOk={handleOk}
      onCancel={() => modelProps.setIsModalOpen(false)}
      okButtonProps={{
        disabled: !(
          paymentData.coverAmount &&
          paymentData.claimStatus &&
          paymentData.submissionDate
        ),
      }}
    >
      <h5>{modelProps?.selectedClaim?.policy_number}</h5>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="amount">
            Cover Amount <span className="text-danger">*Required</span>
          </label>

          <input
            id="amount"
            type="text"
            name="coverAmount"
            className="form-control"
            value={paymentData.coverAmount}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-md-4 flex mb-3">
          <div className="form-group">
            <label htmlFor="floatingstatus">
              Claim Status <span className="text-danger">*Required</span>
            </label>
            <select
              className="form-select"
              style={{ width: "100%" }}
              id="floatingstatus"
              name="claimStatus"
              aria-label="Claim Status"
              onChange={handleInputChange}
              value={paymentData.claimStatus}
            >
              <option value="" selected={!paymentData.claimStatus}>
                ---------
              </option>
              {claimStatuses.map((status: any) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="submissionDate">
              Submission Date <span className="text-danger">*Required</span>
            </label>
            <input
              className="form-control"
              id="submissionDate"
              name="submissionDate"
              type="date"
              value={paymentData.submissionDate}
              onChange={handleInputChange}
            />
            <div style={{ color: "red" }}></div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="floatingnotees">Notes</label>
            <textarea
              className="form-control"
              id="floatingnotees"
              name="notes"
              value={paymentData.notes}
              placeholder="Notes"
              onChange={handleInputChange}
            ></textarea>
            <div style={{ color: "red" }}></div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ClaimInPayment;
