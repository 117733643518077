import React, { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { clearMessages } from "./redux/actions/AppMessageActions";

const MySwal = withReactContent(Swal);

const SwalAlert = () => {
  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state: any) => state.appMessages.successMessage
  );
  const errorMessage = useSelector(
    (state: any) => state.appMessages.errorMessage
  );
  const customMessage = useSelector(
    (state: any) => state.appMessages.customMessage
  );

  useEffect(() => {
    // Display the success message using SweetAlert2
    if (successMessage) {
      MySwal.fire({
        icon: "success",
        title: "Success",
        text: successMessage,
        willClose: () => {
          // Clear the success message after it's displayed
          dispatch(clearMessages());
        },
      });
    }

    // Display the error message using SweetAlert2
    if (errorMessage) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
        willClose: () => {
          // Clear the error message after it's displayed
          dispatch(clearMessages());
        },
      });
    }

    if (customMessage.message) {
      MySwal.fire({
        icon: "success",
        title: customMessage.header ? customMessage.header : "",
        text: customMessage.message,
        willClose: () => {
          // Clear the success message after it's displayed
          dispatch(clearMessages());
        },
      });
    }
  }, [
    successMessage,
    errorMessage,
    dispatch,
    customMessage.header,
    customMessage.message,
  ]);

  return null; // This component doesn't render any UI, it's just for displaying alerts
};

export default SwalAlert;
