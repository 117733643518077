import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPMFile } from "../../redux/actions/PMFileAction";
import { IPMFile } from "../../redux/types/PMFile";
interface DocumentUploadProps {
  supportFile: File | null;
  additionFile: File | null;
  documentationUrl: string;
  setDocumentationUrl: React.Dispatch<React.SetStateAction<string>>;
  setSupportFile: React.Dispatch<React.SetStateAction<File | null>>;
  setAdditionFile: React.Dispatch<React.SetStateAction<File | null>>;
}
const DocumentUpload: React.FC<DocumentUploadProps> = ({
  supportFile,
  additionFile,
  setSupportFile,
  setAdditionFile,
  documentationUrl,
  setDocumentationUrl,
}) => {
  const dispatch = useDispatch();

  const [uploadDocs, setUploadDocs] = useState({
    supportFile,
    additionFile,
    documentationUrl
  });

  useEffect(() => {
    setUploadDocs({
      supportFile,
      additionFile,
      documentationUrl
    });
  }, [supportFile, additionFile, documentationUrl]);


  useEffect(() => {
    const model: IPMFile = {
      supportFile: supportFile,
      additionFile: additionFile,
      documentationUrl: documentationUrl,
    };

    dispatch(setPMFile(model));
  }, [supportFile, additionFile, dispatch, documentationUrl]);

  useEffect(() => {
    supportFile?.name && setSupportFile(supportFile);
    additionFile?.name && setAdditionFile(additionFile);
  }, []);

  const handleSupportingFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSupportFile(event.target.files[0]);
    }
  };

  const handleAdditionalFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAdditionFile(event.target.files[0]);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <section className="row g-3">
          {/* <div className="col-md-6 mb-3 flex">
            <label
              htmlFor="formFilePrincipal"
              className="col-sm-12 col-form-label"
            >
              Supporting Documents{" "}
              <span className="text-danger">* Required</span>
            </label>
            <input
              className="form-control"
              name="identity_for_the_principal_member"
              type="file"
              id="formFilePrincipal"
              accept="application/pdf"
              onChange={handleSupportingFileChange}
            />
          </div> */}

          <div className="col-md-6 mb-3 flex">
            <div className="form-group">
              <label htmlFor="docUrl">
               Supporting Documentation URL
                <span className="text-danger">* Required</span>{" "}
              </label>
              <input
                type="url"
                className="form-control"
                id="docUrl"
                placeholder="documentation url"
                value={documentationUrl}
                onChange={(e: any) => setDocumentationUrl(e.target.value)}
              />
            </div>
          </div>

        </section>
        {/* End Custom Styled Validation */}
      </div>
    </div>

  );
};

export default DocumentUpload;
