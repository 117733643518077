// principalMemberActions.ts

import { ThunkAction } from "redux-thunk";
import instance from "../../axios/axios-instance";
import { IPrincipalMemember } from "../types/principalMember";
import { setErrorMessage, setSuccessMessage } from "./AppMessageActions";

// Action Types
export const SET_MEMBER = "SET_MEMBER";
export const SET_ERROR = "SET_ERROR";
type ThunkResult<R> = ThunkAction<R, any, unknown, any>;

// Action Creators
export interface PrincipalMemberAction {
  type: typeof SET_MEMBER;
  payload: IPrincipalMemember;
}

// Action Creator for setting error
export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

export const setMember = (
  memberDetail: IPrincipalMemember
): PrincipalMemberAction => ({
  type: SET_MEMBER,
  payload: memberDetail,
});

export const setError = (errorMessage: string): SetErrorAction => ({
  type: SET_ERROR,
  payload: errorMessage,
});

const ErrorResponse = (data: any) => {
  var output = "";

  for (var key in data) {
    if (Object.hasOwn(data, key)) {
      var value = data[key];

      output += key + ":\n";

      for (var i = 0; i < value.length; i++) {
        output += value[i] + "\n";
      }
      output += "\n";
    }
  }
  return output;
};

export const createPrincipalMember = (memberData: any): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const response = await instance.post(
        `/api/claims/principal/?format=json`,
        memberData
      );

      // Assuming the API response contains the newly created principal member data
      // const newPrincipalMember: IPrincipalMemember = response.data;
      if (response.status === 201 || response.status === 200) {
        dispatch(setSuccessMessage("Principal member saved successfully"));
      }
    } catch (error: any) {
      dispatch(setErrorMessage(ErrorResponse(error?.response?.data)));
      console.log(error.message);

      // Optionally, you can dispatch a failure action here or show an error notification to the user.
    }
  };
};

export const fetchPrincipalMembers = (): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const response = await instance.get(`/api/claims/principal/?format=json`);

      // Assuming the API response contains an array of principal members
      const principalMembers: any = response.data;
      dispatch(setMember(principalMembers));

      // Optionally, you can add any success handling here, such as updating the UI or performing additional actions.
    } catch (error: any) {
      console.log(error.message);
      // Optionally, you can dispatch a failure action here or show an error notification to the user.
    }
  };
};

// ... (your existing imports)
