import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Radio,
  Select,
  Space,
} from "antd";
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import instance from "../../axios/axios-instance";
import "../../claims/claims.css";
import DuplicateClaim from "../../claims/components/DuplicateClaim";
import UpdateClaim from "../../claims/components/UpdateClaim";
import ClaimInPayment from "../../payments/components/claiminpayment";
import PaymentForm from "../../payments/components/paymentForm";
import { setErrorMessage, setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { getClaims } from "../../redux/actions/ClaimsAction";
import ClaimReviewModal from "./ReviewModal";
import { AuthContext } from "../../context/AuthContext";
import OverwriteEdit from "../../claims/components/OverwriteEdit";
import NewClaimModal from "../../claims/newcapture/NewClaimModal";

interface Scheme {
  id: any;
  scheme_name: string;
}
interface Insurer {
  id: any;
  name: string;
}

interface Claim {
  text: string;
  value: string;
}
interface Assessor {
  text: string;
  value: string;
}

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;
const ReviewClaimsTable: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dataClaims = useSelector((state: any) => state?.claims.oneclaim);
  const allClaims = useSelector((state: any) => state?.claims.cachedClaims);
  const allClaimsResults = useSelector(
    (state: any) => state?.claims?.cachedClaims?.results
  );

  const tableData = useSelector((state: any) => {
    return state.claims.oneclaim?.results || [];
  });
  const [isModalOpenPayment, setIsModalOpenPayment] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tableHeaders, setTableHeaders] = useState<any[]>([]);
  const [claimStatusFilters, setClaimStatusFilters] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [schemes, setSchemes] = useState<Scheme[]>([]); // State to hold schemes data
  const [insurer, setInsurerStatus] = useState<Insurer[]>([]);

  const [claimReviewId, setClaimReviewId] = useState<string>("");
  const [claimPaymentID, setClaimPaymentID] = useState<string | null>(null);
  const totalDataCount = useSelector((state: any) => {
    return state.claims.oneclaim?.total_items || 0;
  });
  const [selectedClaimStatus, setSelectedClaimStatus] = useState<any>([]);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState<any[]>([]);

  const [selectedClaimData, setSelectedClaimData] = useState<any>(null);
  const [existingClaimData, setExistingClaimData] = useState<any>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [schemeFilters, setSchemeFilters] = useState<any[]>([]);
  const [insurerFilters, setInsurerFilters] = useState<any[]>([]);
  const [reviewStandingFilters, setReviewStandingFilters] = useState<any[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<any>([]);

  const [isClaimInPayment, setIsClaimInPayment] = useState<boolean>(false);
  const [selectedAssessor, setSelectedAssessor] = useState<string[]>([]);

  const [startDate, setStartDate] = useState<any>(null);
  const [startDateState, setStartDateState] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [endDateState, setEndDateState] = useState<any>(null);
  const [captureStartDate, setCaptureStartDate] = useState<any>(null);
  const [captureEndDate, setCaptureEndDate] = useState<any>(null);
  const [captureStartDateState, setCaptureStartDateState] = useState<any>(null);
  const [captureEndDateState, setCaptureEndDateState] = useState<any>(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventStartDateState, setEventStartDateState] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [eventEndDateState, setEventEndDateState] = useState(null);
  const [paymentStartDate, setPaymentStartDate] = useState(null);
  const [paymentStartDateState, setPaymentStartDateState] = useState(null);
  const [paymentEndDate, setPaymentEndDate] = useState(null);
  const [paymentEndDateState, setPaymentEndDateState] = useState(null);
  const [assessorFilters, setAssessorFilters] = useState<any[]>([]);
  const [selectedInsurer, setSelectedInsurer] = useState<any[]>([]);
  const [ReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [page_size, setPage_size] = useState<number>(5);
  const [searchInput, setSearchInput] = useState<string>("");
  const isLoading = useSelector((state: any) => state.claims.isLoading);
  const [dataFiltered, setDataFiltered] = useState(false);
  const [toggleFetch, setToggleFetch] = useState<boolean>(false)

  const [isMakeChangesOpen, setMakeChangesOpen] = useState<boolean>(false)

  const [dataSource, setDataSource] = useState<any[]>(
    filteredData.length > 0 ? filteredData : allClaims?.results
  );

  // request for changes
  const { user } = useContext(AuthContext);
  const [isRequestChangesModalOpen, setRequestChangesModalOpen] = useState(false);
  const [requestChangesForm] = Form.useForm();
  const [selectRequestChanges, setSelectRequestChanges] = useState<any>(null)
  const handleRequestChanges = (recordId: any) => {
    setSelectRequestChanges(recordId);
    setRequestChangesModalOpen(true)
  }
  const submitRequestChanges = async () => {
    const { reason_for_request } = requestChangesForm.getFieldsValue()
    const response = await instance.put("/api/claims/request-overwrite-record", {
      id: selectRequestChanges,
      user_id: user!.user_id,
      overwrite_reason: reason_for_request
    });
    if (response?.status >= 200 && response?.status < 300) {
      setToggleFetch(prev => !prev)
      dispatch(setSuccessMessage("Successfully requested overwrite"));
    }
    setRequestChangesModalOpen(false);
  };

  //grant overwrite
  const [isGrantOverwriteModalOpen, setGrantOverwriteModalOpen] = useState(false);
  const [selectGrantOverwrite, setSelectGrantOverwrite] = useState<any>(null)
  const [grantOverwriteForm] = Form.useForm();

  const handleGrantOverwrite = (record: any) => {
    setSelectGrantOverwrite(record);
    setGrantOverwriteModalOpen(true)
  }

  const submitGrantOverwrite = async () => {
    const formData = grantOverwriteForm.getFieldsValue();
    const decision = formData.decision;
    const overwrite_request_decision_comments = formData.overwrite_request_decision_comments;

    const response = await instance.post("/api/claims/request-overwrite-record", {
      id: selectGrantOverwrite.id,
      user_id: user!.user_id,
      decision,
      overwrite_request_decision_comments
    });
    if (response?.status >= 200 && response?.status < 300) {
      setToggleFetch(prev => !prev)
      dispatch(setSuccessMessage("Changes granted"));
    }
    setGrantOverwriteModalOpen(false);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    window.addEventListener("resize", updateWindowHeight);

    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (dataFiltered) {
      setDataSource(filteredData);
    } else if (allClaimsResults) {
      setDataSource(allClaims?.results);
    }
  }, [
    dataFiltered,
    allClaims.results?.length,
    allClaimsResults,
    filteredData,
    allClaims.results,
    JSON.stringify(allClaimsResults),
    JSON.stringify(filteredData),
  ]);

  const handleFilter = () => {
    setStartDate(startDateState);
    setEndDate(endDateState);
  };

  const handleEventDateFilter = () => {
    setEventStartDate(eventStartDateState);
    setEventEndDate(eventEndDateState);
  };

  const handlePaymentDateFilter = () => {
    setPaymentStartDate(paymentStartDateState);
    setPaymentEndDate(paymentEndDateState);
  };

  const handleCaptureDateFilter = () => {
    setCaptureStartDate(captureStartDateState);
    setCaptureEndDate(captureEndDateState);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Assessor",
      dataIndex: ["assessor", "name"],
      key: "assessor",
      fixed: "left",
      filters: assessorFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedAssessor,
      render: (text: string, record: any) => {
        const fullName = `${record.assessor?.name} ${record.assessor?.surname}`;
        return <NoWrap text={fullName}></NoWrap>;
      },
    },
    {
      title: "Deceased",
      dataIndex: "deceased_name",
      key: "1",
      fixed: "left",
      filteredValue: [],
      render: (text: string, record: any) => {
        if (record.deceased_id_number) {
          const fullName = `${record.deceased_id_number}`;
          return fullName;
        } else if (record.passport) {
          const fullName = `${record.passport}`;
          return fullName;
        }
      },
    },
    {
      title: "Policy No",
      dataIndex: "policy_number",
      key: "2",
      fixed: "left",
      filteredValue: [],
    },

    {
      title: "Insurer",
      dataIndex: ["scheme_name", "insurer_name"],
      key: "insurer",
      render: (text, record) => (
        <NoWrap text={record.scheme_name?.insurer_name.insurer_name}></NoWrap>
      ),
      filters: insurerFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedInsurer,
    },
    {
      title: "Scheme",
      dataIndex: ["scheme_name", "scheme_name"],
      key: "scheme",
      filters: schemeFilters,
      filterMultiple: true,
      filterSearch: true,
      filteredValue: selectedScheme,
      render: (_: string, record: any) => (
        <NoWrap text={record.scheme_name?.scheme_name}></NoWrap>
      ),
    },
    {
      title: "Cover Amount",
      dataIndex: "claim_amount",
      key: "5",
      width: "1000",
      filteredValue: [],
      render: (text: string, record: any) => {
        const amount = Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 0,
        }).format(record.claim_amount);
        return <NoWrap text={amount}></NoWrap>;
      },
    },
    {
      title: "Principal",
      dataIndex: ["principal_member", "full_names"],
      filteredValue: [],
      key: "6",
      render: (_: string, record: any) => {
        const { full_names, surname } = record.principal_member;
        const text = `${full_names} ${surname}`.trim();
        return <NoWrap text={text}></NoWrap>;
      },
    },

    {
      title: "Date of Event",
      dataIndex: "deceased_date_of_death",
      key: "7",
      filteredValue: [],
      render: (_: string, record: any) => (
        <NoWrap text={record.deceased_date_of_death}></NoWrap>
      ),
      onFilter: (value: string | number | boolean, record) => {
        if (typeof value === "boolean") {
          return record.deceased_date_of_death.indexOf(value.toString()) === 0;
        }
        return record.deceased_date_of_death.indexOf(value.toString()) === 0;
      },
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[eventStartDateState, eventEndDateState]}
              onChange={(dates: any) => {
                setEventStartDateState(dates[0]);
                setEventEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                handleEventDateFilter();
                confirm();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setEventStartDateState(null);
                setEventEndDateState(null);
                setEventStartDate(null);
                setEventEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Claim Status",
      dataIndex: "progress_status",
      key: "claim_status",
      filters: claimStatusFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedClaimStatus,
      render: (_: string, record: any) => {
        return <NoWrap text={record.progress_status}></NoWrap>;
      },
    },
    {
      title: "Review Standing",
      dataIndex: "review_standing",
      filters: reviewStandingFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedReviewStatus,
      key: "review_standing",
      render: (text: string, record: any) => {
        return <NoWrap text={record.review_standing}></NoWrap>;
      },
    },
    {
      title: "Claim Submitted Date",
      dataIndex: "date_claim_submission",
      width: 150,
      filteredValue: [],
      key: "9",
      render: (date: any) => new Date(date).toLocaleDateString(),
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[startDateState, endDateState]}
              onChange={(dates: any) => {
                setStartDateState(dates[0]);
                setEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                confirm();
                handleFilter();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setStartDateState(null);
                setEndDateState(null);
                setStartDate(null);
                setEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Capture Date",
      dataIndex: "created_date",
      width: 150,
      filteredValue: [],
      key: "9",
      render: (date: any) => new Date(date).toLocaleDateString(),
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[captureStartDateState, captureEndDateState]}
              onChange={(dates: any) => {
                setCaptureStartDateState(dates[0]);
                setCaptureEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                confirm();
                handleCaptureDateFilter();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setCaptureStartDateState(null);
                setCaptureEndDateState(null);
                setCaptureStartDate(null);
                setCaptureEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Last Update",
      dataIndex: "modified_date",
      key: "10",
      width: 120,
      filteredValue: [],
      render: (date: any) => new Date(date).toLocaleDateString(),
      // sorter: (a, b) => {
      //   let dateA = new Date(a?.modified_date);
      //   let dateB = new Date(b?.modified_date);
      //   return dateA.getTime() - dateB.getTime();
      // },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      render: (date: any) => {
        const text = date && new Date(date).toLocaleDateString();
        return <NoWrap text={text}></NoWrap>;
      },
      key: "11",
      filteredValue: [],
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[paymentStartDateState, paymentEndDateState]}
              onChange={(dates: any) => {
                dates && setPaymentStartDateState(dates[0]);
                dates && setPaymentEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                confirm();
                handlePaymentDateFilter();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setPaymentStartDateState(null);
                setPaymentEndDateState(null);
                setPaymentStartDate(null);
                setPaymentEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Actions",
      key: "13",
      fixed: "right",
      filteredValue: [],
      render: (text, record) => (
        <Space size="small" style={{ minWidth: "112px" }}>
          <Space.Compact direction="vertical">
            {
              <Button type="link" onClick={() => handEdit(record.id)}>
                Details
              </Button>
            }

            {
              <Button type="link" onClick={() => handleDuplicate(record.id)}>
                Duplicate
              </Button>
            }

            {record.is_reviewed === false && (
              <Button type="link" onClick={() => handleOpenModal(record.id)}>
                Review
              </Button>
            )}

            {record.is_actioned_payment === true &&
              record.is_uploaded === false && (
                <Button
                  type="link"
                  onClick={() => handleApprovePayment(record.id)}
                >
                  Payment
                </Button>
              )}
            <br />

            {record.is_paid && (
              <Button
                block
                type="link"
                style={{
                  color:
                    !record.pop_document || record.pop_document.trim() === ""
                      ? "red"
                      : "unset",
                }}
                onClick={() =>
                  record.pop_document &&
                  window.open(record.pop_document, "_blank")
                }
              >
                {!record.pop_document || record.pop_document.trim() === ""
                  ? "POP Missing"
                  : "View POP"}
              </Button>
            )}

            {record.progress_status === "Claim In Payment" && (
              <Button
                block
                type="link"
                onClick={() => handleClaimInPayment(record.id)}
              >
                CIP
              </Button>
            )}

            {record?.is_reviewed === true && record?.overwrite_request_decision === null &&
              record?.overwrite_request === false && (
                <Button
                  block
                  type="link"
                  onClick={() => handleRequestChanges(record.id)}
                >
                  Request Changes
                </Button>
              )}
            {record?.is_reviewed === true && record?.overwrite_request_decision === null &&
              record?.overwrite_request === true && record?.overwrite_fulfillment === false && (
                <Button
                  block
                  type="link"
                  onClick={() => handleGrantOverwrite(record)}
                >
                  Grant Overwrite
                </Button>
              )}

            {record?.is_reviewed === true && record?.overwrite_request_decision === 'approve' && (
              <Button
                block
                type="link"
                onClick={() => handleMakeChanges(record.id)}
              >
                Apply Changes
              </Button>
            )}

          </Space.Compact>
        </Space>
      ),
    },
  ];

  const getScheme = async () => {
    try {
      const response = await instance.get(
        `api/claims/underwriter/?format=json`
      );
      const array:
        | ((prevState: Scheme[]) => Scheme[])
        | { id: any; scheme_name: any }[] = [];

      response.data.forEach((element: { scheme_name: any; id: any }) => {
        const model = {
          id: element.id,
          scheme_name: element.scheme_name,
        };

        array.push(model);
      });

      setSchemes(array);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getStatuses = async (apiEndpoint: any) => {
    try {
      const response = await instance.get(apiEndpoint);
      const array = response.data.map((element: any) => ({
        text: element.name,
        value: element.name,
      }));
      return array;
    } catch (error: any) {
      console.log(error.message);
      return [];
    }
  };

  // const fetchAllStatuses = async () => {
  //   const claimStatuses = await getStatuses("api/claims/claim-status/?format=json");
  //   const progressStatuses = await getStatuses("api/claims/closure-status/?format=json");
  //   const reviewStatuses = await getStatuses("api/claims/review-status/?format=json");
  //   const paymentStatuses = await getStatuses("api/claims/payment-status/?format=json");

  //   // Combine all statuses into one array
  //   const allStatuses = [...claimStatuses, ...progressStatuses, ...reviewStatuses, ...paymentStatuses];

  //   // Remove duplicates if needed
  //   const uniqueStatuses = Array.from(new Set(allStatuses.map((status) => status.text)))
  //     .map((text) => allStatuses.find((status) => status.text === text));

  //   setAllStatuses(uniqueStatuses);
  // };

  const getInsurer = async () => {
    try {
      const response = await instance.get(`api/claims/insurer/?format=json`);
      const array:
        | ((prevState: Insurer[]) => Insurer[])
        | { id: any; name: any }[] = [];

      response.data.forEach((element: { insurer_name: any }) => {
        const model = {
          id: element.insurer_name,
          name: element.insurer_name,
        };

        array.push(model);
      });

      setInsurerStatus(array);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    instance
      .get("/api/claims/review-standing/?format=json")
      .then(({ data }) => {
        const standingFilters = data.map((standing: any) => {
          const text = standing.review_standing;
          return {
            text,
            value: text,
          };
        });
        setReviewStandingFilters(standingFilters);
      });
  }, []);

  useEffect(() => {
    instance.get("api/claims/progress-status/?format=json").then(({ data }) => {
      const claimStatusFilters = data.map((status: any) => {
        const text = status.progress_status;
        return {
          text,
          value: text,
        };
      });
      setClaimStatusFilters(claimStatusFilters);
    });
  }, []);

  useEffect(() => {
    instance.get("api/claims/insurer/?format=json").then(({ data }) => {
      const insurerFilters = data.map((insurer: any) => {
        return {
          text: insurer.insurer_name,
          value: insurer.id,
        };
      });

      setInsurerFilters(insurerFilters);
    });
  }, []);

  useEffect(() => {
    instance.get("api/claims/underwriter/?format=json").then(({ data }) => {
      const schemeFilters = data.map((scheme: any) => {
        return {
          text: scheme.scheme_name,
          value: scheme.id,
        };
      });
      setSchemeFilters(schemeFilters);
    });
  }, []);

  useEffect(() => {
    instance.get("api/claims/assessor/?format=json").then(({ data }) => {
      const assessorFilters = data.map((assessor: any) => {
        const text = `${assessor.name} ${assessor.surname}`.trim();
        return {
          text,
          value: assessor.id,
        };
      });
      setAssessorFilters(assessorFilters);
    });
  }, []);

  useEffect(() => {
    getScheme();
    getInsurer();
  }, []);

  const formatDate = (date: any) => {
    return date?.$d.toISOString().slice(0, 10);
  };

  useEffect(() => {
    dispatch(
      getClaims({
        page_size: page_size,
        page: currentPage,
        search_text: search,
        insurer_search: String(selectedInsurer),
        created_date_end: formatDate(endDate),
        created_date_start: formatDate(startDate),
        deceased_date_end: formatDate(eventEndDate),
        deceased_date_start: formatDate(eventStartDate),
        payment_date_end: formatDate(paymentEndDate),
        payment_date_start: formatDate(paymentStartDate),
        capture_date_start: formatDate(captureStartDate),
        capture_date_end: formatDate(captureEndDate),
        progress_search: String(selectedClaimStatus),
        review_search: String(selectedReviewStatus),
        assessor_search: String(selectedAssessor),
        scheme_search: String(selectedScheme),
      })
    );
  }, [
    dispatch,
    page_size,
    currentPage,
    selectedInsurer,
    selectedAssessor,
    selectedScheme,
    selectedReviewStatus,
    selectedClaimStatus,
    search,
    startDate,
    endDate,
    eventStartDate,
    eventEndDate,
    paymentStartDate,
    paymentEndDate,
    toggleFetch,
  ]);

  const handleOpenModal = (claimId: string) => {
    setClaimReviewId(claimId);
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setIsReviewModalOpen(true);

    // Retrieve the Data of this select id
    fetchClaimData(claimId);
  };

  const handleCloseModal = () => {
    setIsReviewModalOpen(false);
  };

  const fetchClaimData = async (claimId: string) => {
    try {
      const response = await instance.get(
        `api/claims/review/${claimId}/?format=json`
      );

      const claimReviewData = response.data;

      setExistingClaimData(claimReviewData);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const handlePaymentModal = () => {
    setIsModalOpenPayment(false);
  };

  const handleApprovePayment = (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setClaimPaymentID(claimId);
    setIsModalOpenPayment(true);
  };

  const download_report = async () => {
    try {
      const response = await instance.post("api/claims/report-download/", {
        responseType: "blob", // Specify the response type as 'blob'
      });

      // Create a Blob object from the response data with the correct content type
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today}- All Captured Claims.csv`; // Set the desired filename with .xlsx extension for Excel

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (error: any) {
      console.error("Error downloading CSV:", error);
      dispatch(setErrorMessage("There was an error processing download"));
      console.log(error.message);
    }
  };

  const handleClaimInPayment = (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setIsClaimInPayment(true);
  };

  const handleMakeChanges = (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setMakeChangesOpen(true);
  };

  const handleDuplicate = async (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setIsDuplicateModalOpen(true);
  };
  const handleDuplicateCancel = () => {
    setIsDuplicateModalOpen(false);
  };

  const handEdit = (claimID: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimID);
    setSelectedClaimData(selectedData);
    setIsEditModalOpen(true);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    let header = [];
    let headers = columns.map((column) => ({
      label: column.title,
      key: column.key,
    }));
    header.push(headers);

    setTableHeaders(header);
  }, []);

  const deepSearch = (obj: any, searchTerm: any) => {
    if (typeof obj !== "object" || obj === null) {
      return false; // Not an object or null, so no properties to search
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // If the value is an object, recursively search its properties
        if (typeof value === "object") {
          if (deepSearch(value, searchTerm)) {
            return true;
          }
        } else {
          // If the value is not an object, perform a case-insensitive search
          if (String(value).toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
      }
    }

    return false; // No properties found or matched the search term
  };

  const onSearch = (searchValue: any) => {
    // Convert searchValue to string then lowercase
    const searchKey =
      typeof searchValue === "string"
        ? searchValue.toLocaleLowerCase()
        : String(searchValue).toLowerCase();

    const filtered = dataSource?.filter((item) => {
      return deepSearch(item, searchKey);
    });

    setFilteredData(filtered); // Update the filteredData state with the filtered results
    setDataFiltered(true);
    setCurrentPage(1);
  };

  const NoWrap = ({ text }: { text: string }) => (
    <div style={{ whiteSpace: "nowrap", minWidth: "112px" }}>{text}</div>
  );

  const fetchFilteredData = async (params: any) => {
    const query = Object.entries(params)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const response = await instance.get(
      `api/claims/report-download-filtered/?format=json&${query}`,
      {
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      download_filtered_report(response);
      return response.data;
    } else {
      setErrorMessage("failed to fetch filtered data");
      return;
    }
  };

  // Download filtered data from backend
  const download_filtered_report = async (response: any) => {
    try {
      // Create a Blob object from the response data with the correct content type
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today}- Captured Claims.csv`; // Set the desired filename with .xlsx extension for Excel

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (error: any) {
      console.error("Error downloading CSV:", error);
      dispatch(setErrorMessage("There was an error processing download"));
      console.log(error.message);
    }
  };

  //  if dataSource
  const handleExportToExcel = async () => {
    // Define the headers to include in the Excel file
    const headers = [
      { label: "Assessor Name", key: "assessor" },
      { label: "Assessor Email", key: "assessor_email" },
      { label: "Insurer Name", key: "insurer_name" },
      { label: "Scheme Name", key: "scheme_name" },
      { label: "Original Claim Form", key: "" },
      { label: "Claim Status", key: "claim_status" },
      { label: "Date Claim Submitted", key: "date_claim_submission" },
      { label: "Date Claim Captured", key: "created" },
      { label: "Modified Date", key: "modified" },
      { label: "Date Claim Paid", key: "payment_date" },
      { label: "Submission Month", key: "submission_month" },
      { label: "Capture Month", key: "capture_month" },
      { label: "Payment Month", key: "payment_month" },
      { label: "External Policy number", key: "policy_number" },
      { label: "Product Type", key: "product_type" },
      { label: "Risk Status", key: "risk_status" },
      { label: "Cause Of Death", key: "cause_of_death" },
      { label: "Inception Date", key: "inception_date" },
      { label: "Termination Date", key: "termination_date" },
      { label: "Gross Premium", key: "gross_premium" },
      { label: "Principal Member", key: "principal_member" },
      { label: "ID Type", key: "id_type" },
      { label: "ID/Passport", key: "principal_id_number" },
      { label: "Date of Death", key: "date_of_death" },
      { label: "Deceased Name(s)", key: "deceased_name" },
      { label: "Deceased Surname", key: "deceased_surname" },
      { label: "Marital Status", key: "marital_status" },
      { label: "Residence Country", key: "residence_country" },
      { label: "Birth Country", key: "birth_country" },
      { label: "Nationality", key: "nationality" },
      { label: "Deceased Member Type", key: "deceased_member" },
      { label: "ID Type", key: "deceased_id_type" },
      { label: "Deceased ID/Passport", key: "deceased_id_number" },
      { label: "Deceased Gender", key: "gender" },
      { label: "Deceased Date of Birth", key: "date_of_birth" },
      { label: "Age Band", key: "age_band" },
      { label: "Age At Death", key: "age_at_death" },
      { label: "Cover Amount", key: "claim_amount" },
      { label: "Amount Paid", key: "amount_paid" },
      { label: "Tax Reference Number(Optional)", key: "tax_reference_number" },
      { label: "Source Of Funds (Optional)", key: "source_of_funds" },
      {
        label: "Nature of Income (Nature of Sanlam Pay-out)(optional)",
        key: "nature_of_income",
      },
      { label: "Place of Death", key: "place_of_death" },
      { label: "Name of Hospital", key: "name_of_hospital" },
      { label: "Medical Practitioner Name", key: "dr_Pathologist_nurse_name" },
      {
        label: "Medical Practitioner Practice Number",
        key: "doctor_practice_umber",
      },
      { label: "Informant Name", key: "informant_name" },
      {
        label: "Informant Relationship To The Deceased",
        key: "relation_to_deceased",
      },
      { label: "Chiefs Name", key: "chiefs_name" },
      { label: "Chiefs Contact", key: "chiefs_phone_number" },
      { label: "Funeral Parlour Performing Service", key: "funeral_parlour" },
      {
        label: "Death Certificate Issuer",
        key: "Death_Certificate_issued_by_User",
      },
      { label: "HA That Issued DC", key: "HA_that_Issued_DC" },
      { label: "DHA1663 Reference Number", key: "DHA1663" },
      { label: "DHA1680 Reference Number", key: "DHA1680" },
      { label: "Name Of Beneficiary", key: "name_of_beneficiary" },
      { label: "Bank Name", key: "bank_name" },
      { label: "Account Type", key: "account_type" },
      { label: "Bank Account Number", key: "account_number" },
      { label: "Branch Code", key: "branch_code" },
      { label: "Premium Check Six Months", key: "premium_check" },
      { label: "Data Confirmation Check", key: "data_confirmation" },
      { label: "Payment Reference", key: "pop_reference" },
      { label: "Notes", key: "notes" },
    ];

    const downloadData = await fetchFilteredData({
      page_size: totalDataCount,
      page: currentPage,
      search_text: search,
      insurer_search: selectedInsurer,
      created_date_end: formatDate(endDate),
      created_date_start: formatDate(startDate),
      deceased_date_end: formatDate(eventEndDate),
      deceased_date_start: formatDate(eventStartDate),
      payment_date_end: formatDate(paymentEndDate),
      payment_date_start: formatDate(paymentStartDate),
      progress_search: selectedClaimStatus,
      review_search: selectedReviewStatus,
      assessor_search: selectedAssessor,
      scheme_search: selectedScheme,
    });
  };

  function isDataFiltered(): boolean {
    const selectedFilters = [
      selectedAssessor,
      selectedInsurer,
      selectedScheme,
      selectedClaimStatus,
      selectedReviewStatus,
      searchInput,
      endDate,
      startDate,
      eventEndDate,
      eventStartDate,
      paymentEndDate,
      paymentStartDate,
    ];
    return selectedFilters.some(
      (filter) => (Array.isArray(filter) && filter.length) || filter
    );
  }

  const filteredText = isDataFiltered()
    ? `Filtered (${totalDataCount})`
    : "No filter";
  const exportToCSVMenuItems: MenuProps["items"] = [
    { label: "All", key: "all", onClick: () => download_report() },
    {
      label: filteredText,
      key: "filtered",
      onClick: () => handleExportToExcel(),
      disabled: !isDataFiltered(),
    },
  ];

  const handleReset = () => {
    setSearch("");
    setSearchInput("");
    setSelectedAssessor([]);
    setSelectedInsurer([]);
    setSelectedReviewStatus([]);
    setSelectedClaimStatus([]);
    setSelectedScheme([]);
    setStartDateState(null);
    setEndDateState(null);
    setStartDate(null);
    setEndDate(null);
    setPaymentStartDateState(null);
    setPaymentEndDateState(null);
    setPaymentStartDate(null);
    setPaymentEndDate(null);
    setEventStartDateState(null);
    setEventEndDateState(null);
    setEventStartDate(null);
    setEventEndDate(null);
    setCaptureStartDate(null);
    setCaptureEndDate(null);
    setCaptureStartDateState(null);
    setCaptureEndDateState(null);
  };

  // const handleRefresh = () => {
  //   const selectedDataId = selectedClaimData?.id;
  //   setSearch("");
  //   setSearchInput("");
  //   setSelectedAssessor([]);
  //   setSelectedInsurer([]);
  //   setSelectedReviewStatus([]);
  //   setSelectedClaimStatus([]);
  //   setSelectedScheme([]);
  //   setStartDateState(null);
  //   setEndDateState(null);
  //   setStartDate(null);
  //   setEndDate(null);
  //   setPaymentStartDateState(null);
  //   setPaymentEndDateState(null);
  //   setPaymentStartDate(null);
  //   setPaymentEndDate(null);
  //   setEventStartDateState(null);
  //   setEventEndDateState(null);
  //   setEventStartDate(null);
  //   setEventEndDate(null);
  //   const selectedData = tableData.find((record: any) => record.id === selectedDataId);
  //   setSelectedClaimData(selectedData);
  // };

  const [errorStatus, setErrorStatus] = useState(true);

  const [newClaims, setNewClaims] = useState<any[]>([{}]);
  const [isCaptureModalOpen, setCaptureModalOpen] = useState<boolean>(false);

  const submitClaimCapture = (data: any) => {
    console.log("submit capture claim:", data);
  }

  return (
    <>
      <div className="">
        <div className="">
          <div
            className="search-container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Space direction="horizontal">
              <Input.Search
                onSearch={() => {
                  setSearch(searchInput);
                  onSearch(searchInput);
                }}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    onSearch(searchInput);
                  }
                }}
                value={searchInput}
                onChange={(e: any) => setSearchInput(e.target.value)}
                onBlur={(e: any) => setSearchInput(e.target.value)}
                className="search-input"
                style={{
                  width: 300,
                  marginBottom: 16,
                  marginTop: 16,
                }}
                placeholder="Enter ID, Passport or Policy Number"
              />
              <Button onClick={handleReset}>Reset filters</Button>
            </Space>

            <div style={{ marginLeft: "auto" }}>
              <Space size="large">
                <Button type="primary" onClick={() => setCaptureModalOpen(true)}>Capture Claim</Button>
                <Dropdown menu={{ items: exportToCSVMenuItems }}>
                  <Button type="primary">
                    <Space>Export to CSV</Space>
                  </Button>
                </Dropdown>
              </Space>
            </div>
          </div>
          <div className="card-body" style={{ position: "relative" }}>
            <Table
              tableLayout="auto"
              onChange={(
                config: TablePaginationConfig,
                filters: Record<string, any>
              ) => {
                setPage_size(config.pageSize!);
                setCurrentPage(config.current!);

                setSelectedAssessor(filters["assessor"] ?? []);
                setSelectedInsurer(filters["insurer"] ?? []);
                setSelectedScheme(filters["scheme"] ?? []);
                setSelectedClaimStatus(filters["claim_status"] ?? []);
                setSelectedReviewStatus(filters["review_standing"] ?? []);
              }}
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 1450, y: windowHeight - 320 }}
              rowKey={(record) => record.id}
              // pagination={{
              //   total: totalDataCount,
              //   showTotal: (total) => `${total} rows`,
              // }}
              pagination={{
                total: totalDataCount,
                showTotal: (total) => `${total} rows`,
                pageSize: page_size,
                current: currentPage,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50', '100'],
              }}
              rowClassName={(record) => record?.overwrite_request ? "requested-changes" : ""}
            />
          </div>
        </div>
      </div>

      {ReviewModalOpen && (
        <ClaimReviewModal
          claimId={claimReviewId}
          isModalOpen={ReviewModalOpen}
          claimData={selectedClaimData}
          onClose={handleCloseModal}
          setIsReviewModalOpen={setIsReviewModalOpen}
          handleReset={handleReset}
        />
      )}

      {isEditModalOpen && (
        <UpdateClaim
          isEditModalOpen={isEditModalOpen}
          claimData={selectedClaimData}
          onClose={handleEditCancel}
          setIsEditModalOpen={setIsEditModalOpen}
          handleReset={handleReset}
        />
      )}

      {isDuplicateModalOpen && (
        <DuplicateClaim
          isDuplicateModalOpen={isDuplicateModalOpen}
          setIsDuplicateModalOpen={setIsDuplicateModalOpen}
          claimData={selectedClaimData}
          onClose={handleDuplicateCancel}
          handleReset={handleReset}
        />
      )}

      {isMakeChangesOpen && (
        <OverwriteEdit
          isEditModalOpen={isMakeChangesOpen}
          claimData={selectedClaimData}
          onClose={() => {
            setMakeChangesOpen(false)
          }}
          setToggleFetch={setToggleFetch}
          setIsEditModalOpen={setMakeChangesOpen}
          handleReset={handleReset}
          user={user}
        />
      )}

      {isModalOpenPayment && (
        <PaymentForm
          isModalOpen={isModalOpenPayment}
          claimData={selectedClaimData}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpenPayment}
          handleReset={handleReset}
        />
      )}

      {isClaimInPayment && (
        <ClaimInPayment
          isModalOpen={isClaimInPayment}
          selectedClaim={selectedClaimData}
          onClose={handleCloseModal}
          setIsModalOpen={setIsClaimInPayment}
          handleReset={handleReset}
        />
      )}

      <Modal
        title="Request Changes"
        open={isRequestChangesModalOpen}
        onOk={submitRequestChanges}
        okText="Submit"
        onCancel={() => {
          setRequestChangesModalOpen(false);
        }}
      >
        <Form layout="vertical" form={requestChangesForm}>
          <Form.Item label="Reason For Request" name="reason_for_request">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Grant Overwrite"
        open={isGrantOverwriteModalOpen}
        onOk={submitGrantOverwrite}
        okText="Submit"
        onCancel={() => {
          setGrantOverwriteModalOpen(false);
        }}
      >
        <Form layout="vertical" form={grantOverwriteForm}>
          <Form.Item label="Approve or Deny" name="decision">
            <Radio.Group>
              <Radio value="approve">Approve</Radio>
              <Radio value="deny">Deny</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Reason">
            <TextArea rows={4} readOnly value={selectGrantOverwrite?.overwrite_reason} />
          </Form.Item>
          <Form.Item label="Requested By">
            <Input readOnly value={selectGrantOverwrite?.overwrite_request_requested_by} />
          </Form.Item>
          <Form.Item label="Comments" name="overwrite_request_decision_comments">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <NewClaimModal
        visible={isCaptureModalOpen}
        onCancel={() => setCaptureModalOpen(false)}
        onOk={submitClaimCapture}
        data={newClaims}
        setData={setNewClaims}
      />
    </>
  );
};
export default ReviewClaimsTable;
