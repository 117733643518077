import Header from "../Dashboard/header";
import AdminTable from "./AdminTable";

const AdminPage = () => {
  return (
    <Header title="Insights">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 30,
          height: "calc(100vh - 140px)", // Set the height of the container to the viewport height minus the total vertical space occupied by the Header component
          overflow: "hidden", // Hide the scrollbar of the page
        }}
      >
        <AdminTable />
      </div>
    </Header>
  );
};

export default AdminPage;