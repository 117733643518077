import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setEventDetail } from "../../redux/actions/EventDetailAction";
import { IEventDetail } from "../../redux/types/EventDetail";
import { Modal, Form, Input, Button, Row, Col } from 'antd';

interface EventProps {
    isEventDetailModalOpen: boolean | false;
    eventDetail: IEventDetail;
    setEventDetailModalOpen: any;
    handleAddEventDetails: any;
}

const EventDetailModal: React.FC<EventProps> = ({
    isEventDetailModalOpen,
    eventDetail,
    setEventDetailModalOpen,
    handleAddEventDetails
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            ...eventDetail
        });
    }, [eventDetail, form]);

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                handleAddEventDetails(values);
                form.resetFields();

            })
            .catch(info => {
                console.log('Validation failed:', info);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        setEventDetailModalOpen(false);
    }


    return (
        <Modal
            open={isEventDetailModalOpen}
            title="Add or Edit Event Details"
            okText="Submit"
            cancelText="Cancel"
            onCancel={handleCancel}
            onOk={handleOk}
            width="60%"
        >
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item name="sourceOfFunds" label="Source of Funds">
                            <Input />
                        </Form.Item>
                        <Form.Item name="taxReference" label="Tax Reference">
                            <Input />
                        </Form.Item>
                        <Form.Item name="natureOfIncome" label="Nature of Income">
                            <Input />
                        </Form.Item>
                        <Form.Item name="placeOfDeath" label="Place of Death">
                            <Input />
                        </Form.Item>
                        <Form.Item name="nameOfHospital" label="Name of Hospital">
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item name="drNursePathologist" label="Name of Dr/Nurse/Pathologist">
                            <Input />
                        </Form.Item>
                        <Form.Item name="drNursePathologistPracticeNumber" label="Dr/Nurse/Pathologist Practice Number">
                            <Input />
                        </Form.Item>
                        <Form.Item name="funeralParlour" label="Funeral Parlour">
                            <Input />
                        </Form.Item>
                        <Form.Item name="chiefsName" label="Chief's Name">
                            <Input />
                        </Form.Item>
                        <Form.Item name="chiefsPhoneNumber" label="Chief's Phone Number">
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item name="dha1663" label="DHA1663">
                            <Input />
                        </Form.Item>
                        <Form.Item name="dha1680" label="DHA1680">
                            <Input />
                        </Form.Item>
                        <Form.Item name="deathCertificateIssuedByUser" label="Death Certificate Issued By User">
                            <Input />
                        </Form.Item>
                        <Form.Item name="haThatIssuedDeathCertificate" label="HA That Issued Death Certificate">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
};

export default EventDetailModal;
