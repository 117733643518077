// appMessagesActions.ts

export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const SET_CUSTOM_MESSAGE = "SET_CUSTOM_MESSAGE";

export const setSuccessMessage = (message: string) => ({
    type: SET_SUCCESS_MESSAGE,
    payload: message,
  });
  
  export const setErrorMessage = (message: string) => ({
    type: SET_ERROR_MESSAGE,
    payload: message,
  });
  // log
  export const setTryMessage = (message: string) => ({
    type: SET_ERROR_MESSAGE,
    payload: message,
  });

  export const setCustomMessage = (header: string, message: string) => ({
    type: SET_CUSTOM_MESSAGE,
    payload: {header, message}
  })

  export const clearMessages = () => ({
    type: CLEAR_MESSAGES,
  });
  