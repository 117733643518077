import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Upload, Button, Select } from "antd";
import SchemeDropdown from "./SchemeDropdown";
// import { UploadOutlined } from "@ant-design/icons";

interface NewPolicyProps {
  isModalVisible: boolean;
  handleOk: any;
  handleCancel: any;
  form: any;
  schemeOptions: any[];
  selectedScheme: number | any;
  setSelectedScheme: any;
}

const NewPolicyModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  form,
  schemeOptions,
  selectedScheme,
  setSelectedScheme,
}: NewPolicyProps) => {
  const normFile = (e: { fileList: any }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const [enableSubmit, setEnableSubmit] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then((values: any) => setEnableSubmit(true))
      .catch(() => setEnableSubmit(false));
  }, [form, values]);

  // useEffect(() => {
  //   if (form.getFieldValue("schemeName") && form.getFieldValue("file")) {
  //     setEnableSubmit(true);
  //   } else {
  //     setEnableSubmit(false);
  //   }
  // }, [form, values]);

  return (
    <Modal
      title="New Scheme Data"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Submit"
      okButtonProps={{
        disabled: !(enableSubmit === true),
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="scheme_id"
          label="Select Scheme"
          rules={[{ required: true, message: "Please select a scheme" }]}>
          <Select options={schemeOptions} showSearch />
          {/* <SchemeDropdown
            options={schemeOptions}
            onValueChange={setSelectedScheme}
            form={form}
          /> */}
        </Form.Item>
        <Form.Item
          name="file"
          label="Upload Scheme Data"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Please upload the CSV file!" }]}
        >
          <Upload
            name="file"
            accept=".csv"
            maxCount={1}
            beforeUpload={() => false} // Prevent automatic upload
          >
            <Button>Click to upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewPolicyModal;