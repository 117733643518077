import { SelectProps } from "antd";
import Select, { DefaultOptionType } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import instance from "../../../axios/axios-instance";
import ClaimantCreate from "../../../claimant/create";
import PrincipalMember from "../../../principal_member/create";
import { setMemberDetail } from "../../../redux/actions/MemberDetailAction";
import { fetchPrincipalMembers } from "../../../redux/actions/PrincipalMemberAction";
import { MemberDetail } from "../../../redux/types/MemberDetail";
import { IPrincipalMemember } from "../../../redux/types/principalMember";
import { SearchClaimants } from "../../capture/components/search-claimant";
import { SearchPrincipalMembers } from "../../capture/components/search-principal-member";

interface MemberDetailProps {
  isReviewed?: boolean;
  MemberDetailPropsIsInput?: boolean;
  principalMember: any;
  setPrincipalMember: React.Dispatch<React.SetStateAction<string>>;
  deceasedType: string;
  setDeceasedType: React.Dispatch<React.SetStateAction<string>>;
  productType: any;
  setProductType: React.Dispatch<React.SetStateAction<any>>;
  informantRelation: string;
  informantName: string;
  claimant?: any;
  setInformantRelation: React.Dispatch<React.SetStateAction<string>>;
  setInformantName: React.Dispatch<React.SetStateAction<string>>;
  setClaimant: React.Dispatch<React.SetStateAction<string>>;
}
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const MembershipDetail: React.FC<MemberDetailProps> = ({
  isReviewed,
  MemberDetailPropsIsInput,
  principalMember,
  setPrincipalMember,
  deceasedType,
  setDeceasedType,
  productType,
  setProductType,
  informantRelation,
  setInformantRelation,
  informantName,
  setInformantName,
  claimant,
  setClaimant,
}) => {
  // const dispatch = useDispatch();
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector((state: any) => state?.principalMember);
  const claimantData = useSelector((state: any) => state?.claimants);
  const [isAddPrincipalMemberDialogOpen, setIsAddPrincipalMemberDialogOpen] =
    useState(false);
  const [isModalOpenClaimant, setIsModalClimant] = useState(false);
  const [isClaimantModalOpen, setIsClaimantModalOpen] = useState(false);
  const [claimantOptions, setClaimantOptions] = useState<any[]>([]);
  const [isSearchPrincipalemberOpen, setIsSearchPrincipalMemberOpen] =
    useState(false);
  const [isSearchClaimantOpen, setIsSearchClaimantOpen] = useState(false);
  const [selectedPrincipalMember, setSelectedPrincipalMember] = useState<
    IPrincipalMemember & { key: any }
  >();

  useEffect(() => {
    setClaimantOptions(
      claimantData.map((item: any) => {
        return {
          label: `${item.name_of_claimant}(${item.id_of_claimant})`,
          value: item.id,
        };
      })
    );
  }, [claimantData]);

  function getClaimant() {
    const { id_of_claimant, name_of_claimant, surname_of_claimant, passport } =
      claimant || ({} as any);
    return [name_of_claimant, surname_of_claimant, id_of_claimant, passport]
      .filter((value) => value)
      .join(" / ");
  }

  const [productTypes, setProductTypes] = useState([]);

  const [value, setValue] = useState(
    principalMember
      ? data
          ?.filter((x: { id: string }) => x.id === principalMember)
          .map(
            (x: { full_names: string; id_number: string }) =>
              `${x.full_names}(${x.id_number})`
          )[0]
      : ""
  );
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  // const [valueClaimant, setValueClaimant] = useState<any>(claimant.id);

  const [valueClaimant, setValueClaimant] = useState(
    claimant
      ? claimantData
          ?.filter((x: any) => x.id.toString() === claimant.id)
          .map(
            (x: { full_names: string; id_number: string }) =>
              `${x.full_names}(${x.id_number})`
          )[0]
      : ""
  );
  const [optionsClaimant, setOptionsClaimant] = useState<
    SelectProps<object>["options"]
  >([]);

  const onChange = (selectedData: string) => {
    setValue(selectedData);

    setPrincipalMember(
      data?.find((item: any) => {
        const ItemName = `${item.full_names}(${item.id_number})`;
        return selectedData === ItemName;
      })
    );
  };

  const onChangeClaimant = (data: string) => {
    setValueClaimant(data);
    setClaimant(data);
  };
  useEffect(() => {
    if (MemberDetailPropsIsInput) {
      setDeceasedType("");
      setProductType("");
      setClaimant("");
      setPrincipalMember("");
      setInformantRelation("");
      setInformantName("");
      setValueClaimant("");
      setValue("");
    }

    // setIClearInputs(false);
  }, [MemberDetailPropsIsInput]);

  const getPanelValue = (searchTerm: string): any => {
    const results = options?.filter((x) => {
      const valueLowerCase = String(x.value).toLowerCase();
      const searchTermLowerCase = searchTerm.toLowerCase();

      return valueLowerCase.includes(searchTermLowerCase);
    });

    if (results?.length === 0) {
      setIsAddPrincipalMemberDialogOpen(false);
      return [
        {
          value: "",
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                <a onClick={OpenModal} rel="noopener noreferrer">
                  No Results
                </a>
              </span>
            </div>
          ),
        },
      ];
    } else {
      return results;
    }
  };

  const getPanelValueClaim = (searchTerm: string): any => {
    const results = optionsClaimant?.filter((x) => x.value === searchTerm);

    if (results?.length === 0) {
      setIsModalClimant(false);
      return [
        {
          value: "",
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                <a onClick={OpenModalClaimant} rel="noopener noreferrer">
                  No Results
                </a>
              </span>
            </div>
          ),
        },
      ];
    } else {
      return results;
    }
  };

  const OpenModal = () => {
    setIsAddPrincipalMemberDialogOpen(true);
  };
  const OpenModalClaimant = () => {
    setIsModalClimant(true);
  };

  useEffect(() => {
    const claims:
      | React.SetStateAction<DefaultOptionType[] | undefined>
      | { value: string; label: string }[] = [];

    const claimants = claimantData;

    claimants?.forEach(
      (element: { surname_of_claimant: any; id_of_claimant: any; id: any }) => {
        const model = {
          value: `${element?.id}`,
          label: `${element?.surname_of_claimant}(${element?.id_of_claimant})`,
        };

        claims.push(model);
      }
    );

    setOptionsClaimant(claims);
  }, [claimantData]);

  const getProducts = async () => {
    try {
      const response = await instance.get(
        `api/claims/product-types/?format=json`
      );
      setProductTypes(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const prinicepals = [];
    const principaleData = data;

    for (let index = 0; index < principaleData?.length; index++) {
      const model = {
        value: `${principaleData[index]?.full_names}(${principaleData[index]?.id_number})`,
        label: `${principaleData[index]?.full_names}(${principaleData[index]?.id_number})`,
      };

      prinicepals.push(model);
    }

    setOptions(prinicepals);
  }, [data]);

  useEffect(() => {
    const principalID = data?.filter(
      (x: { id_number: string }) => x.id_number === value?.replace(/\D/g, "")
    )[0]?.id;

    const claimantID = claimantData?.filter(
      (x: { id_number: string }) =>
        x.id_number === valueClaimant?.replace(/\D/g, "")
    )[0]?.id;

    const model: MemberDetail = {
      deceasedMemberType: deceasedType,
      productTypes:
        typeof productType?.id === "number" &&
        typeof productType?.id !== "undefined"
          ? productType.id
          : productType,
      claimant: Number(claimant?.id),
      principalMember: Number(principalMember.id),
      informantName: informantName,
      informantRelation: informantRelation,
    };

    dispatch(setMemberDetail(model));
  }, [
    deceasedType,
    productType,
    claimant,
    principalMember,
    informantRelation,
    value,
    valueClaimant,
    claimantData,
    data,
    dispatch,
    informantName,
  ]);

  function getPrincipalMember() {
    const { full_names, surname, id_number, passport } = principalMember;
    return [full_names, surname, id_number, passport]
      .filter((value) => value)
      .join(" / ");
  }

  return (
    <div className="row">
      <div
        className="col-md-6 flex"
        id="frontdeceased_informant"
        style={{
          display: deceasedType !== "" ? "block" : "none",
        }}
      >
        <div className="form-group">
          <label style={{ display: "block" }}>Principal Member</label>
          <Select
            style={{
              width: "100%",
              pointerEvents: isReviewed ? "none" : "auto",
              backgroundColor: isReviewed ? "#E2E8F0" : "",
            }}
            value={getPrincipalMember()}
            onClick={() => setIsSearchPrincipalMemberOpen(true)}
            notFoundContent={null}
          ></Select>
        </div>

        <span className="text-danger">* Required</span>
      </div>

      <div className="col-md-6 flex" id="frontdeceased_informant">
        <div className="form-group">
          <label htmlFor="relation_to_deceased">
            Select Deceased Member Type
          </label>
          <select
            className="form-control"
            id="relation_to_deceased"
            placeholder="---------"
            value={deceasedType}
            required
            name="deceasedType"
            onChange={(e) => setDeceasedType(e.target.value)}
          >
            <option value="">---------</option>
            <option value="Child">Child</option>
            <option value="Spouse">Spouse</option>
            <option value="Principal Member">Principal Member</option>
            <option value="Extended Member">Extended Member</option>
          </select>
          <span className="text-danger">* Required</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="product_type">Product Type</label>
          <select
            className="form-control"
            id="product_type"
            placeholder="---------"
            aria-label="Product Type"
            value={
              typeof Number(productType?.id) === "number"
                ? productType?.id
                : productType
            }
            onChange={(e) => setProductType(e.target.value)}
          >
            <option value="">---------</option>
            {productTypes.map((product: any) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
          <span className="text-danger">* Required</span>
        </div>
      </div>

      <div
        className="col-md-6"
        id="claimant_principa_deceased"
        style={{
          display: deceasedType === "Principal Member" ? "block" : "none",
        }}
      >
        <div className="form-group">
          <label style={{ display: "block" }}>Claimant</label>
          <Select
            value={getClaimant()}
            notFoundContent={null}
            onClick={() => setIsSearchClaimantOpen(true)}
            style={{
              width: "100%",
              pointerEvents: isReviewed ? "none" : "auto",
              backgroundColor: isReviewed ? "#E2E8F0" : "",
            }}
          />
        </div>
      </div>

      <div className="col-md-6 flex" id="frontdeceased_informant">
        <div className="form-group">
          <label htmlFor="informant_name">Informant Name</label>
          <input
            type="text"
            className="form-control"
            value={informantName}
            onChange={(e) => setInformantName(e.target.value)}
            id="informant_name"
            placeholder="Informant Name"
          />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="relation_to_deceased">
            Informant Relation To Deceased
          </label>
          <select
            className="form-control"
            id="relation_to_deceased"
            placeholder="---------"
            aria-label="Informant Relation To Deceased"
            value={informantRelation}
            onChange={(e) => setInformantRelation(e.target.value)}
          >
            <option value="">---------</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Sibling">Sibling</option>
            <option value="Offspring">Offspring</option>
            <option value="Extended Family Member">
              Extended Family Member
            </option>
            <option value="In-Law">In-Law</option>
          </select>
        </div>
      </div>

      <SearchPrincipalMembers
        open={isSearchPrincipalemberOpen}
        setOpen={(value) => setIsSearchPrincipalMemberOpen(value)}
        onOk={(value) => {
          setPrincipalMember(value as any);
        }}
        onCreate={() => {
          setIsAddPrincipalMemberDialogOpen(true);
        }}
      />
      <PrincipalMember
        isModalOpen={isAddPrincipalMemberDialogOpen}
        setIsModalOpen={setIsAddPrincipalMemberDialogOpen}
      />

      <SearchClaimants
        open={isSearchClaimantOpen}
        setOpen={(value) => setIsSearchClaimantOpen(value)}
        onCreate={() => {
          setIsModalClimant(true);
        }}
        onOk={(value) => {
          setClaimant(value as any);
        }}
      />
      <ClaimantCreate
        isModalOpen={isModalOpenClaimant}
        setIsModalOpen={setIsModalClimant}
      />
    </div>
  );
};
const connector = connect(null, { fetchPrincipalMembers });
export default connector(MembershipDetail);
