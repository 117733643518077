import React from "react";
import { Modal, Form, DatePicker, Radio, Button } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";

interface BillModalProps {
  open: boolean;
  onSubmit: (values: {
    member_id: string | undefined;
    bill_period: moment.Moment;
    download_option: string | undefined;
  }) => void;
  onCancel: () => void;
}

const BillModal: React.FC<BillModalProps> = ({ open, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const { memberId } = useParams();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onSubmit({
        bill_period: values.billPeriod.format("YYYY-MM"),
        member_id: memberId,
        download_option: values.downloadOption,
      });
      form.resetFields();
    });
  };

  return (
    <Modal title="Generate Bill" open={open} onCancel={onCancel} footer={null}>
      <Form form={form} layout="vertical" className="mt-8">
        <Form.Item
          name="billPeriod"
          label="Bill Period"
          rules={[
            { required: true, message: "Please select the bill period!" },
          ]}
          className="w-1/2"
        >
          <DatePicker picker="month" format="YYYY-MM" />
        </Form.Item>
        <Form.Item
          name="downloadOption"
          label="Download Option"
          rules={[
            { required: true, message: "Please select a download option!" },
          ]}
        >
          <Radio.Group className="flex flex-col">
            <Radio value="downloadNow">Download Now</Radio>
            <Radio value="saveToDatabase">Save to Database</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BillModal;
