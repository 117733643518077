import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk"; // Middleware for handling async actions
import rootReducer from "./rootReducer"; // Import your root reducer
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
/*
const persistConfig: any = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const pstore = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
}); */

//export const persistor = persistStore(store);

// Apply middleware
const middleware = applyMiddleware(thunkMiddleware);

// Create the Redux store
const store = createStore(rootReducer, middleware);

export default store;
