import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import instance from "../../axios/axios-instance";

interface FormData {
  premium_check: string;
  data_confirmation: string;
  date_of_birth: any;
  policy_number: string;
  scheme_name: string;
  scheme_email: string;
  deceased_member: string;
  principal_member: string;
  claimant: string;
  claim_amount: any;
  inception_date: any;
  informant_name: string;
  relation_to_deceased: string;
  gross_premium: any;
  date_claim_submission: any;
  deceased_surname: string;
  deceased_name: string;
  deceased_id_number: string;
  passport: string;
  deceased_date_of_death: any;
  deceased_address: string;
  residence_country: any;
  birth_country: any;
  nationality: any;
  gender: any;
  marital_status: any;
  source_of_funds: string;
  tax_ref: string;
  nature_of_income: string;
  place_of_death: string;
  name_of_hospital: string;
  funeral_parlour: string;
  chiefs_name: string;
  chiefs_phone_number: string;
  Death_Certificate_issued_by_User: string;
  HA_that_Issued_DC: string;
  DHA1663: string;
  DHA1680: string;
  account_holder: string;
  account_number: string;
  account_type: string;
  bank_name: string;
  notes: string;
  dr_Pathologist_nurse_name: string;
  doctor_practice_umber: string;
  documentation: any;
  claim_status: string;
  amountPaid: any;
  paymentDate: any;
  paymentReference: any;
  closureStatus: any;
  proofOfPayments: any;
}

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  claimData: any;
}

const UploadPOP: React.FC<Props> = (Props) => {
  const [isModalOpen, setIsModalOpen] = useState(Props.isModalOpen);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [formData, setFormData] = useState<FormData>({
    // Initialize form data with the existing claim data
    premium_check: Props.claimData.premiumCheck,
    data_confirmation: Props.claimData.dataConfirmation,
    date_of_birth: Props.claimData.dateOfBirth,
    policy_number: Props.claimData?.policy_number || "",
    scheme_name: Props.claimData?.scheme_name.id || "",
    scheme_email: Props.claimData?.scheme_email || "",
    deceased_member: Props.claimData?.deceased_member || "",
    principal_member: Props.claimData?.principal_member.id || "",
    claimant: Props.claimData?.claimant || "",
    claim_amount: Props.claimData?.claim_amount || "",
    inception_date: Props.claimData?.inception_date || "",
    informant_name: Props.claimData?.informant_name || "",
    relation_to_deceased: Props.claimData?.relation_to_deceased || "",
    gross_premium: Props.claimData?.gross_premium || "",
    deceased_surname: Props.claimData?.deceased_surname || "",
    deceased_name: Props.claimData?.deceased_name || "",
    deceased_id_number: Props.claimData?.deceased_id_number || "",
    passport: Props.claimData?.passport || "",
    deceased_date_of_death: Props.claimData?.deceased_date_of_death || "",
    deceased_address: Props.claimData?.deceased_address || "",
    residence_country: Props.claimData?.residence_country || "",
    birth_country: Props.claimData?.birth_country || "",
    nationality: Props.claimData?.nationality || "",
    gender: Props.claimData?.gender || "",
    marital_status: Props.claimData?.marital_status || "",
    source_of_funds: Props.claimData?.source_of_funds || "",
    tax_ref: Props.claimData?.tax_ref || "",
    nature_of_income: Props.claimData?.nature_of_income || "",
    place_of_death: Props.claimData?.place_of_death || "",
    name_of_hospital: Props.claimData?.name_of_hospital || "",
    bank_name: Props.claimData?.bank_name.id || "",
    account_holder: Props.claimData?.account_holder || "",
    account_number: Props.claimData?.account_number || "",
    Death_Certificate_issued_by_User:
      Props.claimData?.Death_Certificate_issued_by_User || "",
    HA_that_Issued_DC: Props.claimData?.HA_that_Issued_DC || "",
    DHA1663: Props.claimData?.DHA1663 || "",
    DHA1680: Props.claimData?.DHA1680 || "",
    chiefs_phone_number: Props.claimData?.chiefs_phone_number || "",
    chiefs_name: Props.claimData?.chiefs_name || "",
    funeral_parlour: Props.claimData?.funeral_parlour || "",
    doctor_practice_umber: Props.claimData?.doctor_practice_umber || "",
    dr_Pathologist_nurse_name: Props.claimData?.dr_Pathologist_nurse_name || "",
    documentation: Props.claimData?.documentation || "",
    notes: Props.claimData?.notes || "",
    account_type: Props.claimData?.account_type || "",
    claim_status: Props.claimData?.claim_status || "",
    date_claim_submission: "",
    amountPaid: "",
    paymentDate: "",
    paymentReference: "",
    closureStatus: "",
    proofOfPayments: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const HandleSubmit = async (e: any) => {
    e.preventDefault();
    const model: any = {
      scheme_name: formData.scheme_name,
      principal_member: formData.principal_member,
      policy_number: formData.policy_number,
      scheme_email: formData.scheme_email,
      inception_date: formData.inception_date,
      date_claim_submission: formData.date_claim_submission,
      claim_amount: formData.claim_amount,
      informant_name: formData.informant_name,
      relation_to_deceased: formData.relation_to_deceased,
      gross_premium: formData.gross_premium,
      deceased_surname: formData.deceased_surname,
      deceased_member: formData.deceased_member,
      deceased_name: formData.deceased_name,
      deceased_id_number: formData.deceased_id_number,
      deceased_date_of_death: formData.deceased_date_of_death,
      deceased_address: formData.deceased_address,
      nationality: formData.nationality,
      birth_country: formData.birth_country,
      residence_country: formData.residence_country,
      marital_status: formData.marital_status,
      bank_name: formData.bank_name,
      source_of_funds: formData.source_of_funds,
      gender: formData.gender,
      DHA1663: formData.DHA1663,
      DHA1680: formData.DHA1680,
      tax_ref: formData.tax_ref,
      nature_of_income: formData.nature_of_income,
      HA_that_Issued_DC: formData.HA_that_Issued_DC,
      Death_Certificate_issued_by_User:
        formData.Death_Certificate_issued_by_User,
      account_number: formData.account_number,
      account_holder: formData.account_holder,
      name_of_hospital: formData.name_of_hospital,
      place_of_death: formData.place_of_death,
      chiefs_phone_number: formData.chiefs_phone_number,
      chiefs_name: formData.chiefs_name,
      funeral_parlour: formData.funeral_parlour,
      doctor_practice_umber: formData.doctor_practice_umber,
      dr_Pathologist_nurse_name: formData.dr_Pathologist_nurse_name,
      documentation: formData.documentation,
      notes: formData.notes,
      account_type: formData.account_type,
      pop_reference: formData.paymentReference,
      claim_closure: formData.closureStatus,
      amount_paid: formData.amountPaid,
      payment_date: formData.paymentDate,
      bank_statement: formData.proofOfPayments,
    };

    try {
      await instance.post("api/claims/new-claim/", model, {
        withCredentials: true,
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };
  useEffect(() => {}, [formData]);

  return (
    <>
      <Modal
        title="Upload POP"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={HandleSubmit}>
            OK
          </Button>,
        ]}
      >
        <div className="row">
          <h5>{formData.policy_number}</h5>

          <form
            className="row g-3"
            method="POST"
            encType="multipart/form-data"
            id="multi-pop"
          >
            <div className="form-group">
              <label htmlFor="submission">Submission Date</label>
              <input
                required
                id="submission"
                type="date"
                name="date_claim_submission"
                className="form-control"
                value={formData.date_claim_submission}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="amount">Amount Paid</label>
              <input
                id="amount"
                type="text"
                name="amountPaid"
                className="form-control"
                value={formData.amountPaid}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="payment">Payment Date</label>
              <input
                id="payment"
                type="date"
                name="paymentDate"
                className="form-control"
                value={formData.paymentDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-12">
              <label htmlFor="formFile" className="col-sm-12 col-form-label">
                Proof of Payment
              </label>
              <input
                onChange={handleInputChange}
                value={formData.proofOfPayments}
                className="form-control"
                name="proofOfPayments"
                type="file"
                id="formFile"
              />
            </div>

            <div className="form-group">
              <label htmlFor="reference">Payment Reference</label>
              <input
                id="reference"
                type="text"
                name="paymentReference"
                className="form-control"
                value={formData.paymentReference}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="closure">Closure Status</label>
              <select
                id="closure"
                name="closureStatus"
                className="form-control"
                value={formData.closureStatus}
                onChange={handleInputChange}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                </option>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
                <option value="Closed Repudiated">Closed Repudiated</option>
                <option value="Claim In Payment">Claim In Payment</option>
              </select>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default UploadPOP;
