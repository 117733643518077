const ConvertCapturedClaim = (data: any, selectedClaim: any) => {
  const newArray: {
    principal_member: any;
    claimant: any;
    scheme_name: any;
    insurer_name: string;
    deceased_member: string;
    policy_number: any;
    claim_amount: any;
    gross_premium: any;
    amount_paid: string;
    inception_date: any;
    scheme_email: string;
    informant_name: string;
    relation_to_deceased: any;
    deceased_surname: any;
    deceased_name: any;
    deceased_id_number: any;
    deceased_date_of_death: any;
    passport: string;
    date_of_birth: string;
    gender: string;
    still_born_date: any;
    deceased_cause_of_death: string;
    deceased_address: string;
    residence_country: string;
    birth_country: string;
    nationality: string;
    marital_status: string;
    source_of_funds: any;
    // Initialize an empty array to store the new objects
    tax_ref: any;
    nature_of_income: any;
    place_of_death: any;
    name_of_hospital: // Loop through the product_type array and match the properties
    any;
    dr_Pathologist_nurse_name: any;
    doctor_practice_number: any;
    funeral_parlour: any;
    chiefs_name: any;
    chiefs_phone_number: any;
    DHA1663: any;
    DHA1680: any;
    Death_Certificate_issued_by_User: any;
    HA_that_Issued_DC: any;

    account_holder: any;
    account_hidnumber: any;
    account_hidpassport: any;
    account_hdate_of_birth: any;
    account_hgender: any;
    bank_name: any;
    account_type: any;
    account_number: any;
    claim_status: any;
    notes: any;
    is_reviewed: boolean;
    is_paid: boolean;
    is_uploaded: boolean;
    is_held: boolean;
    payment_status: any;
    date_claim_submission: string;
    claim_closure: any;
    bank_statement: any;
    pop_reference: string;
    modified: string;
    created: string;
    documentation: File | null;
    product_type: any;
  }[] = [];

  // Loop through the product_type array and match the properties
  data &&
    data.forEach((item: any) => {
      const newObj = {
        principal_member: { id: item.memberDetails.principalMember },
        claimant: { id: item.memberDetails.claimant },
        product_type: { id: item.memberDetails.productTypes },
        scheme_name: item.policy.schemeName,
        insurer_name: item.policy.insurerName,
        deceased_member: item.memberDetails.deceasedMemberType,
        policy_number: item.policy.policyNumber,
        claim_amount: item.policy.claimAmount,
        gross_premium: item.policy.grossPremium,
        amount_paid: item.policy.claimAmount,
        inception_date: item.policy.inceptionDate,
        scheme_email: item.policy.schemeEmail,
        informant_name: item.memberDetails.informantName,
        relation_to_deceased: item.memberDetails.informantRelation,
        deceased_surname: item.deceasedDetails.deceasedSurname,
        deceased_name: item.deceasedDetails.deceasedName,
        deceased_id_number: item.deceasedDetails.deceasedIdNumber,
        deceased_date_of_death: item.deceasedDetails.deceasedDateOfDeath,
        passport: item.deceasedDetails.deceasedPassportNumber,
        date_of_birth: item.deceasedDetails.deceasedDateOfBirth,
        gender: item.deceasedDetails.deceasedGender,
        still_born_date: null,
        deceased_cause_of_death: item.deceasedDetails.deceasedCauseOfDeath,
        deceased_address: item.deceasedDetails.deceasedHomeAddress,
        residence_country: item.deceasedDetails.deceasedResidentialCountry,
        birth_country: item.deceasedDetails.deceasedBirthCountry,
        nationality: item.deceasedDetails.deceasedNationality,
        marital_status: item.deceasedDetails.maritalStatus,
        source_of_funds: item.eventDetails.sourceOfFunds,
        tax_ref: item.eventDetails.taxReference,
        nature_of_income: item.eventDetails.natureOfIncome,
        place_of_death: item.eventDetails.placeOfDeath,
        name_of_hospital: item.eventDetails.nameOfHospital,
        dr_Pathologist_nurse_name: item.eventDetails.drNursePathologist,
        doctor_practice_number:
          item.eventDetails.drNursePathologistPracticeNumber,
        funeral_parlour: item.eventDetails.funeralParlour,
        chiefs_name: item.eventDetails.chiefsName,
        chiefs_phone_number: item.eventDetails.chiefsPhoneNumber,
        DHA1663: item.eventDetails.dha1663,
        DHA1680: item.eventDetails.dha1680,
        Death_Certificate_issued_by_User:
          item.eventDetails.deathCertificateIssuedByUser,
        HA_that_Issued_DC: item.eventDetails.haThatIssuedDeathCertificate,
        // premium_check: item.banks.premium_check,
        // data_confirmation: item.banks.data_confirmation,
        // review_status: item.banks.review_status,
        // payment_date: item.banks.payment_date,
        account_holder: item.bankDetails.AccountHolder,
        account_hidnumber: item.bankDetails.AccountHolderIDType,
        account_hidpassport: item.bankDetails.account_hidpassport,
        account_hdate_of_birth: item.bankDetails.account_hdate_of_birth,
        account_hgender: item.bankDetails.account_hgender,
        bank_name: { id: item.bankDetails.BankName },
        account_type: item.bankDetails.AccountType,
        account_number: item.bankDetails.AccountNumber,
        claim_status: { id: item.bankDetails.ClaimStatus },
        notes: item.bankDetails.Notes,
        is_reviewed: false,
        is_paid: false,
        is_uploaded: false,
        is_held: false,
        payment_status: null,
        date_claim_submission: item.policy.dateClaimSubmission,
        claim_closure: {
          id: selectedClaim?.claim_closure?.id
            ? selectedClaim.claim_closure.id
            : 0,
        },
        bank_statement: null,
        pop_reference: "",
        modified: "",
        created: "",
        documentation: item.PMFiles.documentationUrl,
      };

      newArray.push(newObj);
    });

  return newArray[0];
};

export default ConvertCapturedClaim;
