import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import convertClaimData from "../../claims/components/convertClaimData";
import BankDetail from "../../claims/components/update/BankDetail";
import DeceasedDetails from "../../claims/components/update/DeceasedDetails";
import DocumentUpload from "../../claims/components/update/DocumentUpload";
import EventDetail from "../../claims/components/update/EventDetails";
import MembershipDetail from "../../claims/components/update/Membership";
import Policy from "../../claims/components/update/Policy";
import ConvertCapturedClaim from "../../claims/convertcapturedclaim";
import { editClaim } from "../../redux/actions/ClaimsAction";

interface Props {
  claimData: any;
  handleReset: any;
}

const ReviewEditForm: React.FC<Props> = (Props) => {
  const [selectedClaim, setSelectedClaim] = useState(
    convertClaimData(Props.claimData)
  );
  const [isInput, setInput] = useState(false);
  const [isClearPolicy, setIClearInputsPolicy] = useState(false);
  const dispatch = useDispatch();

  function getFamilyRelationship(relationship: string) {
    if (!relationship) return "";
    switch (relationship.toLowerCase()) {
      case "grandmother":
      case "grandfather":
      case "spouse":
        return "Extended Family Member";
      case "brother":
      case "sister":
        return "Sibling";
      case "daughter":
      case "son":
      case "child":
        return "Offspring";
      case "in-laws":
        return "In-law";
      case "other":
        return "";
      default:
        return relationship;
    }
  }

  const [editClaimID, setEditClaimID] = useState<string>(Props.claimData.id);

  // member details
  const [productType, setProductType] = useState(
    selectedClaim.memberDetails.productTypes || ""
  );
  const [deceasedType, setDeceasedType] = useState(
    selectedClaim.memberDetails.deceasedMemberType || ""
  );
  const [principalMember, setPrincipalMember] = useState(
    selectedClaim.memberDetails.principalMember || null
  );
  const [informantName, setInformantName] = useState(
    selectedClaim.memberDetails.informantName || ""
  );
  const [inoformantRelation, setInformantRelation] = useState(
    getFamilyRelationship(selectedClaim.memberDetails.informantRelation)
  );
  const [claimant, setClaimant] = useState(
    selectedClaim.memberDetails.claimant || ""
  );

  //policy
  const [schemeName, setSchemeName] = useState(
    selectedClaim.policy.schemeName?.id || null
  );
  const [policyNumber, setPolicyNumber] = useState(
    selectedClaim.policy.policyNumber || ""
  );
  const [inceptionDate, setInceptionDate] = useState(
    selectedClaim.policy.inceptionDate || ""
  );
  const [claimAmount, setClaimAmount] = useState(
    selectedClaim.policy.claimAmount || 0
  );
  const [grossPremium, setGrossPremium] = useState(
    selectedClaim.policy.grossPremium || 0
  );
  const [dateClaimSubmission, setDateClaimSubmission] = useState(
    selectedClaim.policy.dateClaimSubmission || 0
  );
  const [schemeEmail, setSchemeEmail] = useState(
    selectedClaim.policy.schemeEmail || ""
  );
  const [insurerName, setInsurerName] = useState(
    selectedClaim.policy.insurerName || ""
  );

  // bank detail
  const [accountIDNumber, setAccountIDNumber] = useState(
    selectedClaim.banks.accountHIDNumber || ""
  );
  const [accountPassport, setAccountPassport] = useState(
    selectedClaim.banks.accountHIDPassport || ""
  );
  const [accountDateOfBirth, setAccountDateOfBirth] = useState(
    selectedClaim.banks.accountHDateOfBirth || ""
  );
  const [claimStatus, setClaimStatus] = useState(
    selectedClaim.banks.claimStatus || ""
  );
  const [notes, setNotes] = useState(selectedClaim.banks.notes || "");
  const [accountType, setAccountType] = useState(
    selectedClaim.banks.accountType || ""
  );
  const [accountIDType, setAccountIDType] = useState(
    selectedClaim.banks.accountHIDNumber || ""
  );
  const [accountHolder, setAccountHolder] = useState(
    selectedClaim.banks.accountHolder || ""
  );
  const [accountNumber, setAccountNumber] = useState(
    selectedClaim.banks.accountNumber || ""
  );
  const [selectedBank, setSelectedBank] = useState(
    selectedClaim.banks.bankName || ""
  );

  // deceased detail

  const [idNumber, setIdNumber] = useState(
    selectedClaim.memberDetails.deceasedIdNumber || ""
  );
  const [passportNumber, setPassportNumber] = useState(
    selectedClaim.memberDetails.passport || ""
  );
  const [licenseNumber, setLicenseNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(
    selectedClaim.memberDetails.dateOfBirth || ""
  );
  const [ageAtDeath, setAgeAtDeath] = useState(
    selectedClaim.memberDetails.ageAtDeath || ""
  );
  const [deceasedOccupation, setDeceasedOccupation] = useState(
    selectedClaim.memberDetails.deceasedOccupation || ""
  );
  const [deceasedSurname, setDeceasedSurname] = useState(
    selectedClaim.memberDetails.deceasedSurname || ""
  );
  const [deceasedName, setDeceasedName] = useState(
    selectedClaim.memberDetails.deceasedName || ""
  );
  const [maritalStatus, setMaritalStatus] = useState(
    selectedClaim.memberDetails.maritalStatus || ""
  );

  const [idType, setIdType] = useState("");
  const [deceasedGender, setDeceasedGender] = useState(
    selectedClaim.memberDetails.gender || ""
  );
  const [deceasedNationality, setDeceasedNationality] = useState(
    selectedClaim.memberDetails.nationality || ""
  );
  const [dateOfDeath, setDateOfDeath] = useState(
    selectedClaim.memberDetails.deceasedDateOfDeath || ""
  );
  const [residentialAddress, setResidentialAddress] = useState(
    selectedClaim.memberDetails.residenceCountry || ""
  );
  const [deceasedCaused, setDeceasedCause] = useState(
    selectedClaim.memberDetails.deceasedCause || ""
  );
  const [postalAddress, setPostalAddress] = useState(
    selectedClaim.memberDetails.birthCountry || ""
  );

  // event details

  const [placeOfDeath, setPlaceOfDeath] = useState(
    selectedClaim.eventDetails.placeOfDeath || ""
  );
  const [funeralParlour, setFuneralParlour] = useState(
    selectedClaim.eventDetails.funeralParlour || ""
  );
  const [dha1663, setDHA1663] = useState(
    selectedClaim.eventDetails.dha1663 || ""
  );
  const [haThatIssuedDeathCertificate, setHAThatIssuedDeathCertificate] =
    useState(selectedClaim.eventDetails.HAthatIssuedDC || "");
  const [sourceOfFunds, setSourceOfFunds] = useState(
    selectedClaim.eventDetails.sourceOfFunds || ""
  );
  const [taxReference, setTaxReference] = useState(
    selectedClaim.eventDetails.taxRef || ""
  );
  const [natureOfIncome, setNatureOfIncome] = useState(
    selectedClaim.eventDetails.natureOfIncome || ""
  );
  const [nameOfHospital, setNameOfHospital] = useState(
    selectedClaim.eventDetails.nameOfHospital || ""
  );
  const [drNursePathologist, setDrNursePathologist] = useState(
    selectedClaim.eventDetails.drPathologistNurseName || ""
  );
  const [
    drNursePathologistPracticeNumber,
    setDrNursePathologistPracticeNumber,
  ] = useState(selectedClaim.eventDetails.doctorPracticeNumber || "");
  const [chiefsName, setChiefsName] = useState(
    selectedClaim.eventDetails.chiefsName || ""
  );
  const [chiefsPhoneNumber, setChiefsPhoneNumber] = useState(
    selectedClaim.eventDetails.chiefsPhoneNumber || ""
  );
  const [dha1680, setDHA1680] = useState(
    selectedClaim.eventDetails.dha1680 || ""
  );
  const [deathCertificateIssuedByUser, setDeathCertificateIssuedByUser] =
    useState(selectedClaim.eventDetails.deathCertificateIssuedByUser || "");

  // document upload

  const [supportFile, setSupportFile] = useState<File | null>(
    selectedClaim.PMFiles.supportFile || null
  );
  const [additionFile, setAdditionFile] = useState<File | null>(null);

  const [documentationUrl, setDocumentationUrl] = useState<string>(
    selectedClaim.PMFiles.documentation || ""
  );

  // setSchemeName(Props.claimData?.policy?.scheme_name || "");

  const updateData = useSelector((state: any) => state.captureClaims);
  const removeClaimClosure = () => {
    delete Props.claimData.claim_closure;
  };

  removeClaimClosure();

  const handleEditClaim = () => {
    delete Props.claimData.claim_closure;
    const getEditData = ConvertCapturedClaim([updateData], Props.claimData);

    try {
      dispatch(editClaim({ getEditData, id: editClaimID }, Props.handleReset));
    } catch (e) {
      console.log("edit error", e);
    }
  };

  return (
    <>
      <form
        className={`row g-3 `}
        method="PUT"
        encType="multipart/form-data"
        id="multi-pop"
      >
        {/* <Collapse accordion={false} items={accordionItems}></Collapse> */}
        <h6
          style={{
            backgroundColor: "#D3D3D3",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          Membership
        </h6>
        <MembershipDetail
          principalMember={principalMember}
          setPrincipalMember={setPrincipalMember}
          deceasedType={deceasedType}
          setDeceasedType={setDeceasedType}
          productType={productType}
          setProductType={setProductType}
          informantName={informantName}
          setInformantName={setInformantName}
          informantRelation={inoformantRelation}
          setInformantRelation={setInformantRelation}
          claimant={claimant}
          setClaimant={setClaimant}
        />
        <h6
          style={{
            backgroundColor: "#D3D3D3",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          Policy
        </h6>
        <Policy
          ClearPropsIsInput={isClearPolicy}
          schemeName={schemeName}
          setSchemeName={setSchemeName}
          policyNumber={policyNumber}
          setPolicyNumber={setPolicyNumber}
          schemeEmail={schemeEmail}
          setSchemeEmail={setSchemeEmail}
          insurerName={insurerName}
          setInsurerName={setInsurerName}
          inceptionDate={inceptionDate}
          setInceptionDate={setInceptionDate}
          claimAmount={claimAmount}
          setClaimAmount={setClaimAmount}
          grossPremium={grossPremium}
          setGrossPremium={setGrossPremium}
          dateClaimSubmission={dateClaimSubmission}
          setDateClaimSubmission={setDateClaimSubmission}
        />
        <h6
          style={{
            backgroundColor: "#D3D3D3",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          Deceased Details
        </h6>
        <DeceasedDetails
          isInput={isInput}
          deceasedSurname={deceasedSurname}
          deceasedName={deceasedName}
          maritalStatus={maritalStatus}
          idType={idType}
          deceasedGender={deceasedGender}
          deceasedNationality={deceasedNationality}
          dateOfDeath={dateOfDeath}
          residentialAddress={residentialAddress}
          deceasedCaused={deceasedCaused}
          postalAddress={postalAddress}
          idNumber={idNumber}
          passportNumber={passportNumber}
          licenseNumber={licenseNumber}
          dateOfBirth={dateOfBirth}
          ageAtDeath={ageAtDeath}
          deceasedOccupation={deceasedOccupation}
          setDeceasedSurname={setDeceasedSurname}
          setDeceasedName={setDeceasedName}
          setMaritalStatus={setMaritalStatus}
          setIdType={setIdType}
          setDeceasedGender={setDeceasedGender}
          setDeceasedNationality={setDeceasedNationality}
          setDateOfDeath={setDateOfDeath}
          setResidentialAddress={setResidentialAddress}
          setDeceasedCause={setDeceasedCause}
          setPostalAddress={setPostalAddress}
          setIdNumber={setIdNumber}
          setPassportNumber={setPassportNumber}
          setLicenseNumber={setLicenseNumber}
          setDateOfBirth={setDateOfBirth}
          setAgeAtDeath={setAgeAtDeath}
          setDeceasedOccupation={setDeceasedOccupation}
        />
        <h6
          style={{
            backgroundColor: "#D3D3D3",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          Event Details
        </h6>
        <EventDetail
          isInput={isInput}
          placeOfDeath={placeOfDeath}
          funeralParlour={funeralParlour}
          dha1663={dha1663}
          haThatIssuedDeathCertificate={haThatIssuedDeathCertificate}
          sourceOfFunds={sourceOfFunds}
          taxReference={taxReference}
          natureOfIncome={natureOfIncome}
          nameOfHospital={nameOfHospital}
          drNursePathologist={drNursePathologist}
          drNursePathologistPracticeNumber={drNursePathologistPracticeNumber}
          chiefsName={chiefsName}
          chiefsPhoneNumber={chiefsPhoneNumber}
          dha1680={dha1680}
          deathCertificateIssuedByUser={deathCertificateIssuedByUser}
          setPlaceOfDeath={setPlaceOfDeath}
          setFuneralParlour={setFuneralParlour}
          setDHA1663={setDHA1663}
          setHAThatIssuedDeathCertificate={setHAThatIssuedDeathCertificate}
          setSourceOfFunds={setSourceOfFunds}
          setTaxReference={setTaxReference}
          setNatureOfIncome={setNatureOfIncome}
          setNameOfHospital={setNameOfHospital}
          setDrNursePathologist={setDrNursePathologist}
          setDrNursePathologistPracticeNumber={
            setDrNursePathologistPracticeNumber
          }
          setChiefsName={setChiefsName}
          setChiefsPhoneNumber={setChiefsPhoneNumber}
          setDHA1680={setDHA1680}
          setDeathCertificateIssuedByUser={setDeathCertificateIssuedByUser}
        />
        <h6
          style={{
            backgroundColor: "#D3D3D3",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          Documents
        </h6>
        <DocumentUpload
          supportFile={supportFile}
          additionFile={additionFile}
          setSupportFile={setSupportFile}
          setAdditionFile={setAdditionFile}
          documentationUrl={documentationUrl}
          setDocumentationUrl={setDocumentationUrl}
        />
        <h6
          style={{
            backgroundColor: "#D3D3D3",
            paddingBottom: "4px",
            paddingTop: "4px",
          }}
        >
          Bank Details
        </h6>
        <BankDetail
          isInput={isInput}
          accountHolder={accountHolder}
          accountIDType={accountIDType}
          selectedBank={selectedBank}
          accountNumber={accountNumber}
          accountIDNumber={accountIDNumber}
          accountPassport={accountPassport}
          accountDateOfBirth={accountDateOfBirth}
          claimStatus={claimStatus}
          notes={notes}
          accountType={accountType}
          setAccountHolder={setAccountHolder}
          setAccountIDType={setAccountIDType}
          setSelectedBank={setSelectedBank}
          setAccountNumber={setAccountNumber}
          setAccountIDNumber={setAccountIDNumber}
          setAccountPassport={setAccountPassport}
          setAccountDateOfBirth={setAccountDateOfBirth}
          setClaimStatus={setClaimStatus}
          setNotes={setNotes}
          setAccountType={setAccountType}
        />
      </form>
    </>
  );
};
export default ReviewEditForm;
