import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import saIdParser from "south-african-id-parser";
import { AuthContext } from "../../../context/AuthContext";
import { setDataError } from "../../../redux/actions/DataErrorActions";
import { createDeceasedDetails } from "../../../redux/actions/DeceasedDetailsAction";
import { IDeceasedDetails } from "../../../redux/types/DeceasedDetails";

interface modelProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const CreateDeceasedDetails: React.FC<modelProps> = (modelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [idNumberError, setIdNumberError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const { user } = useContext(AuthContext);
  const dataError = useSelector((state: any) => state.dataError);

  const [inputValues, setInputValues] = useState({
    assessor: user?.id,
    deceasedSurname: "",
    deceasedName: "",
    maritalStatus: "",
    dateOfBirth: "",
    idType: "",
    idNumber: "",
    passportNumber: "",
    licenseNumber: "",
    deceasedGender: "",
    deceasedNationality: "",
    dateOfDeath: "",
    residentialAddress: "",
    postalAddress: "",
    deceasedCaused: "",
    deceasedOccupation: "",
  });

  interface ICreateDeceasedDetails extends IDeceasedDetails {
    assessor: any;
  }

  const handleOk = () => {
    const model: ICreateDeceasedDetails = {
      assessor: inputValues.assessor,
      deceasedSurname: inputValues.deceasedSurname,
      deceasedName: inputValues.deceasedName,
      maritalStatus: inputValues.maritalStatus,
      deceasedDateOfDeath: inputValues.dateOfDeath,
      deceasedIdType: inputValues.idType,
      deceasedIdNumber: inputValues.idNumber,
      deceasedPassportNumber: inputValues.passportNumber,
      deceasedDlNumber: inputValues.licenseNumber,
      deceasedGender: inputValues.deceasedGender,
      deceasedNationality: inputValues.deceasedNationality,
      deceasedDateOfBirth: inputValues.dateOfBirth,
      deceasedCauseOfDeath: inputValues.deceasedCaused,
      deceasedHomeAddress: inputValues.residentialAddress,
      deceasedResidentialCountry: inputValues.postalAddress,
      deceasedBirthCountry: inputValues.deceasedOccupation,
    };

    dispatch(createDeceasedDetails(model));

    setInputValues({
      deceasedSurname: "",
      deceasedName: "",
      maritalStatus: "",
      dateOfBirth: "",
      idType: "",
      idNumber: "",
      passportNumber: "",
      licenseNumber: "",
      deceasedGender: "",
      deceasedNationality: "",
      dateOfDeath: "",
      residentialAddress: "",
      postalAddress: "",
      deceasedCaused: "",
      deceasedOccupation: "",
      assessor: user?.id,
    });
  };

  const handleCancel = () => {
    modelProps.setIsModalOpen(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    modelProps.setIsModalOpen(false);
    handleOk();
  };

  useEffect(() => {
    if (inputValues.idNumber.trim().length === 13) {
      const isValid = saIdParser.validate(inputValues.idNumber);
      if (!isValid) {
        dispatch(
          setDataError([
            ...dataError,
            { idNumber: "Deceased Details Form ID number is not valid" },
          ])
        );
      }
    }
  }, [inputValues.idNumber, dispatch]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    // Validate ID number input to accept only numbers and 13 characters
    if (name === "idNumber") {
      const onlyNumbers = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      const limitedValue = onlyNumbers.substr(0, 13); // Limit to 13 characters

      // Check if the ID number is exactly 13 characters
      if (limitedValue.length !== 13) {
        setIdNumberError("ID number must be exactly 13 characters.");
      } else {
        if (!saIdParser.validate(value)) {
          setIdNumberError("ID number is not valid");
        } else {
          setIdNumberError(""); // Clear the error message if the ID number is valid
        }
      }

      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: limitedValue,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    modelProps.setIsModalOpen(modelProps.isModalOpen);
  }, [modelProps.isModalOpen]);

  const today = new Date();
  const todayDate = today.toISOString().split("T")[0];

  return (
    <>
      <Modal
        open={modelProps.isModalOpen}
        onCancel={handleCancel}
        okText="Save"
        onOk={handleOk}
        width={1000}
      >
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add a new deceased details</h5>
            <p></p>

            <form
              className="row g-3"
              id="deceased-form"
              onSubmit={handleSubmit}
            >
              <div className="col-md-4 mb-3 flex" id="frontdeceased_surname">
                <div className="form-group">
                  <label htmlFor="deceased_surname">
                    Deceased Surname{" "}
                    <span className="text-danger">*Required</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="deceased_surname"
                    name="deceasedSurname"
                    required
                    placeholder="Deceased Surname"
                    value={inputValues.deceasedSurname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-4 mb-3 flex" id="frontdeceased_name">
                <div className="form-group">
                  <label htmlFor="deceased_name">
                    Deceased Name(s){" "}
                    <span className="text-danger">*Required</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="deceased_name"
                    name="deceasedName"
                    required
                    placeholder="Deceased Name(s)"
                    value={inputValues.deceasedName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="marital_status">
                    Marital Status{" "}
                    <span className="text-danger">*Required</span>
                  </label>
                  <select
                    className="form-select"
                    id="marital_status"
                    name="maritalStatus"
                    value={inputValues.maritalStatus}
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">---------</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widow">Widow</option>
                    <option value="Widower">Widower</option>
                  </select>
                </div>
              </div>

              {inputValues.idType !== "1" && (
                <div className="col-md-4 mb-3 flex">
                  <div className="form-group">
                    <label htmlFor="deceased_date_of_birth">
                      Deceased Date of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="deceased_date_of_birth"
                      name="dateOfBirth"
                      required
                      placeholder="Deceased Date of Birth"
                      value={inputValues.dateOfBirth}
                      onChange={handleInputChange}
                    />
                  </div>
                  <span className="text-danger">*Required</span>
                </div>
              )}

              <div className="col-md-4 mb-3" id="frontdeceased_ID">
                <div className="form-group">
                  <label htmlFor="deceased_id_type">Identification Type</label>
                  <select
                    className="form-select"
                    id="deceased_id_type"
                    name="idType"
                    value={inputValues.idType}
                    onChange={handleInputChange}
                  >
                    <option value="">---------</option>
                    <option value="idNumber">ID Number</option>
                    <option value="passportNumber">Passport Number</option>
                    <option value="still_born">Still Born</option>
                  </select>
                  <span className="text-danger">*Required</span>
                </div>
              </div>

              {inputValues.idType === "idNumber" && (
                <div
                  className="col-md-4 mb-3 flex"
                  id="frontdeceased_ID_number"
                >
                  <div className="form-group">
                    <label htmlFor="deceased_id_number">ID Number</label>
                    <input
                      type="number"
                      className="form-control"
                      id="deceased_id_number"
                      name="idNumber"
                      placeholder="ID Number"
                      maxLength={13}
                      value={inputValues.idNumber}
                      onChange={handleInputChange}
                    />
                    {idNumberError && (
                      <p style={{ color: "red" }}>{idNumberError}</p>
                    )}
                  </div>
                </div>
              )}

              {inputValues.idType === "passportNumber" && (
                <div className="col-md-4 mb-3 flex" id="frontdeceased_passport">
                  <div className="form-group">
                    <label htmlFor="deceased_passport_number">
                      Passport Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="deceased_passport_number"
                      name="passportNumber"
                      placeholder="Passport Number"
                      value={inputValues.passportNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <span className="text-danger">*Required</span>
                </div>
              )}

              {inputValues.idType === "licenseNumber" && (
                <div className="col-md-4 mb-3 flex" id="frontdeceased_dl">
                  <div className="form-group">
                    <label htmlFor="deceased_dl_number">
                      Driver's License Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="deceased_dl_number"
                      name="licenseNumber"
                      placeholder="Driver's License Number"
                      value={inputValues.licenseNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}

              {inputValues.idType !== "idNumber" && (
                <>
                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label htmlFor="deceased_gender">
                        Deceased Gender{" "}
                        <span className="text-danger">*Required</span>
                      </label>
                      <select
                        className="form-select"
                        id="deceased_gender"
                        name="deceasedGender"
                        value={inputValues.deceasedGender}
                        required={inputValues.idType !== "idNumber"}
                        onChange={handleInputChange}
                      >
                        <option value="">---------</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label htmlFor="deceased_nationality">
                        Deceased Nationality{" "}
                        <span className="text-danger">*Required</span>
                      </label>
                      <select
                        className="form-select"
                        id="deceased_nationality"
                        name="deceasedNationality"
                        value={inputValues.deceasedNationality}
                        required={inputValues.idType !== "idNumber"}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Please select...
                        </option>
                        <option value="ZA">South Africa</option>
                        <option value="BW">Botswana</option>
                        <option value="LS">Lesotho</option>
                        <option value="MW">Malawi</option>
                        <option value="MZ">Mozambique</option>
                        <option value="NA">Namibia</option>
                        <option value="SZ">Eswatini</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-4 mb-3 flex">
                <div className="form-group">
                  <label htmlFor="deceased_date_of_death">
                    Date of Death <span className="text-danger">*Required</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="deceased_date_of_death"
                    name="dateOfDeath"
                    required
                    placeholder="Date of Death"
                    max={todayDate}
                    min={inputValues.dateOfBirth}
                    value={inputValues.dateOfDeath}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-4 mb-3 flex">
                <div className="form-group">
                  <label htmlFor="deceased_residential_address">
                    Residential Country{" "}
                    <span className="text-danger">*Required</span>
                  </label>
                  <select
                    className="form-select"
                    id="deceased_residential_address"
                    name="residentialAddress"
                    required
                    placeholder="Deceased Residential Address"
                    value={inputValues.residentialAddress}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Please select...
                    </option>
                    <option value="ZA">South Africa</option>
                    <option value="BW">Botswana</option>
                    <option value="LS">Lesotho</option>
                    <option value="MW">Malawi</option>
                    <option value="MZ">Mozambique</option>
                    <option value="NA">Namibia</option>
                    <option value="SZ">Eswatini</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4 mb-3 flex">
                <div className="form-group">
                  <label htmlFor="deceased_birth_address">
                    Country of Birth
                    <span className="text-danger">*Required</span>
                  </label>
                  <select
                    className="form-select"
                    id="deceased_birth_address"
                    name="postalAddress"
                    required
                    value={inputValues.postalAddress}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Please select...
                    </option>
                    <option value="ZA">South Africa</option>
                    <option value="BW">Botswana</option>
                    <option value="LS">Lesotho</option>
                    <option value="MW">Malawi</option>
                    <option value="MZ">Mozambique</option>
                    <option value="NA">Namibia</option>
                    <option value="SZ">Eswatini</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4 mb-3 flex">
                <div className="form-group">
                  <label htmlFor="cause_of_death">
                    Cause of Death(Yes or No){" "}
                    <span className="text-danger">*Required</span>
                  </label>
                  <select
                    className="form-select"
                    required
                    id="cause_of_death"
                    name="deceasedCaused"
                    placeholder="Deceased Address"
                    value={inputValues.deceasedCaused}
                    onChange={handleInputChange}
                  >
                    <option value="">---------</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4 mb-3 flex">
                <div className="form-group">
                  <label htmlFor="deceased_address">Deceased Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="deceased_address"
                    name="deceasedOccupation"
                    placeholder="Deceased Address"
                    value={inputValues.deceasedOccupation}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateDeceasedDetails;
