import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { ColumnGroupType, ColumnType, TablePaginationConfig } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  fetchPolAdmin,
  createPolAdmin,
  updatePolAdmin,
} from "../redux/actions/PolAdminActions";
import NewPolicyModal from "./NewPolicyModal";
import instance from "../axios/axios-instance";
import { RootState } from "../redux/rootReducer";

type AppDispatch = ThunkDispatch<undefined, any, AnyAction>;

interface DataItem {
  id: number;
  key: string;
  schemeName: string;
  gfsNumber: string;
  bookSize: string;
  membership: string;
  detail: string;
}

const AdminTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [schemes, setSchemes] = useState<any[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<number | any>(null);
  const [data, setData] = useState<any[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [page_size, setPage_size] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchKey, setSearchKey] = useState<string>("");
  const [toggleFetch, setToggleFetch] = useState<boolean>(false);

  // const data = useSelector((state: RootState) => state.policy.schemes); // Adjust according to your state structure

  console.log("pol admin data", data);

  // useEffect(() => {
  //   dispatch(fetchPolAdmin()); // Dispatch fetch action on component mount
  // }, [dispatch]);

  const handleCreate = (values: any) => {
    const { scheme_id } = form.getFieldsValue();
    const formData = new FormData();
    console.log("add new scheme values:", form.getFieldsValue())
    formData.append("file", values?.file[0]?.originFileObj);
    formData.append("scheme_id", scheme_id);

    // Dispatch the create action with formData as the argument
    dispatch(createPolAdmin(formData, setToggleFetch));
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await instance.get(`api/policies/get-membership-data?search=${encodeURIComponent(searchKey)}`);
  //       if (response?.status >= 200 && response?.status < 300) {
  //         setMemberData(response?.data?.data);
  //         setTotalItems(response?.data?.total_items)
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, memberId, selectedPolicyStatus, selectedMemberType, searchKey]);

  const handleUpdate = (id: any, updatedData: any) => {
    dispatch(updatePolAdmin(updatedData)); // Dispatch update action with the id and updated data
  };

  useEffect(() => {
    // Dispatch your fetch action here
  }, []);

  const columns: ColumnGroupType<DataItem>[] | ColumnType<DataItem>[] = [
    {
      title: "Scheme Name",
      dataIndex: "scheme_name",
      key: "scheme_name",
    },
    {
      title: "GFS Number",
      dataIndex: "scheme_number",
      key: "scheme_number",
    },
    {
      title: "Book Size",
      dataIndex: "bookSize",
      key: "bookSize",
    },
    /*  {
      title: "Collection",
      dataIndex: "bookSize",
      key: "bookSize",
    }, */
    {
      title: "Membership",
      dataIndex: "membership",
      key: "membership",
      render: (text: string, record: DataItem) => (
        <Link to={`/membership/${record.id}`}>View Membership</Link>
      ),
    },
    {
      title: "Collection",
      dataIndex: "collection",
      key: "collection",
      render: (text: string, record: DataItem) => (
        <Link to={`/collection-analysis/${record.id}`}>
          View Collection Analysis
        </Link>
      ),
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleCreate(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getScheme = async () => {
    try {
      const response = await instance.get(
        `api/claims/underwriter/?format=json`
      );
      const refinedSchemes = response?.data?.filter((v: any) => v?.on_boarded === false).map((item: any) => {
        return { value: item.id, label: item.scheme_name };
      });
      setSchemes(refinedSchemes);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getScheme();
  }, []);

  console.log("Schems", schemes);
  console.log("Selected Scheme", selectedScheme);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(
          `/api/policies/scheme-data/?format=json&search=${encodeURIComponent(searchKey)}`
        );
        if (response?.status >= 200 && response?.status < 300) {
          setData(response?.data);
          //set total items
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [searchKey, toggleFetch]);

  const handleResetSearch = () => {
    setSearchInput("");
    setSearchKey("");
  };

  return (
    <div>

      <Flex
        justify="space-between"
        style={{ marginBottom: 6, marginTop: 8, marginLeft: 0, marginRight: "auto" }}
      >
        <Space>
          <Input
            placeholder="Search..."
            value={searchInput}
            onChange={(e: any) => setSearchInput(e.target.value)}
          />
          <Button type="primary" onClick={() => setSearchKey(searchInput)}>
            Search
          </Button>
          <Button onClick={handleResetSearch}>Reset</Button>
        </Space>
        <Space>
          <Button type="primary" onClick={showModal}>
            Add New Scheme
          </Button>
        </Space>
      </Flex>

      <Table
        style={{ paddingTop: 32 }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        scroll={{ x: 1000 }}
        // onChange={(
        //   config: TablePaginationConfig,
        // ) => {
        //   setPageSize(config?.pageSize!);
        //   setCurrentPage(config?.current!);
        // }}
        // pagination={{
        //   total: data?.length,
        //   showTotal: (total) => `${total} rows`,
        //   current: currentPage,
        //   pageSize: pageSize,
        // }}
        onChange={(config: TablePaginationConfig) => {
          setPage_size(config.pageSize!);
          setCurrentPage(config.current!);
        }}
        pagination={{
          total: totalItems,
          showTotal: (total) => `${total} rows`,
          pageSize: page_size,
          current: currentPage,
          showSizeChanger: true,
          pageSizeOptions: ['5','10', '20', '50', '100'],
        }}
      />
      <NewPolicyModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        schemeOptions={schemes}
        selectedScheme={selectedScheme}
        setSelectedScheme={setSelectedScheme}
      />
    </div>
  );
};

export default AdminTable;
