import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import instance from "../../axios/axios-instance";
import { AuthContext } from "../../context/AuthContext";
import {
  addClaim,
  claimCapture,
  clearClaims,
  removeClaim,
} from "../../redux/actions/ClaimsAction";
import { clearDataError } from "../../redux/actions/DataErrorActions";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const TableClaims: React.FC<any> = () => {
  const { user } = useContext(AuthContext);
  const data = useSelector((state: any) => state?.claims?.claims);
  const dispatch = useDispatch<AppDispatch>();
  const handleDelete = (claimId: number) => {
    dispatch(removeClaim(claimId));
  };

  const [banks, setBanks] = useState<any>([]);
  const [schemes, setSchemes] = useState<any>([]);
  const [productTypes, setProductTypes] = useState([]);
  const principalMembers = useSelector((state: any) => state?.principalMember);

  const getBanks = async () => {
    try {
      const response = await instance.get(`api/claims/bank-names/?format=json`);
      setBanks(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getScheme = async () => {
    try {
      const response = await instance.get(
        `api/claims/underwriter/?format=json`
      );
      setSchemes(response.data.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getProductTypes = async () => {
    try {
      const response = await instance.get(
        `api/claims/product-types/?format=json`
      );
      setProductTypes(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getBanks();
    getScheme();
    getProductTypes();
  }, []);

  const handleDuplicate = (index: number) => {
    const selectedClaim = data[index];
    dispatch(addClaim(selectedClaim));
  };

  const handleClaimCapture = () => {
    const newArray: {
      principal_member: any;
      claimant: any;
      scheme_name: any;
      insurer_name: string;
      deceased_member: string;
      policy_number: any;
      claim_amount: any;
      gross_premium: any;
      amount_paid: string;
      inception_date: any;
      scheme_email: string;
      informant_name: string;
      relation_to_deceased: any;
      deceased_surname: any;
      deceased_name: any;
      deceased_id_number: any;
      deceased_date_of_death: any;
      passport: string;
      date_of_birth: string;
      gender: string;
      still_born_date: any;
      deceased_cause_of_death: string;
      deceased_address: string;
      residence_country: string;
      birth_country: string;
      nationality: string;
      marital_status: string;
      source_of_funds: any;
      // Initialize an empty array to store the new objects
      tax_ref: any;
      nature_of_income: any;
      place_of_death: any;
      name_of_hospital: // Loop through the product_type array and match the properties
      any;
      dr_Pathologist_nurse_name: any;
      doctor_practice_number: any;
      funeral_parlour: any;
      chiefs_name: any;
      chiefs_phone_number: any;
      DHA1663: any;
      DHA1680: any;
      Death_Certificate_issued_by_User: any;
      HA_that_Issued_DC: any;

      account_holder: any;
      account_hidnumber: any;
      account_hidpassport: any;
      account_hdate_of_birth: any;
      account_hgender: any;
      bank_name: any;
      account_type: any;
      account_number: any;
      claim_status: any;
      notes: any;
      is_reviewed: boolean;
      is_paid: boolean;
      is_uploaded: boolean;
      is_held: boolean;

      date_claim_submission: string;
      claim_closure: string;
      pop_reference: string;
      modified: string;
      created: string;

      product_type: any;
      assessor: any;
      documentation_url: string;
    }[] = [];

    // Loop through the product_type array and match the properties
    data.forEach((item: any) => {
      const newObj = {
        assessor: user?.user_id,
        principal_member: item.memberDetails.principalMember,
        claimant: item.memberDetails.claimant,
        scheme_name: item.policy.schemeName,
        insurer_name: item.policy.insurerName,
        deceased_member: item.memberDetails.deceasedMemberType,
        policy_number: item.policy.policyNumber,
        claim_amount: item.policy.claimAmount,
        gross_premium: item.policy.grossPremium,
        amount_paid: "",
        inception_date: item.policy.inceptionDate,
        scheme_email: item.policy.schemeEmail,
        informant_name: item.memberDetails.informantName,
        relation_to_deceased: item.memberDetails.informantRelation,
        deceased_surname: item.deceasedDetails.deceasedSurname,
        deceased_name: item.deceasedDetails.deceasedName,
        deceased_id_number: item.deceasedDetails.deceasedIdNumber,
        deceased_date_of_death: item.deceasedDetails.deceasedDateOfDeath,
        passport: item.deceasedDetails.deceasedPassportNumber,
        date_of_birth: item.deceasedDetails.deceasedDateOfBirth,
        gender: item.deceasedDetails.deceasedGender,
        still_born_date: null,
        deceased_cause_of_death: item.deceasedDetails.deceasedCauseOfDeath,
        deceased_address: item.deceasedDetails.deceasedHomeAddress,
        residence_country: item.deceasedDetails.deceasedResidentialCountry,
        birth_country: item.deceasedDetails.deceasedBirthCountry,
        nationality: item.deceasedDetails.deceasedNationality,
        marital_status: item.deceasedDetails.maritalStatus,
        source_of_funds: item.eventDetails.sourceOfFunds,
        tax_ref: item.eventDetails.taxReference,
        nature_of_income: item.eventDetails.natureOfIncome,
        place_of_death: item.eventDetails.placeOfDeath,
        name_of_hospital: item.eventDetails.nameOfHospital,
        dr_Pathologist_nurse_name: item.eventDetails.drNursePathologist,
        doctor_practice_number:
          item.eventDetails.drNursePathologistPracticeNumber,
        funeral_parlour: item.eventDetails.funeralParlour,
        chiefs_name: item.eventDetails.chiefsName,
        chiefs_phone_number: item.eventDetails.chiefsPhoneNumber,
        DHA1663: item.eventDetails.dha1663,
        DHA1680: item.eventDetails.dha1680,
        Death_Certificate_issued_by_User:
          item.eventDetails.deathCertificateIssuedByUser,
        HA_that_Issued_DC: item.eventDetails.haThatIssuedDeathCertificate,

        account_holder: item.bankDetails.AccountHolder,
        account_hidtype: item.bankDetails.AccountIDType,
        account_hidnumber: item.bankDetails.AccountHolderIDType,
        account_hidpassport: item.bankDetails.AccountPassport,
        account_hdate_of_birth: item.bankDetails.account_hdate_of_birth,
        account_hgender: item.bankDetails.account_hgender,
        bank_name: item.bankDetails.BankName,
        account_type: item.bankDetails.AccountType,
        account_number: item.bankDetails.AccountNumber,
        claim_status: item.bankDetails.ClaimStatus,
        notes: item.bankDetails.Notes,
        is_reviewed: false,
        is_paid: false,
        is_uploaded: false,
        is_held: false,

        date_claim_submission: item.policy.dateClaimSubmission,
        claim_closure: "",
        pop_reference: "",
        modified: "",
        created: "",

        documentation_url: item.PMFiles.documentationUrl,
        product_type: item.memberDetails.productTypes,
      };

      newArray.push(newObj);
    });

    dispatch(clearDataError());
    dispatch(claimCapture(newArray));
    dispatch(clearClaims());
  };

  // const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div className="card card-user">
        {/* <div className="card-header">
          <h5 className="card-title">Claims</h5>
        </div> */}
        <div className="card-body">
          <div>
            <div style={{ marginBottom: 16 }}>
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={start}
                disabled={!hasSelected}
                style={{ marginRight: 8 }}
              >
                Remove
              </button> */}
              {/* <span style={{ marginLeft: 8 }}>
                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
              </span> */}
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Scheme Name</th>
                  <th>Deceased Name</th>
                  <th>Member Type</th>
                  <th>Document</th>
                  <th>Bank Name</th>
                  <th>Account Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data
                    ?.filter((item: any) => item)
                    .map((item: any, index: number) => (
                      <tr key={index}>
                        <td>
                          {
                            schemes?.find(
                              (scheme: any) =>
                                scheme?.id === item?.policy?.schemeName
                            )?.scheme_name
                          }
                        </td>
                        <td>
                          {item?.deceasedDetails?.deceasedName}{" "}
                          {item?.deceasedDetails?.deceasedSurname}
                        </td>
                        <td>{item?.memberDetails?.deceasedMemberType}</td>
                        <td>{item?.PMFiles?.supportFile?.name}</td>

                        <td>
                          {
                            banks.find(
                              (bank: any) =>
                                bank.id.toString() ===
                                item?.bankDetails?.BankName
                            )?.bank
                          }
                        </td>
                        <td>{item?.bankDetails?.AccountType}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5rem",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleDelete(index)}
                            >
                              Delete
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handleDuplicate(index)}
                            >
                              Duplicate
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>

            <button
              type="button"
              className="btn btn-primary btn-round"
              onClick={() => handleClaimCapture()}
              disabled={!(data?.length > 0)}
            >
              Claim Capture
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableClaims;
