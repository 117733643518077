import type { MenuProps } from "antd";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Header from "../Dashboard/header";
import instance from "../axios/axios-instance";
import "../claims/claims.css";
import UpdateClaim from "../claims/components/UpdateClaim";
import { AuthContext } from "../context/AuthContext";
import ClaimInPayment from "../payments/components/claiminpayment";
import PaymentForm from "../payments/components/paymentForm";
import { setErrorMessage, setSuccessMessage } from "../redux/actions/AppMessageActions";
import { getClaims } from "../redux/actions/ClaimsAction";
import DuplicateClaim from "./components/DuplicateClaim";
import UploadPOP from "./components/multiplePOP";

const { TextArea } = Input;

interface Scheme {
  id: any;
  scheme_name: string;
}
interface Insurer {
  id: any;
  name: string;
}
interface ReviewStatus {
  text: string;
  value: string;
}
interface Claim {
  text: string;
  value: string;
}
interface Assessor {
  text: string;
  value: string;
}

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const Claims: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dataClaims = useSelector((state: any) => state?.claims.oneclaim);
  const allClaims = useSelector((state: any) => state?.claims.cachedClaims);
  const allClaimsResults = useSelector(
    (state: any) => state?.claims?.cachedClaims?.results
  );

  const tableData = useSelector((state: any) => {
    return state.claims.oneclaim?.results || [];
  });
  const totalDataCount = useSelector((state: any) => {
    return state.claims.oneclaim?.total_items || 0;
  });
  const [isModalOpenPayment, setIsModalOpenPayment] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tableHeaders, setTableHeaders] = useState<any[]>([]);
  const [searhTerm, setSearhTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [schemes, setSchemes] = useState<Scheme[]>([]); // State to hold schemes data
  // const [insurer, setInsurerStatus] = useState<Insurer[]>([]);
  const [reviewStatuses, setReviewStatus] = useState<ReviewStatus[]>([]); // State to hold review statuses data
  const [claimReviewId, setClaimReviewId] = useState<string | null>(null);
  const [assessor, setAssessor] = useState<Assessor[]>([]);
  const [selectedAssessor, setSelectedAssessor] = useState<any[]>([]);
  const [notes, setNotes] = useState<string>("");
  const [submittedDocuments, setSubmittedDocuments] = useState<any[]>([]);
  const [claimPaymentID, setClaimPaymentID] = useState<string | null>(null);
  const [selectedClaimData, setSelectedClaimData] = useState(null);
  const [existingClaimData, setExistingClaimData] = useState<any>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [schemeFilters, setSchemeFilters] = useState<any[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<any[]>([]);
  const [insurerFilters, setInsurerFilters] = useState<any[]>([]);
  const [selectedInsurer, setSelectedInsurer] = useState<any[]>([]);
  const [claimProgress, setClaimProgress] = useState<string[]>([]);
  const [progress_status, setProgressStatus] = useState<Claim[]>([]);
  const [claimStatusFilters, setClaimStatusFilters] = useState<any[]>([]);
  const [selectedClaimStatus, setSelectedClaimStatus] = useState<any>([]);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState<any[]>([]);
  const [reviewStandingFilters, setReviewStandingFilters] = useState<any[]>([]);
  const [isModalUploadPayment, setIsModalUploadPayment] = useState(false);

  const [startDate, setStartDate] = useState<any>(null);
  const [startDateState, setStartDateState] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [endDateState, setEndDateState] = useState<any>(null);
  const [captureStartDate, setCaptureStartDate] = useState<any>(null);
  const [captureEndDate, setCaptureEndDate] = useState<any>(null);
  const [captureStartDateState, setCaptureStartDateState] = useState<any>(null);
  const [captureEndDateState, setCaptureEndDateState] = useState<any>(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventStartDateState, setEventStartDateState] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [eventEndDateState, setEventEndDateState] = useState(null);
  const [paymentStartDate, setPaymentStartDate] = useState(null);
  const [paymentStartDateState, setPaymentStartDateState] = useState(null);
  const [paymentEndDate, setPaymentEndDate] = useState(null);
  const [paymentEndDateState, setPaymentEndDateState] = useState(null);
  const [assessorFilters, setAssessorFilters] = useState<any[]>([]);
  const [isClaimInPayment, setIsClaimInPayment] = useState<boolean>(false);
  const [toggleFetch, setToggleFetch] = useState<boolean>(false)

  // request for changes
  const { user } = useContext(AuthContext);
  const [isRequestChangesModalOpen, setRequestChangesModalOpen] = useState(false);
  const [requestChangesForm] = Form.useForm();
  const [selectRequestChanges, setSelectRequestChanges] = useState<any>(null)
  const handleRequestChanges = (recordId: any) => {
    setSelectRequestChanges(recordId);
    setRequestChangesModalOpen(true)
  }
  const submitRequestChanges = async () => {
    const { reason_for_request } = requestChangesForm.getFieldsValue()
    const response = await instance.put("/api/claims/request-overwrite-record", {
      id: selectRequestChanges,
      user_id: user!.user_id,
      overwrite_reason: reason_for_request
    });
    if (response?.status >= 200 && response?.status < 300) {
      setToggleFetch(prev => !prev)
      dispatch(setSuccessMessage("Successfully requested overwrite"));
    }
    setRequestChangesModalOpen(false);
  };

  // const [filteredGGData, setFilteredGGData] = useState([]);
  const [ReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [page_size, setPage_size] = useState<number>(5);
  const [searchInput, setSearchInput] = useState<string>("");
  const isLoading = useSelector((state: any) => state.claims.isLoading);

  const [loadedData, setLoadedData] = useState(dataClaims?.results);
  const [dataSource, setDataSource] = useState<any[]>(
    filteredData.length > 0 ? filteredData : allClaims?.results
  );

  const NoWrap = ({ text }: { text: string }) => (
    <div style={{ whiteSpace: "nowrap" }}>{text}</div>
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  useEffect(() => {
    if (filteredData?.length) {
      setDataSource(filteredData);
    } else if (allClaims?.results?.length && allClaimsResults) {
      setDataSource(allClaims?.results);
    }
  }, [
    filteredData?.length,
    allClaims.results?.length,
    allClaimsResults,
    filteredData,
    allClaims.results,
  ]);

  const handleViewFile = (filePath: any) => {
    const fileURL = `${filePath}`;

    window.open(fileURL, "_blank");
  };

  const selectedFilterValues = (selectedFilter: any) => {
    if (Array.isArray(selectedFilter) && selectedFilter?.length > 0) {
      return selectedFilter;
    } else {
      return [];
    }
  };

  const handleFilter = () => {
    setStartDate(startDateState);
    setEndDate(endDateState);
  };

  const handleEventDateFilter = () => {
    setEventStartDate(eventStartDateState);
    setEventEndDate(eventEndDateState);
  };

  const handlePaymentDateFilter = () => {
    setPaymentStartDate(paymentStartDateState);
    setPaymentEndDate(paymentEndDateState);
  };

  const handleCaptureDateFilter = () => {
    setCaptureStartDate(captureStartDateState);
    setCaptureEndDate(captureEndDateState);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Assessor",
      dataIndex: ["assessor", "name"],
      key: "assessor",
      fixed: "left",
      filters: assessorFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedAssessor,
      render: (text: string, record: any) => {
        const fullName = `${record.assessor?.name} ${record.assessor?.surname}`;
        return <NoWrap text={fullName}></NoWrap>;
      },
    },
    {
      title: "Deceased",
      dataIndex: "deceased_name",
      key: "1",
      fixed: "left",
      filteredValue: [],
      render: (text: string, record: any) => {
        if (record.deceased_id_number) {
          const fullName = `${record.deceased_id_number}`;
          return fullName;
        } else if (record.passport) {
          const fullName = `${record.passport}`;
          return fullName;
        }
      },
    },
    {
      title: "Policy No",
      dataIndex: "policy_number",
      key: "2",
      fixed: "left",
      filteredValue: [],
    },
    {
      title: "Insurer",
      dataIndex: ["scheme_name", "insurer_name"],
      key: "insurer",
      render: (text, record) => (
        <NoWrap text={record.scheme_name.insurer_name.insurer_name}></NoWrap>
      ),
      filters: insurerFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedInsurer,
    },
    {
      title: "Scheme",
      dataIndex: ["scheme_name", "scheme_name"],
      key: "scheme",
      filters: schemeFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedScheme,
      render: (_: string, record: any) => (
        <NoWrap text={record.scheme_name.scheme_name}></NoWrap>
      ),
    },
    {
      title: "Cover Amount",
      dataIndex: "claim_amount",
      key: "5",
      width: "1000",
      filteredValue: [],
      render: (text: string, record: any) => {
        const amount = Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 0,
        }).format(record.claim_amount);
        return <NoWrap text={amount}></NoWrap>;
      },
    },
    {
      title: "Principal",
      dataIndex: ["principal_member", "full_names"],
      key: "6",
      filteredValue: [],
      render: (_: string, record: any) => {
        const { full_names, surname } = record.principal_member;
        const text = `${full_names} ${surname}`.trim();
        return <NoWrap text={text}></NoWrap>;
      },
    },
    {
      title: "Date of Event",
      dataIndex: "deceased_date_of_death",
      filteredValue: [],
      key: "7",
      render: (_: string, record: any) => (
        <NoWrap text={record.deceased_date_of_death}></NoWrap>
      ),
      onFilter: (value: string | number | boolean, record) => {
        if (typeof value === "boolean") {
          return record.deceased_date_of_death.indexOf(value.toString()) === 0;
        }
        return record.deceased_date_of_death.indexOf(value.toString()) === 0;
      },
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[eventStartDateState, eventEndDateState]}
              onChange={(dates: any) => {
                setEventStartDateState(dates[0]);
                setEventEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                handleEventDateFilter();
                confirm();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setEventStartDateState(null);
                setEventEndDateState(null);
                setEventStartDate(null);
                setEventEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Claim Status",
      dataIndex: "progress_status",
      key: "claim_status",
      filters: claimStatusFilters,
      filterSearch: true,
      filterMultiple: true,
      filteredValue: selectedClaimStatus,
      render: (_: string, record: any) => {
        return <NoWrap text={record.progress_status}></NoWrap>;
      },
    },
    {
      title: "Review Standing",
      dataIndex: "review_standing",
      filters: reviewStandingFilters,
      filterMultiple: true,
      filteredValue: selectedReviewStatus,
      key: "review_standing",
      render: (text: string, record: any) => {
        return <NoWrap text={record.review_standing}></NoWrap>;
      },
    },
    {
      title: "Claim Submitted Date",
      dataIndex: "date_claim_submission",
      width: 150,
      key: "9",
      filteredValue: [],
      render: (date: any) => new Date(date).toLocaleDateString(),
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[startDateState, endDateState]}
              onChange={(dates: any) => {
                setStartDateState(dates[0]);
                setEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                confirm();
                handleFilter();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setStartDateState(null);
                setEndDateState(null);
                setStartDate(null);
                setEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Capture Date",
      dataIndex: "created_date",
      width: 150,
      filteredValue: [],
      key: "9",
      render: (date: any) => new Date(date).toLocaleDateString(),
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[captureStartDateState, captureEndDateState]}
              onChange={(dates: any) => {
                setCaptureStartDateState(dates[0]);
                setCaptureEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                confirm();
                handleCaptureDateFilter();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setCaptureStartDateState(null);
                setCaptureEndDateState(null);
                setCaptureStartDate(null);
                setCaptureEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Last Update",
      dataIndex: "modified_date",
      key: "10",
      filteredValue: [],
      render: (date: any) => new Date(date).toLocaleDateString(),
      // sorter: (a, b) => {
      //   let dateA = new Date(a?.modified_date);
      //   let dateB = new Date(b?.modified_date);
      //   return dateA.getTime() - dateB.getTime();
      // },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      filteredValue: [],
      render: (date: any) => {
        const text = date && new Date(date).toLocaleDateString();
        return <NoWrap text={text}></NoWrap>;
      },
      key: "11",
      filterDropdown: isLoading
        ? undefined
        : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
              value={[paymentStartDateState, paymentEndDateState]}
              onChange={(dates: any) => {
                dates && setPaymentStartDateState(dates[0]);
                dates && setPaymentEndDateState(dates[1]);
              }}
            />
            <button
              type="button"
              className="export-btn"
              onClick={() => {
                confirm();
                handlePaymentDateFilter();
              }}
            >
              Filter
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setPaymentStartDateState(null);
                setPaymentEndDateState(null);
                setPaymentStartDate(null);
                setPaymentEndDate(null);
              }}
            >
              Clear Filters
            </button>
          </div>
        ),
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      filteredValue: [],
      render: (text, record) => (
        <Space direction="vertical" style={{ minWidth: "112px" }}>
          {
            <Button block type="link" onClick={() => handEdit(record.id)}>
              Details
            </Button>
          }

          {
            <Button type="link" onClick={() => handleDuplicate(record.id)}>
              Duplicate
            </Button>
          }

          {record.is_actioned_payment === true &&
            record.pop_reference === null && (
              <Button
                block
                type="link"
                onClick={() => handleApprovePayment(record.id)}
              >
                Payment
              </Button>
            )}

          {record.is_paid && (
            <Button
              block
              type="link"
              style={{ color: !record.pop_document ? "red" : "unset" }}
              onClick={() => handleViewFile(record.pop_document)}
            >
              {record.pop_document ? "View POP" : "POP Missing"}
            </Button>
          )}

          {record.progress_status === "Claim In Payment" && (
            <Button
              block
              type="link"
              onClick={() => handleClaimInPayment(record.id)}
            >
              CIP
            </Button>
          )}
          {record?.is_reviewed === true && record?.overwrite_request_decision === null &&
            record?.overwrite_request === false && (
              <Button
                block
                type="link"
                onClick={() => handleRequestChanges(record.id)}
              >
                Request Changes
              </Button>
            )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    instance
      .get("/api/claims/review-standing/?format=json")
      .then(({ data }) => {
        const standingFilters = data.map((standing: any) => {
          const text = standing.review_standing;
          return {
            text,
            value: standing.id,
          };
        });
        setReviewStandingFilters(standingFilters);
      });
  }, []);

  useEffect(() => {
    instance.get("api/claims/progress-status/?format=json").then(({ data }) => {
      const claimStatusFilters = data.map((status: any) => {
        const text = status.progress_status;
        return {
          text,
          value: text,
        };
      });
      setClaimStatusFilters(claimStatusFilters);
    });
  }, []);

  useEffect(() => {
    instance.get("api/claims/insurer/?format=json").then(({ data }) => {
      const insurerFilters = data.map((insurer: any) => {
        return {
          text: insurer.insurer_name,
          value: insurer.id,
        };
      });

      setInsurerFilters(insurerFilters);
    });
  }, []);

  useEffect(() => {
    instance.get("api/claims/underwriter/?format=json").then(({ data }) => {
      const schemeFilters = data.map((scheme: any) => {
        return {
          text: scheme.scheme_name,
          value: scheme.id,
        };
      });
      setSchemeFilters(schemeFilters);
    });
  }, []);

  useEffect(() => {
    instance.get("api/claims/assessor/?format=json").then(({ data }) => {
      const assessorFilters = data.map((assessor: any) => {
        const text = `${assessor.name} ${assessor.surname}`.trim();
        return {
          text,
          value: assessor.id,
        };
      });
      setAssessorFilters(assessorFilters);
    });
  }, []);

  const formatDate = (date: any) => {
    return date?.$d.toISOString().slice(0, 10);
  };

  useEffect(() => {
    dispatch(
      getClaims({
        page_size: page_size,
        page: currentPage,
        search_text: search,
        insurer_search: String(selectedInsurer),
        created_date_end: formatDate(endDate),
        created_date_start: formatDate(startDate),
        deceased_date_end: formatDate(eventEndDate),
        deceased_date_start: formatDate(eventStartDate),
        payment_date_end: formatDate(paymentEndDate),
        payment_date_start: formatDate(paymentStartDate),
        capture_date_start: formatDate(captureStartDate),
        capture_date_end: formatDate(captureEndDate),
        progress_search: String(selectedClaimStatus),
        review_search: String(selectedReviewStatus),
        assessor_search: String(selectedAssessor),
        scheme_search: String(selectedScheme),
      })
    );
  }, [
    dispatch,
    page_size,
    currentPage,
    selectedInsurer,
    selectedAssessor,
    selectedScheme,
    selectedReviewStatus,
    selectedClaimStatus,
    search,
    startDate,
    endDate,
    eventStartDate,
    eventEndDate,
    paymentStartDate,
    paymentEndDate,
    toggleFetch
  ]);

  // const handleOpenModal = (claimId: string) => {
  //   setClaimReviewId(claimId);
  //   const selectedData = dataSource.find(
  //     (record: any) => record.id === claimId
  //   );
  //   setSelectedClaimData(selectedData);
  //   setIsReviewModalOpen(true);

  //   // Retrieve the Data of this select id
  //   fetchClaimData(claimId);
  // };

  const handleCloseModal = () => {
    setIsReviewModalOpen(false);
  };

  const handleClaimInPayment = (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setIsClaimInPayment(true);
  };

  const handleApprovePayment = (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setClaimPaymentID(claimId);
    setIsModalOpenPayment(true);
  };

  // const { user } = useContext(AuthContext);

  const handEdit = (claimID: string) => {
    const selectedData = tableData?.find(
      (record: any) => record.id === claimID
    );
    setSelectedClaimData(selectedData);
    setIsEditModalOpen(true);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleDuplicate = async (claimId: string) => {
    const selectedData = tableData.find((record: any) => record.id === claimId);
    setSelectedClaimData(selectedData);
    setIsDuplicateModalOpen(true);
  };
  const handleDuplicateCancel = () => {
    setIsDuplicateModalOpen(false);
  };

  useEffect(() => {
    let header = [];
    let headers = columns.map((column) => ({
      label: column.title,
      key: column.key,
    }));
    header.push(headers);

    setTableHeaders(header);
  }, []);

  const deepSearch = (obj: any, searchTerm: any) => {
    if (typeof obj !== "object" || obj === null) {
      return false; // Not an object or null, so no properties to search
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        // If the value is an object, recursively search its properties
        if (typeof value === "object") {
          if (deepSearch(value, searchTerm)) {
            return true;
          }
        } else {
          if (String(value).toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
      }
    }

    return false; // No properties found or matched the search term
  };

  const onSearch = (searchValue: any) => {
    // Convert searchValue to string then lowercase
    const searchKey =
      typeof searchValue === "string"
        ? searchValue.toLocaleLowerCase()
        : String(searchValue).toLowerCase();

    const filtered = dataSource?.filter((item) => {
      return deepSearch(item, searchKey);
    });

    setDataSource(filtered); // Update the filteredData state with the filtered results
    setCurrentPage(1); // Reset the currentPage state to 1
    // setSearhTerm(event.target.value);
  };

  useEffect(() => {
    onSearch(search);
  }, [search]);

  const fetchFilteredData = async (params: any) => {
    const query = Object.entries(params)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const response = await instance.get(
      `api/claims/report-download-filtered/?format=json&${query}`,
      { responseType: "blob" }
    );

    if (response.status === 200) {
      download_filtered_report(response);
      return response.data;
    } else {
      setErrorMessage("failed to fetch filtered data");
      return;
    }
  };

  const download_filtered_report = async (response: any) => {
    try {
      // Create a Blob object from the response data with the correct content type
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today}-Captured Claims.csv`; // Set the desired filename with .xlsx extension for Excel

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (error: any) {
      console.error("Error downloading CSV:", error);
      dispatch(setErrorMessage("There was an error processing download"));
      console.log(error.message);
    }
  };

  const handleExportToExcel = async () => {
    // Define the headers to include in the Excel file
    const headers = [
      { label: "Assessor Name", key: "assessor" },
      { label: "Assessor Email", key: "assessor_email" },
      { label: "Insurer Name", key: "insurer_name" },
      { label: "Scheme Name", key: "scheme_name" },
      { label: "Original Claim Form", key: "" },
      { label: "Claim Status", key: "claim_status" },
      { label: "Date Claim Submitted", key: "date_claim_submission" },
      { label: "Date Claim Captured", key: "created" },
      { label: "Modified Date", key: "modified" },
      { label: "Date Claim Paid", key: "payment_date" },
      { label: "Submission Month", key: "submission_month" },
      { label: "Capture Month", key: "capture_month" },
      { label: "Payment Month", key: "payment_month" },
      { label: "External Policy number", key: "policy_number" },
      { label: "Product Type", key: "product_type" },
      { label: "Risk Status", key: "risk_status" },
      { label: "Cause Of Death", key: "cause_of_death" },
      { label: "Inception Date", key: "inception_date" },
      { label: "Termination Date", key: "termination_date" },
      { label: "Gross Premium", key: "gross_premium" },
      { label: "Principal Member", key: "principal_member" },
      { label: "ID Type", key: "id_type" },
      { label: "ID/Passport", key: "principal_id_number" },
      { label: "Date of Death", key: "date_of_death" },
      { label: "Deceased Name(s)", key: "deceased_name" },
      { label: "Deceased Surname", key: "deceased_surname" },
      { label: "Marital Status", key: "marital_status" },
      { label: "Residence Country", key: "residence_country" },
      { label: "Birth Country", key: "birth_country" },
      { label: "Nationality", key: "nationality" },
      { label: "Deceased Member Type", key: "deceased_member" },
      { label: "ID Type", key: "deceased_id_type" },
      { label: "Deceased ID/Passport", key: "deceased_id_number" },
      { label: "Deceased Gender", key: "gender" },
      { label: "Deceased Date of Birth", key: "date_of_birth" },
      { label: "Age Band", key: "age_band" },
      { label: "Age At Death", key: "age_at_death" },
      { label: "Cover Amount", key: "claim_amount" },
      { label: "Amount Paid", key: "amount_paid" },
      { label: "Tax Reference Number(Optional)", key: "tax_reference_number" },
      { label: "Source Of Funds (Optional)", key: "source_of_funds" },
      {
        label: "Nature of Income (Nature of Sanlam Pay-out)(optional)",
        key: "nature_of_income",
      },
      { label: "Place of Death", key: "place_of_death" },
      { label: "Name of Hospital", key: "name_of_hospital" },
      { label: "Medical Practitioner Name", key: "dr_Pathologist_nurse_name" },
      {
        label: "Medical Practitioner Practice Number",
        key: "doctor_practice_umber",
      },
      { label: "Informant Name", key: "informant_name" },
      {
        label: "Informant Relationship To The Deceased",
        key: "relation_to_deceased",
      },
      { label: "Chiefs Name", key: "chiefs_name" },
      { label: "Chiefs Contact", key: "chiefs_phone_number" },
      { label: "Funeral Parlour Performing Service", key: "funeral_parlour" },
      {
        label: "Death Certificate Issuer",
        key: "Death_Certificate_issued_by_User",
      },
      { label: "HA That Issued DC", key: "HA_that_Issued_DC" },
      { label: "DHA1663 Reference Number", key: "DHA1663" },
      { label: "DHA1680 Reference Number", key: "DHA1680" },
      { label: "Name Of Beneficiary", key: "name_of_beneficiary" },
      { label: "Bank Name", key: "bank_name" },
      { label: "Account Type", key: "account_type" },
      { label: "Bank Account Number", key: "account_number" },
      { label: "Branch Code", key: "branch_code" },
      { label: "Premium Check Six Months", key: "premium_check" },
      { label: "Data Confirmation Check", key: "data_confirmation" },
      { label: "Payment Reference", key: "pop_reference" },
      { label: "Notes", key: "notes" },
    ];

    const downloadData = await fetchFilteredData({
      page_size: totalDataCount,
      page: currentPage,
      search_text: search,
      insurer_search: selectedInsurer,
      created_date_end: formatDate(endDate),
      created_date_start: formatDate(startDate),
      deceased_date_end: formatDate(eventEndDate),
      deceased_date_start: formatDate(eventStartDate),
      payment_date_end: formatDate(paymentEndDate),
      payment_date_start: formatDate(paymentStartDate),
      progress_search: selectedClaimStatus,
      review_search: selectedReviewStatus,
      assessor_search: selectedAssessor,
      scheme_search: selectedScheme,
    });

    // Filter the dataSource to include only the required properties
    // const filteredDataSource = downloadData?.map((record: any) => {
    //   let filteredRecord: any = {}; // Initialize filteredRecord for each record
    //   for (const header of headers) {
    //     if (header.key === "claim_status") {
    //       // Access the specific key inside the claim_status dictionary
    //       filteredRecord[header.label] = record.progress_status || "";
    //     } else if (header.key === "product_type") {
    //       // Access the specific key inside the product_type dictionary
    //       filteredRecord[header.label] = record[header.key]?.name || "";
    //     } else if (header.key === "risk_status") {
    //       // Access the specific key inside the risk_status dictionary
    //       filteredRecord[header.label] =
    //         record.principal_member?.risk_status || "";
    //     } else if (header.key === "termination_date") {
    //       filteredRecord[header.label] =
    //         record.principal_member?.termination_date || "";
    //     } else if (header.key === "principal_member") {
    //       // Concatenate the values of "full_names" and "surname" from the "principal_member" field
    //       filteredRecord[header.label] = `${
    //         record.principal_member?.full_names || ""
    //       } ${record.principal_member?.surname || ""}`;
    //     } else if (header.key === "id_type") {
    //       // Determine the ID type based on the presence of "id_number" or "passport" field
    //       filteredRecord[header.label] = record.principal_member?.id_number
    //         ? "RSA ID"
    //         : record.principal_member?.passport
    //         ? "PASSPORT"
    //         : "";
    //     } else if (header.key === "deceased_id_type") {
    //       filteredRecord[header.label] = record.deceased_id_number
    //         ? "RSA ID"
    //         : record.passport
    //         ? "PASSPORT"
    //         : "";
    //     } else if (header.key === "principal_id_number") {
    //       filteredRecord[header.label] =
    //         record.principal_member?.id_number ||
    //         record.principal_member?.passport ||
    //         "N/A";
    //     } else if (header.key === "deceased_id_number") {
    //       filteredRecord[header.label] =
    //         record.deceased_id_number || record.passport || "N/A";
    //     } else if (header.key === "date_claim_submission") {
    //       if (record.date_claim_submission) {
    //         filteredRecord[header.label] = record.date_claim_submission || ""; // or .toLocaleDateString() for localized format
    //       } else {
    //         // If date_claim_submission is missing, use the created field as a fallback
    //         const createdDate = new Date(record.created_date)
    //           .toLocaleDateString("en-ZA")
    //           .split("/")
    //           .join("-");
    //         filteredRecord[header.label] = createdDate; // or .toLocaleDateString() for localized format
    //       }
    //     } else if (header.key === "created") {
    //       filteredRecord[header.label] = new Date(record.created_date)
    //         .toLocaleDateString("en-ZA")
    //         .split("/")
    //         .join("-"); // or .toLocaleDateString() for localized format
    //     } else if (header.key === "modified") {
    //       filteredRecord[header.label] = new Date(record.modified_date)
    //         .toLocaleDateString("en-ZA")
    //         .split("/")
    //         .join("-");
    //     } else if (header.key === "bank_name") {
    //       filteredRecord[header.label] = record.bank_name?.bank || "N/A";
    //     } else if (header.key === "branch_code") {
    //       filteredRecord[header.label] = record.bank_name?.branch_code || "";
    //     } else if (header.key === "scheme_name") {
    //       filteredRecord[header.label] = record.scheme_name?.scheme_name || "";
    //     } else if (header.key === "insurer_name") {
    //       filteredRecord[header.label] = record.scheme_name?.insurer_name || "";
    //     } else if (header.key === "gross_premium") {
    //       filteredRecord[header.label] = record.gross_premium || "";
    //     } else if (header.key === "amount_paid") {
    //       filteredRecord[header.label] = record.amount_paid || "";
    //     } else if (header.key === "nationality") {
    //       filteredRecord[header.label] = record.nationality || "";
    //     } else if (header.key === "date_of_death") {
    //       filteredRecord[header.label] = record.deceased_date_of_death || "";
    //     } else if (header.key === "cause_of_death") {
    //       filteredRecord[header.label] = record.deceased_cause_of_death || "";
    //     } else if (header.key === "residence_country") {
    //       filteredRecord[header.label] = record.residence_country || "";
    //     } else if (header.key === "birth_country") {
    //       filteredRecord[header.label] = record.birth_country || "";
    //     } else if (header.key === "doctor_practice_umber") {
    //       filteredRecord[header.label] = record.doctor_practice_number || "";
    //     } else if (header.key === "informant_name") {
    //       filteredRecord[header.label] = record.informant_name || "";
    //     } else if (header.key === "relation_to_deceased") {
    //       filteredRecord[header.label] = record.relation_to_deceased || "";
    //     } else if (header.key === "chiefs_name") {
    //       filteredRecord[header.label] = record.chiefs_name || "";
    //     } else if (header.key === "chiefs_phone_number") {
    //       filteredRecord[header.label] = record.chiefs_phone_number || "";
    //     } else if (header.key === "capture_month") {
    //       filteredRecord[header.label] = record.capture_month || "";
    //     } else if (header.key === "payment_month") {
    //       filteredRecord[header.label] = record.payment_month || "";
    //     } else if (header.key === "submission_month") {
    //       filteredRecord[header.label] = record.submission_month || "";
    //     } else if (header.key === "name_of_beneficiary") {
    //       filteredRecord[header.label] = record.account_holder || "";
    //     } else if (header.key === "pop_reference") {
    //       filteredRecord[header.label] = record.pop_reference || "";
    //     } else if (header.key === "assessor") {
    //       filteredRecord[header.label] =
    //         `${record.assessor?.name} ${record.assessor?.surname}` || "";
    //     } else if (header.key === "assessor_email") {
    //       filteredRecord[header.label] = `${record.assessor?.email}` || "";
    //     } else {
    //       // For other keys, use the regular key access
    //       filteredRecord[header.label] = record[header.key];
    //     }
    //   }
    //   return filteredRecord;
    // });

    // // Convert filteredDataSource to XLSX format
    // const worksheet = XLSX.utils.json_to_sheet(filteredDataSource);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Claims");

    // // Generate the XLSX file
    // const excelBuffer = XLSX.write(workbook, {
    //   bookType: "xlsx",
    //   type: "array",
    // });

    // // Convert the buffer to Blob
    // const blob = new Blob([excelBuffer], {
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // });

    // // Create a downloadable link for the Blob
    // const url = URL.createObjectURL(blob);
    // const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

    // // Create a link element and set its attributes
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = `${today}-FILTERED-CLAIMS-BULK-REPORT.xlsx`; // Set the desired file name

    // // Append the link to the document body and click it to trigger the download
    // document.body.appendChild(a);
    // a.click();

    // // Clean up by revoking the object URL
    // URL.revokeObjectURL(url);
  };

  const download_report = async () => {
    try {
      const response = await instance.post("api/claims/report-download/", {
        responseType: "blob", // Specify the response type as 'blob'
      });

      // Create a Blob object from the response data with the correct content type
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today} - All Captured Claims.csv`; // Set the desired filename with .xlsx extension for Excel

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (error: any) {
      console.error("Error downloading CSV:", error);
      dispatch(setErrorMessage("There was an error processing download"));
      console.log(error.message);
    }
  };

  const handleUploadCancel = () => {
    setIsModalUploadPayment(false);
  };

  const filteredText = isDataFiltered()
    ? `Filtered (${totalDataCount})`
    : "No filter";

  function isDataFiltered(): boolean {
    const selectedFilters = [
      selectedAssessor,
      selectedInsurer,
      selectedScheme,
      selectedClaimStatus,
      selectedReviewStatus,
      searchInput,
      endDate,
      startDate,
      eventEndDate,
      eventStartDate,
      paymentEndDate,
      paymentStartDate,
    ];
    return selectedFilters.some(
      (filter) => (Array.isArray(filter) && filter.length) || filter
    );
  }

  const exportToCSVMenuItems: MenuProps["items"] = [
    { label: "All", key: "all", onClick: () => download_report() },
    {
      label: filteredText,
      key: "filtered",
      onClick: () => handleExportToExcel(),
      disabled: !isDataFiltered(),
    },
  ];

  const handleReset = () => {
    setSearch("");
    setSearchInput("");
    setSelectedAssessor([]);
    setSelectedInsurer([]);
    setSelectedReviewStatus([]);
    setSelectedClaimStatus([]);
    setSelectedScheme([]);
    setStartDateState(null);
    setEndDateState(null);
    setStartDate(null);
    setEndDate(null);
    setPaymentStartDateState(null);
    setPaymentEndDateState(null);
    setPaymentStartDate(null);
    setPaymentEndDate(null);
    setEventStartDateState(null);
    setEventEndDateState(null);
    setEventStartDate(null);
    setEventEndDate(null);
    setCaptureStartDate(null);
    setCaptureEndDate(null);
    setCaptureStartDateState(null);
    setCaptureEndDateState(null);
  };

  return (
    <Header title="View Claims">
      <div className="">
        <div
          className="search-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal">
            <Input.Search
              onSearch={() => {
                setSearch(searchInput);
                onSearch(searchInput);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSearch(searchInput);
                }
              }}
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onBlur={(e: any) => {
                setSearchInput(e.target.value);
                onSearch(searchInput);
              }}
              className="search-input"
              disabled={isLoading}
              style={{
                width: 300,
                marginBottom: 16,
                marginTop: 16,
              }}
              placeholder="Enter ID, Passport or Policy Number"
            />
            <Button onClick={handleReset}>Reset filters</Button>
          </Space>

          <div style={{ marginLeft: "auto" }}>
            <Dropdown menu={{ items: exportToCSVMenuItems }}>
              <Button type="primary">
                <Space>Export to CSV</Space>
              </Button>
            </Dropdown>
          </div>
        </div>

        <Table
          tableLayout="auto"
          onChange={(
            config: TablePaginationConfig,
            filters: Record<string, any>
          ) => {
            setPage_size(config.pageSize!);
            setCurrentPage(config.current!);
            setSelectedAssessor(filters["assessor"] ?? []);
            setSelectedInsurer(filters["insurer"] ?? []);
            setSelectedScheme(filters["scheme"] ?? []);
            setSelectedClaimStatus(filters["claim_status"] ?? []);
            setSelectedReviewStatus(filters["review_standing"] ?? []);
          }}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: 1300, y: windowHeight - 320 }}
          rowKey={(record) => record.id}
          // pagination={{
          //   total: totalDataCount,
          //   showTotal: (total) => `${total} rows`,
          // }}
          pagination={{
            total: totalDataCount,
            showTotal: (total) => `${total} rows`,
            pageSize: page_size,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
          }}
        />
      </div>
      {isEditModalOpen && (
        <UpdateClaim
          isEditModalOpen={isEditModalOpen}
          claimData={selectedClaimData}
          onClose={handleEditCancel}
          setIsEditModalOpen={setIsEditModalOpen}
          handleReset={handleReset}
        />
      )}

      {isModalUploadPayment && (
        <UploadPOP
          isModalOpen={isModalUploadPayment}
          claimData={selectedClaimData}
          onClose={handleUploadCancel}
        />
      )}

      {isDuplicateModalOpen && (
        <DuplicateClaim
          isDuplicateModalOpen={isDuplicateModalOpen}
          setIsDuplicateModalOpen={setIsDuplicateModalOpen}
          claimData={selectedClaimData}
          onClose={handleDuplicateCancel}
          handleReset={handleReset}
        />
      )}

      {isModalOpenPayment && (
        <PaymentForm
          isModalOpen={isModalOpenPayment}
          claimData={selectedClaimData}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpenPayment}
          handleReset={handleReset}
        />
      )}

      {isClaimInPayment && (
        <ClaimInPayment
          isModalOpen={isClaimInPayment}
          selectedClaim={selectedClaimData}
          onClose={handleCloseModal}
          setIsModalOpen={setIsClaimInPayment}
          handleReset={handleReset}
        />
      )}
      <Modal
        title="Request Changes"
        open={isRequestChangesModalOpen}
        onOk={submitRequestChanges}
        okText="Submit"
        onCancel={() => {
          setRequestChangesModalOpen(false);
        }}
      >
        <Form layout="vertical" form={requestChangesForm}>
          <Form.Item label="Reason For Request" name="reason_for_request">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </Header>
  );
};
export default Claims;
