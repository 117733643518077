import React, { useEffect, useState } from "react";
import {
    Button,
    Input,
    Modal,
    Space,
    Table,
    Form,
    Flex,
    TablePaginationConfig,
} from "antd";
import instance from "../../axios/axios-instance";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface MemberType {
    id: string;
    member_type: string;
}

function MemberTypesTable() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [editMemberTypeForm] = Form.useForm();
    const [selectedMemberType, setSelectedMemberType] =
        useState<MemberType>();
    const [query, setQuery] = useState("");
    const dispatch = useDispatch<AppDispatch>();

    const fetchMemberTypes = async () => {
        const response = await instance.get("/api/administration/member-types");
        setData(response?.data);
        setFilteredData(response?.data);
    };

    useEffect(() => {
        fetchMemberTypes();
    }, []);

    const performSearch = () => {
        const filteredData = data.filter(
            (memberType: MemberType) =>
                memberType.member_type.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredData(filteredData);
    };

    const resetSearch = () => {
        setQuery("");
        setFilteredData(data);
    };

    const createNewMemberType = async () => {
        const response = await instance.post(
            "/api/administration/member-types",
            {
                ...editMemberTypeForm.getFieldsValue(),
            }
        );
        if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Member Type added"));
            fetchMemberTypes();
        }
        setCreateModalOpen(false);
    };

    const editMemberType = async () => {
        const response = await instance.put(
            `/api/administration/member-types?id=${selectedMemberType?.id}`,
            {
                ...editMemberTypeForm.getFieldsValue(),
            }
        );
        if (response?.status >= 200 && response?.status < 300) {
            dispatch(setSuccessMessage("Member Type Updated"));
            fetchMemberTypes();
        }
        setEditModalOpen(false);
    };

    const handleDetails = (record: any) => {
        editMemberTypeForm.setFieldsValue({
            member_type: record.member_type,
        });
        setSelectedMemberType(record);
        setEditModalOpen(true);
    };

    const columns: any[] = [
        {
            title: "Member Type",
            dataIndex: "member_type",
            key: "member_type",
            width: 150,
            editable: true,
            fixed: "left",
        },
        {
            title: "Actions",
            key: "actions",
            width: 150,
            fixed: "right",
            render: (record: any) => (
                <Space size="small" style={{ minWidth: "112px" }}>
                    <Button onClick={() => handleDetails(record)}>Details</Button>
                </Space>
            ),
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPage_size] = useState(4);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        let timeoutId: any;

        const updateWindowHeight = () => {
            timeoutId = setTimeout(() => {
                setWindowHeight(window.innerHeight);
            }, 300);
        };

        window.addEventListener("resize", updateWindowHeight);

        return () => {
            window.removeEventListener("resize", updateWindowHeight);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <Space direction="vertical">
            <Flex justify="space-between">
                <Space>
                    <Input
                        value={query}
                        onChange={(e) => setQuery(e.target.value.trim())}
                        placeholder="Enter search to begin"
                    ></Input>
                    <Button disabled={!query} onClick={() => performSearch()}>
                        Search
                    </Button>
                    <Button
                        disabled={!query.trim() || filteredData?.length === 0}
                        onClick={() => resetSearch()}
                    >
                        Reset
                    </Button>
                </Space>
                <Space align="end" direction="horizontal">
                    <Button
                        type="primary"
                        onClick={() => {
                            editMemberTypeForm.resetFields();
                            setCreateModalOpen(true);
                        }}
                    >
                        Add Member Type
                    </Button>
                </Space>
            </Flex>
            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="id"
                scroll={{ y: windowHeight - 420 }}
                style={{ width: 1100 }}
                onChange={(config: TablePaginationConfig) => {
                    setPage_size(config.pageSize!);
                    setCurrentPage(config.current!);
                }}
                pagination={{
                    total: filteredData?.length,
                    showTotal: (total) => `${total} rows`,
                    pageSize: page_size,
                    current: currentPage,
                }}
            />
            <Modal
                title="Create a Member Type"
                open={isCreateModalOpen}
                onOk={createNewMemberType}
                okText="Save"
                onCancel={() => {
                    setCreateModalOpen(false);
                }}
            >
                <Form layout="vertical" form={editMemberTypeForm}>
                    <Form.Item label="Member Type" name="member_type">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Edit Member Type"
                open={isEditModalOpen}
                onOk={editMemberType}
                okText="Update"
                onCancel={() => {
                    setEditModalOpen(false);
                }}
            >
                <Form layout="vertical" form={editMemberTypeForm}>
                    <Form.Item label="Member Type" name="member_type">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
}

export default MemberTypesTable;
