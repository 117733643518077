// PolAdminActions.ts

import { ThunkAction } from "redux-thunk";
import instance from "../../axios/axios-instance";
import { setSuccessMessage } from "./AppMessageActions";
// import { PolAdmin } from "../types/PolAdmin";

type PolAdmin = {};
// Action Types
export const SET_POL_ADMIN = "SET_POL_ADMIN";

// Action Creators
export interface PolAdminAction {
  type: typeof SET_POL_ADMIN;
  payload: PolAdmin;
}

export const setPolAdmin = (polAdmin: PolAdmin): PolAdminAction => {
  return {
    type: SET_POL_ADMIN,
    payload: polAdmin,
  };
};

type ThunkResult<R> = ThunkAction<R, any, unknown, any>;

export const fetchPolAdmin = (): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const response = await instance.get(
        `/api/policies/scheme-data/?format=json`
      );
      console.log("schemes res", response.data);
      dispatch(setPolAdmin(response.data));
    } catch (error: any) {
      console.log(error.message);
    }
  };
};

export const createPolAdmin = (
  polAdminData: FormData,
  setToggleFetch: any
): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      // Send a POST request to the backend endpoint
      // The second argument is the FormData instance
      // The third argument is an options object with a headers property
      // The headers property is an object with 'Content-Type' set to 'multipart/form-data'
      const response = await instance.post(
        `/api/policies/membership-data-onboarding/`,
        polAdminData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.status >= 200 && response?.status < 300) {
        dispatch(setSuccessMessage("Successfully added new scheme"));
        setToggleFetch((prev: boolean) => !prev);
      }

      // Process the response data
      const newPolAdmin: PolAdmin = response.data;

      // Dispatch the setPolAdmin action with the response data
      dispatch(setPolAdmin(newPolAdmin));
    } catch (error: any) {
      console.log(error.message);
    }
  };
};

export const updatePolAdmin = (polAdminData: any): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const response = await instance.put(
        `/api/pol/admin/${polAdminData.id}/?format=json`,
        polAdminData
      );
      const updatedPolAdmin: PolAdmin = response.data;
      dispatch(setPolAdmin(updatedPolAdmin));
    } catch (error: any) {
      console.log(error.message);
    }
  };
};
