import { Button, Flex, Input, Modal, Space, Table } from "antd";
import { useState } from "react";
import instance from "../../../axios/axios-instance";
import { IClaimant } from "../../../redux/types/Claimant";

export interface SearchClaimantProps {
  open: boolean;
  setOpen(open: boolean): void;
  onOk(member: IClaimant & { key: any }): void;
  onCreate(): void;
}

function SearchClaimants(props: SearchClaimantProps) {
  const [query, setQuery] = useState("");
  const [dataSource, setDataSource] = useState<
    Array<IClaimant & { key: any }>
  >([]);
  const [selectedMember, setSelectedMember] = useState<
    (IClaimant & { key: any }) | null
  >();

  function performSearch() {
    instance.get(`/api/claims/claimant/?query=${query}`).then((response) => {
      setDataSource(
        response.data.map((member: any) => {
          return {
            key: member.id,
            ...member,
          };
        })
      );
    });
  }

  const columns = [
    { key: "full_names", render: (v: any) => {
      const { name_of_claimant, surname_of_claimant} = v;
      return `${name_of_claimant} ${surname_of_claimant}`.trim();
    }, title: "Full names" },
    {
      key: "idorPassport",
      title: "ID/Passport",
      render: (v: any) => {
        return v.id_of_claimant || v.passport;
      },
    },
  ];

  return (
    <Modal
      open={props.open}
      okButtonProps={{ disabled: !selectedMember }}
      onOk={() => {
        props.setOpen(false);
        props.onOk(selectedMember!);
        setQuery("");
        setDataSource([]);
        setSelectedMember(null);
      }}
      onCancel={() => {
        props.setOpen(false);
        setQuery("");
        setDataSource([]);
        setSelectedMember(null);
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Flex>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value.trim())}
            placeholder="Enter search to begin"
          ></Input>
          <Button
            style={{ marginLeft: "8px" }}
            disabled={!query}
            onClick={() => performSearch()}
          >
            Search
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={props.onCreate}>
            Create new
          </Button>
        </Flex>
        <Table
          rowSelection={{
            type: "radio",
            onChange: (value) => {
              const member = dataSource.find(
                (member) => member.key === value[0]
              );
              setSelectedMember(member);
            },
          }}
          columns={columns}
          dataSource={dataSource}
        ></Table>
      </Space>
    </Modal>
  );
}

export { SearchClaimants  };
