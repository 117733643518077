import { Button, Form, Modal, Select, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Dashboard/header";
import instance from "../axios/axios-instance";
import { addClaim } from "../redux/actions/ClaimsAction";
import BankDetail from "./capture/BankDetail";
import DeceasedDetails from "./capture/DeceasedDetails";
import DocumentUpload from "./capture/DocumentUpload";
import EventDetail from "./capture/EventDetails";
import MembershipDetail from "./capture/Membership";
import Policy from "./capture/Policy";
import TableClaims from "./capture/TableClaims";

const { Option } = Select; // Extract Option from Select

const AddNew = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("1"); // Track the current active tab
  const data = useSelector((state: any) => state);
  const [isClearInputsMember, setIClearInputsMember] = useState(false);
  const [isClearPolicy, setIClearInputsPolicy] = useState(false);
  const [isClearDeceasedDetails, setIClearInputsDeceasedDetails] =
    useState(false);
  const [isClearEventDetail, setIClearInputsEventDetail] = useState(false);
  const [isClearBankDetail, setIClearBankDetail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for controlling modal visibility
  const [selectedClaimStatus, setSelectedClaimStatus] = useState(""); // State to store the selected claim status
  const [claimStatuses, setClaimStatuses] = useState([]);

  // membership  state

  const [productType, setProductType] = useState("");
  const [deceasedType, setDeceasedType] = useState("");
  const [principalMember, setPrincipalMember] = useState("");

  // policy state
  const [schemeName, setSchemeName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [inceptionDate, setInceptionDate] = useState("");
  const [claimAmount, setClaimAmount] = useState(0);
  const [grossPremium, setGrossPremium] = useState(0);

  const [dateClaimSubmission, setDateClaimSubmission] = useState("");

  // Deceasead details state

  const [deceasedSurname, setDeceasedSurname] = useState("");
  const [deceasedName, setDeceasedName] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [idType, setIdType] = useState("");
  const [deceasedGender, setDeceasedGender] = useState("");
  const [deceasedNationality, setDeceasedNationality] = useState("");
  const [dateOfDeath, setDateOfDeath] = useState("");
  const [residentialAddress, setResidentialAddress] = useState("");
  const [deceasedCaused, setDeceasedCause] = useState("");
  const [postalAddress, setPostalAddress] = useState("");

  // document upload state

  const [supportFile, setSupportFile] = useState<File | null>(null);
  const [additionFile, setAdditionFile] = useState<File | null>(null);

  const [documentationUrl, setDocumentationUrl] = useState<string>("");

  // event details state

  const [placeOfDeath, setPlaceOfDeath] = useState("");
  const [funeralParlour, setFuneralParlour] = useState("");
  const [dha1663, setDHA1663] = useState("");
  const [haThatIssuedDeathCertificate, setHAThatIssuedDeathCertificate] =
    useState("");

  // bank details state

  const [accountHolder, setAccountHolder] = useState("");
  const [accountIDType, setAccountIDType] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const isFormValid = (data: any) => {
    return (
      data.memeber &&
      data.policy &&
      data.deceased &&
      data.event &&
      data.banks &&
      Object.values(data.memeber).every((value) => value !== "") &&
      Object.values(data.policy).every((value) => value !== "") &&
      Object.values(data.deceased).every((value) => value !== "") &&
      Object.values(data.event).every((value) => value !== "") &&
      Object.values(data.banks).every((value) => value !== "")
    );
  };

  const onFinish = (values: any) => {
    const model = {
      id: Date.now(),
      banks: data.banks,
      deceased: data.deceased,
      event: data.event,
      memeber: data.memeber,
      policy: data.policy,
    };

    if (isFormValid(model)) {
      setIsModalVisible(true);
    }

    setCurrentTab("1");
    setIClearInputsMember(true);
    setIClearBankDetail(true);
    setIClearInputsEventDetail(true);
    setIClearInputsDeceasedDetails(true);
    setIClearInputsPolicy(true);
  };

  const tableRow = useSelector((state: any) => state.captureClaims);

  const handleAddToTable = (tableRow: any) => {
    dispatch(addClaim(tableRow));
  };

  const handleNext = () => {
    // Clear the input completion flags for the next tab
    setIClearInputsMember(false);
    setIClearBankDetail(false);
    setIClearInputsEventDetail(false);
    setIClearInputsDeceasedDetails(false);
    setIClearInputsPolicy(false);

    // Increment the current tab value to move to the next tab
    const nextTab = String(Number(currentTab) + 1);
    setCurrentTab(nextTab);
  };

  const handleOk = () => {
    const model = {
      id: Date.now(),
      banks: data.banks,
      deceased: data.deceased,
      event: data.event,
      memeber: data.memeber,
      policy: data.policy,
    };

    // const updatedBanks = { ...model.banks, ClaimStatus: selectedClaimStatus };
    // const updatedData = { ...model, banks: updatedBanks };
    // dispatch(addClaim(model));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBack = () => {
    // Decrement the current tab value to move to the previous tab
    const previousTab = String(Number(currentTab) - 1);
    setCurrentTab(previousTab);
  };

  const getStatusClaims = async () => {
    try {
      const response = await instance.get(
        `api/claims/claim-status/?format=json`
      );
      setClaimStatuses(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getStatusClaims();
  }, []);

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Member Details",
      children: (
        <MembershipDetail
          ClearPropsIsInput={isClearInputsMember}
          principalMember={principalMember}
          setPrincipalMember={setPrincipalMember}
          deceasedType={deceasedType}
          setDeceasedType={setDeceasedType}
          productType={productType}
          setProductType={setProductType}
          documentationUrl={documentationUrl}
          setDocumentationUrl={setDocumentationUrl}
        />
      ),
    },
    {
      key: "2",
      label: "Policy Details",
      children: (
        <Policy
          ClearPropsIsInput={isClearPolicy}
          schemeName={schemeName}
          setSchemeName={setSchemeName}
          policyNumber={policyNumber}
          setPolicyNumber={setPolicyNumber}
          inceptionDate={inceptionDate}
          setInceptionDate={setInceptionDate}
          claimAmount={claimAmount}
          setClaimAmount={setClaimAmount}
          grossPremium={grossPremium}
          setGrossPremium={setGrossPremium}
          dateClaimSubmission={dateClaimSubmission}
          setDateClaimSubmission={setDateClaimSubmission}
        />
      ),
    },
    {
      key: "3",
      label: "Deceased Details",
      children: (
        <DeceasedDetails
          isInput={isClearDeceasedDetails}
          deceasedCaused={deceasedCaused}
          deceasedGender={deceasedGender}
          deceasedNationality={deceasedNationality}
          deceasedSurname={deceasedSurname}
          dateOfDeath={dateOfDeath}
          residentialAddress={residentialAddress}
          postalAddress={postalAddress}
          deceasedName={deceasedName}
          idType={idType}
          maritalStatus={maritalStatus}
          setDeceasedCause={setDeceasedCause}
          setDeceasedGender={setDeceasedGender}
          setDeceasedNationality={setDeceasedNationality}
          setDeceasedSurname={setDeceasedSurname}
          setDateOfDeath={setDateOfDeath}
          setResidentialAddress={setResidentialAddress}
          setPostalAddress={setPostalAddress}
          setDeceasedName={setDeceasedName}
          setIdType={setIdType}
          setMaritalStatus={setMaritalStatus}
          inceptionDate={inceptionDate}
          dateClaimSubmission={dateClaimSubmission}
        />
      ),
    },
    {
      key: "4",
      label: "Documents",
      children: (
        <DocumentUpload
          supportFile={supportFile}
          setSupportFile={setSupportFile}
          additionFile={additionFile}
          setAdditionFile={setAdditionFile}
          documentationUrl={documentationUrl}
          setDocumentationUrl={setDocumentationUrl}
        />
      ),
    },
    {
      key: "5",
      label: "Event Details",
      children: (
        <EventDetail
          isInput={isClearEventDetail}
          placeOfDeath={placeOfDeath}
          funeralParlour={funeralParlour}
          dha1663={dha1663}
          haThatIssuedDeathCertificate={haThatIssuedDeathCertificate}
          setPlaceOfDeath={setPlaceOfDeath}
          setFuneralParlour={setFuneralParlour}
          setDHA1663={setDHA1663}
          setHAThatIssuedDeathCertificate={setHAThatIssuedDeathCertificate}
        />
      ),
    },
    {
      key: "6",
      label: "Bank Details",
      children: (
        <BankDetail
          isInput={isClearBankDetail}
          accountHolder={accountHolder}
          accountIDType={accountIDType}
          selectedBank={selectedBank}
          accountNumber={accountNumber}
          setAccountHolder={setAccountHolder}
          setAccountIDType={setAccountIDType}
          setSelectedBank={setSelectedBank}
          setAccountNumber={setAccountNumber}
        />
      ),
    },
  ];

  return (
    <Header title="">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Capture Claim</h5>
        </div>
        <div className="card-body">
          <Form
            className="row g-3"
            id="headclaim-form"
            encType="multipart/form-data"
            method="POST"
            action="/head/claims/add"
            noValidate
            onFinish={onFinish}
          >
            <Tabs
              activeKey={currentTab}
              className="col-12"
              tabBarGutter={32}
              tabBarStyle={{ pointerEvents: "none" }}
              items={tabItems}
            />

            <div className="col-12">
              {currentTab !== "1" && (
                <Button type="default" onClick={handleBack}>
                  Back
                </Button>
              )}
              {currentTab !== "6" && (
                <Button
                  type="primary"
                  onClick={handleNext}
                  style={{ marginLeft: "8px" }}
                  disabled={
                    !(
                      (principalMember &&
                        deceasedType &&
                        productType &&
                        currentTab === "1") ||
                      (schemeName &&
                        policyNumber &&
                        inceptionDate &&
                        grossPremium &&
                        claimAmount &&
                        dateClaimSubmission &&
                        currentTab === "2") ||
                      (deceasedSurname &&
                        deceasedName &&
                        maritalStatus &&
                        idType &&
                        (idType === "idNumber" || deceasedGender) &&
                        (idType === "idNumber" || deceasedNationality) &&
                        dateOfDeath &&
                        residentialAddress &&
                        postalAddress &&
                        deceasedCaused &&
                        currentTab === "3") ||
                      (principalMember && currentTab === "4") ||
                      (placeOfDeath &&
                        funeralParlour &&
                        dha1663 &&
                        haThatIssuedDeathCertificate &&
                        currentTab === "5")
                    )
                  }
                >
                  Next
                </Button>
              )}
              {currentTab === "6" && (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "8px" }}
                  disabled={
                    !(
                      accountHolder &&
                      accountIDType &&
                      selectedBank &&
                      accountNumber &&
                      currentTab === "6"
                    )
                  }
                  onClick={() => handleAddToTable(tableRow)}
                >
                  Add to the table
                </Button>
              )}
            </div>

            <TableClaims />
          </Form>
        </div>
      </div>

      <Modal
        title="Select Claim Status"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Select Claim Status"
          onChange={(value) => setSelectedClaimStatus(value)} // Update selected claim status in state
          value={selectedClaimStatus} // Set the default value of the Select component
        >
          {claimStatuses.map((claimStatus: any) => (
            <Option key={claimStatus.id} value={claimStatus.id}>
              {claimStatus.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </Header>
  );
};

export default AddNew;
