import React, { useState } from "react";
import { Button } from "antd";
import Header from "../../Dashboard/header";
import BanksTable from "./BanksTable";
import InsurersTable from "./InsurersTable";
import SchemesTable from "./SchemesTable";
import MemberKeywordsTable from "./MemberKeywordsTable";
import ClaimStatusTable from "./ClaimStatusTable";
import SchemeStatusTable from "./SchemeStatusTable";
import ReviewStatusTable from "./ReviewStatusTable";
import MemberTypesTable from "./MemberTypes";
import PolicyStatusTable from "./PolicyStatus";

const AdministrationPage = () => {
  const [activeTable, setActiveTable] = useState("schemes");

  const renderTable = () => {
    switch (activeTable) {
      case "banks":
        return <BanksTable />;
      case "insurers":
        return <InsurersTable />;
      case "schemes":
        return <SchemesTable />;
      case "memberTypesCategories":
        return <MemberKeywordsTable />;
      case "claimStatus":
        return <ClaimStatusTable />;
      case "schemeStatus":
        return <SchemeStatusTable />;
      case "reviewStatus":
        return <ReviewStatusTable />;
      case "memberTypes":
        return <MemberTypesTable />;
      case "policyStatus":
        return <PolicyStatusTable />;
      default:
        return <SchemesTable />;
    }
  };

  return (
    <Header title="Insights">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "calc(100vh - 140px)",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", gap: "2rem", marginBottom: "4rem" }}>
          <Button
            type="primary"
            ghost={activeTable !== "schemes"}
            onClick={() => setActiveTable("schemes")}
          >
            Schemes
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "banks"}
            onClick={() => setActiveTable("banks")}
          >
            Banks
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "insurers"}
            onClick={() => setActiveTable("insurers")}
          >
            Insurers
          </Button>

          <Button
            type="primary"
            ghost={activeTable !== "memberTypesCategories"}
            onClick={() => setActiveTable("memberTypesCategories")}
          >
            Member Types Categories
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "claimStatus"}
            onClick={() => setActiveTable("claimStatus")}
          >
            Claim Statuses
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "schemeStatus"}
            onClick={() => setActiveTable("schemeStatus")}
          >
            Scheme Statuses
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "reviewStatus"}
            onClick={() => setActiveTable("reviewStatus")}
          >
            Review Statuses
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "memberTypes"}
            onClick={() => setActiveTable("memberTypes")}
          >
            Member Types
          </Button>
          <Button
            type="primary"
            ghost={activeTable !== "policyStatus"}
            onClick={() => setActiveTable("policyStatus")}
          >
            Policy Status
          </Button>
        </div>
        {renderTable()}
      </div>
    </Header>
  );
};

export default AdministrationPage;
