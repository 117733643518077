import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import SwalAlert from "./appalert";
import AddNew from "./claims/AddNew";
import Insights from "./claims/Insights";
import Claims from "./claims/viewClaims";
import { AuthProvider } from "./context/AuthContext";
import NetworkAndError from "./network-and-error/network-and-error";
import AwaitingPayments from "./payments/AwaitingPage";
import HeldClaimsPage from "./payments/HeldClaimsPage";
import ReviewClaims from "./review/ReviewClaims";
import ForgotPassword from "./screens/forgot-password/forgotPassword";
import { GenerateQuote } from "./screens/generate-quote/generateQuote";
import { InsightsOverview } from "./screens/insights/overview";
import Login from "./screens/login/Login";
import OTP from "./screens/otp/otp";
import CollectionAnalysis from "./policy-admin/CollectionAnalysis";
import AdministrationPage from "./policy-admin/Administration/AdminsPage";
import AdminPage from "./policy-admin/AdminPage";
import MembersTable from "./policy-admin/Membership/MemberTable";

function App() {
  return (
    <div className="App">
      <Router>
        <NetworkAndError />
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<OTP />} />
            <Route path="/view-claims" element={<Claims />} />
            <Route path="/capture-claims" element={<AddNew />} />
            <Route path="/awaiting-payments" element={<AwaitingPayments />} />
            <Route path="/held-claims" element={<HeldClaimsPage />} />
            <Route path="/review-claims" element={<ReviewClaims />} />
            <Route path="/password-reset" element={<ForgotPassword />} />
            <Route path="/insights-overview" element={<InsightsOverview />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/generate-quote" element={<GenerateQuote />} />
            <Route path="/generate-quote" element={<GenerateQuote />} />
            <Route path="/membership/:memberId" element={<MembersTable />} />
            <Route path="/administration" element={<AdministrationPage />} />
            <Route path="/policy-admin" element={<AdminPage />} />
            <Route
              path="/collection-analysis/:memberId"
              element={<CollectionAnalysis />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
          <SwalAlert />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
