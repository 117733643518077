import { Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import instance from "../../axios/axios-instance";
import ConvertCapturedClaim from "../../claims/convertcapturedclaim";
import { AuthContext } from "../../context/AuthContext";
import { editClaim } from "../../redux/actions/ClaimsAction";
import ReviewEditForm from "./ReviewEditForm";

interface FormData {
  premium_check: string;
  data_confirmation: string;
  assessor_notes: any;
  reviewer_notes: any;
  documentation: any;
  claim_closure: string;
  review_status: string;
  is_reviewed: boolean;
  policy_number: string;
}

interface Props {
  claimId: string;
  claimData: any;
  onClose: () => void;
  isModalOpen: boolean;
  setIsReviewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleReset: any;
}

interface Review {
  id: string;
  name: string;
}

interface Closure {
  id: string;
  name: string;
}
const ClaimReviewModal: React.FC<Props> = (Props) => {
  const dispatch = useDispatch();
  const { user } = React.useContext(AuthContext);
  const [review, setReview] = useState<Review[]>([]);
  const [closure, setClosure] = useState<Closure[]>([]);
  const [formData, setFormData] = useState<FormData>({
    premium_check: Props.claimData?.premium_check || "",
    data_confirmation: Props.claimData?.data_confirmation || "",
    claim_closure: Props.claimData?.claim_closure || "",
    assessor_notes: Props.claimData?.notes || "",
    documentation: Props.claimData?.documentation || "",
    review_status: Props.claimData?.review_status || "",
    is_reviewed: Props.claimData?.is_reviewed || false,
    reviewer_notes: Props.claimData?.reviewer_notes || "",
    policy_number: Props.claimData?.policy_number || "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const isClosureStatusVisible = () => {
    return ["22", "21", "19", "18"].includes(formData.review_status);
  };

  const updateData = useSelector((state: any) => state.captureClaims);

  const handleOk = async () => {
    const getEditData = ConvertCapturedClaim([updateData], Props.claimData);

    await instance.put(`/api/claims/update-claim/${Props.claimData.id}/`, {
      getEditData,
      id: Props.claimData.id,
    });

    Props.setIsReviewModalOpen(false);

    const model: any = {
      updated_by: user?.user_id,
      id: Props.claimId,
      notes: formData.assessor_notes,
      premium_check: formData.premium_check,
      data_confirmation: formData.data_confirmation,
      review_status: { id: parseInt(formData.review_status) },
      is_reviewed: true,
      reviewer_notes: formData.reviewer_notes,
    };

    if (isClosureStatusVisible()) {
      model.claim_closure = 6;
    }

    try {
      const response = await instance.put(
        `api/claims/review/${Props.claimId}/`,
        model
      );
      if (response.status === 200 || response.status === 201) {
        Props.onClose();
        setFormData((prevData) => ({ ...prevData, claim_closure: "" }));
        Swal.fire({
          icon: "success",
          title: "Claim Reviewed Successfully",
          text: response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }

    Props.handleReset()
    Props.onClose();
  };

  function getClaimant() {
    const { id_of_claimant, name_of_claimant, surname_of_claimant, passport } =
      Props.claimData.claimant || ({} as any);
    return [name_of_claimant, surname_of_claimant, id_of_claimant, passport]
      .filter((value) => value)
      .join(" / ");
  }

  useEffect(() => {
    const getClosure = async () => {
      try {
        const response = await instance.get(
          `api/claims/closure-status/?format=json`
        );
        setClosure(response.data);
        if (Props.claimData) {
          setFormData((prevData) => ({
            ...prevData,
            claim_closure: Props.claimData.claim_closure?.id || "",
          }));
        }
      } catch (error: any) {
        console.log(error.message);
      }
    };
    getClosure();
    const getReview = async () => {
      try {
        const response = await instance.get(
          `api/claims/review-status/?format=json`
        );
        setReview(response.data);
        if (Props.claimData) {
          setFormData((prevData) => ({
            ...prevData,
            review_status: Props.claimData.review_status?.id || "",
          }));
        }
      } catch (error: any) {
        console.log(error.message);
      }
    };
    getReview();
  }, [Props.claimData]);

  return (
    <>
      <Modal
        width={1500}
        title={<h4>Review Claim</h4>}
        open={Props.isModalOpen}
        onOk={handleOk}
        onCancel={Props.onClose}
        okButtonProps={{
          disabled: !(
            formData.premium_check &&
            formData.data_confirmation &&
            formData.review_status
          ),
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "0.5rem",
          }}
        >
          <h5 className="ml-0">{formData.policy_number}</h5>
        </div>
        <ReviewEditForm
          claimData={Props.claimData}
          handleReset={Props.handleReset}
        />
        <form className="row g-3" method="PUT" id="member-form">

          <Divider />
          <div>
            <label htmlFor="notes">
              Assessor Notes <span className="text-danger">*Required</span>
            </label>
            <input
              disabled
              className="form-control"
              type="text"
              id="notes"
              value={formData.assessor_notes}
            />
          </div>

          <div className="form-group">
            <label htmlFor="id_Notes">
              Reviewer Notes <span className="text-danger"></span>
            </label>
            <input
              type="text"
              onChange={handleInputChange}
              value={formData.reviewer_notes}
              className="form-control"
              id="id_Notes"
              name="reviewer_notes"
            />
          </div>
          {formData.documentation !== " " ? (
            <div className="form-group">
              <label htmlFor="documentation">
                Documentation <span className="text-danger">Link</span>
              </label>
              <ul>
                <li>
                  <a
                    href={formData.documentation}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Link to Supporting Documents
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <div className="form-group">
              <label>Documentation</label>
              <p className="text-danger">No documents provided</p>
            </div>
          )}

          <div className="form-group">
            <div>
              <label htmlFor="premium">
                Premium Check <span className="text-danger">*Required</span>
              </label>
              <select
                id="premium"
                className="form-select"
                name="premium_check"
                onChange={handleInputChange}
              >
                <option selected value="">
                  ------
                </option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
            </div>
            <div>
              <label htmlFor="data_confirm">
                Data Confirmation <span className="text-danger">*Required</span>
              </label>
              <select
                id="data_confirm"
                className="form-select"
                name="data_confirmation"
                onChange={handleInputChange}
              >
                <option selected value="">
                  ------
                </option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="review_status">
              Review Status <span className="text-danger">*Required</span>
            </label>
            <select
              onChange={handleInputChange}
              value={formData.review_status}
              name="review_status"
              id="review_status"
              className="form-select"
            >
              <option value="">---------</option>
              {review.map((review) => (
                <option key={review.id} value={review.id}>
                  {review.name}
                </option>
              ))}
            </select>
          </div>

          {isClosureStatusVisible() && (
            <div className="form-group" style={{ display: "none" }}>
              <label htmlFor="claim_closure">
                Closure Status1 <span className="text-danger">*Required</span>
              </label>
              <select
                id="claim_closure"
                className="form-select"
                name="claim_closure"
                onChange={handleInputChange}
                value={3}
              >
                <option value="3">Closed Repudiated</option>
              </select>
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};
export default ClaimReviewModal;
