// appMessagesReducer.ts
import { SET_SUCCESS_MESSAGE, SET_ERROR_MESSAGE, CLEAR_MESSAGES, SET_CUSTOM_MESSAGE } from "../actions/AppMessageActions";
const initialState = {
    successMessage: null,
    errorMessage: null,
    customMessage: {},
  };

  interface setSuccessAction {
    type: typeof SET_SUCCESS_MESSAGE,
    payload: any
  }

  interface setErrorAction {
    type: typeof SET_ERROR_MESSAGE,
    payload: any
  }

  interface setCustomAction {
    type: typeof SET_CUSTOM_MESSAGE,
    payload: any,
  }

  interface ClearAction {
    type: typeof CLEAR_MESSAGES,
    payload: any
  }

  type Action = setSuccessAction | setErrorAction | ClearAction | setCustomAction ;
  
  const appMessagesReducer = (state = initialState, action: Action) => {
    switch (action.type) {
      case 'SET_SUCCESS_MESSAGE':
        return {
          ...state,
          successMessage: action.payload,
          errorMessage: null, // Clear any previous error message
        };
      case 'SET_ERROR_MESSAGE':
        return {
          ...state,
          errorMessage: action.payload,
          successMessage: null, // Clear any previous success message
        };
      case 'SET_CUSTOM_MESSAGE':
        return {
          ...state,
          customMessage: {
            header: action.payload.header,
            message: action.payload.message,
          }
        }
      case 'CLEAR_MESSAGES':
        return {
          ...state,
          errorMessage: null,
          successMessage: null,
        };
      default:
        return state;
    }
  };
  
  export default appMessagesReducer;
  