import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IBankDetail } from "../../../redux/types/bankDetail";
import { setBankDetail } from "../../../redux/actions/BankDetailActions";
import instance from "../../../axios/axios-instance";

import { get } from "http";
interface bankProps {
  isInput: boolean | false;
  accountHolder: string;
  accountIDType: string;
  selectedBank: any;
  accountNumber: string;
  accountIDNumber: string;
  accountPassport: string;
  accountDateOfBirth: string;
  claimStatus: any;
  notes: string;
  accountType: string;
  setAccountHolder: React.Dispatch<React.SetStateAction<string>>;
  setAccountIDType: React.Dispatch<React.SetStateAction<string>>;
  setSelectedBank: React.Dispatch<React.SetStateAction<any>>;
  setAccountNumber: React.Dispatch<React.SetStateAction<string>>;
  setAccountIDNumber: React.Dispatch<React.SetStateAction<string>>;
  setAccountPassport: React.Dispatch<React.SetStateAction<string>>;
  setAccountDateOfBirth: React.Dispatch<React.SetStateAction<string>>;
  setClaimStatus: React.Dispatch<React.SetStateAction<any>>;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  isReviewed?: boolean;
}

const BankDetail: React.FC<bankProps> = ({
  isInput,
  accountHolder,
  accountIDType,
  selectedBank,
  accountNumber,
  accountIDNumber,
  accountPassport,
  accountDateOfBirth,
  claimStatus,
  notes,
  accountType,
  isReviewed,
  setAccountHolder,
  setAccountIDType,
  setSelectedBank,
  setAccountNumber,
  setAccountIDNumber,
  setAccountPassport,
  setAccountDateOfBirth,
  setClaimStatus,
  setNotes,
  setAccountType,
}) => {
  const dispatch = useDispatch();

  const [banks, setBanks] = useState([]);
  const [claimStatuses, setClaimStatuses] = useState([]);

  const handleAccountHolderChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountHolder(event.target.value);
  };

  const handleAccountIDTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountIDType(event.target.value);
  };

  const handleAccountTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountType(event.target.value);
  };

  const handleAccountIDNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountIDNumber(event.target.value);
  };

  const handleAccountPassportChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountPassport(event.target.value);
  };

  const handleAccountDateOfBirthChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountDateOfBirth(event.target.value);
  };

  const handleAccountNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAccountNumber(event.target.value);
  };

  const handleClaimStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setClaimStatus(event.target.value);
  };

  const handleBankChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedBank(event.target.value);
    // You can perform any other actions or state updates based on the selected value here
  };

  const handleNotesChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNotes(event.target.value);
  };

  useEffect(() => {
    if (isInput) {
      setAccountHolder("");
      setAccountIDType("");
      setAccountIDNumber("");
      setAccountPassport("");
      setAccountDateOfBirth("");
      setAccountNumber("");
      setAccountType("");
      //   setClaimStatus("");
      setNotes("");
    }
  }, [isInput]);


  useEffect(() => {
    const model: IBankDetail = {
      BankName:
        typeof selectedBank?.id === "number" &&
          typeof selectedBank?.id !== "undefined"
          ? selectedBank.id
          : Number(selectedBank),
      AccountIDType: accountIDType,
      AccountHolder: accountHolder,
      AccountIDNumber: accountIDNumber,
      AccountPassport: accountPassport,
      AccountNumber: accountNumber,
      AccountType: accountType,
      Notes: notes,
      ClaimStatus:
        typeof claimStatus?.id === "number" &&
          typeof claimStatus?.id !== "undefined"
          ? claimStatus.id
          : Number(claimStatus),
    };

    dispatch(setBankDetail(model));
  }, [
    accountHolder,
    accountIDType,
    accountType,
    accountIDNumber,
    accountPassport,
    accountNumber,
    claimStatus,
    notes,
    selectedBank,
    dispatch,
  ]);

  const getBanks = async () => {
    try {
      const response = await instance.get(`api/claims/bank-names/?format=json`);
      setBanks(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const getStatusClaims = async () => {
    try {
      const response = await instance.get(
        `api/claims/claim-status/?format=json`
      );
      setClaimStatuses(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getBanks();
    getStatusClaims();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="floatingbank">
              Bank Name <span className="text-danger">* Required</span>
            </label>
            <select
              className="form-select"
              style={{ width: "100%", }}
              id="floatingbank"
              name="bank_name"
              aria-label="Bank Name"
              onChange={handleBankChange}
              value={
                typeof Number(selectedBank.id) === "number"
                  ? selectedBank.id
                  : selectedBank
              } // Set the selected value of the dropdown
            >
              <option value="" selected>
                ---------
              </option>
              {banks.map((bank: any) => (
                <option key={bank.id} value={bank.id}>
                  {bank.bank}
                </option>
              ))}
              {/* Add your new option below */}
              {/* <option value="Your New Bank">Your New Bank</option> */}
            </select>
          </div>
        </div>

        <div className="col-md-4 flex mb-3">
          <div className="form-group">
            <label htmlFor="floatingiaccounttype">
              Account Type <span className="text-danger">* Required</span>
            </label>
            <select
              className="form-select"
              style={{ width: "100%" }}
              id="floatingiaccounttype"
              name="account_type"
              value={accountType}
              onChange={handleAccountTypeChange}
              aria-label="Account Type"
            >
              <option value="" selected>
                ---------
              </option>
              <option value="Savings">Savings</option>
              <option value="Cheque">Cheque</option>
              <option value="Transmission">Transmission</option>
            </select>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="floatingaccountname">
              Account Holder <span className="text-danger">* Required</span>
            </label>
            <input
              type="text"
              required
              className="form-control"
              id="floatingaccountname"
              name="account_holder"
              value={accountHolder}
              placeholder="Account Holder"
              onChange={handleAccountHolderChange}
            />
            <div style={{ color: "red" }}></div>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex" id="accountID">
          <div className="form-group">
            <label htmlFor="accountidtype">Account Holder ID Type</label>
            <select
              className="form-select"
              id="accountidtype"
              value={accountIDType}
              onChange={handleAccountIDTypeChange}
              aria-label=""
            >
              <option value="none">--------</option>
              <option value="1" selected={accountIDNumber ? true : false}>
                RSA ID
              </option>
              <option value="2" selected={accountPassport ? true : false}>
                Non RSA Member
              </option>
            </select>
          </div>
        </div>

        {(accountIDType === "1" || accountIDNumber) && (
          <div className="col-md-4" id="rsa-account-id-type">
            <div className="form-group">
              <label htmlFor="floatingID">Account Holder ID Number</label>
              <input
                type="text"
                value={accountIDNumber}
                className="form-control"
                id="floatingID"
                name="account_hidnumber"
                placeholder="Account Holder ID Number"
                onChange={handleAccountIDNumberChange}
              />
            </div>
          </div>
        )}

        {(accountIDType === "2" || accountPassport) && (
          <div className="col-md-4" id="passport-account-id-type">
            <div className="form-group">
              <label htmlFor="floatingpass">Account Holder Passport</label>
              <input
                type="text"
                value={accountPassport}
                className="form-control"
                id="floatingpass"
                name="account_hidpassport"
                placeholder="Account Holder Passport"
                onChange={handleAccountPassportChange}
              />
            </div>
          </div>
        )}

        {accountIDType === "2" && (
          <div
            className="col-md-4"
            id="passport-account-date-id-type"
            style={{ display: "none" }}
          >
            <div className="form-group">
              <label htmlFor="floatingdateob">
                Account Holder Date of Birth
              </label>
              <input
                type="date"
                value={accountDateOfBirth}
                className="form-control"
                id="floatingdateob"
                name="account_hdate_of_birth"
                placeholder="Account Holder Date of Birth"
                onChange={handleAccountDateOfBirthChange}
              />
            </div>
          </div>
        )}

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="floatingaccno">
              Account Number <span className="text-danger">* Required</span>
            </label>
            <input
              type="number"
              required
              className="form-control"
              id="floatingaccno"
              name="account_number"
              value={accountNumber}
              min="0"
              pattern="^[0-9]*$"
              placeholder="Account Number"
              onChange={handleAccountNumberChange}
            />
            <div style={{ color: "red" }}></div>
          </div>
        </div>

        <div className="col-md-4 flex mb-3">
          <div className="form-group">
            <label htmlFor="floatingstatus">Claim Status</label>
            <select
              className="form-select"
              style={{ width: "100%" }}
              id="floatingstatus"
              name="claim_status"
              aria-label="Claim Status"
              onChange={handleClaimStatusChange}
              value={
                typeof Number(claimStatus.id) === "number"
                  ? claimStatus.id
                  : claimStatus
              }
            >
              <option value="">---------</option>
              {claimStatuses.map((status: any) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="floatingnotees">Notes</label>
            <textarea
              className="form-control"
              id="floatingnotees"
              name="notes"
              value={notes}
              placeholder="Notes"
              onChange={handleNotesChange}
              readOnly={isReviewed}
            ></textarea>
            <div style={{ color: "red" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetail;
