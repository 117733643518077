import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Upload } from "antd";
import { IPMFile } from "../../../redux/types/PMFile";
import { setPMFile } from "../../../redux/actions/PMFileAction";
interface DocumentUploadProps {
  supportFile: File | null;
  additionFile: File | null;
  documentationUrl: string;
  setDocumentationUrl: React.Dispatch<React.SetStateAction<string>>;
  setSupportFile: React.Dispatch<React.SetStateAction<File | null>>;
  setAdditionFile: React.Dispatch<React.SetStateAction<File | null>>;
}
const DocumentUpload: React.FC<DocumentUploadProps> = ({
  supportFile,
  additionFile,
  setSupportFile,
  setAdditionFile,
  documentationUrl,
  setDocumentationUrl,
}) => {
  // State variables to hold the selected files

  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const handleFileChange = (event: {
    target: { files: React.SetStateAction<null>[] };
  }) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const model: IPMFile = {
      supportFile: supportFile,
      additionFile: additionFile,
      documentationUrl: documentationUrl,
    };

    dispatch(setPMFile(model));
  }, [supportFile, additionFile, dispatch, documentationUrl]);

  const [uploadDocs, setUploadDocs] = useState({
    supportFile,
    additionFile,
    documentationUrl,
  });

  useEffect(() => {
    setUploadDocs({
      supportFile,
      additionFile,
      documentationUrl,
    });
  }, [supportFile, additionFile, documentationUrl]);

  // Event handlers to update the state variables when files are selected
  const handleSupportingFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSupportFile(event.target.files[0]);
    }
  };

  const handleAdditionalFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAdditionFile(event.target.files[0]);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <section className="row g-3">
          {/* <div className="col-md-6 mb-3 flex">
          <label
            htmlFor="formFilePrincipal"
            className="col-sm-12 col-form-label"
          >
            Supporting Documents{" "}
            <span className="text-danger">* Required</span>
          </label>
          <input
            className="form-control"
            name="identity_for_the_principal_member"
            type="file"
            id="formFilePrincipal"
            accept="application/pdf"
            onChange={handleSupportingFileChange}
          />
        </div> */}

          <div className="col-md-6 mb-3 flex">
            <div className="form-group">
              <label htmlFor="docUrl">
                Documentation URL
                <span className="text-danger">* Required</span>{" "}
              </label>
              <input
                type="url"
                className="form-control"
                id="docUrl"
                placeholder="documentation url"
                value={documentationUrl}
                onChange={(e: any) => setDocumentationUrl(e.target.value)}
              />
            </div>
          </div>

          {
            <div className="col-md-6 mb-3 d-flex justify-content-center align-items-center">
              <button
                disabled={!documentationUrl}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    documentationUrl,
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="btn btn-primary block mb-0 mt-3"
              >
                Link to Supporting Documents
              </button>
            </div>
          }

          {/* <div className="col-md-6 mb-3 flex">
          <label
            htmlFor="formFilePrincipal"
            className="col-sm-12 col-form-label"
          >
            Additional Documents{" "}
            <span className="text-danger">* Required</span>
          </label>
          <input
            className="form-control"
            name="identity_for_the_principal_member"
            type="file"
            id="formFilePrincipal"
            accept="application/pdf"
            onChange={handleAdditionalFileChange}
          />
        </div> */}

          {/* ...rest of the form... */}
        </section>
        {/* End Custom Styled Validation */}
      </div>
    </div>
  );
};

export default DocumentUpload;
