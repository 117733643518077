import { useSelector } from "react-redux/es/hooks/useSelector";
import { ThunkAction } from "redux-thunk/es/types";
import instance from "../../axios/axios-instance";
import { IClaimant } from "../types/Claimant";
import { setErrorMessage, setSuccessMessage } from "./AppMessageActions";

type ThunkResult<R> = ThunkAction<R, any, unknown, any>;

const ErrorResponse = (data: any) => {
  var output = "";

  for (var key in data) {
    if (Object.hasOwn(data, key)) {
      var value = data[key];

      output += key + ":\n";

      for (var i = 0; i < value.length; i++) {
        output += value[i] + "\n";
      }
      output += "\n";
    }
  }
  return output;
};

// Action Types
export const SET_CLAIMANT = "SET_CLAIMANT";

// Action Creators
export interface SetClaimantAction {
  type: typeof SET_CLAIMANT;
  payload: IClaimant;
}

export const setClaimant = (claimant: any): SetClaimantAction => ({
  type: SET_CLAIMANT,
  payload: claimant,
});

const useClaimantData = () => {
  const claimantsData = useSelector((state: any) => state?.claimants);
  return claimantsData;
};

export const createClaimant = (model: any): ThunkResult<void> => {
  return async (dispatch) => {
    try {
      const response = await instance.post(
        "/api/claims/claimant/?format=json",
        model
      );

      // Assuming the API response contains the newly created principal member data

      const claimant: any = response;

      if (response.status === 201) {
        dispatch(setSuccessMessage("Claimant saved successfully"));
      } else {
        dispatch(setErrorMessage("There was an error creating a claimant"));
      }

      // Dispatch the "SET_MEMBER" action with the new principal member data
      // dispatch(setClaimant([...useClaimantData(), model]));

      // Optionally, you can add any success handling here, such as showing a success notification, etc.
    } catch (error: any) {
      // console.log(error.message);
      dispatch(setErrorMessage(ErrorResponse(error?.response?.data)));
      // dispatch(setErrorMessage(`${error.code}: ${error.message}`));
      console.log(error);

      // Optionally, you can dispatch a failure action here or show an error notification to the user.
    }
  };
};
