import { Button, Modal, Space, Table, Typography } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useState } from "react";
import instance from "../axios/axios-instance";
import { setErrorMessage } from "../redux/actions/AppMessageActions";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

const { Title, Text } = Typography;

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface Props {
  tableData: any;
  onClose: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  progress_status: string;
  review_standing: string;
  statistic_output: string;
  selectedCount: string;
}

const InsightsModal: React.FC<Props> = (Props) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const dispatch = useDispatch<AppDispatch>();
  const [assessorFilters, setAssessorFilters] = useState<any[]>([]);
  const [selectedAssessor, setSelectedAssessor] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [page_size, setPage_size] = useState<number>(5);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const model = {
          progress_status: Props.progress_status,
          review_standing: Props.review_standing,
          statistic_output: Props.statistic_output,
          page_size,
          page: currentPage,
        };

        const query = Object.entries(model)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        const response = await instance.get(
          `/api/claims/insights-data/?format=json&${query}`
        );
        console.log("insights query", query);
        console.log("insights data response", response);
        if (response.statusText === "OK") {
          setTableData(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [
    Props.statistic_output,
    Props.review_standing,
    Props.progress_status,
    currentPage,
    page_size,
  ]);

  useEffect(() => {
    instance.get("api/claims/assessor/?format=json").then(({ data }) => {
      const assessorFilters = data.map((assessor: any) => {
        const text = `${assessor.name} ${assessor.surname}`.trim();
        return {
          text,
          value: assessor.id,
        };
      });
      setAssessorFilters(assessorFilters);
    });
  }, []);

  const downloadData = async () => {
    try {
      const model = {
        progress_status: Props.progress_status,
        review_standing: Props.review_standing,
        statistic_output: Props.statistic_output,
        page_size: Props.selectedCount,
        assessor_search: String(selectedAssessor),
        page: 1,
      };

      const query = Object.entries(model)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      const response = await instance.get(
        `/api/claims/insights-data-download/?format=json&${query}`,
        {
          responseType: "blob",
        }
      );

      console.log("insights query", query);
      console.log("insights data response", response);

      // Create a Blob object from the response data with the correct content type
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today}_Filtered_Claims_Insights.csv`; // Set the desired filename with .xlsx extension for Excel

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (e) {
      console.log(e);
    }
  };

  const NoWrap = ({ text }: { text: string }) => (
    <div style={{ whiteSpace: "nowrap", minWidth: "112px" }}>{text}</div>
  );
  const columns: ColumnsType<any> = [
    {
      title: "Assessor",
      dataIndex: ["assessor", "name"],
      key: "assessor",
      fixed: "left",
      // filters: assessorFilters,
      // filterSearch: true,
      // filterMultiple: true,
      // filteredValue: selectedAssessor,
      render: (text: string, record: any) => {
        const fullName = `${record.assessor?.name} ${record.assessor?.surname}`;
        return <NoWrap text={fullName}></NoWrap>;
      },
    },
    {
      title: "Deceased",
      dataIndex: "deceased_name",
      key: "1",
      fixed: "left",
      filteredValue: [],
      render: (text: string, record: any) => {
        if (record.deceased_id_number) {
          const fullName = `${record.deceased_id_number}`;
          return fullName;
        } else if (record.passport) {
          const fullName = `${record.passport}`;
          return fullName;
        }
      },
    },

    {
      title: "Insurer",
      dataIndex: ["scheme_name", "insurer_name"],
      key: "insurer",
      render: (text, record) => (
        <NoWrap text={record.scheme_name?.insurer_name.insurer_name}></NoWrap>
      ),
    },
    {
      title: "Scheme",
      dataIndex: ["scheme_name", "scheme_name"],
      key: "scheme",

      render: (_: string, record: any) => (
        <NoWrap text={record.scheme_name?.scheme_name}></NoWrap>
      ),
    },
    {
      title: "Cover Amount",
      dataIndex: "claim_amount",
      key: "5",
      width: "1000",
      filteredValue: [],
      render: (text: string, record: any) => {
        const amount = Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 0,
        }).format(record.claim_amount);
        return <NoWrap text={amount}></NoWrap>;
      },
    },
    {
      title: "Principal",
      dataIndex: ["principal_member", "full_names"],
      filteredValue: [],
      key: "6",
      render: (_: string, record: any) => {
        const { full_names, surname } = record.principal_member;
        const text = `${full_names} ${surname}`.trim();
        return <NoWrap text={text}></NoWrap>;
      },
    },

    {
      title: "Date of Event",
      dataIndex: "deceased_date_of_death",
      key: "7",
      filteredValue: [],
      render: (_: string, record: any) => (
        <NoWrap text={record.deceased_date_of_death}></NoWrap>
      ),
    },
    {
      title: "Claim Status",
      dataIndex: "progress_status",
      key: "claim_status",
      render: (_: string, record: any) => {
        return <NoWrap text={record.progress_status}></NoWrap>;
      },
    },
    {
      title: "Claim Submitted Date",
      dataIndex: "date_claim_submission",
      width: 150,
      key: "9",
      render: (date: any) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Capture Date",
      dataIndex: "created_date",
      width: 150,
      filteredValue: [],
      key: "9",
      render: (date: any) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Last Update",
      dataIndex: "modified_date",
      key: "10",
      width: 120,
      render: (date: any) => new Date(date).toLocaleDateString(),
      // sorter: (a, b) => {
      //   let dateA = new Date(a?.modified_date);
      //   let dateB = new Date(b?.modified_date);
      //   return dateA.getTime() - dateB.getTime();
      // },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      render: (date: any) => {
        const text = date && new Date(date).toLocaleDateString();
        return <NoWrap text={text}></NoWrap>;
      },
      key: "11",
      filteredValue: [],
    },
  ];

  return (
    <Modal
      width={1500}
      title={
        <section
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              flex: 1,
            }}
          >
            <Text>
              <h4>
                {Props.statistic_output === "today"
                  ? "Today Stats"
                  : "Historic Stats"}
              </h4>
            </Text>
            <Text>
              <h5 style={{ display: "inline", fontWeight: "lighter" }}>
                Review Standing:
              </h5>{" "}
              <h5 style={{ display: "inline" }}>{Props.review_standing}</h5>
            </Text>
          </div>
          <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <Button type="primary" onClick={downloadData}>
              <Space>Download CSV</Space>
            </Button>
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <p>{"  "}</p>
          </div>
        </section>
      }
      open={Props.isModalOpen}
      onOk={Props.onClose}
      onCancel={Props.onClose}
    >
      <div>
        <Table
          tableLayout="auto"
          /* onChange={(
            config: TablePaginationConfig,
            filters: Record<string, any>
          ) => {
            setPage_size(config.pageSize!);
            setCurrentPage(config.current!);

            setSelectedAssessor(filters["assessor"] ?? []);
            setSelectedInsurer(filters["insurer"] ?? []);
            setSelectedScheme(filters["scheme"] ?? []);
            setSelectedClaimStatus(filters["claim_status"] ?? []);
            setSelectedReviewStatus(filters["review_standing"] ?? []);
          }} */
          columns={columns}
          dataSource={tableData}
          scroll={{ x: 1450, y: windowHeight - 320 }}
          rowKey={(record) => record.id}
          pagination={{
            total: Number(Props.selectedCount),
            showTotal: (total) => `${total} rows`,
            defaultPageSize: 5,
          }}
          onChange={(
            config: TablePaginationConfig,
            filters: Record<string, any>
          ) => {
            setPage_size(config.pageSize!);
            setCurrentPage(config.current!);
            setSelectedAssessor(filters["assessor"] ?? []);
          }}
        />
      </div>
    </Modal>
  );
};
export default InsightsModal;
