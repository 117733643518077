import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import store from "./redux/store";

if (!window.location.hostname.match(/localhost/))
  Sentry.init({
    dsn: "https://ac0d78feaf6ca4fabcda17052ad517e7@o4506240378929152.ingest.sentry.io/4506240404553728",
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "01aaad",
        },
        components: {
          Layout: {
            headerBg: "#008484",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);
