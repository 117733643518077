import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  Form,
  Flex,
  TablePaginationConfig,
} from "antd";
import instance from "../../axios/axios-instance";
import { setSuccessMessage } from "../../redux/actions/AppMessageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

interface MemberKeyword {
  id: string;
  beneficiary_key_words: string;
  child_key_words: string;
  principal_member_key_words: string;
  spouse_key_words: string;
  inserted_by: number;
  updated_by: number;
}

function MemberKeywordsTable() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editMemberKeywordForm] = Form.useForm();
  const [selectedMemberKeyword, setSelectedMemberKeyword] =
    useState<MemberKeyword>();
  const [query, setQuery] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const fetchMemberKeywords = async () => {
    const response = await instance.get(
      "/api/administration/member-types-keywords"
    );
    setData(response?.data);
    setFilteredData(response?.data);
  };

  useEffect(() => {
    fetchMemberKeywords();
  }, []);

  const performSearch = () => {
    const filteredData = data.filter(
      (memberKeyword: MemberKeyword) =>
        memberKeyword.beneficiary_key_words
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        memberKeyword.child_key_words
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        memberKeyword.principal_member_key_words
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        memberKeyword.spouse_key_words
          .toLowerCase()
          .includes(query.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const resetSearch = () => {
    setQuery("");
    setFilteredData(data);
  };

  const createNewMemberKeyword = async () => {
    const response = await instance.post(
      "/api/administration/member-types-keywords",
      {
        ...editMemberKeywordForm.getFieldsValue(),
      }
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Member Keyword added"));
      fetchMemberKeywords();
    }
    setCreateModalOpen(false);
  };

  const editMemberKeyword = async () => {
    const response = await instance.put(
      `/api/administration/member-types-keywords?id=${selectedMemberKeyword?.id}`,
      {
        ...editMemberKeywordForm.getFieldsValue(),
      }
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(setSuccessMessage("Member Keyword Updated"));
      fetchMemberKeywords();
    }
    setEditModalOpen(false);
  };

  const handleDetails = (record: any) => {
    editMemberKeywordForm.setFieldsValue({
      beneficiary_key_words: record.beneficiary_key_words,
      child_key_words: record.child_key_words,
      principal_member_key_words: record.principal_member_key_words,
      spouse_key_words: record.spouse_key_words,
    });
    setSelectedMemberKeyword(record);
    setEditModalOpen(true);
  };

  function FormIsValid() {
    const data = editMemberKeywordForm.getFieldsValue();
    return (
      data.beneficiary_key_words &&
      data.child_key_words &&
      data.principal_member_key_words &&
      data.spouse_key_words
    );
  }

  const columns: any[] = [
    {
      title: "Beneficiary Keywords",
      dataIndex: "beneficiary_key_words",
      key: "beneficiary_key_words",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Child Keywords",
      dataIndex: "child_key_words",
      key: "child_key_words",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Principal Member Keywords",
      dataIndex: "principal_member_key_words",
      key: "principal_member_key_words",
      width: 150,
      editable: true,
      fixed: "left",
    },
    {
      title: "Spouse Keywords",
      dataIndex: "spouse_key_words",
      key: "spouse_key_words",
      width: 150,
      editable: true,
    },
    // {
    //   title: "Created By",
    //   dataIndex: "inserted_by",
    //   key: "inserted_by",
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    //   width: 150,
    //   editable: true,
    // },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      fixed: "right",
      render: (record: any) => (
        <Space size="small" style={{ minWidth: "112px" }}>
          <Button onClick={() => handleDetails(record)}>Details</Button>
        </Space>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [page_size, setPage_size] = useState(4);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  console.log("member keyword form", editMemberKeywordForm.getFieldsValue());

  return (
    <Space direction="vertical">
      <Flex justify="space-between">
        <Space>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value.trim())}
            placeholder="Enter search to begin"
          ></Input>
          <Button disabled={!query} onClick={() => performSearch()}>
            Search
          </Button>
          <Button
            disabled={!query.trim() || filteredData?.length === 0}
            onClick={() => resetSearch()}
          >
            Reset
          </Button>
        </Space>
        <Space align="end" direction="horizontal">
          <Button
            type="primary"
            onClick={() => {
              editMemberKeywordForm.resetFields();
              setCreateModalOpen(true);
            }}
          >
            Add Member Type Category
          </Button>
        </Space>
      </Flex>
      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        scroll={{ y: windowHeight - 420 }}
        style={{ width: 1100 }}
        onChange={(config: TablePaginationConfig) => {
          setPage_size(config.pageSize!);
          setCurrentPage(config.current!);
        }}
        pagination={{
          total: filteredData?.length,
          showTotal: (total) => `${total} rows`,
          pageSize: page_size,
          current: currentPage,
        }}
      />
      <Modal
        title="Create a Member Types Category"
        open={isCreateModalOpen}
        onOk={createNewMemberKeyword}
        okText="Save"
        /* okButtonProps={{ disabled: !FormIsValid() }} */
        onCancel={() => {
          setCreateModalOpen(false);
        }}
      >
        <Form layout="vertical" form={editMemberKeywordForm}>
          <Form.Item label="Beneficiary Keywords" name="beneficiary_key_words">
            <Input />
          </Form.Item>
          <Form.Item label="Child Keywords" name="child_key_words">
            <Input />
          </Form.Item>
          <Form.Item
            label="Principal Member Keywords"
            name="principal_member_key_words"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Spouse Keywords" name="spouse_key_words">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Member Types Categories"
        open={isEditModalOpen}
        onOk={editMemberKeyword}
        okText="Update"
        /* okButtonProps={{ disabled: !FormIsValid() }} */
        onCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <Form layout="vertical" form={editMemberKeywordForm}>
          <Form.Item label="Beneficiary Keywords" name="beneficiary_key_words">
            <Input />
          </Form.Item>
          <Form.Item label="Child Keywords" name="child_key_words">
            <Input />
          </Form.Item>
          <Form.Item
            label="Principal Member Keywords"
            name="principal_member_key_words"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Spouse Keywords" name="spouse_key_words">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
}

export default MemberKeywordsTable;
