import React from "react";
import Header from "../Dashboard/header";
import ReviewClaimsTable from './components/ReviewClaimsTable';



const ReviewClaims: React.FC<any> = () => {
  return (
    <Header title="Review Claims">
        <ReviewClaimsTable />
    </Header>
  );
};

export default ReviewClaims;
