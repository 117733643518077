import { Button, Input, Modal, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import instance from "../../axios/axios-instance";

const MySwal = withReactContent(Swal);

interface DataItem {
  id: React.Key; // Assuming 'id' is of type React.Key
  headerAssessor: string;
  deceased_name: string;
  deceased_surname: string;
  deceased_id_number: string;
  is_held: boolean;
}

const HeldClaimsTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [page_number, setPage_number] = useState<number>(1);
  const [page_size, setPage_size] = useState<number>(10);
  const [totalData, setTotalData] = useState<any>(0);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);

  const navigate = useNavigate();
  const storedAuthTokens = localStorage.getItem("authTokens");
  const authTokens = storedAuthTokens ? JSON.parse(storedAuthTokens) : null;

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    let timeoutId: any;

    // Define a function to update the window height with a delay
    const updateWindowHeight = () => {
      timeoutId = setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 300);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateWindowHeight);

    // Remove the event listener and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowHeight);
      clearTimeout(timeoutId); // Clear any pending timeouts
    };
  }, []);

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const [selectedRowsData, setSelectedRowsData] = useState<
    { id: React.Key; isActioned: boolean }[]
  >([]);
  const [showButton, setShowButton] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "Assessor",
      dataIndex: ["assessor", "name"],
      key: "headerAssessor",
      render: (text: string, record: any) => {
        if (record.assessor === null) {
          return "No Assessor";
        }
        return `${record.assessor?.name} ${record.assessor?.surname}`;
      },
    },
    {
      title: "Deceased Name",
      dataIndex: "deceased_name",
      key: "deceasedName",
      render: (text: string, record: any) => {
        const fullName = `${record.deceased_name} ${record.deceased_surname}`;
        return fullName;
      },
    },
    {
      title: "ID/Passport",
      dataIndex: "deceased_id_number",
      key: "idPassport",
      render: (text: string, record: any) => {
        if (record.deceased_id_number) {
          return record.deceased_id_number;
        } else if (record.passport_number) {
          return record.passport_number;
        }
      },
    },
    {
      title: "Principal Name",
      dataIndex: ["principal_member", "full_names"],
      key: "principalName",
      render: (text: string, record: any) => {
        if (record.principal_member === null) {
          return "No Principal";
        } else if (record.principal_member) {
          if (record.principal_member.id_number) {
            return `${record.principal_member.full_names} ${record.principal_member.surname} (${record.principal_member.id_number})`;
          } else if (record.principal_member.passport) {
            return `${record.principal_member.full_names} ${record.principal_member.surname} (${record.principal_member.passport})`;
          }
        }
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policy_number",
      key: "policyNumber",
    },
    {
      title: "Scheme",
      dataIndex: ["scheme_name", "scheme_name"],
      key: "claimNumber",
    },
    {
      title: "Cover Amount (R)",
      dataIndex: "claim_amount",
      key: "coverAmount",
      render: (text: string, record: any) => {
        const amount = Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 0,
        }).format(record.claim_amount);
        return amount;
      },
    },
    {
      title: "Progress Status",
      dataIndex: "progress_status",
      key: "reviewStatus",
    },
    {
      title: "Bank Name",
      dataIndex: ["bank_name", "bank"],
      key: "bankName",
    },
    {
      title: "Time Last Modified",
      dataIndex: "modified_date",
      key: "timeLastModified",
      render: (text: string, record: any) => {
        if (record.modified_date === null) {
          return "";
        }
        const date = new Date(record.modified_date);

        const formattedDate = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        return `${formattedDate} By ${record.updated_by?.name} ${record.updated_by?.surname}`;
      },
    },
    {
      title: "Actions",
      dataIndex: "is_held",
      key: "heldOption",
      fixed: "right",
      render: (text: boolean, record: DataItem) => (
        <Space size="middle">
          {record.is_held === true ? (
            <Button
              type="link"
              onClick={() => handleHoldRelease(record.id, false)}
            >
              Release
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => handleHoldRelease(record.id, true)}
            >
              Hold
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const handleReview = () => {
    navigate("/review-claims");
  };

  const onSelectChange = async (newSelectedRowKeys: React.Key[]) => {
    setSelectedKeys(newSelectedRowKeys);

    const newlySelectedRowKeys = newSelectedRowKeys.filter(
      (key) => !selectedRowsData.some((data) => data.id === key)
    );

    // Identify deselected rows
    const deselectedRowKeys = selectedRowsData.filter(
      (data) => !newSelectedRowKeys.includes(data.id)
    );

    // Update selectedRowsData for newly selected rows
    const updatedSelectedRowsData = [
      ...selectedRowsData,
      ...newlySelectedRowKeys.map((key) => ({ id: key, isActioned: true })),
    ];

    // Update selectedRowsData for deselected rows
    const updatedSelectedRowsDataAfterDeselection =
      updatedSelectedRowsData.filter(
        (data) =>
          !deselectedRowKeys.some(
            (deselectedData) => deselectedData.id === data.id
          )
      );

    setSelectedRowsData(updatedSelectedRowsDataAfterDeselection);
    setShowButton(updatedSelectedRowsDataAfterDeselection.length > 0);
  };
  const handleHoldRelease = async (id: React.Key, shouldHold: boolean) => {
    try {
      const response = await instance.post(`api/claims/hold/${id}/`, {
        is_held: shouldHold,
        id: id,
      });
      // Assuming your API call is successful, update the state accordingly
      const updatedData = data.map((item) =>
        item.id === id ? { ...item, is_held: shouldHold } : item
      );
      setData(updatedData);

      if (response.data.message.includes("now on hold")) {
        MySwal.fire({
          position: "top",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (response.data.message.includes("has been released")) {
        MySwal.fire({
          position: "top",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
      window.location.reload();
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleSelected = async () => {
    const transformedData = selectedKeys.map((id) => ({
      id,
      is_actioned_for_payment: true,
    }));

    // const filteredData = data.filter((item: any) => {
    //   if (selectedKeys.includes(item.id)) {
    //     return false;
    //   }
    //   return true;
    // });

    // setData(filteredData);

    try {
      const response = await instance.post(
        "api/claims/download-report/",
        transformedData,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today}-CLAIMS-BULK_REPORT.csv`; // Set the desired filename
      anchor.target = "_blank";

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (error: any) {
      console.log(error.message);
    }
    window.location.reload();
  };

  const handleSelectAllDownload = async () => {
    try {
      const response = await instance.get(
        // "api/claims/download-report-all/",
        "api/claims/download-report-all-held/",
        // is_held status
        // is_actioned_payment status
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a URL for the Blob object
      const blobURL = window.URL.createObjectURL(blob);
      const today = new Date().toLocaleDateString("en-ZA").split("/").join("-");

      // Create a temporary anchor element to trigger the download
      const anchor = document.createElement("a");
      anchor.href = blobURL;
      anchor.download = `${today}-CLAIMS-BULK_REPORT.csv`; // Set the desired filename
      anchor.target = "_blank";

      // Programmatically click the anchor element to initiate download
      anchor.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(blobURL);
    } catch (error: any) {
      console.log(error.message);
    }
    window.location.reload();
  };

  const onSelectAll = (
    selected: boolean | ((prevState: boolean) => boolean),
    selectedRows: any,
    changeRows: any
  ) => {
    if (selected) {
      setIsAllSelected(selected);
    } else {
      setIsAllSelected(selected);
    }
  };

  console.log("s all status: ", isAllSelected);

  const rowSelection = {
    selectedRowKeys: selectedRowsData.map((data) => data.id),
    onChange: onSelectChange,
    getCheckboxProps: (record: DataItem) => ({
      disabled: record.is_held === false, // Disable the checkbox if the item is held
    }),
    onSelectAll,
  };

  // const onSearch = (event: {
  //   target: { value: React.SetStateAction<string> };
  // }) => {
  //   const searchValue = event.target.value.toString().toLowerCase();
  //   const filtered = data.filter((item: any) => {
  //     return Object.values(item).some((fieldValue) =>
  //       String(fieldValue).toLowerCase().includes(searchValue)
  //     );
  //   });

  //   // setFilteredData(filtered); // Update the filteredData state with the filtered results
  //   // setSearhTerm(event.target.value);
  // };
  // const onSearchChange = async (searchText: string) => {
  //   try {
  //     let response;

  //     if (searchText === "") {
  //       response = await instance.get("api/claims/awaiting/?format=json");
  //     } else {
  //       response = await instance.get(
  //         `api/claims/awaiting/?format=json&search=${searchText}`
  //       );
  //     }

  //     setData(response.data.results);
  //   } catch (error: any) {
  //     console.log(error.message);
  //   }
  // };

  useEffect(() => {
    const params = { search, page_size, page: page_number };

    const query = Object.entries(params)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    instance
      .get(`api/claims/held-claims/?format=json&${query}`)
      .then((response) => {
        const data = response.data.data;
        setData(data);
        setTotalData(response.data.size);
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
      });
  }, [search, page_size, page_number]);

  return (
    <div>
      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Input.Search
            onSearch={(value) => {
              setSearch(value); // Call the onSearchChange function with the search value
            }}
            onChange={(e: any) => setSearchInput(e.target.value)}
            className="search-input"
            style={{ width: 300, marginBottom: 16, marginTop: 16 }}
            placeholder="Search ..."
            value={searchInput}
          />
        </div>

        {showButton && (
          <div>
            <button
              type="button"
              className="btn btn-outline-primary mb-3"
              onClick={() => {
                if (isAllSelected) {
                  setSelectModalOpen(true);
                } else {
                  handleSelected();
                }
              }}
            >
              Generate Bulk Report
            </button>
          </div>
        )}
      </div>

      <Table
        rowSelection={rowSelection}
        rowKey="id"
        columns={columns}
        dataSource={data}
        onChange={(config: TablePaginationConfig) => {
          setPage_size(config.pageSize!);
          setPage_number(config.current!);
        }}
        scroll={{ x: 1300, y: windowHeight - 320 }}
        pagination={{
          total: Number(totalData),
          showTotal: (total) => `${total} rows`,
        }}
      />

      <Modal
        title="Confirm Download"
        open={selectModalOpen}
        onOk={() => {
          setSelectModalOpen(false);
          handleSelectAllDownload();
        }}
        onCancel={() => setSelectModalOpen(false)}
      >
        <p>
          You are about to download all claims in this lobby. <br />
          Click <button style={{backgroundColor: 'skyblue', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer'}}>OK</button>  
           to proceed with the download.
        </p>

      </Modal>
    </div>
  );
};
export default HeldClaimsTable;
