import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, InputNumber, Row, Col } from 'antd';
import instance from '../../axios/axios-instance';
import moment from 'moment';

const ClaimDetailsModal = ({ visible, onCreate, onCancel, claimData }:
    {
        visible: boolean; onCreate: any; onCancel: any; claimData: any;
    }) => {
    const [form] = Form.useForm();
    const [claimStatusOptions, setClaimStatusOptions] = useState([]);
    const [bankDetailsOptions, setBankDetailsOptions] = useState([]);

    useEffect(() => {
        form.resetFields()
        if (claimData) {
            form.setFieldsValue({
                ...claimData
            });
        }
    }, [claimData, form, visible]);

    useEffect(() => {
        // Fetch claim status options
        const fetchClaimStatusOptions = async () => {
            const response = await instance.get("api/claims/claim-status/?format=json");
            setClaimStatusOptions(response.data);
        };
        fetchClaimStatusOptions();
    }, []);

    const handleSearchBankDetails = async (searchText: any) => {
        const response = await instance.get(`/api/claims/search-bank-details?search=${searchText}`);
        setBankDetailsOptions(response.data);
    };

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                onCreate(values);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validation failed:', info);
            })
            .finally(() => onCancel());
    };

    return (
        <Modal
            open={visible}
            title="Add or Edit Claim Details"
            okText="Submit"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            width="45%"
            onOk={handleOk}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="claim_amount" label="Claim Amount">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item name="gross_premium" label="Gross Premium">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="claim_status" label="Claim Status">
                            <Select options={claimStatusOptions} />
                        </Form.Item>
                        <Form.Item name="bank_details" label="Bank Details">
                            <Select
                                showSearch
                                onSearch={handleSearchBankDetails}
                                options={bankDetailsOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="date_claim_submitted" label="Date Claim Submitted">
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                        <Form.Item name="document_url" label="Document URL">
                            <Input />
                        </Form.Item>
                        <Form.Item name="notes" label="Notes">
                            <Input.TextArea rows={4} />
                        </Form.Item>

                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ClaimDetailsModal;
