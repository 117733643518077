import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  InputNumber,
  Col,
  Row,
} from "antd";
import saIdParser from 'south-african-id-parser';
import { setErrorMessage } from "../../redux/actions/AppMessageActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";

const { Option } = Select;
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const Amendments = ({
  ammendmentsForm,
  isAmmendmentsOpen,
  setAmmendmentsOpen,
  handleAmmendmentsSubmit,
  policySelectOptions,
  memberSelectOptions
}: any) => {

  const dispatch = useDispatch<AppDispatch>();

  const [rsaId, setRsaId] = useState("");
  const [rsaIdValid, setRsaIdValid] = useState(false);

  const handleRsaIdChange = (event: { target: { value: any; }; }) => {
    const id = event.target.value;
    setRsaId(id);
    if (id.length === 13 && saIdParser.validate(id)) {
      setRsaIdValid(true);
    } else {
      setRsaIdValid(false);
    }
  };

  let rsaIdStatus = "";
  let rsaIdHelp = "";

  if (rsaId.length !== 13) {
    rsaIdStatus = "error";
    rsaIdHelp = "RSA ID must be 13 digits";
  } else if (!saIdParser.validate(rsaId)) {
    rsaIdStatus = "error";
    rsaIdHelp = "RSA ID is not valid";
  } else {
    rsaIdStatus = "success";
    rsaIdHelp = "RSA ID is valid";
  }

  const handleRsaIdInput = (event: any) => {
    // Remove any non-integer characters from the input value
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  };

  const handleOk = () => {
    if (rsaIdValid || ammendmentsForm.getFieldValue('passport_number')) {
      ammendmentsForm
        .validateFields()
        .then((values: any) => {
          ammendmentsForm.resetFields();
          console.log(values);
          handleAmmendmentsSubmit(values);
          setAmmendmentsOpen(false);
        })
        .catch((info: any) => {
          console.log("Validate Failed:", info);
        });
    } else {
      dispatch(setErrorMessage("Please enter a valid RSA ID or Passport Number"))
    }
  };

  const handleCancel = () => {
    setAmmendmentsOpen(false);
  };

  const countryOptions = [
    { label: 'South Africa', value: 'ZA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Eswatini', value: 'SZ' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' },
  ];

  return (
    <Modal
      title="Amendments"
      open={isAmmendmentsOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width="65%"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
    >
      <Form form={ammendmentsForm} layout="vertical" name="form_in_modal">
        <Row gutter={16}>
          <Col span={12}>
            <h2>Person Details</h2>
            <Form.Item
              name="first_names"
              label="First Names"
              rules={[
                { required: true, message: "Please input the first names!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="surname"
              label="Surname"
              rules={[
                { required: true, message: "Please input the surname!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="rsa_id_number"
              label="RSA ID Number"
              validateStatus={rsaIdStatus as any}
              help={rsaIdHelp}
            >
              <Input onInput={handleRsaIdInput} onChange={handleRsaIdChange} />
            </Form.Item>
            <Form.Item
              name="passport_number"
              label="Passport Number"
            >
              <Input />
            </Form.Item>
            {!rsaIdValid && (
              <Form.Item
                name="date_of_birth"
                label="Date of Birth"
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
            )}
            <Form.Item
              name="cellphone_number"
              label="Cellphone Number"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email_address"
              label="Email Address"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not a valid email!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="gender" label="Gender">
              <Select options={[{ value: "MALE", label: "MALE" }, { value: "FEMALE", label: "FEMALE" }]} />
            </Form.Item>
            <Form.Item
              name="residential_address"
              label="Residential Address"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="nationality"
              label="Nationality"
            >
              <Select options={countryOptions} placeholder="Please select..." />
            </Form.Item>
            {!rsaIdValid && (
              <Form.Item
                name="citizenship"
                label="Citizenship"
              >
                <Select options={countryOptions} placeholder="Please select..." />
              </Form.Item>
            )}
            <Form.Item
              name="country_of_residence"
              label="Country of Residence"
            >
              <Select options={countryOptions} placeholder="Please select..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h2>Policy Details</h2>
            <Form.Item
              name="policy_number"
              label="Policy Number"
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="policy_status"
              label="Policy Status"
            >
              <Select options={policySelectOptions} showSearch />
            </Form.Item>
            <Form.Item
              name="policy_start_date"
              label="Policy Start Date"
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item
              name="member_type"
              label="Member Type"
            >
              <Select options={memberSelectOptions} showSearch />
            </Form.Item>
            <Form.Item
              name="cover_amount"
              label="Cover Amount"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="scheme_premium_amount"
              label="Scheme Premium Amount"
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Amendments;
