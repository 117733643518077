import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import saIdParser from "south-african-id-parser";
import instance from "../../axios/axios-instance";
import { setDataError } from "../../redux/actions/DataErrorActions";
import { setFormData } from "../../redux/actions/DeceasedDetailsAction";
import { IDeceasedDetails } from "../../redux/types/DeceasedDetails";

interface DeceasedProps {
  isInput: boolean | false;
  deceasedSurname: string;
  deceasedName: string;
  maritalStatus: string;
  idType: string;
  deceasedGender: string;
  deceasedNationality: string;
  dateOfDeath: string;
  residentialAddress: string;
  deceasedCaused: string;
  postalAddress: string;
  inceptionDate: string;
  dateClaimSubmission: string;
  setDeceasedSurname: React.Dispatch<React.SetStateAction<string>>;
  setDeceasedName: React.Dispatch<React.SetStateAction<string>>;
  setMaritalStatus: React.Dispatch<React.SetStateAction<string>>;
  setIdType: React.Dispatch<React.SetStateAction<string>>;
  setDeceasedGender: React.Dispatch<React.SetStateAction<string>>;
  setDeceasedNationality: React.Dispatch<React.SetStateAction<string>>;
  setDateOfDeath: React.Dispatch<React.SetStateAction<string>>;
  setResidentialAddress: React.Dispatch<React.SetStateAction<string>>;
  setDeceasedCause: React.Dispatch<React.SetStateAction<string>>;
  setPostalAddress: React.Dispatch<React.SetStateAction<string>>;
}
interface Countries {
  code: string;
  name: string;
}

const DeceasedDetails: React.FC<DeceasedProps> = ({
  isInput,
  deceasedSurname,
  deceasedName,
  maritalStatus,
  idType,
  deceasedGender,
  deceasedNationality,
  dateOfDeath,
  residentialAddress,
  deceasedCaused,
  postalAddress,
  inceptionDate,
  dateClaimSubmission,
  setDeceasedSurname,
  setDeceasedName,
  setMaritalStatus,
  setIdType,
  setDeceasedGender,
  setDeceasedNationality,
  setDateOfDeath,
  setResidentialAddress,
  setDeceasedCause,
  setPostalAddress,
}) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState<Countries[]>([]);
  const [idNumber, setIdNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [ageAtDeath, setAgeAtDeath] = useState("");
  const [deceasedOccupation, setDeceasedOccupation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dataError = useSelector((state: any) => state.dataError);

  const [deceasedDetails, setDeceasedDetails] = useState({
    idNumber,
    passportNumber,
    licenseNumber,
    dateOfBirth,
    ageAtDeath,
    deceasedOccupation,
    errorMessage,
    deceasedSurname,
    deceasedName,
    maritalStatus,
    idType,
    deceasedGender,
    deceasedNationality,
    dateOfDeath,
    residentialAddress,
    deceasedCaused,
    postalAddress,
  });

  useEffect(() => {
    setDeceasedDetails({
      idNumber,
      passportNumber,
      licenseNumber,
      dateOfBirth,
      ageAtDeath,
      deceasedOccupation,
      errorMessage,
      deceasedSurname,
      deceasedName,
      maritalStatus,
      idType,
      deceasedGender,
      deceasedNationality,
      dateOfDeath,
      residentialAddress,
      deceasedCaused,
      postalAddress,
    });
  }, [
    idNumber,
    passportNumber,
    licenseNumber,
    dateOfBirth,
    ageAtDeath,
    deceasedOccupation,
    errorMessage,
    deceasedSurname,
    deceasedName,
    maritalStatus,
    idType,
    deceasedGender,
    deceasedNationality,
    dateOfDeath,
    residentialAddress,
    deceasedCaused,
    postalAddress,
  ]);

  useEffect(() => {
    if (idNumber.trim().length === 13) {
      const isValid = saIdParser.validate(idNumber);
      if (!isValid) {
        dispatch(
          setDataError([
            ...dataError,
            { idNumber: "Deceased Details ID number is not valid" },
          ])
        );
      }
    }
  }, [idNumber, dispatch]);

  const handleDeceasedSurnameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeceasedSurname(event.target.value);
  };

  const handleDeceasedNameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeceasedName(event.target.value);
  };

  const handleMaritalStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMaritalStatus(event.target.value);
  };

  const handleDateOfDeathChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDateOfDeath(event.target.value);
  };

  const handleIdTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setIdType(event.target.value);
  };

  const handleIdNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const value = event.target.value;
    setIdNumber(event.target.value);

    if (!value.toString().trim().length) {
      setErrorMessage("Required");
      return;
    }
    if (value.length !== 13) {
      setErrorMessage("ID Number must be exactly 13 characters.");
    } else {
      const validateId: any = event.target.value;
      if (!saIdParser.validate(validateId)) {
        setErrorMessage("ID Number is not valid");
      } else {
        setErrorMessage("");
      }
    }
  };

  const handlePassportNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPassportNumber(event.target.value);
  };

  const handleLicenseNumberChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLicenseNumber(event.target.value);
  };

  const handleDeceasedGenderChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeceasedGender(event.target.value);
  };

  const handleDeceasedCause = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeceasedCause(event.target.value);
  };

  const handleDeceasedNationalityChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeceasedNationality(event.target.value);
  };

  const handleDateOfBirthChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDateOfBirth(event.target.value);
  };

  const handleAgeAtDeathChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAgeAtDeath(event.target.value);
  };

  const handleDeceasedOccupationChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeceasedOccupation(event.target.value);
  };

  const handleResidentialAddressChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setResidentialAddress(event.target.value);
  };

  const handlePostalAddressChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPostalAddress(event.target.value);
  };
  const getCountries = async () => {
    try {
      const response = await instance.get(`/api/claims/countries/`);
      setCountries(response.data.countries);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (isInput) {
      setDeceasedSurname("");
      setDeceasedName("");
      setMaritalStatus("");
      setDateOfDeath("");
      setIdType("");
      setIdNumber("");
      setPassportNumber("");
      setLicenseNumber("");
      setDeceasedGender("");
      setDeceasedCause("");
      setDeceasedNationality("");
      setDateOfBirth("");
      setAgeAtDeath("");
      setDeceasedOccupation("");
      setResidentialAddress("");
      setPostalAddress("");
    }
  }, [isInput]);

  useEffect(() => {
    const model: IDeceasedDetails = {
      deceasedSurname: deceasedSurname,
      deceasedName: deceasedName,
      maritalStatus: maritalStatus,
      deceasedDateOfDeath: dateOfDeath,
      deceasedIdType: idType,
      deceasedIdNumber: idNumber,
      deceasedPassportNumber: passportNumber,
      deceasedDlNumber: "",
      deceasedGender: deceasedGender,
      deceasedNationality: deceasedNationality,
      deceasedDateOfBirth: dateOfBirth,
      deceasedHomeAddress: deceasedOccupation,
      deceasedResidentialCountry: residentialAddress,
      deceasedBirthCountry: postalAddress,
      deceasedCauseOfDeath: deceasedCaused,
    };

    dispatch(setFormData(model));
  }, [
    deceasedSurname,
    deceasedName,
    maritalStatus,
    postalAddress,
    deceasedCaused,
    residentialAddress,
    deceasedOccupation,
    dateOfBirth,
    dateOfDeath,
    idType,
    idNumber,
    passportNumber,
    licenseNumber,
    deceasedGender,
    deceasedNationality,
    dispatch,
  ]);

  useEffect(() => {
    getCountries();
  }, []);

  // Get the current date
  const today = new Date();
  const todayDate = today.toISOString().split("T")[0];

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3 flex" id="frontdeceased_surname">
          <div className="form-group">
            <label htmlFor="deceased_surname">
              Deceased Surname <span className="text-danger">*Required</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="deceased_surname"
              required
              placeholder="Deceased Surname"
              value={deceasedSurname}
              onChange={handleDeceasedSurnameChange}
            />
          </div>
        </div>

        <div className="col-md-4 mb-3 flex" id="frontdeceased_name">
          <div className="form-group">
            <label htmlFor="deceased_name">
              Deceased Name(s) <span className="text-danger">*Required</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="deceased_name"
              required
              placeholder="Deceased Name(s)"
              value={deceasedName}
              onChange={handleDeceasedNameChange}
            />
          </div>
        </div>

        <div className="col-md-4 mb-3">
          <div className="form-group">
            <label htmlFor="marital_status">
              Marital Status <span className="text-danger">*Required</span>
            </label>
            <select
              className="form-select"
              id="marital_status"
              value={maritalStatus}
              required
              onChange={handleMaritalStatusChange}
            >
              <option value="">---------</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widow">Widow</option>
              <option value="Widower">Widower</option>
            </select>
          </div>
        </div>

        {idType !== "1" && (
          <div className="col-md-4 mb-3 flex">
            <div className="form-group">
              <label htmlFor="deceased_date_of_birth">
                Deceased Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                id="deceased_date_of_birth"
                required
                placeholder="Deceased Date of Birth"
                value={dateOfBirth}
                onChange={handleDateOfBirthChange}
              />
            </div>
            <span className="text-danger">*Required</span>
          </div>
        )}

        <div className="col-md-4 mb-3" id="frontdeceased_ID">
          <div className="form-group">
            <label htmlFor="deceased_id_type">Identification Type</label>
            <select
              className="form-select"
              id="deceased_id_type"
              value={idType}
              onChange={handleIdTypeChange}
            >
              <option value="">---------</option>
              <option value="idNumber">ID Number</option>
              <option value="passportNumber">Passport Number</option>
              <option value="still_born">Still Born</option>
            </select>
            <span className="text-danger">*Required</span>
          </div>
        </div>

        {idType === "idNumber" && (
          <div className="col-md-4 mb-3 flex" id="frontdeceased_ID_number">
            <div className="form-group">
              <label htmlFor="deceased_id_number">ID Number</label>
              <input
                type="number"
                className="form-control"
                id="deceased_id_number"
                placeholder="ID Number"
                maxLength={13}
                value={idNumber}
                onChange={handleIdNumberChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div>
        )}

        {idType === "passportNumber" && (
          <div className="col-md-4 mb-3 flex" id="frontdeceased_passport">
            <div className="form-group">
              <label htmlFor="deceased_passport_number">Passport Number</label>
              <input
                type="text"
                className="form-control"
                id="deceased_passport_number"
                placeholder="Passport Number"
                value={passportNumber}
                onChange={handlePassportNumberChange}
              />
            </div>
            <span className="text-danger">*Required</span>
          </div>
        )}

        {idType === "licenseNumber" && (
          <div className="col-md-4 mb-3 flex" id="frontdeceased_dl">
            <div className="form-group">
              <label htmlFor="deceased_dl_number">
                Driver's License Number
              </label>
              <input
                type="text"
                className="form-control"
                id="deceased_dl_number"
                placeholder="Driver's License Number"
                value={licenseNumber}
                onChange={handleLicenseNumberChange}
              />
            </div>
          </div>
        )}

        {idType !== "idNumber" && (
          <>
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label htmlFor="deceased_gender">
                  Deceased Gender <span className="text-danger">*Required</span>
                </label>
                <select
                  className="form-select"
                  id="deceased_gender"
                  value={deceasedGender}
                  required={idType !== "idNumber"}
                  onChange={handleDeceasedGenderChange}
                >
                  <option value="">---------</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label htmlFor="deceased_nationality">
                  Deceased Nationality{" "}
                  <span className="text-danger">*Required</span>
                </label>
                <select
                  className="form-select"
                  id="deceased_nationality"
                  value={deceasedNationality}
                  required={idType !== "idNumber"}
                  onChange={handleDeceasedNationalityChange}
                >
                  <option value="" disabled>
                    Please select...
                  </option>
                  <option value="ZA">South Africa</option>
                  <option value="BW">Botswana</option>
                  <option value="LS">Lesotho</option>
                  <option value="MW">Malawi</option>
                  <option value="MZ">Mozambique</option>
                  <option value="NA">Namibia</option>
                  <option value="SZ">Eswatini</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
              </div>
            </div>
          </>
        )}

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="deceased_date_of_death">
              Date of Death <span className="text-danger">*Required</span>
            </label>
            <input
              type="date"
              className="form-control"
              id="deceased_date_of_death"
              required
              placeholder="Date of Death"
              max={todayDate}
              min={dateOfBirth}
              value={dateOfDeath}
              onChange={handleDateOfDeathChange}
            />
          </div>
        </div>

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="deceased_residential_address">
              Residential Country <span className="text-danger">*Required</span>
            </label>
            <select
              className="form-select"
              id="deceased_residential_address"
              required
              placeholder="Deceased Residential Address"
              value={residentialAddress}
              onChange={handleResidentialAddressChange}
            >
              <option value="" disabled>
                Please select...
              </option>
              <option value="ZA">South Africa</option>
              <option value="BW">Botswana</option>
              <option value="LS">Lesotho</option>
              <option value="MW">Malawi</option>
              <option value="MZ">Mozambique</option>
              <option value="NA">Namibia</option>
              <option value="SZ">Eswatini</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="deceased_birth_address">
              Country of Birth<span className="text-danger">*Required</span>
            </label>
            <select
              className="form-select"
              id="deceased_birth_address"
              required
              value={postalAddress}
              onChange={handlePostalAddressChange}
            >
              <option value="" disabled>
                Please select...
              </option>
              <option value="ZA">South Africa</option>
              <option value="BW">Botswana</option>
              <option value="LS">Lesotho</option>
              <option value="MW">Malawi</option>
              <option value="MZ">Mozambique</option>
              <option value="NA">Namibia</option>
              <option value="SZ">Eswatini</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="cause_of_death">
              Cause of Death(Yes or No){" "}
              <span className="text-danger">*Required</span>
            </label>
            <select
              className="form-select"
              required
              id="cause_of_death"
              placeholder="Deceased Address"
              value={deceasedCaused}
              onChange={handleDeceasedCause}
            >
              <option value="">---------</option>
              <option value="YES">YES</option>
              <option value="NO">NO</option>
            </select>
          </div>
        </div>

        <div className="col-md-4 mb-3 flex">
          <div className="form-group">
            <label htmlFor="deceased_address">Deceased Address</label>
            <input
              type="text"
              className="form-control"
              id="deceased_address"
              placeholder="Deceased Address"
              value={deceasedOccupation}
              onChange={handleDeceasedOccupationChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeceasedDetails;
