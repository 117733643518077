import { SelectProps } from "antd";
import Select, { DefaultOptionType } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import instance from "../../axios/axios-instance";
import ClaimantCreate from "../../claimant/create";
import PrincipalMember from "../../principal_member/create";
import { setMemberDetail } from "../../redux/actions/MemberDetailAction";
import { fetchPrincipalMembers } from "../../redux/actions/PrincipalMemberAction";
import { MemberDetail } from "../../redux/types/MemberDetail";
import { IPrincipalMemember } from "../../redux/types/principalMember";
import { SearchClaimants } from "./components/search-claimant";
import { SearchPrincipalMembers } from "./components/search-principal-member";
import { SearchDeceasedDetails } from "./components/search-deceased-details";
import CreateDeceasedDetails from "./components/create-deceased-details";

interface ClearProps {
  ClearPropsIsInput: boolean;
  principalMember: string;
  setPrincipalMember: React.Dispatch<React.SetStateAction<string>>;
  deceasedType: string;
  setDeceasedType: React.Dispatch<React.SetStateAction<string>>;
  productType: string;
  setProductType: React.Dispatch<React.SetStateAction<string>>;
  documentationUrl: string;
  setDocumentationUrl: React.Dispatch<React.SetStateAction<string>>;
}
type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const MembershipDetail: React.FC<ClearProps> = ({
  ClearPropsIsInput,
  principalMember,
  setPrincipalMember,
  deceasedType,
  setDeceasedType,
  productType,
  setProductType,
  documentationUrl,
  setDocumentationUrl,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector((state: any) => state?.principalMember);
  const claimantData = useSelector((state: any) => state?.claimants);
  const [isPrincipalModalOpen, setIsPrincipalModalOpen] = useState(false);
  const [isClaimantModalOpen, setIsClaimantModalOpen] = useState(false);
  const [isDeceasedModalOpen, setIsDeceasedModalOpen] = useState(false);
  const [isSearchPrincipalemberOpen, setIsSearchPrincipalMemberOpen] =
    useState(false);
  const [isSearchClaimantOpen, setIsSearchClaimantOpen] = useState(false);
  const [isSearchDeceasedOpen, setIsSearchDeceasedOpen] = useState(false);

  const [claimant, setClaimant] = useState<any>("");
  const [selectedDeceased, setSelectedDeceased] = useState<any>("");
  const [informantRelation, setInformantRelation] = useState("");
  const [informantName, setInformantName] = useState("");
  const [productTypes, setProductTypes] = useState([]);
  const [selectedPrincipalMember, setSelectedPrincipalMember] = useState<
    IPrincipalMemember & { key: any }
  >();

  const [membership, setMembership] = useState({
    informantRelation,
    informantName,
    productType,
    deceasedType,
    principalMember,
    claimant,
  });

  //update the member details object when each field is updated
  useEffect(() => {
    setMembership({
      informantRelation,
      informantName,
      productType,
      deceasedType,
      principalMember,
      claimant,
    });
  }, [
    informantRelation,
    informantName,
    productType,
    deceasedType,
    principalMember,
    claimant,
  ]);

  const [value, setValue] = useState(
    principalMember
      ? data
          ?.filter((x: { id: string }) => x.id === principalMember)
          .map(
            (x: { full_names: string; id_number: string }) =>
              `${x.full_names}(${x.id_number})`
          )[0]
      : ""
  );
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [valueClaimant, setValueClaimant] = useState(
    claimant
      ? claimantData
          ?.filter((x: { id: string }) => x.id === claimant)
          .map(
            (x: { full_names: string; id_number: string }) =>
              `${x.full_names}(${x.id_number})`
          )[0]
      : ""
  );
  const [optionsClaimant, setOptionsClaimant] = useState<
    SelectProps<object>["options"]
  >([]);

  const onChangeClaimant = (data: any) => {
    setValueClaimant(
      optionsClaimant?.find((item: any) => item.value === data)?.label
    );
    setClaimant(data.value);
  };

  useEffect(() => {
    if (ClearPropsIsInput) {
      setDeceasedType("");
      setProductType("");
      setClaimant("");
      setPrincipalMember("");
      setInformantRelation("");
      setInformantName("");
      setValueClaimant("");
      setValue("");
    }
  }, [ClearPropsIsInput]);

  useEffect(() => {
    const claims:
      | React.SetStateAction<DefaultOptionType[] | undefined>
      | { value: number; label: string }[] = [];

    const claimants = claimantData;

    claimants?.forEach(
      (element: {
        name_of_claimant: string;
        surname_of_claimant: string;
        id_of_claimant: string;
        id: number;
        passport: string;
      }) => {
        const name =
          `${element.name_of_claimant} ${element.surname_of_claimant}`.trim();
        const identifier = element.id_of_claimant
          ? element.id_of_claimant
          : element.passport;
        const model = {
          value: element.id,
          label: `${name} (${identifier})`,
        };

        claims.push(model);
      }
    );

    setOptionsClaimant(claims);
  }, [claimantData]);

  const getProducts = async () => {
    try {
      const response = await instance.get(
        `api/claims/product-types/?format=json`
      );
      setProductTypes(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const prinicepals = [];
    const principaleData = data;

    for (let index = 0; index < principaleData?.length; index++) {
      const item = principaleData[index];
      const name = `${item?.full_names} ${item?.surname}`.trim();
      const identifier = item?.id_number ? item.id_number : item?.passport;
      const model = {
        value: item?.id,
        label: `${name} (${identifier})`,
      };

      prinicepals.push(model);
    }

    setOptions(prinicepals);
  }, [data]);

  useEffect(() => {
    const model: MemberDetail = {
      deceasedMemberType: deceasedType,
      productTypes: productType,
      claimant: Number(claimant?.id),
      principalMember: parseFloat(principalMember),
      informantName: informantName,
      informantRelation: informantRelation,
    };

    dispatch(setMemberDetail(model));
  }, [
    deceasedType,
    productType,
    claimant,
    principalMember,
    informantRelation,
    value,
    valueClaimant,
    claimantData,
    data,
    dispatch,
    informantName,
  ]);

  function getPrincipalMember() {
    const { full_names, surname, id_number, passport } =
      selectedPrincipalMember || {};
    return [full_names, surname, id_number, passport]
      .filter((value) => value)
      .join(" / ");
  }

  function getClaimant() {
    const { id_of_claimant, name_of_claimant, surname_of_claimant, passport } =
      claimant || ({} as any);
    return [name_of_claimant, surname_of_claimant, id_of_claimant, passport]
      .filter((value) => value)
      .join(" / ");
  }

  function getDeceasedDetails() {
    const { full_names, surname, id_number, passport } = selectedDeceased || {};
    return [full_names, surname, id_number, passport]
      .filter((value) => value)
      .join(" / ");
  }

  return (
    <div className="row">
      <div className="col-md-4 flex">
        <div className="form-group">
          <label htmlFor="deceasedMemberType">
            Select Deceased Member Type
          </label>
          <select
            className="form-control"
            id="deceasedMemberType"
            placeholder="---------"
            value={deceasedType}
            required
            name="deceasedType"
            onChange={(e) => setDeceasedType(e.target.value)}
          >
            <option value="">---------</option>
            <option value="Child">Child</option>
            <option value="Spouse">Spouse</option>
            <option value="Principal Member">Principal Member</option>
            <option value="Extended Member">Extended Member</option>
          </select>
          <span className="text-danger">* Required</span>
        </div>
      </div>
      <div className="col-md-4 flex">
        <div className="form-group">
          <label htmlFor="principalMember">Principal Member</label>
          <Select
            id="principalMember"
            style={{ width: "100%" }}
            labelInValue
            value={getPrincipalMember()}
            onClick={() => setIsSearchPrincipalMemberOpen(true)}
            notFoundContent={null}
          ></Select>
          <span className="text-danger">* Required</span>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="product_type">Product Type</label>
          <select
            className="form-control"
            id="product_type"
            placeholder="---------"
            aria-label="Product Type"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
          >
            <option value="">---------</option>
            {productTypes.map((product: any) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
          <span className="text-danger">* Required</span>
        </div>
      </div>

      <div
        className="col-md-4"
        id="claimant_principa_deceased"
        style={{
          display: deceasedType === "Principal Member" ? "block" : "none",
        }}
      >
        <div className="form-group">
          <label htmlFor="claimant" style={{ display: "block" }}>
            Claimant
          </label>
          <Select
            id="claimant"
            labelInValue
            value={getClaimant()}
            onClick={() => setIsSearchClaimantOpen(true)}
            style={{ width: "100%" }}
            notFoundContent={null}
          />
          <span className="text-danger">* Required</span>
        </div>
      </div>

{/*       <div className="col-md-4" id="deceased-details">
        <div className="form-group">
          <label htmlFor="claimant" style={{ display: "block" }}>
            Deceased
          </label>
          <Select
            id="deceased"
            labelInValue
            value={getDeceasedDetails()}
            onClick={() => setIsSearchDeceasedOpen(true)}
            style={{ width: "100%" }}
            notFoundContent={null}
          />
          <span className="text-danger">* Required</span>
        </div>
      </div> */}

      <div className="col-md-4 flex">
        <div className="form-group">
          <label htmlFor="informant_name">Informant Name</label>
          <input
            type="text"
            className="form-control"
            value={informantName}
            onChange={(e) => setInformantName(e.target.value)}
            id="informant_name"
            placeholder="Informant Name"
          />
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="relation_to_deceased">
            Informant Relation To Deceased
          </label>
          <select
            className="form-control"
            id="relation_to_deceased"
            placeholder="---------"
            aria-label="Informant Relation To Deceased"
            value={informantRelation}
            onChange={(e) => setInformantRelation(e.target.value)}
          >
            <option value="">---------</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Spouse">Spouse</option>
            <option value="Sibling">Sibling</option>
            <option value="Offspring">Offspring</option>
            <option value="Extended Family Member">
              Extended Family Member
            </option>
            <option value="In-Law">In-Law</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      {/* <div className="col-md-4 flex">
        <div className="form-group">
          <label htmlFor="docUrl">
            Supporting Documentation URL
            <span className="text-danger">* Required</span>{" "}
          </label>
          <input
            type="url"
            className="form-control"
            id="docUrl"
            placeholder="documentation url"
            value={documentationUrl}
            onChange={(e: any) => setDocumentationUrl(e.target.value)}
          />
        </div>
      </div> */}

      <SearchClaimants
        open={isSearchClaimantOpen}
        setOpen={(value) => setIsSearchClaimantOpen(value)}
        onOk={(value) => {
          setClaimant(value as any);
        }}
        onCreate={() => {
          setIsClaimantModalOpen(true);
        }}
      />
      <ClaimantCreate
        isModalOpen={isClaimantModalOpen}
        setIsModalOpen={setIsClaimantModalOpen}
      />
      <SearchPrincipalMembers
        open={isSearchPrincipalemberOpen}
        setOpen={(value) => setIsSearchPrincipalMemberOpen(value)}
        onOk={(value) => {
          setSelectedPrincipalMember(value);
          setPrincipalMember(value.key);
        }}
        onCreate={() => {
          setIsPrincipalModalOpen(true);
        }}
      />
      <PrincipalMember
        isModalOpen={isPrincipalModalOpen}
        setIsModalOpen={setIsPrincipalModalOpen}
      />
      <SearchDeceasedDetails
        open={isSearchDeceasedOpen}
        setOpen={(value) => setIsSearchDeceasedOpen(value)}
        onOk={(value) => {
          setSelectedDeceased(value as any);
        }}
        onCreate={() => {
          setIsDeceasedModalOpen(true);
        }}
      />
      <CreateDeceasedDetails
        isModalOpen={isDeceasedModalOpen}
        setIsModalOpen={setIsDeceasedModalOpen}
      />
    </div>
  );
};
const connector = connect(null, { fetchPrincipalMembers });
export default connector(MembershipDetail);
