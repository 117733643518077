import { IPMFile } from "../types/PMFile";

export const SET_PM_FILE = "SET_PM_FILE";

export interface setPMFileAction {
  type: typeof SET_PM_FILE;
  payload: IPMFile;
}

export const setPMFile = (files: IPMFile): setPMFileAction => {
  return {
    type: SET_PM_FILE,
    payload: files,
  };
};
