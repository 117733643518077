function convertClaimData(newObj: any) {
  return {
    memberDetails: {
      principalMember: newObj.principal_member,
      claimant: newObj.claimant,
      deceasedMemberType: newObj.deceased_member,
      informantName: newObj.informant_name,
      informantRelation: newObj.relation_to_deceased,
      deceasedSurname: newObj.deceased_surname,
      deceasedName: newObj.deceased_name,
      deceasedIdNumber: newObj.deceased_id_number,
      deceasedDateOfDeath: newObj.deceased_date_of_death,
      passport: newObj.passport,
      dateOfBirth: newObj.date_of_birth,
      gender: newObj.gender,
      stillBornDate: newObj.still_born_date,
      deceasedCause: newObj.deceased_cause_of_death,
      deceasedOccupation: newObj.deceased_address,
      residenceCountry: newObj.residence_country,
      birthCountry: newObj.birth_country,
      nationality: newObj.nationality,
      maritalStatus: newObj.marital_status,
      productTypes: newObj.product_type,
      ageAtDeath: newObj.deceased_date_of_death,
    },
    policy: {
      schemeName: newObj.scheme_name,
      insurerName: newObj.insurer_name,
      deceasedMember: newObj.deceased_member,
      policyNumber: newObj.policy_number,
      claimAmount: newObj.claim_amount,
      grossPremium: newObj.gross_premium,
      amountPaid: null,
      inceptionDate: newObj.inception_date,
      schemeEmail: newObj.scheme_email,
      informantName: newObj.informant_name,
      relationToDeceased: newObj.relation_to_deceased,
      dateClaimSubmission: newObj.date_claim_submission,
    },
    eventDetails: {
      sourceOfFunds: newObj.source_of_funds,
      taxRef: newObj.tax_ref,
      natureOfIncome: newObj.nature_of_income,
      placeOfDeath: newObj.place_of_death,
      nameOfHospital: newObj.name_of_hospital,
      drPathologistNurseName: newObj.dr_Pathologist_nurse_name,
      doctorPracticeNumber: newObj.doctor_practice_number,
      funeralParlour: newObj.funeral_parlour,
      chiefsName: newObj.chiefs_name,
      chiefsPhoneNumber: newObj.chiefs_phone_number,
      dha1663: newObj.DHA1663,
      dha1680: newObj.DHA1680,
      deathCertificateIssuedByUser: newObj.Death_Certificate_issued_by_User,
      HAthatIssuedDC: newObj.HA_that_Issued_DC,
    },
    banks: {
      accountHolder: newObj.account_holder,
      accountHIDNumber: newObj.account_hidnumber,
      accountHIDPassport: newObj.account_hidpassport,
      accountHDateOfBirth: newObj.account_hdate_of_birth,
      accountHGender: newObj.account_hgender,
      bankName: newObj.bank_name,
      accountType: newObj.account_type,
      accountNumber: newObj.account_number,
      claimStatus:  newObj.claim_status,
      notes: newObj.notes,
      isReviewed: newObj.is_reviewed,
      isPaid: newObj.is_paid,
      isUploaded: newObj.is_uploaded,
      isHeld: newObj.is_held,
      paymentStatus: newObj.payment_status,
      dateClaimSubmission: newObj.date_claim_submission,
      claimClosure: newObj.claim_closure,
      bankStatement: newObj.bank_statement,
      popReference: newObj.pop_reference,
      modified: newObj.modified,
      created: newObj.created,
    },
    PMFiles: {
      supportFile: null,
      documentation: newObj.documentation
    },
    is_reviewed: newObj?.is_reviewed,
  };
}

export default convertClaimData;
