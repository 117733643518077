// reducer.ts
import { SET_DATA_ERROR, CLEAR_DATA_ERROR } from './../actions/DataErrorActions';


const initialState: any[] = [];

const dataErrorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_DATA_ERROR:
      return [...state, action.payload];
    case CLEAR_DATA_ERROR:
      return [];
    default:
      return state;
  }
};

export default dataErrorReducer;
