// rootReducer.ts
import { combineReducers } from "redux";
import ClaimReducer from "./reducers/ClaimReducers";
import PrincipalMemberReduce from "./reducers/PrincipalMemberReducer";
import PolicyReducer from "./reducers/PolicyReducers";
import EventDetailReducer from "./reducers/EventDetailReducer";
import MemberDetailReducer from "./reducers/MemberDetailReducer";
import DeceasedDetailsReducer from "./reducers/DeceasedDetailsReducer";
import bankDetailReducer from "./reducers/bankDetailReducer";
import PMFileReducer from "./reducers/PMFileReducer";
import ClaimantReducer from "./reducers/ClaimantReducer";
import appMessagesReducer from "./reducers/appMessagesReducer";
import dataErrorReducer from "./reducers/DataErrorReducer";

interface CaptureRootState {
  policy: ReturnType<typeof PolicyReducer>;
  eventDetails: ReturnType<typeof EventDetailReducer>;
  memberDetails: ReturnType<typeof MemberDetailReducer>;
  deceasedDetails: ReturnType<typeof DeceasedDetailsReducer>;
  bankDetails: ReturnType<typeof bankDetailReducer>;
  PMFiles: ReturnType<typeof PMFileReducer>;
}

const captureReducer = combineReducers<CaptureRootState>({
  policy: PolicyReducer,
  eventDetails: EventDetailReducer,
  memberDetails: MemberDetailReducer,
  deceasedDetails: DeceasedDetailsReducer,
  bankDetails: bankDetailReducer,
  PMFiles: PMFileReducer,
});

// Import other reducers
export interface RootState {
  claims: ReturnType<typeof ClaimReducer>;
  principalMember: ReturnType<typeof PrincipalMemberReduce>;
  captureClaims: ReturnType<typeof captureReducer>;
  claimants: ReturnType<typeof ClaimantReducer>;
  appMessages: ReturnType<typeof appMessagesReducer>;
  dataError: ReturnType<typeof dataErrorReducer>;
  policy: ReturnType<typeof PolicyReducer>;
  // Add other slices as needed
}

const rootReducer = combineReducers<RootState>({
  claims: ClaimReducer,
  principalMember: PrincipalMemberReduce,
  captureClaims: captureReducer,
  claimants: ClaimantReducer,
  appMessages: appMessagesReducer,
  dataError: dataErrorReducer,
  policy: PolicyReducer,
});

export default rootReducer;
