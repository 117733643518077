import React, { useState } from 'react';
import { Button, Modal, Input, Table, Space, Flex } from 'antd';
import moment from 'moment';
import instance from '../../axios/axios-instance';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../redux/actions/AppMessageActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

type AppDispatch = ThunkDispatch<any, unknown, AnyAction>;

const SearchPolicy = ({ visible, onOk, onCancel }:
    { visible: boolean; onOk: any; onCancel: any; }) => {
    const [query, setQuery] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    function performSearch() {
        instance.get(`/api/policies/?action=search_policy_details&search_key=${query}`)
            .then((response) => {
                if (response.data.data.length > 0) {
                    setDataSource(
                        response.data.data.map((policy: any) => {
                            return {
                                key: policy.id,
                                ...policy,
                            };
                        })
                    );
                } else {
                    dispatch(setErrorMessage(response.data.message))
                }
            });
    }

    const handleSelectPolicy = (policy: any) => {
        onOk(policy);
        onCancel()
    };

    const columns = [
        { key: "policy_number", dataIndex: "policy_number", title: "Policy Number" },
        { key: "member", dataIndex: ["member", "first_names"], title: "Member" },
        { key: "scheme", dataIndex: ["scheme", "scheme_name"], title: "Scheme" },
        { key: "policy_status", dataIndex: ["policy_status", "policy_status"], title: "Policy Status" },
        {
            key: "policy_start_date",
            dataIndex: "policy_start_date",
            title: "Policy Start Date",
            render: (date: any) => moment(date).format('YYYY-MM-DD')
        },
    ];

    console.log("selected policies", selectedPolicy)

    return (
        <Modal
            visible={visible}
            title="Search for a Policy"
            okText="Select"
            cancelText="Cancel"
            width={720}
            onCancel={() => {
                onCancel();
                setSelectedPolicy([]);
                setDataSource([]);
            }}
            onOk={() => {
                handleSelectPolicy(selectedPolicy);
                onCancel();
                setSelectedPolicy([]);
                setDataSource([]);
            }}
        >
            <Flex justify='space-between' >
                <Space direction="horizontal">
                    <Input
                        value={query}
                        onChange={(e) => setQuery(e.target.value.trim())}
                        placeholder="Enter search to begin"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                performSearch();
                            }
                        }}
                    />
                    <Button onClick={performSearch}>Search</Button>
                </Space>
            </Flex>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record: any) => record?.id}
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selectedPolicy.map((policy: any) => policy?.id),  // Highlight the selected rows
                    onChange: (selectedRowKeys: any) => {
                        console.log("selected", selectedRowKeys)
                        // Find the selected policies from the data source
                        const selectedPolicies = dataSource.filter((policy: any) => selectedRowKeys.includes(policy?.id));
                        setSelectedPolicy(selectedPolicies);
                    },
                }}
            />
        </Modal>
    );
};
export default SearchPolicy;